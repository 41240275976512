const { WS_ACTION } = require('../../../fo-common/src/constants');

export default class WSClient {
    constructor (url, opt = {}) {
        // The url can be a full URL or partial.
        // If partial, convert the HTTP origin for the root.
        if (url.startsWith('/')) {
            url = `${location.origin.replace(/^http/, 'ws')}${url}`
        }

        this._conf = {
            url, // Websocket URL to connect to.
            listeners: new Map(), // Holds registered listeners.
            isOpen: false, // The state of the websocket based on the open/close events.
            reconnect: opt.reconnect === 0 || opt.reconnect === false // Attempt reconnection if set. Value as seconds.
                ? undefined
                : isNaN(opt.reconnect) ? 1000 : opt.reconnect * 1000,
            reTimer: null
        };
    }

    open () {
        if (this._conf.reTimer) clearTimeout(this._conf.reTimer);
        const ws = new WebSocket(this._conf.url);
        ws.addEventListener('open', this.onOpen.bind(this));
        ws.addEventListener('close', this.onClose.bind(this));
        ws.addEventListener('message', this.onMessage.bind(this));
        this._ws = ws;
    }

    close () {
        ws.close();
    }

    reconnect () {
        // Try to reconnect every second.
        if (this._conf.reconnect) {
            if (this._conf.reTimer) clearTimeout(this._conf.reTimer);
            this._conf.reTimer = setTimeout(() => {
                this.open();
            }, this._conf.reconnect || 1000);
        }
    }

    send (topic, message) {
        this._ws.send(WSClient.getStringValue({
            A: WS_ACTION.SEND,
            T: topic,
            M: [1, message]
        }));
    }

    sendJson (message) {
        this._ws.send(WSClient.getStringValue(message));
    }

    onMessage (evt) {
        // const data = typeof evt.data === 'string' && (evt.data[0] === '{' || evt.data[0] === '[') ? JSON.parse(evt.data) : evt.data;
        const data = evt.data[0] === '{' || evt.data[0] === '[' ? JSON.parse(evt.data) : evt.data;
        if (Array.isArray(data) && data[0] === 1) {
            this._id = data[1];
            this.join(data[1]);
            return;
        }
        // this.trigger(WSClient.EVENTS.MESSAGE, Array.isArray(data) ? data[1] : data);
        this.trigger(WSClient.EVENTS.MESSAGE, data);
    }

    join (topic) {
        this.sendJson({ 'A': WS_ACTION.JOIN, 'T': topic });
    }

    leave (topic) {
        this.sendJson({ 'A': WS_ACTION.LEAVE, 'T': topic });
    }

    isOpen () {
        return this._conf.isOpen;
    }

    on (eventName, callback, checkPast = false) {
        this._conf.isOpen = true;
        // Many.
        // this._conf.listeners.has(eventName) || this._conf.listeners.set(eventName, []);
        // this._conf.listeners.get(eventName).push(callback);
        // One.
        this._conf.listeners.set(eventName, callback);
    }

    off (eventName/* , callback */) {
        this._conf.isOpen = false;
        // Many.
        /* if (!callback) {
            this._conf.listeners.delete(eventName);
        }
        else {
            let listeners = this._conf.listeners.get(eventName);
            if (listeners) {
                this._conf.listeners.set(eventName, listeners.filter((value) => !(value === callback)));
            }
        } */
        // One.
        if (this._conf.listeners.has(eventName)) this._conf.listeners.delete(eventName);
    }

    trigger (eventName, evtArg) {
        // Many.
        /* let listeners = this._conf.listeners.get(eventName);
        if (listeners) {
            listeners.forEach(listener => {
                listener(evtArg);
            });
        } */
        // One.
        if (this._conf.listeners.has(eventName)) this._conf.listeners.get(eventName)(evtArg);
    }

    onOpen (evt) {
        this.trigger(WSClient.EVENTS.OPEN, evt);
    }

    onClose (evt) {
        // 1001 - CLOSE_GOING_AWAY - leaving (tab closed)
        // 1005 - CLOSED_NO_STATUS - close expected but got none
        // 1006 - CLOSE_ABNORMAL
        if (this._conf.reTimer) clearTimeout(this._conf.reTimer);
        if (evt.code !== 1000 && evt.code !== 1001 && evt.code !== 1005) {
            this.reconnect();
            return;
        }
        this.trigger(WSClient.EVENTS.CLOSE, evt);
    }

    getEvents () {
        return WSClient.EVENTS;
    }

    static getStringValue (v) {
        switch (typeof v) {
            case 'string': return v;
            case 'object': return JSON.stringify(v);
            default: return `${v}`;
        }
    }

    static get EVENTS () {
        return {
            OPEN: 'open',
            CLOSE: 'close',
            MESSAGE: 'message',
        };
    }
}