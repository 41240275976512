import Tesseract from 'tesseract.js';

export default {
    Design: (FOUI) => ({
        Type: 'LayoutGrid',
        Props: {
            Name: 'Application',
            Class: 'foui-pa-0',
            Width: 'auto',
            Height: '100%',
            Columns: '1fr 500px',
            Rows: '36px 1fr 40px',
            GapCols: 3,
            GapRows: 3,
            Cells: [
                { Location: '1 / 1', Controls: [{ Type: 'Toolbar', Props: { Class: 'foui-pa-2', Controls: [
                    { Type: 'Label', Props: { Name: 'AppNameLabel', Text: 'Get List', Icon: 'More2', Tip: 'View name and path', Class: 'foui-h4 foui-text-input foui-text-nowrap foui-ml-10' } },
                    { Type: 'Button', Props: { Name: 'RecipesButton', Text: 'Recipes', Icon: 'Cupcake3', Class: 'inset-focus foui-text-nowrap foui-ml-10 foui-mr-4', Events: { Click: 'onSignIn' } } },
                    { Type: 'Button', Props: { Name: 'FavButton', Text: 'Favourites', Icon: 'Heart', Class: 'inset-focus foui-text-nowrap foui-mr-10', Events: { Click: 'onSignIn' } } },
                ] } }] },
                { Location: '1 / 2', Controls: [{ Type: 'Toolbar', Props: { Class: 'foui-pa-2', Controls: [
                    { Type: 'Button', Props: { Name: 'SelectListButton', Text: 'Select/New List', Icon: 'FolderGroup', Class: 'inset-focus foui-text-nowrap foui-ml-10 foui-mr-10', Events: { Click: 'onSignIn' } } },
                    { Type: 'Spacer', Props: { Direction: 'horizontal' } },
                    { Type: 'Button', Props: { Name: 'ClearListButton', Text: 'Clear', Icon: 'Clear', Class: 'inset-focus foui-text-nowrap foui-ml-10 foui-mr-4', Events: { Click: 'onSignIn' } } },
                    { Type: 'Button', Props: { Name: 'DeleteListButton', Text: 'Delete', Icon: 'Delete', Class: 'inset-focus foui-text-nowrap foui-mr-10', Events: { Click: 'onSignIn' } } },
                ] } }] },
                { Location: '2 / 1', Controls: [{ Type: 'LayoutGrid', Props: { Name: 'ProjectArea', Columns: '1fr', Rows: '1fr', GapCols: 3, GapRows: 3,
                    Cells: [
                        { Location: '1 / 1', Controls: [{ Type: 'PointerDraw', Props: { Name: 'PointerDrawing', Height: '100%', Width: '100%', Events: { Change: 'onDrawChange' } } }] },
                    ]
                } }] },
                { Location: '2 / 2 / 4 / auto', Controls: [{ Type: 'LayoutGrid', Props: { Name: 'WorkspaceArea', Columns: '1fr', Rows: '1fr', GapCols: 3, GapRows: 3,
                    Cells: [
                        { Location: '1 / 1', Controls: [{ Type: 'List', Props: { Name: 'ItemDisplayList', Value: '', Items: ':ItemList', Bordered: false, FieldKey: 'id', FieldText: 'text', ItemRenderer: '@onItemRender', Actions: ':ItemActions', Events: { Action: 'onListAction' } } }] },
                    ]
                } }] },
                /*{ Location: '2 / 3', Controls: [{ Type: 'LayoutGrid', Props: { Name: 'PropertiesArea', Columns: '1fr', Rows: '16px 32px 32px 1fr', GapCols: 3, GapRows: 3,
                    Cells: [
                        { Location: '1 / 1', Controls: [{ Type: 'HeaderLine', Props: { Text: 'Properties' } }] },
                    ]
                } }] },*/
                { Location: '3 / 1', Controls: [{ Type: 'Toolbar', Props: { Class: 'foui-pa-2', Controls: [
                    { Type: 'Button', Props: { Name: 'ClearDrawButton', Text: 'Clear', Icon: 'Clear', Class: 'inset-focus foui-text-nowrap foui-ml-10 foui-mr-10', Events: { Click: 'onDrawClear' } } },
                    { Type: 'Splitter', Props: { Direction: 'vertical' } },
                    { Type: 'Button', Props: { Name: 'UndoDrawButton', Text: 'Undo', Icon: 'Undo', Class: 'inset-focus foui-text-nowrap foui-ml-10 foui-mr-4', Events: { Click: 'onDrawUndo' } } },
                    { Type: 'Button', Props: { Name: 'RedoDrawButton', Text: 'Redo', Icon: 'Redo', Class: 'inset-focus foui-text-nowrap foui-mr-10', Events: { Click: 'onDrawRedo' } } },
                    { Type: 'Splitter', Props: { Direction: 'vertical' } },
                    { Type: 'Spacer', Props: { Size: 10 } },
                    // { Type: 'Label', Props: { Name: 'DrawTextLabel', Text: '^^^ Write something ^^^', Tip: 'Username', Class: 'foui-h3 foui-text-nowrap foui-text-center foui-stretch-x foui-block' } },
                    { Type: 'Text', Props: { Name: 'DrawTextLabel', Value: ':ItemTextResult', Placeholder: '^^^ Write something above ^^^', Bordered: false, Class: 'foui-h3foui-text-center' } },
                    { Type: 'Spacer', Props: { Size: 10 } },
                    { Type: 'Splitter', Props: { Direction: 'vertical' } },
                    { Type: 'Button', Props: { Name: 'AddDrawButton', Text: 'Add', Icon: 'Plus', Class: 'inset-focus foui-text-nowrap foui-ml-10 foui-mr-10', Events: { Click: 'onDrawAdd' } } },
                ] } }] },
            ]
        }
    }),
    Data: {
        LabelText: 'Kelly',
        Tester: {
            Name: 'Kelly Belly',
            Age: 20
        },
        ItemTextResult: '',
        ItemList: [],
        ItemActions: [
            { Id: 'done', Icon: 'Success', Color: '#00CC33' },
            { Id: 'delete', Icon: 'Delete', Color: '#DD0000' },
        ],
        DisplayName: 'Guest',
    },
    Code: {
        init () {
            // Bind to some useful events.
            this.FOUI.PubSub.subscribe(this.FOUI.Const.PUB_SUB.AUTH_CHANGED, this.code.onAuthChanged.bind(this));

            this.meta.itemActiveRender = this.FOUI.Util.template`<div class="foui-pa-5">${'text'}<span class="foui-o-5 f-m foui-pl-10">${'dt'}</span></div>`;
            this.meta.itemDoneRender = this.FOUI.Util.template`<div class="foui-pa-5 f-strike">${'text'}<span class="foui-o-5 f-m foui-pl-10">${'dt'}</span></div>`;
            this.data.ItemList = [
                { id: '1', text: 'Bread', icon: '', done: false, dt: new Date() },
                { id: '2', text: 'Milk', icon: '', done: false, dt: new Date() },
                { id: '3', text: 'Cheese', icon: '', done: false, dt: new Date() },
                { id: '4', text: 'Tomatoes', icon: '', done: false, dt: new Date() },
                { id: '5', text: 'Lettuce', icon: '', done: false, dt: new Date() },
            ];

            this.code.initTesseract(); // .then(() => {});

            this.data.WorkspaceItems = [
                { id: 'root', name: 'Workspace', link: '', icon: 'Project2', open: true },
                { id: 'activity', name: 'Activity Logs', link: 'root', icon: 'FolderGroup', open: true },
                { id: 'connections', name: 'Connections', link: 'root', icon: 'FolderGroup', open: true },
                { id: 'data_views', name: 'Data Views', link: 'root', icon: 'FolderGroup', open: true },
                { id: 'project', name: 'Project Designer', link: 'root', icon: 'FolderGroup', open: true },
                { id: 'services', name: 'Services', link: 'root', icon: 'FolderGroup', open: true },
            ];
        },
        async onAuthChanged (msg, data) {
            if (data) {
                this.data.DisplayName = 'TODO!';
                // const path = 'http://localhost:3777/api/session/profile';
                /* const path = `http://localhost:3777/api/users/tCKQEfEJgwtQ`;
                const res = await this.FOUI.Http.get(path);
                console.log(res.data); */

                /*const aConf = await this.FOUI.Auth.getOidcConfig();
                console.log(aConf);
                const res = await this.FOUI.Http.get(aConf.userinfoEndpoint, {
                    headers: {
                        // Authorization: ''
                    }
                });
                console.log(res.data);*/
                /* const userInfo = await getIdTokenClaims();
                setUser(userInfo ?? { sub: 'N/A', username: 'N/A' }); */
            }
            else this.data.DisplayName = 'Guest';
        },
        onSignIn () {
            this.FOUI.Auth.signIn('http://localhost:1400/callback');
        },
        async initTesseract () {
            const { createWorker, setLogging } = Tesseract;
            this.meta.tesseWorker = await createWorker({
                workerPath: new URL(location.origin + '/tesseract/worker.min.js', import.meta.url), // new URL('worklet.js', import.meta.url)
                langPath: new URL(location.origin + '/tesseract/lang-pack', import.meta.url),
                corePath: new URL(location.origin + '/tesseract/tesseract-core.wasm.js', import.meta.url),
                /*logger: m => {
                    m = {
                        "workerId": "Worker-0-49031",
                        "status": "initialized api",
                        "progress": 1,
                        "userJobId": "Job-2-1f4d3"
                    }
                },*/
            });
            await this.meta.tesseWorker.load();
            await this.meta.tesseWorker.loadLanguage('eng');
            await this.meta.tesseWorker.initialize('eng');
            setLogging(false);
        },
        clickMe () {
            console.warn(this.data.LabelText);
            // console.log(this);
            this.code.myAlert();
        },
        myAlert () {
            // alert('Alert has been called!');
            console.log(this.data.toJSON());
            // this.$name.Btn1.props.Fit = !this.$name.Btn1.props.Fit;
        },
        onItemRender (idx, item) {
            // console.log(idx, item);
            const o = Object.assign({}, item);
            o.dt = o.dt.toISOString();
            if (this.meta.itemActiveRender) {
                return o.done
                    ? this.meta.itemDoneRender(idx, o)
                    : this.meta.itemActiveRender(idx, o);
            }
            // else return o.text;
        },
        onDrawClear () {
            this.$name.PointerDrawing.clear();
        },
        onDrawUndo () {
            this.$name.PointerDrawing.undo();
        },
        onDrawRedo () {
            this.$name.PointerDrawing.redo();
        },
        onDrawAdd () {
            const itemText = this.data.ItemTextResult;
            if (!itemText) return;
            this.data.ItemList.push({ id: `${this.data.ItemList.length + 1}`, text: itemText, icon: '', done: false, dt: '' });
            // this.data.ItemList = [{ id: `${this.data.ItemList.length + 1}`, text: itemText, icon: '', done: false, dt: '' }];
            this.data.ItemTextResult = '';
        },
        onDrawChange (evt) {
            // console.log(evt.detail);
            clearTimeout(this.meta.waitTimer);
            this.meta.waitTimer = setTimeout(async() => {
                const { data: { text } } = await this.meta.tesseWorker.recognize(this.$name.PointerDrawing.ui.canvas);
                this.data.ItemTextResult = text;
            }, 600);
            // await this.meta.tesseWorker.terminate();
        },
        onListAction (evt) {
            console.log(evt.detail);
            switch (evt.detail.Action) {
                case 'done': {
                    this.data.ItemList[evt.detail.Index].done = !this.data.ItemList[evt.detail.Index].done;
                    /*const item = this.data.ItemList.splice(evt.detail.Index, 1);
                    item.done = !item.done;
                    setTimeout(() => {
                        this.data.ItemList.push(item);
                    }, 100);*/
                    break;
                }
                case 'delete':
                    this.data.ItemList.splice(evt.detail.Index, 1);
                    break;
            }
            console.log(this.data.ItemList);
        },
    }
}
