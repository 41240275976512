import CoreBase from './base';
import UI from '../../util/ui';
import Util from '../../util/util';

export default class CoreText extends CoreBase {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'CoreText';
        definition.Props.Tag = 'input';
        super(definition, CoreText._DEF.Properties);
    }

    /* init () {
        //
    } */

    attachEvents () {
        // Bind to this control.
        this.handlers.onInput = this.onInput.bind(this);
        this.handlers.onValueChange = this.onValueChange.bind(this);
        // Attach the listener(s).
        UI.on(this.elem, 'input', this.handlers.onInput, true);
        UI.on(this.elem, 'change', this.handlers.onValueChange, true);
    }

    onChange (changes) {
        if (changes.has('Value')) {
            const v = changes.get('Value');
            const value = Util.checkForBoundedValue(v, this);
            if (this.props.Type === 'checkbox' || this.props.Type === 'radio') UI.attr(this.elem, { checked: value }); // this.props.Value = this.elem.checked;
            else this.elem.value = this.props.Value;
        }
    }

    onInput () {
        // Update the property.
        this.props.Value = this.elem.value;
    }

    onValueChange () {
        // Update the property.
        if (this.props.Type === 'checkbox') this.props.Value = this.elem.checked;
        else this.props.Value = this.elem.value;
    }
}

// Control property definitions.
Object.defineProperty(CoreText, '_DEF', {
    value: {
        Group: 'Core Controls',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.', Attr: 'id' },
            Class: { Default: undefined, Group: 'Style', Label: 'Style Class', Type: 'Text', Note: 'Style class name(s) to visually format the control.', Attr: 'class' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.', Attr: 'class' },
            Disabled: { Default: undefined, Group: 'General', Label: 'Disabled', Type: 'Flag', Note: 'Disables the control.', Attr: 'disabled' },
            Group: { Default: undefined, Group: 'General', Label: 'Group', Type: 'Text', Note: 'The name of the group this option belongs to. Options are toggled within the same group.', Attr: 'name' },
            Max: { Default: undefined, Group: 'General', Label: 'Maximum', Type: 'Number', Note: 'The maximum number allowed (for number and slider types).', Attr: 'max' },
            Min: { Default: undefined, Group: 'General', Label: 'Minimum', Type: 'Number', Note: 'The minimum number allowed (for number and slider types).', Attr: 'min' },
            Multiple: { Default: undefined, Group: 'General', Label: 'Multiple', Type: 'Flag', Note: 'Indicates multiple email addresses allowed, comma separated.', Attr: 'multiple' },
            Placeholder: { Default: undefined, Group: 'General', Label: 'Placeholder', Type: 'Text', Note: 'Describe the expected value.', Attr: 'placeholder' },
            ReadOnly: { Default: undefined, Group: 'General', Label: 'Read Only', Type: 'Flag', Note: 'Prevents input.', Attr: 'readonly' },
            Step: { Default: undefined, Group: 'General', Label: 'Step', Type: 'Number', Note: 'Increase or decrease by this value (for number and slider types).', Attr: 'step' },
            TabIndex: { Default: undefined, Group: 'General', Label: 'Tab Index', Type: 'Number', Note: 'Indicates that the control can be focused.', Attr: 'tabindex' },
            Tip: { Default: undefined, Group: 'General', Label: 'Tool Tip', Type: 'Any', Note: 'Information related to the control.', Attr: 'title' },
            Type: { Default: 'text', Group: 'General', Label: 'Type', Type: 'Text', Note: 'Input type.', Attr: 'type' }, // text | password | email (multiple) | search | tel | url | number (min, max, step) | slider (min, max, step) | datetime-local | month | time | week | color
            Value: { Default: '', Group: 'General', Label: 'Value', Type: 'Text', Note: 'Text value.', Attr: 'value' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
