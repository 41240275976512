// NPM.
import axios from 'axios';
// import faker from 'faker';
import LogtoClient from '@logto/browser';
import PubSub from 'pubsub-js';
// Local.
// import AlertEngine from './util/alertEngine';
import Controls from './control';
import EventConstants from './constants/event-constants';
import PopupEngine from './util/popupEngine';
import Time from './util/time';
import UI from './util/ui';
import Util from './util/util';
import { VERSION } from './version';
import Watch from './util/watch';
import WSClient from './util/wsClient';

// Themes.
import themeLight from './theme/default/variables/light';
import themeDark from './theme/default/variables/dark';
import themeBlueprint from './theme/default/variables/blueprint';
import themeAirLight from './theme/default/variables/air-light';
import themeAirDark from './theme/default/variables/air-dark';
import themeAirDarkClassic from './theme/default/variables/air-dark-classic';

// Icons.
import lineMonoDark from '../src/svg/LineMonoDark.js';
// import logo from './assets/images/FOLogo.svg';

const controlRefs = Symbol('crs');

export default class App {
    /**
     * Creates an instance of a FireOcto App.
     *
     * @param {Any} elem A string ID for the element or and element instance.
     * @param {Object} options Application options.
     */
    constructor (elem, options) {
        if (!elem) throw Error('An element ID or instance is required to create an App.');
        this.elem = typeof elem === 'string' ? UI.find(document.body, elem) : elem;

        this._options = options;

        themeLight.svg.icon = lineMonoDark;
        themeDark.svg.icon = lineMonoDark;
        themeAirLight.svg.icon = lineMonoDark;
        themeAirDark.svg.icon = lineMonoDark;

        window.OS_Name = UI.getOSType();
        this.FOUI = {
            IDX: 0, // Counter index that can be used for local uniqueness.
            Controls, // All framework controls available for use.
            Util, // Util helper.
            UI, // UI helper.
            Watch, // Object watcher.
            Themes: { // All loaded themes.
                light: themeLight,
                dark: themeDark,
                blueprint: themeBlueprint,
                airLight: themeAirLight,
                airDark: themeAirDark,
                airDarkClassic: themeAirDarkClassic,
            },
            ThemeKey: 'dark', // Util.getLS('FO_THEME') || 'dark', // light | dark // Display theme key.
            Languages: options.Languages, // All the loaded language packs.
            Lang: Util.getLS('FO_LANG') || 'en', // Text language key.
            Meta: {},
            Const: EventConstants,
            // Auth: new Auth({ mock: true }),
            PubSub, // Local pub/sub engine.
            State: Watch({}), // Global state that can be used as a data store etc.
            Views: {}, // Loaded views.
            Version: VERSION, // Framework version.
        };

        // References to instantiated controls by their Name/id.
        this.FOUI[controlRefs] = new Map();
        // Helper functions for the "hidden" control reference list.
        this.FOUI.addControl = (id, ctrl) => {
            return this.FOUI[controlRefs].set(id, ctrl);
        };
        this.FOUI.getControl = (id) => {
            return this.FOUI[controlRefs].get(id);
        };
        this.FOUI.hasControl = (id) => {
            return this.FOUI[controlRefs].has(id);
        };
        this.FOUI.removeControl = (id) => {
            return this.FOUI[controlRefs].delete(id);
        };

        if (options.Http) { // The HTTP handler.
            this.FOUI.Http = axios.create({
                baseURL: options.Http.Path || 'http://localhost:9020',
                timeout: options.Http.Timeout || 1000,
                headers: options.Http.Headers || { 'X-Foui': '0.0.1' }
            });

            // Add a response interceptor.
            this.FOUI.Http.interceptors.response.use(response => {
                if (response.data.d !== undefined) {
                    Time.checkDates(response.data.d); // Checks for string dates and convert them to JS dates.
                }
                return response;
            }, error => Promise.reject(error));
        }

        if (options.Ws) { // The WebSocket handler.
            this.FOUI.Ws = new WSClient(options.Ws.Path);
        }

        // Init the auth module.
        /* this.FOUI.Auth = new LogtoClient({
            endpoint: 'http://localhost:3777',
            appId: 'Q67cS1oZwuPWaX4Sx2i8e',
        }); */

        // window.alert = AlertEngine.show;
        // window.dialog = PopupEngine.dialog;
        window.popup = PopupEngine.popup;
    }

    async init () {
        // Load the views used by the project.
        /* UI.storeView([
            mainViewDef,
            form,
            // workspaceNew,
        ]); */

        if (typeof this.elem === 'string') this.elem = UI.find(document, this.elem);
        const appElem = this.elem;

        // Bind events to the document to handle popups etc.
        /* UI.on(document, 'click', () => {
            if (this.FOUI.Meta.popup) {
                const meta = this.FOUI.Meta;
                meta.popup.detachEvents();
                meta.popup.destroy();
                meta.popup.elem.remove();
                delete meta.popup;
            }
        }, true); */

        console.table({ Theme: this.FOUI.ThemeKey, Language: this.FOUI.Lang, Version: VERSION });

        const view = this._options.StartView;
        const design = view.Design(this.FOUI);
        view.Data = Watch(view.Data);

        this.app = new Controls[design.Type]({
            Parent: appElem,
            Props: design.Props,
            Data: view.Data,
            Code: view.Code,
            CodeBindThis: true, // Binds the functions in code to this control instance.
            $name: {},
        });

        /* const data = Watch({
            LabelText: 'Kelly',
            Tester: {
                Name: 'Kelly Belly',
                Age: 20
            },
            controls: [
                { Type: 'Label', Props: { Text: ':LabelText', Class: 'foui-float-left' } },
                { Type: 'Label', Props: { Text: 'I am 2', Class: 'foui-float-right' } },
            ],
            DisplayName: 'Guest',
            WorkspaceItems: [],
            ControlList: [],
            PropData: {},
            SchemaData: {},
            DesignModeOn: false,
            ViewWidth: '780', // 100% 780 auto
            ViewHeight: '750', // 100% 750 auto
            GridSizeBy: 10,
            GridGapColumns: 10,
            GridGapRows: 10,
            ShowGridCells: true,
            ShowGridSizers: false,
            EditViewName: 'DemoView.design',
            EditView: [],
            WorkViewTypes: [
                { id: 1, name: 'Backend', type: 'Server Code' },
                { id: 2, name: 'Frontend', type: 'Browser Code' },
            ],
            NameList: ['James', 'Robert', 'John', 'Michael', 'David', 'William', 'Richard', 'Joseph', 'Thomas', 'Charles', 'Christopher', 'Daniel', 'Matthew', 'Anthony', 'Mark', 'Donald', 'Steven', 'Paul', 'Andrew', 'Joshua', 'Kenneth', 'Kevin', 'Brian', 'George', 'Timothy', 'Ronald', 'Edward', 'Jason', 'Jeffrey', 'Ryan', 'Jacob', 'Gary', 'Nicholas', 'Eric', 'Jonathan', 'Stephen', 'Larry', 'Justin', 'Scott', 'Brandon', 'Benjamin', 'Samuel', 'Gregory', 'Alexander', 'Frank', 'Patrick', 'Raymond', 'Jack', 'Dennis', 'Jerry', 'Tyler', 'Aaron', 'Jose', 'Adam', 'Nathan', 'Henry', 'Douglas', 'Zachary', 'Peter', 'Kyle', 'Ethan', 'Walter', 'Noah', 'Jeremy', 'Christian', 'Keith', 'Roger', 'Terry', 'Gerald', 'Harold', 'Sean', 'Austin', 'Carl', 'Arthur', 'Lawrence', 'Dylan', 'Jesse', 'Jordan', 'Bryan', 'Billy', 'Joe', 'Bruce', 'Gabriel', 'Logan', 'Albert', 'Willie', 'Alan', 'Juan', 'Wayne', 'Elijah', 'Randy', 'Roy', 'Vincent', 'Ralph', 'Eugene', 'Russell', 'Bobby', 'Mason', 'Philip', 'Louis'],
            Genders: [{ id: 'male', text: 'Male' }, { id: 'female', text: 'Female' }],
            TestPropControls: [
                { id: 1, name: 'UserLogin', type: 'View' },
                { id: 2, name: 'UsernameLabel', type: 'Label' },
                { id: 3, name: 'UsernameInput', type: 'Text' },
                { id: 4, name: 'PasswordLabel', type: 'Label' },
                { id: 5, name: 'PasswordInput', type: 'Text' },
            ],
            TableData: [],
            TableColumns: [
                /!* { Field: 'Key', Header: [{ Header: '#', Align: 'Center', RowSpan: 2, Width: '60px' }, undefined], Align: 'End', Renderer (column, item) { // Header: { Header: 'Key' }
                    // console.log(Util); UI.create('tr', { 'data-idx': idx, 'data-key': rec.Key });
                    return `<span style='color:#999;font-size:smaller;'>${item.Key}</span>`;
                } }, *!/
                { Field: 'FirstName', Color: 'rgb(251, 9, 125)', Header: [
                    { Header: 'Customer Information', ColumnSpan: 'all', Align: 'Center', Width: '100%' },
                    { Header: 'Name', Filter: '1', Sort: 'no', Width: '50%' }
                ] },
                // { Field: 'LastName' }, // , Class: 'foui-text-primary'
                { Field: 'LastName', Header: [undefined, { Header: 'Surname', Filter: '1', Sort: 'no', Width: '50%' }] },
                { Field: 'Company', Header: [undefined, { Header: 'Company', Filter: '1', Sort: 'no', Width: '200px' }] },
                { Field: 'Tag', Header: [undefined, 'Tag'], Width: '100px', Back: 'rgb(218, 179, 32, 0.1)', Formatter (item) { // Header: 'Tag'
                    return item.Tag === undefined ? '-' : item.Tag;
                } },
                { Field: 'Age', Header: [undefined, { Header: 'Age', Filter: '1', Sort: 'no', Width: '100px' }], Align: 'End', PreRender (column, item) { // Header: 'Age'
                    if (item.Age < 30) {
                        column.Back = 'rgb(32, 79, 198, 0.2)';
                        column.Color = 'rgb(32, 109, 218)';
                        item.Age = `Accepted ${item.Age}`;
                    }
                    else if (item.Age >= 50) {
                        column.Back = 'rgba(201, 9, 105, 0.2)';
                        column.Color = 'rgb(251, 9, 125)';
                        item.Age = `!!! ${item.Age}`;
                    }
                    return { column, item };
                } },
                // Align
                // AlignVertical
                // Back
                // Class
                // Color
                // DisplayAs (Slider, Dot, Arrow, Bar, SparkLine, etc)
                // EditControl (Number)
                // EditRenderer
                // Field
                // Formatter
                // Header (can be object like Column)
                // PreRender
                // Renderer
                // Width
            ],
            TableCustom: [
                { Columns: [
                    { ColumnSpan: 'all', Value: 'Column field names derived from the file - Select for import and rename if needed', Class: 'foui-h6 foui-dull', Padding: '2px 5px' }
                ] },
                /!* { Columns: [
                    { Value: 'Name', EditControl: { Type: 'Text', Checked: true } },
                    { Value: 'Surname', EditControl: { Type: 'Text', Checked: false } },
                    { ColumnSpan: 'all', Value: '' },
                ] },
                { Columns: [
                    { ColumnSpan: 'all', Value: 'Column value type conversion - all CSV file values are of type text/string by default', Class: 'foui-h6 foui-dull', Padding: '2px 5px' }
                ] },
                { Columns: [
                    { Value: 'Text', EditControl: { Type: 'Select', Items: ':ValueTypes' } },
                    { Value: 'Text', EditControl: { Type: 'Select', Items: ':ValueTypes' } },
                    { ColumnSpan: 'all', Value: '' },
                ] }, *!/
            ],
            TabSelected: 'file1',
            TabData: [{
                id: 'file1',
                text: 'File 1',
                canClose: true,
                icon: 'File',
                // html: '',
            }],
        }); */
        // console.log('HERE', data);
        // data._mapOut();
        // data.Party = { When: 'NOW' };
        // data._mapOut();
        /* setTimeout(() => {
            // console.log('HERE', data);
            data.LabelText = 'Kelly Smit'; // 'Kelly Belly Smit'
            // data.Tester.Name = 'Kelly Smit';
            // data.Tester.Age = 300;
            // data.controls[0].props.Class = 'foui-float-right';
            // data.Party.When = 'YESTERDAY';
        }, 1000); */
        /* setTimeout(() => {
            // console.log(data.LabelText);
            // data.controls.push({ Type: 'Label', Props: { Text: 'Lucy Smit!' } });
            // data.controls = [{ Type: 'Label', Props: { Text: 'Meek!' } }, { Type: 'Label', Props: { Text: 'MOOT' } }];
            // data.LabelText = 'Lucy Smit!';
            data.controls[0].props.Class = 'foui-float-right';
            console.log('Done');
        }, 3000); */

        /* const editDesign = {
            Type: 'LayoutGrid',
            Props: {
                Name: 'DesignView',
                Class: 'foui-pa-10',
                DesignMode: ':DesignModeOn',
                Width: ':ViewWidth',
                Height: ':ViewHeight',
                ShowCells: ':ShowGridCells',
                ShowSizers: ':ShowGridSizers',
                SizeBy: ':GridSizeBy',
                Columns: '200px 140px 400px',
                Rows: '30px 25px 25px 25px 25px 25px 225px 25px 25px 160px 40px', // 30px 25px 25px 25px 25px 25px auto 225px 25px 25px 160px 40px 1fr
                GapCols: ':GridGapColumns',
                GapRows: ':GridGapRows',
                Cells: [
                    { Location: '1 / 1', Controls: [{ Type: 'Label', Props: { Text: ':LabelText', Class: 'foui-float-left' } }] },
                    { Location: '1 / 2', Controls: [{ Type: 'Label', Props: { Text: 'I am 2', Class: 'foui-float-right' } }] },
                    { Location: '1 / 3', Controls: [{ Type: 'Check', Props: { Text: 'A checkbox with a long description', Class: 'foui-float-right', Value: true } }] },
                    { Location: '2 / 2', Controls: [{ Type: 'Text', Props: { Name: 'SampleText', Value: ':LabelText', Class: 'foui-flex' } }] },
                    { Location: '2 / 3', Controls: [{ Type: 'Button', Props: { Name: 'Btn1', Text: ':LabelText', Fit: true, Events: { Click: 'clickMe' } } }, { Type: 'Button', Toggle: true, Value: true, Props: { Name: 'Btn2', Text: 'Submit', Fit: true, Class: 'foui-ml-5', Events: { Click: 'clickMe' } } }] },

                    { Location: '3 / 2', Controls: [{ Type: 'Label', Props: { Text: 'Pie One', For: 'opt1 > input' } }] },
                    { Location: '4 / 2', Controls: [{ Type: 'Label', Props: { Text: 'Pie Two', For: 'opt2' } }] },
                    { Location: '5 / 2', Controls: [{ Type: 'Label', Props: { Text: 'Pie Three', For: 'opt3' } }] },
                    { Location: '3 / 3', Controls: [{ Type: 'Option', Props: { Text: 'Pie Option 1', Name: 'opt1', Group: 'PieOptions', Value: true } }] },
                    { Location: '4 / 3', Controls: [{ Type: 'Option', Props: { Text: 'Pie Option 2', Name: 'opt2', Group: 'PieOptions' } }] },
                    { Location: '5 / 3', Controls: [{ Type: 'Option', Props: { Text: 'Pie Option 3', Name: 'opt3', Group: 'PieOptions' } }] },

                    { Location: '6 / 2', Controls: [{ Type: 'Label', Props: { Text: 'Ketchup?', For: 'confi1' } }] },
                    { Location: '6 / 3', Controls: [{ Type: 'Switch', Props: { Name: 'confi1', Value: true, FalseText: 'N', TrueText: 'Y' } }] },

                    { Location: '7 / 1', Controls: [{ Type: 'Label', Props: { Text: '', Icon: 'Settings', IconWidth: 40, IconHeight: 40 } }] }, // justify-content: center;
                    { Location: '7 / 2', Controls: [{ Type: 'Label', Props: { Text: 'Names', For: 'nameList' } }] },
                    // { Location: '7 / 3', Controls: [{ Type: 'List', Props: { Name: 'genderSelect', Value: 'male', Items: ':Genders', FieldKey: 'id', FieldText: 'text' } }] },
                    { Location: '7 / 3', Controls: [{ Type: 'List', Props: { Name: 'nameList', Value: 'John', Items: ':NameList', ItemRenderer: Util.template`<i>${0}.</i> ${1}` } }] },

                    { Location: '8 / 2', Controls: [{ Type: 'Label', Props: { Text: 'Gender', For: 'genderSelect' } }] },
                    { Location: '8 / 3', Controls: [{ Type: 'Select', Props: { Name: 'genderSelect', Value: 'male', Items: ':Genders', FieldKey: 'id', FieldText: 'text' } }] },
                ]
            }
        }; */

        const editDesign = {
            Type: 'LayoutGrid',
            Props: {
                Name: 'LoginView',
                Class: 'foui-pa-10',
                DesignMode: ':DesignModeOn',
                Width: ':ViewWidth',
                Height: ':ViewHeight',
                ShowCells: ':ShowGridCells',
                ShowSizers: ':ShowGridSizers',
                SizeBy: ':GridSizeBy',
                Columns: '1fr 300px 1fr',
                Rows: '1fr 160px 30px 30px 30px 20px 30px 4fr',
                GapCols: ':GridGapColumns',
                GapRows: ':GridGapRows',
                Cells: [
                    // logo
                    { Location: '2 / 2', Controls: [{ Type: 'Label', Props: { Text: '', Icon: 'FireOcto', IconHeight: 150, IconWidth: 150, Class: 'foui-hero3', Alignment: 'center-top' } }] }, // FireOcto Administration Portal
                    { Location: '3 / 2', Controls: [{ Type: 'Label', Props: { Text: 'Log in to your Account', Class: 'foui-h1 foui-text-normal' } }] },
                    { Location: '4 / 2', Controls: [{ Type: 'Text', Props: { Name: 'NameBox', Value: '', Placeholder: 'Username' } }] },
                    { Location: '5 / 2', Controls: [{ Type: 'Text', Props: { Name: 'PasswordBox', Value: '', Placeholder: 'Password', Type: 'password' } }] },
                    { Location: '6 / 2', Controls: [{ Type: 'Check', Props: { Text: 'Remember me', Class: 'foui-float-right', Value: true } }, { Type: 'Label', Props: { Text: 'Forgot password?', Class: 'foui-float-right' } }] },
                    { Location: '7 / 2', Controls: [{ Type: 'Button', Props: { Name: 'Btn1', Text: 'Login', Fit: true, Class: 'primary', Events: { Click: 'clickMe' } } }, { Type: 'Button', Toggle: true, Value: true, Props: { Name: 'Btn2', Text: 'Register new account', Fit: true, Class: 'foui-ml-10', Events: { Click: 'clickMe' } } }] },
                ]
            }
        };

        /* const editDesign = {
            Type: 'LayoutGrid',
            Props: {
                Name: 'TableView',
                Class: 'foui-pa-10',
                DesignMode: ':DesignModeOn',
                Width: '100%',
                Height: 'auto',
                ShowCells: ':ShowGridCells',
                ShowSizers: ':ShowGridSizers',
                SizeBy: ':GridSizeBy',
                Columns: '1fr',
                Rows: '1fr',
                GapCols: '0',
                GapRows: '20',
                Cells: [
                    { Location: '1 / 1', Controls: [{ Type: 'Table', Props: { Items: ':TableData', HeaderBorders: false, ItemBorders: 'none', Editable: true, Select: 'row', StickyHeader: 'asis', Zebra: true, RowNumbers: true, Columns: ':TableColumns', CustomHeaders: ':TableCustom' } }] },
                    // { Location: '1 / 1', Controls: [{ Type: 'Panel', Props: { Text: ':LabelText', Class: 'foui-stretch foui-dull-dark' } }] },
                ]
            }
        }; */

        /* const editDesign = {
            Type: 'LayoutGrid',
            Props: {
                Name: 'TabView',
                Class: 'foui-pa-10',
                DesignMode: ':DesignModeOn',
                Width: 800,
                Height: 800,
                ShowCells: ':ShowGridCells',
                ShowSizers: ':ShowGridSizers',
                SizeBy: ':GridSizeBy',
                Columns: '1fr',
                Rows: '1fr',
                GapCols: '10',
                GapRows: '10',
                Cells: [
                    { Location: '1 / 1', Controls: [{ Type: 'Tabs', Props: { Items: ':TabData', Value: ':TabSelected' } }] },
                    // { Location: '1 / 1', Controls: [{ Type: 'Panel', Props: { Text: ':LabelText', Class: 'foui-stretch foui-dull-dark' } }] },
                ]
            }
        }; */

        // this.app = new Controls.View({ Props: { ViewName: mainViewDef.design.Name }, Parent: appElem });
        /* const view = {
            // design: {
            //     Type: 'Panel',
            //     Name: 'Application',
            //     Controls: [
            //         { Type: 'Label', Props: { Text: ':LabelText' } },
            //         { Type: 'Text', Props: { Name: 'MyText', Value: ':LabelText' } },
            //         { Type: 'Button', Props: { Text: 'Click Me!', Events: { Click: 'clickMe' } } },
            //     ]
            // },
            Design: {
                Type: 'LayoutGrid',
                Props: {
                    Name: 'Application',
                    Class: 'foui-pa-0',
                    Width: 'auto',
                    Height: '100%',
                    Columns: '200px 1fr 500px',
                    Rows: '36px 1fr 26px',
                    GapCols: 3,
                    GapRows: 3,
                    Cells: [
                        { Location: '1 / 1 / auto / 4', Controls: [{ Type: 'Toolbar', Props: { Controls: [
                            { Type: 'Label', Props: { Name: 'AppNameLabel', Text: 'FireOcto', Icon: 'Block', Tip: 'View name and path', Class: 'foui-h4 foui-text-input foui-text-nowrap foui-ml-10' } },
                            { Type: 'Button', Props: { Name: 'UserButtonTop', Text: ':DisplayName', Icon: 'User2', Class: 'inset-focus foui-text-nowrap', Events: { Click: 'onSignIn' } } },
                        ] } }] },
                        { Location: '2 / 1', Controls: [{ Type: 'LayoutGrid', Props: { Name: 'ProjectArea', Columns: '1fr', Rows: '16px 32px 32px 400px 16px 32px 1fr', GapCols: 3, GapRows: 3,
                            Cells: [
                                { Location: '1 / 1', Controls: [{ Type: 'HeaderLine', Props: { Text: 'Workspace' } }] },
                                { Location: '2 / 1', Controls: [{ Type: 'Toolbar', Props: { Class: 'foui-pa-2', Controls: [
                                    { Type: 'Select', Props: { Name: 'WorkViewType', Value: 1, Items: ':WorkViewTypes', Bordered: false, FieldKey: 'id', FieldText: 'name', ItemRenderer: Util.template`${'name'}&nbsp;&nbsp;<span class="foui-o-5 f-m">${'type'}</span>` } },
                                ] } }] },
                                { Location: '3 / 1', Controls: [{ Type: 'Toolbar', Props: { Class: 'foui-pa-2', Controls: [
                                    { Type: 'Button', Props: { Name: 'PropsXButton', Text: '', Icon: 'Plus', Class: 'inset-focus' } },
                                    { Type: 'Splitter', Props: { Name: 'PropsXSplit1', Direction: 'vertical' } },
                                    { Type: 'Button', Props: { Name: 'PropsXSortButton', Text: '', Icon: 'Refresh', Class: 'inset-focus' } },
                                    { Type: 'Button', Props: { Name: 'PropsXGroupButton', Text: '', Icon: 'Settings4', Class: 'inset-focus' } },
                                    { Type: 'Spacer', Props: { Name: 'Spacer1Proj', Direction: 'horizontal' } },
                                    // { Type: 'Select', Props: { Name: 'WorkViewType', Value: 1, Items: ':TestPropControls', Class: 'inset-focus', FieldKey: 'id', FieldText: 'name', ItemRenderer: Util.template`<b>${'name'}</b>&nbsp;&nbsp;${'type'}` } },
                                    { Type: 'Button', Props: { Name: 'ProjectMoreButton', Text: '', Icon: 'More', Tip: 'Project options', Class: 'inset-focus', Events: { Click: 'clickMe' } } },
                                ] } }] },
                                // { Location: '4 / 1', Controls: [{ Type: 'Panel', Props: { Text: ':LabelText', Class: 'foui-stretch foui-dull-dark' } }] },
                                { Location: '4 / 1', Controls: [{ Type: 'Tree', Props: { Name: 'WorkspaceTree', Items: ':WorkspaceItems', FieldLinkParent: 'link', FieldKey: 'id', FieldText: 'name', FieldIcon: 'icon', ItemRenderer: Util.template`${'name'}&nbsp;&nbsp;<span class="foui-o-5 f-m">${0}</span>`, Bordered: false, Class: 'foui-dull-dark' } }] },
                                { Location: '5 / 1', Controls: [{ Type: 'HeaderLine', Props: { Text: 'UI Controls' } }] },
                                { Location: '6 / 1', Controls: [{ Type: 'Toolbar', Props: { Class: 'foui-pa-2', Controls: [
                                    { Type: 'Button', Props: { Name: 'PropsZButton', Text: '', Icon: 'Plus', Class: 'inset-focus' } },
                                    { Type: 'Splitter', Props: { Name: 'PropsZSplit1', Direction: 'vertical' } },
                                    { Type: 'Button', Props: { Name: 'PropsZSortButton', Text: '', Icon: 'Refresh', Class: 'inset-focus' } },
                                    { Type: 'Button', Props: { Name: 'PropsZGroupButton', Text: '', Icon: 'Settings4', Class: 'inset-focus' } },
                                    { Type: 'Spacer', Props: { Name: 'Spacer1Controls', Direction: 'horizontal' } },
                                    { Type: 'Button', Props: { Name: 'ControlsMoreButton', Text: '', Icon: 'More', Tip: 'Control options', Class: 'inset-focus', Events: { Click: 'clickMe' } } },
                                ] } }] },
                                // { Location: '7 / 1', Controls: [{ Type: 'List', Props: { Name: 'ControlList', Items: ':ControlList', Bordered: false, Class: 'foui-dull-dark' } }] },
                                { Location: '7 / 1', Controls: [{ Type: 'Tree', Props: { Name: 'ControlTree', Items: ':ControlList', HideTreeLine: true, NoValueSpacing: true, FieldLinkParent: 'link', FieldKey: 'id', FieldText: 'name', FieldIcon: 'icon', Bordered: false, Class: 'foui-dull-dark' } }] },
                                // { Location: '7 / 1', Controls: [{ Type: 'Panel', Props: { Text: ':LabelText', Class: 'foui-stretch foui-dull-dark' } }] },
                            ]
                        } }] },
                        // { Location: '2 / 1', Controls: [{ Type: 'Button', Props: { Name: 'Btn1', Text: 'DESIGN Click', Fit: true, Events: { Click: 'clickMe' } } }] },
                        // { Location: '2 / 2', Controls: ':EditView', Events: { LayoutSelect: 'designSelect' } }, // { Type: 'Label', Props: { Text: 'Editor', Class: 'foui-float-right' } }
                        { Location: '2 / 2', Controls: [{ Type: 'LayoutGrid', Props: { Name: 'WorkspaceArea', Columns: '1fr', Rows: '16px 32px 32px 1fr', GapCols: 3, GapRows: 3,
                            Cells: [
                                { Location: '1 / 1', Controls: [{ Type: 'HeaderLine', Props: { Text: 'Editor' } }] },
                                { Location: '2 / 1', Controls: [{ Type: 'Toolbar', Props: { Class: 'foui-pa-2', Controls: [
                                    { Type: 'Button', Props: { Name: 'DesignButton', Text: 'Design', Toggle: true, Value: true, ToggleGroup: 'DesignViewGroup', Icon: 'Design', Class: 'inset-focus', Events: { Change: 'onDesignLogicChange' } } },
                                    { Type: 'Button', Props: { Name: 'LogicButton', Text: 'Logic', Toggle: true, ToggleGroup: 'DesignViewGroup', Icon: 'Logic', Class: 'inset-focus foui-ml-4', Events: { Change: 'onDesignLogicChange' } } },
                                    { Type: 'Spacer', Props: { Name: 'Spacer1', Direction: 'horizontal', Size: 20 } },
                                    { Type: 'Button', Props: { Name: 'UndoButton', Text: '', Tip: 'Undo', Icon: 'Undo', Class: 'inset-focus foui-ml-4', Events: { Click: 'clickMe' } } },
                                    { Type: 'Button', Props: { Name: 'RedoButton', Text: '', Tip: 'Redo', Icon: 'Redo', Class: 'inset-focus foui-ml-4 foui-mr-4', Events: { Click: 'clickMe' } } },
                                    { Type: 'Splitter', Props: { Name: 'ActionSplit', Direction: 'vertical' } },
                                    { Type: 'Button', Props: { Name: 'SaveButton', Text: '', Tip: 'Save', Icon: 'Save', Class: 'inset-focus foui-ml-4', Events: { Click: 'clickMe' } } },
                                    { Type: 'Spacer', Props: { Name: 'Spacer2', Direction: 'horizontal', Size: 20 } },
                                    { Type: 'Label', Props: { Name: 'NameLabel', Text: ':EditViewName', Tip: 'View name and path', Class: 'foui-h4 foui-text-input foui-text-nowrap' } },
                                    { Type: 'Spacer', Props: { Name: 'Spacer3', Direction: 'horizontal' } },
                                    { Type: 'Button', Props: { Name: 'ThemeButton', Text: 'Air Dark', Icon: 'Theme2', Tip: 'Theme name', Class: 'inset-focus foui-text-nowrap foui-ml-4', Events: { Click: 'clickMe' } } },
                                    // { Type: 'Label', Props: { Name: 'ThemeLabel', Text: 'Air Dark', Tip: 'Theme name', Width: 'auto' } },
                                    { Type: 'Button', Props: { Name: 'GridButton', Text: '', Tip: 'Display layout grid', Toggle: true, Value: ':ShowGridCells', Icon: 'Grid3', Class: 'inset-focus foui-ml-4' } }, // , Events: { Change: 'onGridShowChange' }
                                    { Type: 'Button', Props: { Name: 'SizeButton', Text: '', Tip: 'Display layout grid sizing controls', Toggle: true, Value: ':ShowGridSizers', Icon: 'SizeBox2', Class: 'inset-focus foui-ml-4 foui-mr-4' } }, // , Events: { Change: 'onGridSizersChange' }
                                    { Type: 'Splitter', Props: { Name: 'LangSplit', Direction: 'vertical' } },
                                    { Type: 'Button', Props: { Name: 'LanguageButton', Text: 'English', Icon: 'Language2', Tip: 'Language', Class: 'inset-focus', Events: { Click: 'clickMe' } } },
                                    // { Type: 'Label', Props: { Name: 'LanguageLabel', Text: 'English', Tip: 'Language', Width: 'auto' } },
                                    { Type: 'Splitter', Props: { Name: 'PreviewSplit', Direction: 'vertical' } },
                                    { Type: 'Button', Props: { Name: 'PreviewButton', Text: '', Tip: 'Preview', Icon: 'PreviewScreen', Class: 'inset-focus foui-ml-4', Events: { Click: 'onPreviewDesign' } } },
                                ] } }] },
                                { Location: '3 / 1', Controls: [{ Type: 'Toolbar', Props: { Class: 'foui-pa-2', Controls: [
                                    { Type: 'Button', Props: { Name: 'AddRowButton', Text: 'Row', Icon: 'Plus', Tip: 'Insert Row', Class: 'inset-focus', Events: { Click: 'clickMe' } } },
                                    { Type: 'Button', Props: { Name: 'AddColumnButton', Text: 'Column', Icon: 'Plus', Tip: 'Insert Column', Class: 'inset-focus foui-ml-4', Events: { Click: 'clickMe' } } },
                                    { Type: 'Spacer', Props: { Name: 'Spacer1B', Direction: 'horizontal' } },
                                    { Type: 'Label', Props: { Name: 'ViewWidthLabel', Text: 'View width', Width: 'auto', Class: 'foui-text-nowrap foui-ml-10' } },
                                    { Type: 'Text', Props: { Name: 'ViewWidthInput', Value: ':ViewWidth', Tip: 'View width', Width: '60px', Type: 'number', Class: 'inset-focus foui-ml-5' } },
                                    { Type: 'Label', Props: { Name: 'ViewHeightLabel', Text: 'height', Width: 'auto', Class: 'foui-text-nowrap foui-ml-5' } },
                                    { Type: 'Text', Props: { Name: 'ViewHeightInput', Value: ':ViewHeight', Tip: 'View height', Width: '60px', Type: 'number', Class: 'inset-focus foui-ml-5 foui-mr-5' } },
                                    { Type: 'Spacer', Props: { Name: 'Spacer2B', Direction: 'horizontal' } },
                                    { Type: 'Label', Props: { Name: 'GridSizeLabel', Text: 'Size by', Width: 'auto', Class: 'foui-text-nowrap' } },
                                    { Type: 'Text', Props: { Name: 'GridSizeInput', Value: ':GridSizeBy', Tip: 'Resize by step', Width: '60px', Type: 'number', Class: 'inset-focus foui-ml-5' } },
                                    { Type: 'Label', Props: { Name: 'GridGapRowLabel', Text: 'Gap between rows', Width: 'auto', Class: 'foui-text-nowrap foui-ml-10' } },
                                    { Type: 'Text', Props: { Name: 'GridGapRowInput', Value: ':GridGapRows', Tip: 'Gap between rows', Width: '60px', Type: 'number', Class: 'inset-focus foui-ml-5' } },
                                    { Type: 'Label', Props: { Name: 'GridGapColumnLabel', Text: 'columns', Width: 'auto', Class: 'foui-text-nowrap foui-ml-5' } },
                                    { Type: 'Text', Props: { Name: 'GridGapColumnInput', Value: ':GridGapColumns', Tip: 'Gap between columns', Width: '60px', Type: 'number', Class: 'inset-focus foui-ml-5 foui-mr-5' } },
                                    { Type: 'Splitter', Props: { Name: 'MoreSplit', Direction: 'vertical' } },
                                    { Type: 'Button', Props: { Name: 'GridMoreButton', Text: '', Icon: 'More', Tip: 'Grid options', Class: 'inset-focus', Events: { Click: 'clickMe' } } },
                                ] } }] },
                                // --
                                { Location: '4 / 1', Controls: [{ Type: 'Panel', Props: {
                                    Class: 'foui-pa-15 foui-stretch foui-overflow-auto', Controls: [{ Type: 'WorkflowBoard', Props: { Height: '100%', Width: '100%' } }]
                                } }] },
                                // --
                                /!* { Location: '4 / 1', Controls: [{ Type: 'Panel', Props: {
                                    Class: 'foui-pa-15 foui-stretch foui-overflow-auto', Controls: [{ Type: 'Panel', Props: {
                                        Class: 'foui-inline-block foui-overflow-visible foui-b-4 foui-designer-panel', Controls: ':EditView', Events: { LayoutSelect: 'designSelect' } // foui-box-shadow-small
                                    } }]
                                } }] }, *!/
                                // --
                                // { Location: '4 / 1', Controls: ':EditView', Events: { LayoutSelect: 'designSelect' } },
                                /!* { Location: '4 / 1', Controls: [{ Type: 'LayoutGrid', Props: { Name: 'WorkspaceArea', Columns: '1fr', Rows: '16px 32px 29px 1fr', GapCols: 3, GapRows: 3,
                                    Cells: [
                                        { Location: '1 / 1', Controls: [{ Type: 'Label', Props: { Text: 'Toolbar', Class: 'foui-float-right' } }] },
                                    ]
                                } }] }, *!/
                            ]
                        } }] },
                        // { Location: '2 / 3', Controls: [{ Type: 'PropertyGrid', Props: { Name: 'PropGridDemo', Item: ':PropData', Schema: ':SchemaData' } }] },
                        { Location: '2 / 3', Controls: [{ Type: 'LayoutGrid', Props: { Name: 'PropertiesArea', Columns: '1fr', Rows: '16px 32px 32px 1fr', GapCols: 3, GapRows: 3,
                            Cells: [
                                { Location: '1 / 1', Controls: [{ Type: 'HeaderLine', Props: { Text: 'Properties' } }] },
                                // { Location: '2 / 1', Controls: [{ Type: 'Select', Props: { Name: 'controlPropsSelected', Value: 1, Items: ':TestPropControls', FieldKey: 'id', FieldText: 'name', ItemRenderer: Util.template`<b>${'name'}</b>&nbsp;&nbsp;${'type'}` } }] },
                                { Location: '2 / 1', Controls: [{ Type: 'Toolbar', Props: { Class: 'foui-pa-2', Controls: [
                                    { Type: 'Select', Props: { Name: 'controlPropsSelected', Value: 1, Items: ':TestPropControls', Bordered: false, FieldKey: 'id', FieldText: 'name', ItemRenderer: Util.template`${'name'}&nbsp;&nbsp;<span class="foui-o-5 f-m">${'type'}</span>` } },
                                ] } }] },
                                { Location: '3 / 1', Controls: [{ Type: 'Toolbar', Props: { Class: 'foui-pa-2', Controls: [
                                    { Type: 'Button', Props: { Name: 'PropsButton', Text: '', Toggle: true, Value: true, ToggleGroup: 'PropsEventsViewGroup', Icon: 'Properties', Class: 'inset-focus' } },
                                    { Type: 'Button', Props: { Name: 'EventsButton', Text: '', Toggle: true, ToggleGroup: 'PropsEventsViewGroup', Icon: 'Events', Class: 'inset-focus' } },
                                    { Type: 'Splitter', Props: { Name: 'PropsSplit1', Direction: 'vertical' } },
                                    { Type: 'Button', Props: { Name: 'PropsGroupButton', Text: '', Toggle: true, Icon: 'Grouped', Class: 'inset-focus' } },
                                    { Type: 'Button', Props: { Name: 'PropsSortButton', Text: '', Toggle: true, Value: true, Icon: 'SortAsc', Class: 'inset-focus' } },
                                    // { Type: 'Label', Props: { Name: 'AppNameLabel', Text: 'FireOcto', Icon: 'Block', Tip: 'View name and path', Class: 'foui-h4 foui-text-input foui-text-nowrap foui-ml-10' } },
                                ] } }] },
                                { Location: '4 / 1', Controls: [{ Type: 'PropertyGrid', Props: { Name: 'PropGridDemo', Item: ':PropData', Schema: ':SchemaData' } }] },
                            ]
                        } }] },
                        { Location: '3 / 1 / auto / 4', Controls: [{ Type: 'Toolbar', Props: { Controls: [
                            { Type: 'Button', Props: { Name: 'UserButton', Text: ':DisplayName', Icon: 'User2', Class: 'inset-focus foui-text-nowrap', Events: { Click: 'onSignIn' } } },
                            // { Type: 'Label', Props: { Name: 'UserLabel', Text: 'User: Marius Smit', Tip: 'Username', Class: 'foui-h5 foui-text-nowrap foui-ml-10' } },
                        ] } }] },
                        //
                        // { Location: '2 / 2', Controls: [{ Type: 'Text', Props: { Value: ':LabelText', Class: 'foui-flex' } }] },
                        // { Location: '2 / 3', Controls: [{ Type: 'Button', Props: { Name: 'Btn1', Text: ':LabelText', Fit: true, Events: { Click: 'clickMe' } } }, { Type: 'Button', Props: { Name: 'Btn2', Text: 'Submit', Fit: true, Class: 'foui-ml-5', Events: { Click: 'clickMe' } } }] },
                    ]
                }
            },
            Data: data,
            Code: {
                init () {
                    // Bind to some useful events.
                    this.FOUI.PubSub.subscribe(this.FOUI.Const.PUB_SUB.AUTH_CHANGED, this.code.onAuthChanged.bind(this));

                    this.data.WorkspaceItems = [
                        { id: 'root', name: 'Workspace', link: '', icon: 'Project2', open: true },
                        { id: 'activity', name: 'Activity Logs', link: 'root', icon: 'FolderGroup', open: true },
                        { id: 'connections', name: 'Connections', link: 'root', icon: 'FolderGroup', open: true },
                        { id: 'data_views', name: 'Data Views', link: 'root', icon: 'FolderGroup', open: true },
                        { id: 'project', name: 'Project Designer', link: 'root', icon: 'FolderGroup', open: true },
                        { id: 'services', name: 'Services', link: 'root', icon: 'FolderGroup', open: true },
                    ];

                    const keys = Object.keys(this.FOUI.Controls);
                    // const tree = [{ id: 'root', name: 'Project Files', link: '', icon: '', open: true }]; // Project2
                    const tree = [];
                    const groups = [];
                    for (const key of keys) {
                        // this.data.ControlListDetail.push();
                        const def = this.FOUI.Controls[key]._DEF;
                        if (def.Design === 'NONE') continue;
                        if (groups.indexOf(def.Group) > -1) continue;
                        groups.push(def.Group);
                        tree.push({ id: UI.safeId(def.Group), name: def.Group, link: '', icon: '', open: true }); // root Folder
                    }
                    groups.sort();
                    for (const key of keys) {
                        const def = this.FOUI.Controls[key]._DEF;
                        if (def.Design === 'NONE') continue;
                        tree.push({ id: UI.safeId(key), name: key, link: UI.safeId(def.Group), icon: 'Block' });
                    }
                    this.data.ControlList = tree;

                    this.data.EditView = [editDesign];

                    const len = 30;
                    setTimeout(() => {
                        const data = [];
                        for (let i = 1; i <= len; i++) {
                            data.push({ Key: i, FirstName: faker.name.firstName(), LastName: faker.name.lastName(), Company: faker.company.companyName(), Age: Util.randomInteger(18, 60), Job: faker.name.jobTitle() }); // `Item${i}`
                        }
                        this.data.TableData = data;
                    }, 100);
                },
                async onAuthChanged (msg, data) {
                    if (data) {
                        this.data.DisplayName = '...';
                        // const path = 'http://localhost:3777/api/session/profile';
                        /!* const path = `http://localhost:3777/api/users/tCKQEfEJgwtQ`;
                        const res = await this.FOUI.Http.get(path);
                        console.log(res.data); *!/

                        const aConf = await this.FOUI.Auth.getOidcConfig();
                        console.log(aConf);
                        const res = await this.FOUI.Http.get(aConf.userinfoEndpoint, {
                            headers: {
                                // Authorization: ''
                            }
                        });
                        console.log(res.data);
                        /!* const userInfo = await getIdTokenClaims();
                        setUser(userInfo ?? { sub: 'N/A', username: 'N/A' }); *!/
                    }
                    else this.data.DisplayName = 'Guest';
                },
                clickMe () {
                    console.warn(this.data.LabelText);
                    // console.log(this);
                    this.code.myAlert();
                },
                myAlert () {
                    // alert('Alert has been called!');
                    console.log(data.toJSON());
                    // this.$name.Btn1.props.Fit = !this.$name.Btn1.props.Fit;
                },
                designSelect (evt) {
                    const ctrl = evt.detail;
                    const defProps = Controls[ctrl.meta.type]._DEF.Properties;
                    const o = {};
                    const s = {};
                    const keys = Object.keys(defProps);
                    for (let i = 0; i < keys.length; i++) {
                        const key = keys[i];
                        // Value.
                        /!* if (ctrl.props[key] === undefined && defProps.Default !== undefined) {
                            ctrl.props[key] = defProps.Default;
                        } *!/
                        o[key] = ctrl.props[key] === undefined ? defProps.Default : ctrl.props[key];
                        if (o[key] === undefined) {
                            o[key] = '';
                        }
                        // Schema.
                        s[key] = defProps[key];
                    }
                    data.SchemaData = s;
                    // data.PropData = ctrl.props;
                    if (this.meta.props) this.meta.props.unlisten();
                    this.meta.props = Watch(o, 'PropGrid');
                    this.meta.props.listen(changes => {
                        for (const [key, v] of changes) {
                            ctrl.props[key] = v;
                        }
                    });
                    data.PropData = this.meta.props;
                    // console.log(props);
                },
                onDesignLogicChange (evt) {
                    if (evt.detail === 'DesignButton') this.data.EditViewName = 'DemoView.design';
                    else this.data.EditViewName = 'DemoView.logic';
                },
                onPreviewDesign (evt) {
                    const windowFeatures = 'left=100,top=100,width=600,height=600,location=no,titlebar=no';
                    const handle = window.open('https://www.mozilla.org/', 'mozillaWindow', windowFeatures);
                    if (!handle) {
                        // The window wasn't allowed to open
                        // This is likely caused by built-in popup blockers.

                        // …
                    }
                },
                onSignIn () {
                    this.FOUI.Auth.signIn('http://localhost:1400/callback');
                }
            }
        }; */

        // Here
        /* this.app = new Controls[view.Design.Type]({
            Parent: appElem,
            Props: view.Design.Props,
            Data: view.Data,
            Code: view.Code,
            CodeBindThis: true, // Binds the functions in code to this control instance.
            $name: {},
        }); */
        // console.log(this.app);
        /* setTimeout(() => {
            console.log(this.app.props.toJSON());
        }, 5000); */
        // console.log(this.app.$name.EditorLocation);
        /* setTimeout(() => {
            data.EditView = [editDesign];
        }, 100); */

        // Tester button.
        /* const but = UI.create('button', { id: 'butTester', text: 'Submit' });
        appElem.append(but);
        but.addEventListener('click', () => {
            // console.log(JSON.stringify(view.design, null, 4));
            console.log(JSON.stringify(data, null, 4));
        }); */

        /* // Init the navigator.
        this.FOUI.Navigator = new ViewNavigator({
            mainName: 'AppMain'
        });
        const nav = this.FOUI.Navigator;
        // Show that we are busy loading.
        // const loadingData = { Title: '@Loading', Message: '@LoadingBusy', ShowProgress: true };
        // nav.open('Processing', Util.applyLanguage('', loadingData), appElem);

        // Load the main view.
        // appElem.innerHTML = '';
        // new Controls.View(mainViewDef, appElem);
        this.app = new Controls.View({ Props: { ViewName: mainViewDef.design.Name }, Parent: appElem });
        nav.app = this.app;
        nav.main = this.app.$name[nav.mainName];
        // nav.go(workspaceChoose.design.Name, 'workspace/choose'); // With browser navigation/history.
        // nav.open(workspaceChoose.design.Name, null, nav.main.elem); // No nav. */

        // ----------------------------------------------------------------------------------------------------
        // Handle the auth callback route.
        /* try {
            await this.FOUI.Auth.handleSignInCallback(window.location.href);
            const isAuthed = await this.FOUI.Auth.isAuthenticated();
            this.FOUI.PubSub.publish(this.FOUI.Const.PUB_SUB.AUTH_CHANGED, isAuthed);
        }
        catch {
            // Handle error.
        }
        // Check if the user is authenticated.
        const isAuthed = await this.FOUI.Auth.isAuthenticated();
        // Publish the state for the app instance.
        this.FOUI.PubSub.publish(this.FOUI.Const.PUB_SUB.AUTH_CHANGED, isAuthed); */
    }
}
