export default class Data {
    static objHasProp (o, prop) {
        if (prop === undefined) return false;
        const pos = prop.indexOf('.'); // Check if a nested property.
        if (pos > -1) { // Nested property e.g. Object.Object.Value
            const startPart = prop.substring(0, pos); // get the first level name.
            if (o[startPart] !== undefined) { // Does the property exist.
                return this.objHasProp(o[startPart], prop.substring(pos + 1)); // Call self to go deeper.
            }
            return false; // The first level property does not exist.
        }
        return o[prop] !== undefined; // Return the existance of the property.
    }

    static objGetProp (o, prop) {
        if (prop === undefined) return undefined;
        const pos = prop.indexOf('.'); // Check if a nested property.
        if (pos > -1) { // Nested property e.g. Object.Object.Value
            const startPart = prop.substring(0, pos); // get the first level name.
            if (o[startPart] !== undefined) { // Does the property exist.
                return this.objGetProp(o[startPart], prop.substring(pos + 1)); // Call self to go deeper.
            }
            return undefined; // The first level property does not exist.
        }
        return o[prop]; // Return the value.
    }

    static objSetProp (o, prop, value) {
        const pos = prop.indexOf('.'); // Check if a nested property.
        if (pos > -1) { // Nested property e.g. Object.Object.Value
            const startPart = prop.substring(0, pos); // get the first level name.
            // console.log(startPart);
            if (o[isNaN(startPart) ? startPart : +startPart] !== undefined) { // o[startPart] !== undefined
                this.objSetProp(o[isNaN(startPart) ? startPart : +startPart], prop.substring(pos + 1), value); // Call self to go deeper.
            }
            // else console.log(startPart, o); // Property does not exist.
            return; // The first level property does not exist.
        }
        if (o === undefined) console.trace(prop, value);
        if (value !== null && typeof value === 'object' && value._watching) return;
        // o[isNaN(prop) ? prop : +prop] = value.toJSON ? value.toJSON() : value; // Set the value.
        o[isNaN(prop) ? prop : +prop] = value; // Set the value.
    }
}
