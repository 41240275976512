// import { APP_KEY } from '../../demo-views/builder/app-constants';
import Controls from '../control';
import UI from './ui';
import Util from './util';
import Watch from './watch';

export default class PopupEngine {
    static menu (opt, callback) {
        // Anchor, position, items
        if (!opt) return;
        if (!opt.Anchor.parentElement) return;
        const anchor = opt.Anchor;
        // delete opt.Anchor;
        // const parent = opt.ParentControl;
        // delete opt.ParentControl;
        // const FOUI = window[window.FOUI_APP_KEY].FOUI;
        // TODO: Util.applyLanguage('Popup', opt, FOUI.Languages[FOUI.Lang]); // Replace value placeholder keys withg the selected language values.
        const wAnchor = anchor.offsetWidth;
        const popProperties = {
            AnchorToSide: opt.Position,
            Items: opt.Items || [],
            Width: wAnchor,
            Events: {
                Close: (evt) => {
                    UI.stopEvent(evt);
                    if (callback) callback(evt.detail);
                }
            }
        };
        const pop = new Controls.PopupMenu({ Props: popProperties, Parent: opt.ParentControl, Data: opt.Data || Watch({}), Code: opt.code || {}, $name: opt.$name });
        /* const pop = new Controls.PopupMenu({
            Props: { Type: 'PopupMenu', AnchorToSide: opt.Position, Items: opt.Items || [], Width: wAnchor, Events: { Close: 'onClose' } },
            parent: anchor.parentElement,
            parentControl: parent,
            // data: this.data,
            codeHandlers: {
                onClose (evt) {
                    UI.stopEvent(evt);
                    if (callback) callback(evt.detail);
                }
            }
        }); */
        // const w = document.body.offsetWidth;
        const h = document.body.offsetHeight;
        setImmediate(() => {
            const pos = UI.positionRect(anchor);
            // const style = `opacity:1; top:${h * 0.2}px; left:${(w - pop.elem.offsetWidth) * 0.5}px; max-width:400px; max-height:600px; transform:scale(1);`;
            let x = 0;
            switch (opt.Position) {
                case 'Start': x = anchor.offsetLeft; break;
                case 'Center': x = (anchor.offsetLeft + (pos.width * 0.5)) - (pop.elem.offsetWidth * 0.5); break;
                case 'End': x = anchor.offsetLeft + pos.width - pop.elem.offsetWidth; break;
            }
            let y = anchor.offsetTop + pos.height; // Below.
            if (pos.top + pop.elem.offsetHeight > h) { // Is past the screen bottom.
                y = anchor.offsetTop - pop.elem.offsetHeight; // Above
                if (pos.top - pop.elem.offsetHeight < 0) { // Is past the screen top.
                    if (pop.elem.offsetHeight > h || pos.top < (h * 0.5)) { // Place screen top.
                        y = y + (pop.elem.offsetHeight - pos.top);
                    }
                    else { // Place screen bottom.
                        y = anchor.offsetTop + ((h - pos.top) - pop.elem.offsetHeight);
                    }
                }
            }
            const style = `opacity:1; top:${y}px; left:${x}px;min-width:${wAnchor}px`;
            UI.style(pop.elem, style);
            pop.elem.focus();
        });
    }

    static showX (message, title, type) {
        const overlay = UI.create('div', { class: 'foui-alert-overlay' });
        document.body.appendChild(overlay);
        const alert = new Controls.Alert({
            Props: { Type: 'Alert', Title: title, Message: message, AlertType: type || 'Info', Events: { Close: 'onClose' } },
            Parent: document.body,
            // arentControl: this,
            // data: this.data,
            codeHandlers: {
                onClose (evt) {
                    // console.log('Closed', evt.detail);
                    UI.remove(overlay);
                }
            }
        });
        const w = document.body.offsetWidth;
        const h = document.body.offsetHeight;
        setImmediate(() => {
            const style = `opacity:1; top:${h * 0.2}px; left:${(w - alert.elem.offsetWidth) * 0.5}px; max-width:400px; max-height:600px; transform:scale(1);`;
            UI.style(alert.elem, style);
            UI.style(overlay, 'backdrop-filter:blur(1px); opacity:1;');
            alert.meta.ui.button.focus();
        });
    }

    static dialog (opt, callback) {
        if (!opt) return;
        const FOUI = window[window.FOUI_APP_KEY].FOUI;
        Util.applyLanguage('Dialog', opt, FOUI.Languages[FOUI.Lang]); // Replace value placeholder keys withg the selected language values.
        const overlay = UI.create('div', { class: 'foui-dialog-overlay' });
        document.body.appendChild(overlay);
        const Props = {
            Type: 'Dialog',
            Title: opt.title,
            IconName: opt.icon,
            Wizard: opt.wizard === true,
            ManualClose: opt.manual || false,
            Height: opt.height,
            Width: opt.width,
            Content: opt.content,
            Buttons: opt.buttons,
            WizardFields: opt.fields,
            WizardValues: opt.values,
            Events: {
                Close: 'onClose',
                ManualClose: 'onManualClose'
            }
        };
        const dialog = new Controls.Dialog({
            Props,
            Parent: document.body,
            parentControl: null,
            // data: this.data,
            initParams: opt.initParams,
            codeHandlers: {
                onClose (evt) {
                    UI.stopEvent(evt);
                    UI.remove(overlay);
                    if (callback) callback(evt.detail);
                },
                onManualClose (evt) {
                    UI.stopEvent(evt);
                    UI.remove(overlay);
                }
            }
        });
        const w = document.body.offsetWidth;
        const h = document.body.offsetHeight;
        setImmediate(() => {
            // const pos = UI.positionRect(dialog.elem);
            // const style = `opacity:1; top:${h * 0.2}px; left:${(w - dialog.elem.offsetWidth) * 0.5}px; max-width:400px; max-height:600px; transform:scale(1);`;
            const own = parseInt(opt.width);
            const ow = opt.width.endsWith('%') ? (own / 100) * w : own;
            // console.log(ow, w, (own / 100) * w, (own / 100));
            let x = (w - ow) * 0.5;
            if (x < 0) x = 0;
            const ohn = parseInt(opt.height);
            const oh = opt.height.endsWith('%') ? (ohn / 100) * h : ohn;
            let y = (h - oh) * 0.33;
            if (h < 0) h = 0;
            const style = `opacity:1; transform:scale(1); top:${y}px; left:${x}px;`;
            // const style = `opacity:1; top:${h * 0.33 - 200}px; left:${w * 0.5 - 200}px; transform:scale(1);`; // max-width:400px; max-height:600px;
            UI.style(dialog.elem, style);
            UI.style(overlay, 'backdrop-filter:blur(1px); opacity:1;');
            dialog.elem.focus();
        });

        return dialog;
    }

    static popup ({ Anchor, Position, Control, ParentControl, MaxHeight, CloseOnEvent, PreCloseValidate }, callback) {
        if (!Anchor || !Control) return console.warn('Cannot show a popup without Anchor or Control.');

        // Store the popup on the FOUI instance Meta object.
        const meta = ParentControl.FOUI.Meta;
        const onPopupClose = function (evt) {
            // console.log(evt, meta.popup !== undefined);
            // UI.stopEvent(evt);
            // if (!(evt instanceof CustomEvent)) return;
            // if (Object.prototype.toString.call(evt) !== '[object CustomEvent]') return;
            const isCustom = Object.prototype.toString.call(evt) === '[object CustomEvent]';
            UI.off(document, 'click', onPopupClose, false);
            setImmediate(() => {
                if (!meta.popup) return;
                meta.popup.detachEvents();
                meta.popup.destroy();
                delete meta.popup;
                if (callback && isCustom) callback(evt.detail);
            });
        };
        /* UI.on(document, 'click', onPopupClose, false); */
        // UI.on(document.body, 'close-popup', onPopupClose, true);

        const anchorWidth = Anchor.offsetWidth;
        const popProperties = {
            // AnchorToSide: Position,
            // Control,
            // Items: opt.Items || [],
            Width: anchorWidth,
            Events: {
                Close: onPopupClose
                /* Close: (evt) => {
                    console.log(evt);
                    UI.stopEvent(evt);
                    pop.detachEvents();
                    pop.destroy();
                    if (callback) callback(evt.detail);
                } */
            }
        };
        // const pop = new Controls.PopupMenu({ Props: popProperties, Parent: opt.ParentControl, Data: opt.Data || Watch({}), Code: opt.code || {}, $name: opt.$name });
        // const pop = ParentControl.newControl({ Type: 'Popup', Props: popProperties, Control }, null); // null so that it isn't added to ParentControl
        meta.popup = new Controls.Popup({ Type: 'Popup', GlobalHide: true, Props: popProperties, Meta: { CloseOnEvent, PopControl: Control }, Parent: undefined, Data: ParentControl.data, Code: ParentControl.code, $name: ParentControl.$name });
        const pop = meta.popup;
        document.body.append(pop.elem);
        // const w = document.body.offsetWidth;
        const h = document.body.offsetHeight;
        setImmediate(() => {
            UI.on(document, 'click', onPopupClose, false);
            // Position.
            let maxHeight = 'auto';
            const anchor = UI.positionRect(Anchor);
            const popSize = UI.positionRect(pop.elem);
            if (popSize.height > MaxHeight) maxHeight = `${MaxHeight}px`;
            // const style = `opacity:1; top:${h * 0.2}px; left:${(w - pop.elem.offsetWidth) * 0.5}px; max-width:400px; max-height:600px; transform:scale(1);`;
            let x = 0;
            switch (Position) {
                case 'Start': x = anchor.left; break;
                case 'Center': x = (anchor.left + (anchor.width * 0.5)) - (pop.elem.offsetWidth * 0.5); break;
                case 'End': x = anchor.left + anchor.width - pop.elem.offsetWidth; break;
            }
            let y = anchor.top + anchor.height; // Below.
            if (anchor.top + pop.elem.offsetHeight > h) { // Is past the screen bottom.
                y = anchor.top - pop.elem.offsetHeight; // Above
                if (anchor.top - pop.elem.offsetHeight < 0) { // Is past the screen top.
                    if (pop.elem.offsetHeight > h || anchor.top < (h * 0.5)) { // Place screen top.
                        y = y + (pop.elem.offsetHeight - anchor.top);
                    }
                    else { // Place screen bottom.
                        y = anchor.top + ((h - anchor.top) - pop.elem.offsetHeight);
                    }
                }
            }
            const style = `opacity:1; top:${y}px; left:${x}px; min-width:${anchor.width}px; height:${maxHeight};`;
            UI.style(pop.elem, style);
            setTimeout(() => {
                UI.attr(pop.ui.popControl.elem, { tabindex: 0 });
                pop.ui.popControl.elem.focus();
            }, 25);
        });
    }
}
