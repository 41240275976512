// import Color from '../../util/color';
import Control from '../control';
import paper from 'paper';
import PaperUtil from '../../util/paperUtil';
import Util from '../../util/util';
import UI from '../../util/ui';
import { CONTROL_EVENTS } from '../../constants/mapped-events';
// import PropertyConstants from '../../constants/property-constants';

const connectorOffsetY = 31;
const hitOptions = {
    segments: true,
    stroke: true,
    fill: true,
    tolerance: 5
};

export default class WorkflowBoard extends Control {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'WorkflowBoard';
        super(definition, WorkflowBoard._DEF.Properties);
    }

    init () {
        const style = getComputedStyle(document.body);
        const controlClr = style.getPropertyValue('--control');
        this.colour = {
            primary: style.getPropertyValue('--primary'),
            accent: style.getPropertyValue('--accent'),
            success: style.getPropertyValue('--success'),
            warning: style.getPropertyValue('--warning'),
            danger: style.getPropertyValue('--danger'),
            info: style.getPropertyValue('--info'),
            dull: style.getPropertyValue('--dull'),
            control: controlClr,
            border: style.getPropertyValue('--border'),
            main: style.getPropertyValue('--text-main'),
            input: style.getPropertyValue('--text-input'),
            primaryText: style.getPropertyValue('--primary-text'),
        };

        this.meta.steps = new Map();
        this.meta.links = new WeakMap();
        this.addClass('foui-ctrl foui-control-panel foui-stretch foui-back-halftone');
        this.selected = [];
        if (this.props.SnapGrid === undefined) this.props.SnapGrid = 0;
        setTimeout(() => {
            this.ui.canvas = UI.create('canvas', { class: 'foui-workflow', style: 'width: 100%; height: 100%;' });
            this.elem.append(this.ui.canvas);
            paper.setup(this.ui.canvas);
            // this.setBackground();
            this.update();
            // Events.
            paper.view.on('mousedown', this.handlers.onMouseDown);
            paper.view.on('mousedrag', this.handlers.onMouseDrag);
            paper.view.on('mouseup', this.handlers.onMouseUp);
            paper.view.on('frame', this.handlers.onFrame);
            // paper.view.zoom = 0.75;
            // paper.view.translate(new paper.Point(100, 100));
        }, 50);
    }

    attachEvents () {
        // Bind to this control.
        // this.handlers.onClick = this.onClick.bind(this);
        this.handlers.onMouseDown = this.onMouseDown.bind(this);
        this.handlers.onMouseDrag = this.onMouseDrag.bind(this);
        this.handlers.onMouseUp = this.onMouseUp.bind(this);
        this.handlers.onFrame = this.onFrame.bind(this);
        // Attach the listener(s).
        // UI.on(this.elem, 'click', this.handlers.onClick, true);

        this.handlers.sendChange = Util.throttle(this.sendChange, 200).bind(this);
        this.handlers.sendSelect = Util.throttle(this.sendSelect, 200).bind(this);
    }

    detachEvents () {
        // UI.off(this.elem, 'click', this.handlers.onClick, true); // mousedown
        paper.view.off('mousedown', this.handlers.onMouseDown);
        paper.view.off('mousedrag', this.handlers.onMouseDrag);
        paper.view.off('mouseup', this.handlers.onMouseUp);
        paper.view.off('frame', this.handlers.onFrame);
    }

    onChange (changes) {
        for (const [key, v] of changes) {
            // console.log('flow X', key, v);
            const value = Util.checkForBoundedValue(v, this);
            switch (key) {
                case 'Class': {
                    this.setClass(value);
                    if (this.props.Bordered) this.addClass('bordered');
                    else this.removeClass('bordered');
                    break;
                }
                /* case 'Controls': {
                    if (value && value.length) {
                        for (const def of this.props.Controls) {
                            // const control = this.props.DesignMode ? def : Util.duplicate(def);
                            // const ctrl = new Controls[control.Type]({ Props: control.Props, Parent: this, Data: this.data, Code: this.code, $name: this.$name });
                            const ctrl = this.newControl(def);
                            this.meta.ControlList.push(ctrl);
                        }
                    }
                    break;
                } */
                /* case 'Item': {
                    console.log('flow', v);
                    break;
                } */
                case 'Height': this.setHeight(value); break;
                case 'Width': this.setWidth(value); break;
            }
        }
    }

    /* setBackground () {
        const path = new paper.Path.Circle(new paper.Point(0, 0), 1);
        path.fillColor = '#555555';
        path.locked = true;
        // Create a symbol from the path:
        const symbol = new paper.Symbol(path);
        symbol.locked = true;

        const unit = 10;
        const w = paper.view.bounds.width;
        const h = paper.view.bounds.height;
        let x = 0;
        let y = 0;
        for (let r = 0; r < h; r += unit) {
            y += unit;
            x = 0;
            for (let c = 0; c < w; c += unit) {
                x += unit;
                // plot.push(new paper.Point(x, y));
                const placed = symbol.place(new paper.Point(x, y));
                placed.locked = true;
            }
        }

        paper.project.activeLayer.locked = true;
        this.layer = new paper.Layer();
    } */

    update (steps) {
        // Create the Step.
        const stepList = steps || this.props.Item.Steps;
        for (const step of stepList) {
            const stepNow = paper.project.getItem({
                match: (o) => o.name === step.Name && o.className === 'Group' && o.data.workstep === 1,
            });
            if (stepNow) {
                // console.log('step', stepNow);
                const text = stepNow.getItem({
                    match: (o) => o.data.type === 'text',
                });
                if (text.content !== step.Text) text.content = step.Text;
                const icon = stepNow.getItem({
                    match: (o) => o.data.type === 'icon',
                });
                if (icon.name !== `icon_${step.Icon}`) PaperUtil.replaceStepIcon(paper, stepNow, step.Icon);
                continue;
            }

            const uiStep = PaperUtil.makeStep(paper, {
                colour: this.colour,
                icon: step.Icon,
                text: step.Text
            });
            uiStep.name = step.Name;
            this.meta.steps.set(step.Name, uiStep);
            uiStep.position = new paper.Point(step.Position.x, step.Position.y);
        }

        // Create the links.
        const children = paper.project.activeLayer.children;
        for (const step of stepList) {
            if (!step.Out.length) continue;
            for (const out of step.Out) {
                const uiFrom = children[step.Name];
                const toStep = this.props.Item.Steps.find(o => o.Name === out.Name);
                const uiTo = children[toStep.Name];

                const linkName = `${step.Name}_to_${toStep.Name}`;
                const linkNow = paper.project.getItem({
                    match: (o) => o.name === linkName && o.className === 'Group' && o.data.worklink === 1,
                });
                if (linkNow) {
                    console.log('link', linkNow);
                    continue;
                }

                const fromPoint = new paper.Point(uiFrom.bounds.topCenter.x + 30, uiFrom.bounds.y + connectorOffsetY);
                const toPoint = new paper.Point(uiTo.bounds.topCenter.x - 30, uiTo.bounds.y + connectorOffsetY);
                const link = PaperUtil.makeJoinLine(paper, fromPoint, toPoint, { colour: this.colour.primary });
                link.name = linkName;

                const linksFrom = this.meta.links.get(uiFrom) || {};
                if (!linksFrom.to) linksFrom.to = [];
                linksFrom.to.push(link);
                this.meta.links.set(uiFrom, linksFrom);

                const linksTo = this.meta.links.get(uiTo) || {};
                if (!linksTo.from) linksTo.from = [];
                linksTo.from.push(link);
                this.meta.links.set(uiTo, linksTo);
            }
        }

        /* const endRadians = Math.atan((this.y2 - this.y1) / (this.x2 - this.x1));
        endRadians += ((this.x2 > this.x1) ? 90 : -90) * Math.PI / 180;
        this.drawArrowhead(ctx, this.x2, this.y2, endRadians); */

        // Create a Paper.js Path to draw a line into it:
        /* const path = new paper.Path();
        // Give the stroke a color
        path.strokeColor = '#FFFFFF';
        const start = new paper.Point(100, 100);
        // Move to start and draw a line from there
        path.moveTo(start);
        // Note that the plus operator on Point objects does not work
        // in JavaScript. Instead, we need to call the add() function:
        path.lineTo(start.add([200, -50]));
        // Draw the view now:
        paper.view.draw(); */

        /* const rect = new paper.Rectangle(new paper.Point(0, 0), new paper.Point(60, 60));
        const radius = new paper.Size(3, 3);
        const path = new paper.Path.Rectangle(rect, radius);
        path.strokeColor = '#6C6C6C';
        path.strokeWidth = 2;
        // path.dashArray = [10, 12];
        // path.strokeCap = 'round';
        path.fillColor = '#454545';

        // const symbol = new Symbol(path);
        // const placed = symbol.place(new Point(80, 50));
        // const g = paper.Group([]);

        let iconString = Util.getIconString('Data');
        // The icon cannot have 100% width/height. Set a fixed size.
        if (iconString.indexOf('width="100%"') > -1) iconString = iconString.replace('width="100%"', 'width="32px"');
        if (iconString.indexOf('height="100%"') > -1) iconString = iconString.replace('height="100%"', 'height="32px"');
        const svg = paper.project.importSVG(iconString);
        // svg.scale(2);
        svg.position = new paper.Point(30, 30);

        const text = new paper.PointText({
            point: [0, 0],
            content: 'PostgreSQL',
            fillColor: '#D4D4D4',
            fontFamily: 'Inter var',
            fontWeight: '300',
            fontSize: 14,
            justification: 'center',
        });
        // text.pivot = new paper.Point(text.bounds.x, text.bounds.y * -1);
        text.position = new paper.Point(30, 75); */
    }

    onMouseDown (evt) {
        // Test if anything is under the pointer.
        const hitResult = paper.project.hitTest(evt.point, hitOptions);
        if (!hitResult) { // Nothing under the pointer, then deselect an existing selection.
            this.deselectAll();
            this.runningStep = null;
            return;
        }

        // Check if the clicked item is in the current selection. If so, do nothing further.
        const step = this.getGroup(hitResult.item);
        if (this.selected.length && this.selected.indexOf(step) > -1) {
            /* const offsetPoint = new paper.Point(evt.point.x - step.bounds.x, evt.point.y - step.bounds.y);
            for (const o of this.selected) {
                o.data.point = new paper.Point(o.bounds.x, o.bounds.y);
                o.data.pointOffset = offsetPoint;
            } */
            return;
        }

        this.deselectAll();
        // Single select the item under the pointer.
        this.selected = [step];
        // step.data.point = new paper.Point(step.bounds.center.x, step.bounds.center.y);
        /* step.data.point = new paper.Point(step.bounds.x, step.bounds.y);
        step.data.pointOffset = new paper.Point(evt.point.x - step.bounds.x, evt.point.y - step.bounds.y); */
        step.data.selected = true;
        step.children[0].visible = true;
        UI.trigger(this.elem, CONTROL_EVENTS.Select, [step.name]);

        /* this.runningStep = step;
        this.antStep = 0;
        const ant = this.runningStep.children[0];
        ant.dashArray = [21, 11]; // 6, 4 // 21, 11
        ant.dashOffset = this.antStep;
        const running = this.runningStep.children[6];
        running.opacity = 1; */
    }

    onMouseDrag (evt) {
        if (!this.selected.length || this.selectionStarted) {
            // If nothing is already selected and no drag selection has started, this is the start of the drag selection rectangle.
            this.selectionStarted = true;
            // Check if the focus rectangle has been created yet.
            if (!this.focusRect) {
                // Doesn't exist yet. Create it.
                const fillClr = new paper.Color(this.colour.primary);
                fillClr.alpha = 0.1;
                this.focusRect = new paper.Path.Rectangle(evt.point, new paper.Size(1, 1));
                this.focusRect.strokeColor = this.colour.primary;
                this.focusRect.fillColor = fillClr;
                this.focusRect.strokeWidth = 1;
                this.focusRect.dashArray = [5, 3];
                // this.focusRect.opacity = 0.5;
                this.focusRect.data.startPoint = evt.point; // Keep track of the start point.
                // this.focusRect.blendMode = 'overlay';
                this.focusRect.pivot = evt.point; // Local coords to 0, 0.
            }
            else {
                // The rectangle exists. Adjust its size.
                const startPoint = this.focusRect.data.startPoint;
                const newPos = startPoint.clone();
                const newSize = new paper.Size(evt.point.x - startPoint.x, evt.point.y - startPoint.y);
                // Check for negative selection.
                if (newSize.width < 0) {
                    newSize.width *= -1;
                    newPos.x = startPoint.x - newSize.width;
                }
                // Check for negative selection.
                if (newSize.width === 0) newSize.width = 1;
                if (newSize.height < 0) {
                    newSize.height *= -1;
                    newPos.y = startPoint.y - newSize.height;
                }
                if (newSize.height === 0) newSize.height = 1;
                // Update the path.
                this.focusRect.position = newPos;
                this.focusRect.bounds.width = newSize.width;
                this.focusRect.bounds.height = newSize.height;

                // Select all the controls inside the selection block.
                // const hitResult = paper.project.hitTestAll(evt.point, hitOptions);
                const items = paper.project.getItems({
                    // class: paper.Group,
                    overlapping: new paper.Rectangle(newPos, newSize),
                    match: (o) => o.className === 'Group' && o.data.workstep === 1,
                });
                // Deselect the items that are not in the selection anymore.
                for (const o of this.selected) {
                    if (items.indexOf(o) > -1) continue;
                    o.data.selected = false;
                    o.children[0].visible = false;
                }
                // Update the list and set selected.
                this.selected = items;
                for (const o of this.selected) {
                    if (o.data.selected) continue;
                    o.data.selected = true;
                    o.children[0].visible = true;
                }
                // UI.trigger(this.elem, CONTROL_EVENTS.Select, this.selected.map(o => o.name));
                this.handlers.sendSelect(this.selected.map(o => o.name));
            }
            return;
        }

        // Getting here will move the selected steps.
        for (const step of this.selected) {
            /* step.data.point = step.data.point.add(evt.delta);
            const pt = step.data.point.clone(); */
            const pt = evt.delta;
            /* if (this.props.SnapGrid > 1) {
                pt.x = Math.round(pt.x / this.props.SnapGrid) * this.props.SnapGrid;
                pt.y = Math.round(pt.y / this.props.SnapGrid) * this.props.SnapGrid;
            } */
            if (step.position.x === pt.y && step.position.x === pt.y) continue; // Nothing has changed.
            // Move the step.
            /* step.position.x = pt.x + step.data.pointOffset.x;
            step.position.y = pt.y + step.data.pointOffset.y; */
            step.position.x += pt.x;
            step.position.y += pt.y;
            // Update the linked data item.
            const dataStep = this.props.Item.Steps.find(o => o.Name === step.name);
            dataStep.Position.x = step.position.x;
            dataStep.Position.y = step.position.y;
            // UI.trigger(this.elem, CONTROL_EVENTS.Change, { Position: dataStep.Position });

            // Move the link connectors.
            const links = this.meta.links.get(step);
            // Current implementation is based on a 4 segment line.
            if (links.from && links.from.length) {
                for (const link of links.from) {
                    const point = new paper.Point(step.bounds.topCenter.x - 30, step.bounds.y + connectorOffsetY + 3);
                    const line = link.children[1];
                    line.segments[3].point = point;
                    const offset = point.subtract(new paper.Point(10, 0));
                    line.segments[2].point = offset;
                    const arrow = link.children[2];
                    arrow.position = point;
                }
            }
            if (links.to && links.to.length) {
                for (const link of links.to) {
                    const point = new paper.Point(step.bounds.topCenter.x + 30, step.bounds.y + connectorOffsetY + 3);
                    const line = link.children[1];
                    line.segments[0].point = point;
                    const offset = point.add(new paper.Point(10, 0));
                    line.segments[1].point = offset;
                    const ball = link.children[0];
                    ball.position = point;
                }
            }
        }
        this.handlers.sendChange(this.selected.map(o => o.name));
    }

    onMouseUp () {
        if (this.focusRect) {
            this.focusRect.remove();
            delete this.focusRect;
        }
        if (this.selectionStarted) this.selectionStarted = false;
        /* if (this.selected.length) {
            for (const o of this.selected) {
                o.data.point = new paper.Point(o.bounds.x, o.bounds.y);
            }
        } */
    }

    onFrame () {
        if (!this.runningStep) return;
        const ant = this.runningStep.children[0];
        this.antStep += 0.5;
        if (this.antStep > 1000000) this.antStep = 0;
        ant.dashOffset = this.antStep;

        const o = this.runningStep.children[5];
        o.opacity += 0.015 * this.runningStep.data.dir;
        if (o.opacity > 0.6) {
            this.runningStep.data.dir = -0.5;
        }
        if (o.opacity < 0.3) {
            this.runningStep.data.dir = 0.5;
        }
    }

    deselectAll () {
        if (this.selected.length) {
            UI.trigger(this.elem, CONTROL_EVENTS.Select, []);
            for (let i = this.selected.length - 1; i >= 0; i--) {
                const step = this.selected.pop();
                step.children[0].visible = false;
                step.data.selected = false;
            }
        }
    }

    getGroup (item) {
        if (!item.parent) return null;
        if (item.parent.className === 'Group' && item.parent.data.workstep === 1) return item.parent;
        return this.getGroup(item.parent);
    }

    sendChange (data) {
        UI.trigger(this.elem, CONTROL_EVENTS.Change, data);
    }

    sendSelect (data) {
        UI.trigger(this.elem, CONTROL_EVENTS.Select, data);
    }
}

// Control property definitions.
Object.defineProperty(WorkflowBoard, '_DEF', {
    value: {
        Group: 'Workflow',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Style Class', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout panel into design mode.' },
            Height: { Default: undefined, Group: 'Size', Label: 'Width', Type: 'Number', Note: 'Height set as px or %.' },
            Item: { Default: undefined, Group: 'Data', Label: 'Item', Type: 'Any', Note: 'Workflow definition and steps.' },
            SnapGrid: { Default: undefined, Group: 'Data', Label: 'Snap Grid', Type: 'Number', Note: 'The unit size of the grid the item snaps to when moved.' },
            Width: { Default: undefined, Group: 'Size', Label: 'Width', Type: 'Number', Note: 'Width set as px or %.' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
