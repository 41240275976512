import Control from './control';
import Util from '../util/util';
import UI from '../util/ui';

export default class Table extends Control {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'Table';
        // definition.Props.ClassFixed = 'foui-ctrl foui-control-panel foui-stretch';
        super(definition, Table._DEF.Properties);
    }

    init () {
        const clsFixed = `foui-ctrl foui-control-panel foui-stretch foui-table ${this.props.ClassFixed || ''}`.trim();
        this.addClass(clsFixed);
        // Setting `this.meta.ClassFixed` only works if `Class` is handled in `onChange`.
        this.props.ClassFixed = clsFixed;
        UI.attr(this.elem, { tabindex: 0 });

        // Name starts with _ so that it won't be added to the $name lookups.
        /* this.ui.text = this.newControl({ Type: 'CoreInput', Props: { Name: `_${this.props.Name}_text`, ClassFixed: 'foui-text foui-stretch-y' } });
        this.bindControl(this.ui.text, [
            { other: 'Class', local: 'Class' },
            // { other: 'ClassFixed', local: 'ClassFixed' },
            { other: 'Disabled', local: 'Disabled' },
            { other: 'Max', local: 'Max' },
            { other: 'Min', local: 'Min' },
            { other: 'Multiple', local: 'Multiple' },
            { other: 'Placeholder', local: 'Placeholder' },
            { other: 'ReadOnly', local: 'ReadOnly' },
            { other: 'Step', local: 'Step' },
            { other: 'Tip', local: 'Tip' },
            { other: 'Type', local: 'Type' },
            { other: 'Value', local: 'Value' },
        ]); */

        this.meta.filterFields = {};
        this.ui.table = UI.create('table', { class: 'foui-table-table' });
        this.ui.header = UI.create('thead', { class: 'foui-table-head' });
        this.ui.body = UI.create('tbody', { class: 'foui-table-body' });
        this.ui.table.append(this.ui.header);
        this.ui.table.append(this.ui.body);
        this.elem.append(this.ui.table);
        // this.ui.indeterminate = UI.create('div', { class: 'foui-table-progress foui-progress', html: '<div class="foui-progress-line"></div><div class="foui-progress-subline foui-progress-inc"></div><div class="foui-progress-subline foui-progress-dec"></div>' });
        this.ui.indeterminate = UI.create('div', { class: 'foui-table-progress', html: '<div class="foui-progress"><div class="foui-progress-line"></div><div class="foui-progress-subline foui-progress-inc"></div><div class="foui-progress-subline foui-progress-dec"></div></div>' });

        this.loadItems();
    }

    attachEvents () {
        // Bind to this control.
        this.handlers.onHeaderClick = this.onHeaderClick.bind(this);
        this.handlers.onHeaderFocus = this.onHeaderFocus.bind(this);
        this.handlers.onCellClick = this.onCellClick.bind(this);
        this.handlers.onCellDblClick = this.onCellDblClick.bind(this);
        this.handlers.onKeyUp = this.onKeyUp.bind(this);
        // Attach the listener(s).
        UI.on(this.ui.table, 'keyup', this.handlers.onKeyUp, true);
        UI.on(this.ui.header, 'click', this.handlers.onHeaderClick, true);
        UI.on(this.ui.header, 'focus', this.handlers.onHeaderFocus, true);
        UI.on(this.ui.body, 'click', this.handlers.onCellClick, true);
        UI.on(this.ui.body, 'dblclick', this.handlers.onCellDblClick, true);
    }

    detachEvents () {
        UI.off(this.ui.table, 'keyup', this.handlers.onKeyUp, true);
        UI.off(this.ui.header, 'click', this.handlers.onHeaderClick, true);
        UI.off(this.ui.header, 'focus', this.handlers.onHeaderFocus, true);
        UI.off(this.ui.body, 'click', this.handlers.onCellClick, true);
        UI.off(this.ui.body, 'dblclick', this.handlers.onCellDblClick, true);
    }

    onChange (changes) {
        // console.log('Text changes', changes);
        for (const [key, v] of changes) {
            const value = Util.checkForBoundedValue(v, this);
            switch (key) {
                case 'Bordered': {
                    if (value) this.addClass('bordered');
                    else this.removeClass('bordered');
                    break;
                }
                case 'Busy': {
                    this.doBusy();
                    break;
                }
                case 'Columns': {
                    this.loadHeaders();
                    break;
                }
                case 'CustomHeaders': {
                    this.loadHeaders();
                    break;
                }
                case 'HeaderBorders': {
                    if (value) UI.addClass(this.elem, 'foui-table-head-borders');
                    else UI.removeClass(this.elem, 'foui-table-head-borders');
                    break;
                }
                case 'Items': {
                    // Clear sort and filter.
                    delete this.meta.sortItems;
                    delete this.meta.filterItems;
                    // Make sure there are ids and indexes.
                    this.props.Items.forEach((item, i) => {
                        if (item._idx_ === undefined) item._idx_ = i;
                        if (item.Key === undefined) item.Key = i + 1;
                    });
                    this.loadItems();
                    break;
                }
                case 'ItemBorders':
                    UI.removeClass(this.elem, 'foui-table-borders-all');
                    UI.removeClass(this.elem, 'foui-table-borders-horz');
                    UI.removeClass(this.elem, 'foui-table-borders-vert');
                    switch (value) {
                        case 'all': UI.addClass(this.elem, 'foui-table-borders-all'); break;
                        case 'horz': UI.addClass(this.elem, 'foui-table-borders-horz'); break;
                        case 'vert': UI.addClass(this.elem, 'foui-table-borders-vert'); break;
                    }
                    break;
                case 'StickyHeader':
                    UI.removeClass(this.elem, 'foui-table-sticky-asis');
                    UI.removeClass(this.elem, 'foui-table-sticky-top');
                    switch (value) {
                        case 'asis': UI.addClass(this.elem, 'foui-table-sticky-asis'); break;
                        case 'top': UI.addClass(this.elem, 'foui-table-sticky-top'); break;
                    }
                    break;
                case 'Zebra':
                    if (value) UI.addClass(this.elem, 'foui-table-zebra');
                    else UI.removeClass(this.elem, 'foui-table-zebra');
                // case 'Width': this.updateWidth(); break;
            }
        }
    }

    doBusy () {
        if (this.props.Busy) {
            this.elem.append(this.ui.indeterminate);
        }
        else {
            UI.remove(this.ui.indeterminate);
        }
    }

    getItems () {
        return this.meta.sortItems || this.meta.filterItems || this.props.Items || [];
    }

    getHeader (col, idx, headLen, isArray) {
        return headLen > 1
            ? (typeof col.Header === 'string' && idx === 0
                ? col.Header
                : (isArray
                    ? (typeof col.Header[idx] === 'string' ? { Header: col.Header[idx] } : col.Header[idx])
                    : (idx === 0 ? col.Header : null)
                )
            )
            : typeof col.Header === 'string' ? col : (isArray ? null : col.Header); // Single row header.
    }

    loadHeaders () {
        // this.ui.header.innerHTML = '';

        // Check how many header rows are defined.
        // Check if filters are activated.
        // Check if a 'new record' line must be added.
        // Check if any column has a filter flag set.
        let filter = false;
        const columns = this.props.Columns;
        const items = []; // Create rows based on the column definitions.
        columns.forEach((col, ci) => {
            if (Array.isArray(col.Header)) {
                for (let i = 0; i < col.Header.length; i++) { // Create a row for each header row.
                    const h = col.Header[i];
                    if (!h) continue;
                    if (!items[i]) items[i] = { Key: `_head_${i + 1}`, Columns: [] };
                    if (typeof h === 'object') {
                        if (!filter && h.Filter) filter = true;
                        items[i].Columns[ci] = h.toJSON();
                    }
                    else {
                        items[i].Columns[ci] = { Header: h }; // Just the title is supplied.
                    }
                    if (!items[i].Columns[ci].Align && col.Align) items[i].Columns[ci].Align = col.Align;
                    if (items[i].Columns[ci].Width === undefined && col.Width) { // && col.Width !== undefined
                        items[i].Columns[ci].Width = col.Width;
                    }
                    items[i].Columns[ci].Field = col.Field;
                    if (items[i].Columns[ci].Sort !== undefined && !items[i].Sort) items[i].Sort = true;
                }
            }
            else if (typeof col.Header === 'object' && col.Header.Filter && !filter) filter = true;
        });
        if (filter) {
            const cols = [];
            columns.forEach((col, i) => {
                if (col.Header === undefined) {
                    cols[i] = { Value: '' };
                    return;
                }
                let head = null;
                // let headIdx = 0;
                if (Array.isArray(col.Header)) {
                    for (let hi = col.Header.length - 1; hi >= 0; hi--) { // Last Filter.
                        if (col.Header[hi] && col.Header[hi].Filter) {
                            head = col.Header[hi];
                            // headIdx = hi;
                            break;
                        }
                    }
                    if (!head) head = { Value: '' };
                }
                else if (typeof col.Header === 'object') {
                    if (col.Header.Filter) head = col.Header;
                    else head = { Value: '' };
                }
                if (head) { // Filter.
                    cols[i] = head.toJSON ? head.toJSON() : head;
                }
                // else // No filter.
            });
            items.push({ Key: `_head_${items.length + 1}`, Filter: true, Columns: cols });
        }
        if (this.props.CustomHeaders.length) {
            this.props.CustomHeaders.forEach((row, ri) => {
                if (!row.Key) row.Key = `_head_${items.length + 1}`;
                const cust = { Key: row.Key, Custom: true, Columns: [] };
                row.Columns.forEach((col, ci) => {
                    cust.Columns[ci] = col.toJSON();
                    if (cust.Columns[ci].EditControl && cust.Columns[ci].EditControl.Checked !== undefined) cust.Toggle = true;
                });
                items.push(cust);
                // { Value: 'Name', EditControl: { Type: 'Text', Checked: true } },
                // if (items[i].Columns[ci].Sort !== undefined && !items[i].Sort) items[i].Sort = true;
            });
        }
        const headLen = items.length;
        if (!this.meta.loadedHeader) { // Initial load.
            for (let ri = 0; ri < headLen; ri++) {
                // this.addHeader(ri, columns, headLen);
                this.addHeader(items[ri], ri, headLen);
            }
        }
        else {
            const foundItems = [];
            items.forEach((item, i) => {
                // Diff the new list with the old list and only apply changed items.
                const old = this.meta.loadedHeader.find(o => o.Key === item.Key);
                console.log('H OLD', old);
                if (old) { // Update.
                    foundItems.push(item.Key);
                    // Check if different.
                    const delta = Util.delta(old, item);
                    if (delta) {
                        const keys = Object.keys(delta);
                        // console.log('Item Changes ---------------');
                        const elem = UI.find(this.ui.header, `[data-key="${old.Key}"]`);
                        this.updateHeader(elem, Object.assign({}, item, delta), delta, keys, columns); // New delta combined rec with assign.
                    }
                }
                else { // Doesn't exist. Full add.
                    const itemAt = this.meta.loadedHeader[i]; // Get the old element at this position.
                    if (itemAt) {
                        const elemAt = UI.find(this.ui.header, `[data-idx="${i}"]`);
                        if (elemAt) {
                            const ctrl = this.addHeader(items[i], i, headLen, true);
                            elemAt.before(ctrl);
                        }
                        else this.addHeader(items[i], i, headLen);
                    }
                    else this.addHeader(items[i], i, headLen);
                }
            });
            // Check which items were removed.
            for (const old of this.meta.loadedHeader) {
                const has = items.find(o => o.Key === old.Key);
                if (!has) {
                    // No existing item for the old entry. Remove its control.
                    const elem = UI.find(this.ui.header, `[data-key="${old.Key}"]`);
                    UI.remove(elem);
                }
            }
        }
        this.meta.loadedHeader = Util.duplicate(items);
    }

    addHeader (record, hi, headLen, virtual) {
        // Header row properties.
        // Field | Selected | Busy | Align | AlignVertical | Back | Class | Color | DisplayAs | EditControl | EditRenderer | Field | Formatter | Header | PreRender | Renderer | Width
        // DisplayAs - Slider, Dot, Arrow, Bar, SparkLine, etc
        const tr = UI.create('tr', { 'data-key': record.Key, 'data-idx': hi });
        if (this.props.RowNumbers) {
            this.addHeaderCounterColumn(tr, 1, record.Toggle);
        }
        record.Columns.forEach((head, i) => {
            if (!head) return;
            const attr = { 'data-idx': i };
            if (record.Filter) {
                if (head.Value === '') attr.html = '&nbsp;';
                else {
                    attr.contentEditable = true;
                    attr.tabindex = 0;
                    attr.class = `${attr.class || ''} foui-table-filter`;
                    attr['data-for'] = 'filter';
                    attr['data-idx'] = i;
                }
            }
            else if (record.Custom) {
                attr.html = head.Value;
                attr['data-for'] = 'custom';
                if (head.EditControl) {
                    if (head.EditControl.Type !== 'Select') attr.contenteditable = true;
                    attr.class = `${attr.class || ''} foui-table-accent-edit ${this.getEditTypeCss(head.EditControl.Type)}`;
                    if (head.EditControl.Checked !== undefined) {
                        attr.class = `${attr.class || ''} foui-table-check-${head.EditControl.Checked ? 'on' : 'off'}`;
                    }
                }
            }
            else if (head.Sort) {
                attr.html = `${head.Header}<span class="foui-table-sort-ico">${Util.getIconString('Sort-Mini')}</span>`;
                attr['data-for'] = 'sort';
            }
            else attr.html = head.Header;
            if (head.Width !== undefined && head.Width !== 'dynamic') attr.style = `width:${head.Width};min-width:${head.Width}`;
            // else if (head.Width === undefined && col.Width && col.Width !== 'dynamic') attr.style = `width:${col.Width};min-width:${col.Width}`;
            if (head.ColumnSpan) attr.colspan = `${head.ColumnSpan === 'all' ? headLen + 1 : head.ColumnSpan}`;
            if (head.RowSpan) attr.rowspan = `${head.RowSpan}`;
            if (head.Back) attr.style = `${attr.style || ''};background-color:${head.Back}`;
            if (head.Color) attr.style = `${attr.style || ''};color:${head.Color}`;
            if (head.Align) {
                switch (head.Align) {
                    case 'Start': attr.class = `${attr.class || ''} foui-text-start`; break;
                    case 'End': attr.class = `${attr.class || ''} foui-text-end`; break;
                    case 'Center': attr.class = `${attr.class || ''} foui-text-center`; break;
                }
            }
            if (head.Class) attr.class = `${attr.class || ''} ${head.Class}`;
            const th = UI.create('th', attr);
            tr.append(th);
        });
        /* columns.forEach((col, i) => {
            if (col.Header === undefined) return;
            const isArray = Array.isArray(col.Header);
            let head = this.getHeader(col, ri, headLen, isArray);
            if (!head) return;
            const attr = { 'data-idx': i, 'data-hidx': (isArray ? col.Header.indexOf(head) : 0) };
            if (head.Sort) {
                attr.html = `${head.Header}<span class="foui-table-sort-ico">${Util.getIconString('Sort-Mini')}</span>`;
                attr['data-for'] = 'sort';
            }
            else attr.html = head.Header;
            // if (col.Width) attr.style = `width:${col.Width} ${attr.style || ''}`;
            if (head.Width && head.Width !== 'dynamic') attr.style = `width:${head.Width};min-width:${head.Width}`;
            else if (col.Width && col.Width !== 'dynamic') attr.style = `width:${col.Width};min-width:${col.Width}`;
            if (head.ColumnSpan) attr.colspan = `${head.ColumnSpan === 'all' ? headLen : head.ColumnSpan}`;
            if (head.RowSpan) attr.rowspan = `${head.RowSpan}`;
            if (head.Back) attr.style = `${attr.style || ''};background-color:${head.Back}`;
            if (head.Color) attr.style = `${attr.style || ''};color:${head.Color}`;
            if (head.Align) {
                switch (head.Align) {
                    case 'Start': attr.class = 'foui-text-start'; break;
                    case 'End': attr.class = 'foui-text-end'; break;
                    case 'Center': attr.class = 'foui-text-center'; break;
                }
            }
            if (head.Class) attr.class = `${attr.class || ''} ${head.Class}`;
            const th = UI.create('th', attr);
            tr.append(th);
        }); */
        if (virtual) return tr;
        this.ui.header.append(tr);
    }

    updateHeader (elem, rec, delta, keys, columns) {
        console.log('Update header!');
    }

    addHeaderCounterColumn (tr, headLen, isToggle) {
        const attr = { 'data-idx': -1, class: 'foui-text-end foui-table-counter' };
        // if (text) attr.text = text;
        if (headLen > 1) attr.rowspan = headLen;
        if (isToggle !== undefined) {
            attr['data-for'] = 'sort-toggle';
            attr.title = 'Toggle all';
            attr.class = `${attr.class} foui-table-check-${isToggle ? 'on' : 'off'}`;
        }
        const th = UI.create('th', attr);
        tr.append(th);
    }

    propertyMatchCheck (old, item) {
        if (!old) return { upp: Object.keys(item), rem: [] };
        const actions = {
            upp: [],
            rem: []
        };
        const oKeys = Object.keys(old);
        const nKeys = Object.keys(item);
        for (const nKey of nKeys) {
            if (oKeys.indexOf(nKey) > -1) {
                actions.upp.push(nKey);
            }
        }
        for (const oKey of oKeys) {
            if (nKeys.indexOf(oKey) === -1) {
                actions.rem.push(oKey);
            }
        }
        return actions;
    }

    loadItems () {
        const items = this.getItems();
        console.log('TABLE LEN:', items.length);

        if (!Array.isArray(items)) return;
        const columns = this.props.Columns;
        if (!this.meta.loaded) { // Initial load.
            items.forEach((item, i) => {
                this.addRow(item, i, columns);
            });
        }
        else {
            const foundItems = [];
            items.forEach((item, i) => {
                if (item.Key === undefined) item.Key = i + 1;
                // Diff the new list with the old list and only apply changed items.
                let old = this.meta.loaded.find(o => o.Key === item.Key);
                // Check for any overlapping properties. If none, remove the old record from the UI and treat this as a new record.
                // TODO:
                const propChecks = this.propertyMatchCheck(old, item);
                // console.log(propChecks);
                if (propChecks.upp.length === 2 && propChecks.upp[0] === '_idx_' && propChecks.upp[1] === 'Key') {
                    // Remove the old UI row.
                    const elem = UI.find(this.ui.body, `[data-key="${old.Key}"]`);
                    UI.remove(elem);
                    old = null;
                }
                // console.log('OLD', old);
                if (old) { // Update.
                    foundItems.push(item.Key);
                    // Check if different.
                    const delta = Util.delta(old, item);
                    // console.log('DELTA', delta, old, item);
                    if (delta) {
                        const keys = Object.keys(delta);
                        // console.log('Item Changes ---------------');
                        const elem = UI.find(this.ui.body, `[data-key="${old.Key}"]`);
                        this.updateRow(elem, Object.assign({}, item, delta), delta, keys, columns); // New delta combined rec with assign.
                    }
                }
                else { // Doesn't exist. Full add.
                    // const itemAt = this.meta.loaded[i]; // Get the old element at this position.
                    const idx = item._idx_;
                    const itemAt = this.meta.loaded.find(o => o._idx_ === item._idx_); // Get the old element at this position.
                    if (itemAt) {
                        const elemAt = UI.find(this.ui.body, `[data-idx="${idx}"]`);
                        if (elemAt) {
                            const ctrl = this.addRow(item, i, columns, true);
                            elemAt.before(ctrl);
                        }
                        else this.addRow(item, i, columns);
                    }
                    else {
                        // First try and add it at the right index.
                        const ctrl = this.addRow(item, i, columns, true);
                        let added = false;
                        const alen = this.meta.loaded.length;
                        for (let li = 0; li < alen; li++) {
                            const lidx = this.meta.loaded[li]._idx_;
                            if (lidx > idx) {
                                added = true;
                                const elemAt = UI.find(this.ui.body, `[data-idx="${lidx}"]`);
                                if (elemAt) elemAt.before(ctrl);
                                break;
                            }
                        }
                        if (!added) this.ui.body.append(ctrl); // Add at the end.
                    }
                }
            });
            // Check which items were removed.
            for (const old of this.meta.loaded) {
                const has = items.find(o => o.Key === old.Key);
                if (!has) {
                    // No existing item for the old entry. Remove its control.
                    const elem = UI.find(this.ui.body, `[data-key="${old.Key}"]`);
                    UI.remove(elem);
                }
            }
        }
        this.meta.loaded = Util.duplicate(items);
    }

    addRow (record, i, columns, virtual) {
        // Row properties.
        // A table row works differently to other control properties in that the props is the data.
        // Key | Selected | Class | Busy
        const rec = Util.duplicate(record);
        const attrRow = { 'data-key': rec.Key, 'data-idx': record._idx_, class: 'foui-table-row' };
        const tr = UI.create('tr', attrRow);
        if (this.props.RowNumbers) {
            const propsCounter = { text: record._idx_ + 1, 'data-for': 'col', class: 'foui-table-header foui-table-counter foui-text-end' };
            const td = UI.create('td', propsCounter);
            tr.append(td);
        }
        columns.forEach(col => {
            let { column, item } = col.PreRender ? col.PreRender(Util.duplicate(col), rec) : { column: col, item: rec };
            if (!column) column = col;
            if (!item) item = rec;
            const text = column.Renderer
                ? ''
                : (column.Formatter ? column.Formatter(item) : item[column.Field]);
            const attr = { html: text, 'data-for': 'col' };
            if (column.ActionIcon) {
                // console.log(column.ActionIcon);
                attr.html = Util.getIconString(column.ActionIcon); // this.props.IconName
            }
            if (column.ColumnSpan) attr.colspan = col.ColumnSpan === 'all' ? columns.length + 1 : col.ColumnSpan;
            // if (head.ColumnSpan) attr.colspan = `${head.ColumnSpan === 'all' ? headLen : head.ColumnSpan}`;
            // attr.colspan = col.ColumnSpan === 'all' ? columns.length : `${col.ColumnSpan}`;
            if (column.RowSpan) attr.rowspan = `${column.RowSpan}`;
            if (column.Back) attr.style = `background-color:${column.Back}`;
            if (column.Color) attr.style = `${attr.style || ''};color:${column.Color}`;
            if (column.Align) {
                switch (column.Align) {
                    case 'Start': attr.class = 'foui-text-start'; break;
                    case 'End': attr.class = 'foui-text-end'; break;
                    case 'Center': attr.class = 'foui-text-center'; break;
                }
            }
            if (column.AlignVertical) {
                switch (column.AlignVertical) {
                    case 'Top': attr.class = `${attr.class || ''} foui-text-top`; break;
                    case 'Middle': attr.class = `${attr.class || ''} foui-text-middle`; break;
                    case 'Bottom': attr.class = `${attr.class || ''} foui-text-bottom`; break;
                }
            }
            if (column.Width) {
                switch (column.Width) {
                    // case 'auto': break; // Do nothing. attr.style = `${attr.style || ''};width:${column.Width}`;
                    case 'dynamic': attr.style = `${attr.style || ''};white-space:nowrap;max-width:inherit`; break;
                    default: break; // Is set on the header or just auto.
                }
            }
            if (column.Class) attr.class = `${attr.class || ''} ${column.Class}`;
            const td = UI.create('td', attr);
            if (column.Renderer) {
                const contents = column.Renderer(column, item);
                if (typeof contents === 'string') td.innerHTML = contents;
                else td.append(contents);
            }
            tr.append(td);
        });
        if (virtual) return tr;
        this.ui.body.append(tr);
    }

    updateRow (elem, rec, delta, keys, columns) {
        console.log(keys, delta);
        const rowNums = this.props.RowNumbers;
        for (const key of keys) {
            if (key === '') continue;
            const col = columns.find(o => o.Field === key);
            if (!col) continue;
            const { column, item } = col.PreRender ? col.PreRender(Util.duplicate(col), rec) : { column: col, item: rec };
            const colIdx = columns.indexOf(col) + (rowNums ? 2 : 1); // The nth-child selector is 1 based.
            const td = UI.find(elem, `td:nth-child(${colIdx})`);
            const attr = {};
            if (!column.Renderer) attr.html = column.Formatter ? column.Formatter(item) : item[column.Field];
            if (column.ActionIcon) {
                attr.html = Util.getIconString(column.ActionIcon); // this.props.IconName
            }
            if (column.ColumnSpan) attr.colspan = `${column.ColumnSpan}`;
            if (column.RowSpan) attr.rowspan = `${column.RowSpan}`;
            if (column.Back) attr.style = `background-color:${column.Back}`;
            if (column.Color) attr.style = `${attr.style || ''};color:${column.Color}`;
            if (column.Align) {
                switch (column.Align) {
                    case 'Start': attr.class = 'foui-text-start'; break;
                    case 'End': attr.class = 'foui-text-end'; break;
                    case 'Center': attr.class = 'foui-text-center'; break;
                }
            }
            if (column.AlignVertical) {
                switch (column.AlignVertical) {
                    case 'Top': attr.class = `${attr.class || ''} foui-text-top`; break;
                    case 'Middle': attr.class = `${attr.class || ''} foui-text-middle`; break;
                    case 'Bottom': attr.class = `${attr.class || ''} foui-text-bottom`; break;
                }
            }
            if (column.Width) {
                switch (column.Width) {
                    // case 'auto': break; // Do nothing. attr.style = `${attr.style || ''};width:${column.Width}`;
                    case 'dynamic': attr.style = `${attr.style || ''};white-space:nowrap;max-width:inherit`; break;
                    default: break; // Is set on the header or just auto.
                }
            }
            if (column.Class) attr.class = `${attr.class || ''} ${column.Class}`;
            UI.attr(td, attr);
            if (column.Renderer) {
                const contents = column.Renderer(column, item);
                if (typeof contents === 'string') td.innerHTML = contents;
                else td.append(contents);
            }
        }
    }

    onKeyUp (evt) {
        const target = evt.target;
        // console.log(target);
        const eventFor = target.dataset.for;
        switch (eventFor) {
            case 'filter': {
                setImmediate(() => { this.doFilter(target); });
                break;
            }
            case 'custom':
                this.doCustomEdit(target);
                break;
        }
    }

    onHeaderClick (evt) {
        const target = evt.target;
        const eventFor = target.dataset.for;
        switch (eventFor) {
            case 'sort': {
                setImmediate(() => { this.doSort(target); });
                break;
            }
            case 'sort-toggle': {
                const parent = target.closest('tr');
                const key = parent.dataset.key;
                const head = this.prop.CustomHeaders.find(o => o.Key === key);
                const row = this.meta.loadedHeader.find(o => o.Key === key);
                const state = !UI.hasClass(target, 'foui-table-check-on');
                this.setHeaderCheckState(target, state);
                head.Columns.forEach((o, i) => {
                    if (o.EditControl && o.EditControl.Checked !== undefined) {
                        o.EditControl.Checked = state;
                        row.Columns[i].EditControl.Checked = state;
                        // TEMP:
                        const el = UI.find(this.ui.header, `tr[data-key="${key}"] th[data-idx="${i}"]`);
                        if (!el) return;
                        this.setHeaderCheckState(el, state);
                    }
                });
                // this.loadHeaders();
                break;
            }
            case 'custom':
                this.doCustomClick(target, evt.offsetX, evt.offsetY);
                break;
        }
    }

    onHeaderFocus (evt) {
        const target = evt.target;
        if (target.contentEditable) {
            if (window.getSelection && document.createRange) {
                const range = document.createRange();
                range.selectNodeContents(target);
                const sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);
            }
            else if (document.body.createTextRange) {
                const range = document.body.createTextRange();
                range.moveToElementText(target);
                range.select();
            }
        }
    }

    onCellClick (evt) {
        const target = evt.target;
        const td = target.tagName === 'TD' ? target : target.closest('td');
        const tr = td.closest('tr');
        setImmediate(() => { UI.sendEvent(this.elem, 'cell-click', { row: +tr.dataset.idx, col: td.cellIndex }); });
    }

    onCellDblClick (evt) {
        const target = evt.target;
        const td = target.tagName === 'TD' ? target : target.closest('td');
        const tr = td.closest('tr');
        setImmediate(() => { UI.sendEvent(this.elem, 'cell-dblclick', { row: +tr.dataset.idx, col: td.cellIndex }); });
    }

    doFilter (target) {
        // TODO: For items count up to 1000, search immediately. If over, use a worker. this.props.Worker (Worker when more than)
        const findLower = target.textContent.toLocaleLowerCase();
        // Do nothing if there is no change.
        if (this.meta.filterText === findLower) return;
        // Remember the text.
        this.meta.filterText = findLower;
        // Get the column the filter is on.
        const col = this.props.Columns[+target.dataset.idx];
        // Check by field if the filter is already specified in filterFields.
        if (findLower === '' && this.meta.filterFields[col.Field] !== undefined) {
            delete this.meta.filterFields[col.Field];
        }
        else {
            this.meta.filterFields[col.Field] = findLower;
        }
        // Check if there are any filters.
        const fields = Object.keys(this.meta.filterFields);
        if (!fields.length) {
            if (this.meta.filterItems) delete this.meta.filterItems;
            // Reload the view.
            setImmediate(this.loadItems.bind(this));
            return;
        }
        // Filter the data.
        const matchCountRequired = fields.length;
        const filters = this.meta.filterFields;
        this.meta.filterItems = this.prop.Items.filter(o => {
            let matchCount = 0;
            for (const field of fields) {
                if (o[field] === undefined) return false;
                const strVal = `${o[field]}`.toLocaleLowerCase();
                if (strVal.includes(filters[field])) matchCount += 1;
            }
            if (matchCount === matchCountRequired) return o;
        });
        // Reload the view.
        setImmediate(this.loadItems.bind(this));
    }

    doSort (target) {
        // TODO: For items count up to 1000, sort immediately. If over, use a worker. this.props.Worker (Worker when more than)
        // Get the column the sort is on.
        const parent = target.closest('tr');
        const key = parent.dataset.key;
        const idx = +target.dataset.idx;
        const head = this.meta.loadedHeader.find(o => o.Key === key);
        const col = head.Columns[idx];
        // Toggle the state.
        if (col.Sort === 'no') col.Sort = 'asc';
        else if (col.Sort === 'asc') col.Sort = 'desc';
        else if (col.Sort === 'desc') col.Sort = 'no';
        // Sort the data.
        if (col.Sort === 'no') {
            delete this.meta.sortItems;
            // Update the header icon.
            // this.setHeaderSortIco(target, 'Sort-Mini');
            this.updateHeaderSortIcos(key, idx, col.Sort);
            // Reload the view.
            this.ui.body.innerHTML = ''; // Clear because it is a reload.
            this.meta.loaded = [];
            // setImmediate(this.loadItems.bind(this));
            this.loadItems();
            return;
        }
        const asc = col.Sort === 'asc';
        const sorter = Util.sorter(col.Field, asc);
        this.meta.sortItems = Util.duplicate(this.meta.filterItems || this.props.Items); // Make a copy otherwise the original data gets sorted and cannot be unsorted.
        this.meta.sortItems.sort(sorter);
        // data.sort((a, b) => a.city.localeCompare(b.city) || b.price - a.price);
        /* data.sort(function (a, b) {
            return a.city.localeCompare(b.city) || b.price - a.price;
        }); */
        // Update the header icon.
        // this.setHeaderSortIco(target, asc ? 'SortAsc-Mini' : 'SortDesc-Mini');
        this.updateHeaderSortIcos(key, idx, col.Sort);
        // Reload the view.
        this.ui.body.innerHTML = ''; // Clear because it is a reload.
        this.meta.loaded = [];
        // setImmediate(this.loadItems.bind(this));
        this.loadItems();
    }

    setHeaderSortIco (elHeader, icoName) {
        const ico = UI.find(elHeader, '.foui-table-sort-ico');
        if (icoName) {
            if (ico) {
                ico.innerHTML = Util.getIconString(icoName);
            }
        }
        else {
            ico.innerHTML = '';
        }
    }

    setHeaderCheckState (el, state) {
        if (state) {
            UI.removeClass(el, 'foui-table-check-off');
            UI.addClass(el, 'foui-table-check-on');
        }
        else {
            UI.removeClass(el, 'foui-table-check-on');
            UI.addClass(el, 'foui-table-check-off');
        }
    }

    updateHeaderSortIcos (key, idx, sort) {
        const row = this.meta.loadedHeader.find(o => o.Key === key);
        // This is a single column sort only.
        // Go over all the columns and reset the icon.
        row.Columns.forEach((col, i) => {
            const elHeader = UI.find(this.ui.header, `tr[data-key="${key}"] th[data-idx="${i}"]`);
            if (!elHeader) return;
            const ico = UI.find(elHeader, '.foui-table-sort-ico');
            if (!ico) return;
            if (+elHeader.dataset.idx !== idx) { // Not the sorted column.
                ico.innerHTML = Util.getIconString('Sort-Mini');
                return;
            }
            ico.innerHTML = Util.getIconString(sort === 'no'
                ? 'Sort-Mini'
                : (sort === 'asc' ? 'SortAsc-Mini' : 'SortDesc-Mini'));
        });
    }

    getEditTypeCss (type) {
        let value = 'pencil';
        switch (type) {
            case 'Text': value = 'pencil'; break;
            case 'Select': value = 'select'; break;
        }
        return `foui-table-edit-${value}`;
    }

    doCustomEdit (target) {
        const parent = target.closest('tr');
        const key = parent.dataset.key;
        const value = target.textContent;
        const idx = +target.dataset.idx;
        const head = this.props.CustomHeaders.find(o => o.Key === key);
        const col = head.Columns[idx];
        if (!col.EditControl) return;
        col.Value = value;
    }

    doCustomClick (target, x, y) {
        const parent = target.closest('tr');
        const key = parent.dataset.key;
        const idx = +target.dataset.idx;
        const head = this.props.CustomHeaders.find(o => o.Key === key);
        const col = head.Columns[idx];
        if (!col.EditControl) return;

        if (x < 23) {
            col.EditControl.Checked = !col.EditControl.Checked;
            // TEMP: Remove when headers can update.
            if (col.EditControl.Checked) {
                UI.removeClass(target, 'foui-table-check-off');
                UI.addClass(target, 'foui-table-check-on');
            }
            else {
                UI.removeClass(target, 'foui-table-check-on');
                UI.addClass(target, 'foui-table-check-off');
            }
            this.loadHeaders();
            return;
        }

        switch (col.EditControl.Type) {
            case 'Select': {
                const typeItems = Util.duplicate(typeof col.EditControl.Items === 'string' && col.EditControl.Items.startsWith(':') ? this.objGetProp(this.data, col.EditControl.Items.substr(1)) : col.EditControl.Items);
                const items = [];
                for (const item of typeItems) {
                    if (!item.Type) item.Type = 'Label';
                    items.push(item);
                }
                window.popup({ Anchor: target, ParentControl: this, Position: 'Start', Items: items }, (detail) => {
                    if (detail === undefined) return; // Popup closed but nothing was selected.
                    col.Value = detail;
                    const selected = items.find(o => o.Key === detail);
                    target.textContent = selected.Text;
                });
                break;
            }
            /* case 'Select': {
                // TODO: Handle checkbox.
                break;
            } */
        }
    }

    /* updateWidth () {
        let value = this.props.Width;
        if (value === '' || value === undefined) {
            UI.attr(this.elem, { style: 'flex-basis:inherit' });
            UI.attr(this.elem, { style: 'min-width:inherit' });
            return;
        }
        if (typeof value === 'number') value = `${value}px`;
        UI.attr(this.elem, { style: `flex-basis:${value}` });
        UI.attr(this.elem, { style: `min-width:${value}` });
    } */
}

// Control property definitions.
Object.defineProperty(Table, '_DEF', {
    value: {
        Group: 'Data',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            Bordered: { Default: true, Group: 'Style', Label: 'Bordered', Type: 'Flag', Note: 'Display a border around the control. Useful to disable when using this control inside another composite control.' },
            Busy: { Default: undefined, Group: 'State', Label: 'Busy', Type: 'Flag', Note: 'Indicates the control is in a busy/processing state.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Style Class', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            Columns: { Default: [], Group: 'General', Label: 'Columns', Type: 'List', Note: 'Column definitions.' },
            CustomHeaders: { Default: [], Group: 'General', Label: 'Custom Header Rows', Type: 'List', Note: 'Definitions for custom header rows.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout panel into design mode.' },
            HeaderBorders: { Default: true, Group: 'Style', Label: 'Header Borders', Type: 'Flag', Note: 'Display column header borders.' },
            Items: { Default: [], Group: 'General', Label: 'Items', Type: 'List', Note: 'Record items.' },
            // ItemBorders: { default: 'all', group: 'Style', label: 'Item Borders', type: 'List', items: PropertyConstants.SIZE, note: 'Borders on item cells.' },
            Multiple: { Default: undefined, Group: 'General', Label: 'Multiple', Type: 'Flag', Note: 'Indicates multiple email addresses allowed, comma separated.' },
            RowNumbers: { Default: false, Group: 'General', Label: 'Row Numbers', Type: 'Flag', Note: 'First column is automatic row numbers.' },
            // StickyHeader: { default: 'none', group: 'Style', label: 'Sticky Header', type: 'List', items: PropertyConstants.TABLE_STICKY, note: 'Table header scrolling behaviour.' },
            Tip: { Default: undefined, Group: 'General', Label: 'Tool Tip', Type: 'Any', Note: 'Information related to the control.' },
            Width: { Default: undefined, Group: 'Style', Label: 'Width', Type: 'Any', Note: 'Fixed control width. Clear (leave blank) to allow the control to use the maximum available space.' },
            Zebra: { Default: true, Group: 'Style', Label: 'Zebra', Type: 'Flag', Note: 'Highlight every odd row.' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
