import Control from './control';
import Util from '../util/util';
import UI from '../util/ui';

export default class Panel extends Control {
    /**
     * The Panel is a basic DIV container.
     * It can be extended therefore the constructor code looks a little different.
     *
     * @param definition Control definition.
     * @param extendWithProps If extended by another control, the properties to extend with.
     */
    constructor (definition, extendWithProps) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        if (!definition.Type) definition.Type = 'Panel';
        // if (!definition.Props.ClassFixed) definition.Props.ClassFixed = 'foui-panel';
        super(definition, extendWithProps ? Object.assign({ _isExtended: true }, extendWithProps, Panel._DEF.Properties) : Panel._DEF.Properties);
    }

    init () {
        // if (!this.meta.ClassFixed) this.meta.ClassFixed = 'foui-panel';
        if (this.meta.type === 'Panel') {
            this.setClass('foui-panel');
            this.props.ClassFixed = 'foui-panel';
        }
        // this.addClass('foui-panel');
        this.meta.ControlList = [];
        /* // , Map: { Control: 'label', Prop: 'Text' }
        this.ui.div = new Controls.CoreDiv({ Props: { ClassFixed: 'foui-panel' }, Parent: this, Data: this.data, Code: this.code, $name: this.$name }); // Value: this.props.Value, //
        this.bindControl(this.ui.div, [
            { other: 'Class', local: 'Class' },
            { other: 'HTML', local: 'HTML' },
            { other: 'Text', local: 'Text' },
            { other: 'Tip', local: 'Tip' },
            { other: 'Value', local: 'Value' },
        ]);

        this.meta.ControlList = [];
        if (this.props.Controls && this.props.Controls.length) {
            for (const def of this.props.Controls) {
                const control = Util.duplicate(def);
                const ctrl = new Controls[control.Type]({ Props: control.Props, parent: this, data: this.data, code: this.code, $name: this.$name });
                this.meta.ControlList.push(ctrl);
            }
        } */
    }

    onChange (changes) {
        for (const [key, v] of changes) {
            const value = Util.checkForBoundedValue(v, this);
            switch (key) {
                case 'Bordered': this.updateBordered(); break;
                case 'Class': {
                    this.setClass(value);
                    if (this.props.Bordered) this.addClass('bordered');
                    else this.removeClass('bordered');
                    break;
                }
                case 'Controls': {
                    if (value && value.length) {
                        for (const def of this.props.Controls) {
                            // const control = this.props.DesignMode ? def : Util.duplicate(def);
                            // const ctrl = new Controls[control.Type]({ Props: control.Props, Parent: this, Data: this.data, Code: this.code, $name: this.$name });
                            const ctrl = this.newControl(def);
                            this.meta.ControlList.push(ctrl);
                        }
                    }
                    break;
                }
                case 'DesignMode': {
                    for (const ctrl of this.meta.ControlList) {
                        ctrl.props.DesignMode = value;
                    }
                    break;
                }
                case 'Height': this.setHeight(value); break;
                case 'HTML': UI.attr(this.elem, { html: value }); break;
                case 'Text': UI.attr(this.elem, { text: value }); break;
                case 'TintedBackground': this.updateTintedBackground(); break;
                case 'Width': this.setWidth(value); break;
            }
        }
    }

    updateBordered () {
        if (this.props.Bordered) this.addClass('bordered');
        else this.removeClass('bordered');
    }

    updateTintedBackground () {
        if (this.props.TintedBackground) this.addClass('tinted-background');
        else this.removeClass('tinted-background');
    }
}

// Control property definitions.
Object.defineProperty(Panel, '_DEF', {
    value: {
        Group: 'Containers',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            Bordered: { Default: undefined, Group: 'Style', Label: 'Bordered', Type: 'Flag', Note: 'Display a border around the control. Useful to disable when using this control inside another composite control.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Style Class', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            // ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            Controls: { Default: undefined, Group: 'General', Label: 'Controls', Type: 'List', Note: 'Other control items "inside" this control.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout into design mode.' },
            Height: { Default: undefined, Group: 'Size', Label: 'Width', Type: 'Number', Note: 'Height set as px or %.' },
            HTML: { Default: undefined, Group: 'General', Label: 'HTML', Type: 'Text', Note: 'HTML code as content.' },
            // Padding: { Default: undefined, Group: 'General', Label: 'Style', Type: 'Text', Note: 'Spacing inside the control borders.' },
            Text: { Default: undefined, Group: 'General', Label: 'Text', Type: 'Text', Note: 'Text value to display.' },
            TintedBackground: { Default: undefined, Group: 'Style', Label: 'Tinted Background', Type: 'Flag', Note: 'Tints the background to be slightly different to the standard background colour.' },
            Tip: { Default: undefined, Group: 'General', Label: 'Tool Tip', Type: 'Any', Note: 'Information related to the control.' },
            // Visible: { Default: true, Group: 'State', Label: 'Visible', Type: 'Flag', Note: 'Visible or hidden state of the control.' },
            Width: { Default: undefined, Group: 'Size', Label: 'Width', Type: 'Number', Note: 'Width set as px or %.' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
