import Control from './control';
// import CoreTable from './core/table';
import Check from './check';
import Text from './text';
import UI from '../util/ui';
import Util from '../util/util';
import CoreInput from './core/input';
import Select from './select';

export default class PropertyForm extends Control {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'PropertyForm';
        // definition.Props.ClassFixed = 'foui-ctrl foui-property-grid';
        super(definition, PropertyForm._DEF.Properties);
    }

    init () {
        // this.meta.ClassFixed = 'foui-property-grid';
        this.addClass('foui-ctrl foui-property-form');
        this.meta.ControlList = {};

        // The holder is on the same depth as the note. The holder allows the table to grow and scroll as needed without hiding the note.
        const holder = UI.create('div', { class: 'foui-property-holder' });
        this.ui.form = UI.create('div', { class: 'foui-property-value-form' });
        holder.append(this.ui.form);
        this.elem.append(holder);

        /* const tr = UI.create('tr', { 'data-idx': -1 });
        header.append(tr);
        // Property name.
        const th1 = UI.create('th', { text: 'Property' }); // , width: `${Math.floor(this.elem.offsetWidth / 2)}px`
        tr.append(th1);
        const th2 = UI.create('th', { text: 'Value' });
        tr.append(th2);
        this.ui.body = UI.create('tbody', { class: 'foui-property-body' });
        // this.loadGrid();
        this.ui.grid.append(header);
        this.ui.grid.append(this.ui.body);
        holder.append(this.ui.grid);
        this.elem.append(holder);
        const note = UI.create('div', { class: 'foui-property-note' });
        this.ui.noteTitle = UI.create('div', { class: 'foui-property-note-title', text: 'Property' });
        this.ui.noteDescription = UI.create('div', { text: 'Description.' });
        note.append(this.ui.noteTitle);
        note.append(this.ui.noteDescription);
        this.elem.append(note); */
    }

    attachEvents () {
        // Bind to this control.
        // this.handlers.onClick = this.onClick.bind(this);
        // Attach the listener(s).
        // UI.on(this.ui.form, 'mousedown', this.handlers.onClick, true);
    }

    detachEvents () {
        // UI.off(this.elem, 'click', this.handlers.onClick, true); // mousedown
        delete this.handlers;
    }

    onChange (changes) {
        // console.log('Button changes', changes);
        for (const [key, v] of changes) {
            switch (key) {
                // case 'Disabled': UI.attr(this.ui.button, { disabled: v }); break;
                case 'Item': {
                    const value = Util.checkForBoundedValue(v, this);
                    this.updateItems(value);
                    break;
                }
                // case 'Schema': break;
            }
        }
    }

    updateItems (value) {
        const keys = typeof value === 'object' ? Object.keys(value) : [];
        // console.log(this.props.Schema);
        // console.log(this.props.Item);
        // Show that there are no properties.
        if (!keys.length) {
            this.ui.form.innerHTML = '<div class="foui-property-form-row pf-label"><label>No step selected.</label></div>';
            this.meta.oldItem = value;
            return;
        }
        if (!this.meta.oldItem) this.meta.oldItem = value;
        // If the item is new, unbind the previous listeners.
        if (value !== this.meta.oldItem) {
            // Clean the form and watchers.
            const oKeys = Object.keys(this.meta.oldItem);
            for (let i = 0; i < oKeys.length; i++) {
                const key = oKeys[i];
                const input = this.meta.ControlList[key];
                input.props.unbindFrom(value, key, 'Value');
                value.unbindFrom(input.props, 'Value', key);
            }
            this.ui.form.innerHTML = '';
            this.meta.oldItem = value;
            // this.ui.noteTitle.textContent = 'Property';
            // this.ui.noteDescription.textContent = 'Description';
        }
        // Create or update the inputs for the item's properties.
        this.sortBy(keys);
        // const gridWidth = this.elem.offsetWidth;
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const keyRow = UI.find(this.ui.form, `div[data-prop="${key}"]`);
            if (keyRow) { // If the property row exists, update it.
                console.log('PF Key Row', keyRow);
            }
            else { // Add a new property row.
                const schema = this.props.Schema[key];
                const trLabel = UI.create('div', { 'data-idx': i, 'data-prop': key, class: 'foui-property-form-row pf-label' });
                const keyProps = { html: schema ? schema.Label : key };
                const labelKey = UI.create('label', keyProps);
                trLabel.append(labelKey);
                this.ui.form.append(trLabel);

                // const valueProps = { Class: 'pty-edit' }; // html: value[key],
                // const tdValue = UI.create('td', valueProps);
                // tr.append(tdValue);
                const trInput = UI.create('div', { 'data-idx': i, 'data-prop': key, class: 'foui-property-form-row pf-input' });
                let input;
                switch (schema.Type) {
                    case 'Any':
                    case 'Text':
                        // input = new CoreInput({ Props: { Name: `_${key}_text`, ClassFixed: 'foui-text foui-stretch-y', Value: value[key] }, Parent: tdValue, Data: this.data, Code: this.code, $name: this.$name }); // Value: this.props.Value,
                        input = new Text({ Props: { Name: `_${key}_text`, ClassFixed: 'foui-text foui-stretch-y', Value: value[key] }, GlobalHide: true, Parent: trInput, Data: this.data, Code: this.code, $name: this.$name }); // Value: this.props.Value,
                        break;
                    case 'Number':
                        // input = new CoreInput({ Props: { Name: `_${key}_text`, ClassFixed: 'foui-text foui-stretch-y', Value: value[key], Type: 'number' }, Parent: tdValue, Data: this.data, Code: this.code, $name: this.$name });
                        break;
                    case 'Flag':
                        // input = new CoreInput({ Props: { Name: `_${key}_text`, ClassFixed: 'foui-text foui-stretch-y', Value: value[key], Type: 'checkbox' }, Parent: tdValue, Data: this.data, Code: this.code, $name: this.$name });
                        input = new Check({ Props: { Name: `_${key}_check`, Value: value[key], Text: '' }, GlobalHide: true, Parent: trInput, Data: this.data, Code: this.code, $name: this.$name });
                        break;
                    case 'List':
                        // input = new CoreInput({ Props: { Name: `_${key}_text`, ClassFixed: 'foui-text foui-stretch-y', Value: value[key] }, Parent: tdValue, Data: this.data, Code: this.code, $name: this.$name }); // Value: this.props.Value,
                        input = new Select({ Props: { Name: `_${key}_select`, Value: value[key], Items: schema.Items, FieldKey: 'Key', FieldText: 'Text' }, GlobalHide: true, Parent: trInput, Data: this.data, Code: this.code, $name: this.$name });
                        break;
                    case 'Object':
                        // input = new CoreInput({ Props: { Name: `_${key}_text`, ClassFixed: 'foui-text foui-stretch-y', Value: value[key] }, Parent: tdValue, Data: this.data, Code: this.code, $name: this.$name }); // Value: this.props.Value,
                        break;
                }
                // Bind the two data objects.
                if (!input) console.warn(key, schema);
                input.props.bindTo(value, key, 'Value');
                value.bindTo(input.props, 'Value', key);
                this.meta.ControlList[key] = input;

                this.ui.form.append(trInput);
            }
        }
    }

    onClick (evt) {
        // Selection highlight.
        const row = evt.target.closest('tr');
        // const clickCol = [...row.children].indexOf(evt.target);
        const prevSel = UI.find(this.ui.body, '.foui-selected');
        if (prevSel) UI.removeClass(prevSel, 'foui-selected');
        UI.addClass(row, 'foui-selected');

        // Note.
        const key = row.dataset.prop;
        const schema = this.props.Schema[key];
        this.ui.noteTitle.textContent = schema ? schema.Label : key;
        this.ui.noteDescription.textContent = schema ? schema.Note : '';
    }

    sortBy (keys) {
        // TODO: Add sort options ASC, DESC and in grouped.
        // Sort by the key names.
        keys.sort();
        // Remove name and re-add it to make it the first item.
        const pos = keys.indexOf('Name');
        const name = keys.splice(pos, 1);
        keys.unshift(name);
    }
}

// Control property definitions.
Object.defineProperty(PropertyForm, '_DEF', {
    value: {
        Group: 'Data',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Style Class', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout panel into design mode.' },
            Disabled: { Default: undefined, Group: 'General', Label: 'Disabled', Type: 'Flag', Note: 'Disables the control.' },
            Item: { Default: {}, Group: 'General', Label: 'Item', Type: 'Object', Note: 'Object item instance with properties to edit.' },
            Schema: { Default: {}, Group: 'General', Label: 'Schema', Type: 'Object', Note: 'The schema detailing the object item properties.' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
