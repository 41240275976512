export default {
    lang: 'en',
    shared: {
        DayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        DayNames3: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        DayNames1: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        MonthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        MonthNames3: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        ThemeLang: 'Theme & Language',
        Username: 'Username',
        Password: 'Password',
        Cancel: 'Cancel',
        Close: 'Close',
        Create: 'Create',
        Join: 'Join',
        Preview: 'Preview',
        Yes: 'Yes',
        No: 'No',
        Name: 'Name',
        Desc: 'Description',
        Processing: 'Processing',
        Workspace: 'Workspace',
        Loading: 'Loading',
        WorkspaceBusyConfig: 'Please wait while the workspace is being configured.',
        ProjectBusyConfig: 'Please wait while the project is being configured.',
        LoadingBusy: 'Please wait while the environment loads...',
        Project: 'Project',
        Connection: 'Connection',
        Notifications: 'Notifications',
        User: 'User',
        Settings: 'Settings',
        Feedback: 'Feedback',
        Version: 'Version',
        Education: 'Education',
        Support: 'Support',
    },
    views: {
        Workspace: {
            Usage: 'Usage:',
            Accrued: 'Accrued charges since last invoice',
        },
        WorkspaceChoose: {
            Options: 'Workspace options',
            Create: 'Create a new workspace...',
            Join: 'Join an existing workspace...',
        },
        WorkspaceNew: {
            Title: 'Create a new workspace...',
            Region: 'Region',
            Hint: {
                Name: 'e.g. ACME',
                Desc: 'e.g. Gather sales figures',
            }
        },
        WorkspaceJoin: {
            Title: 'Join an existing Workspace',
            Code: 'Reference code',
            HelpNote: 'A reference code is required to join a workspace. Codes can only be obtained from an existing workspace user with the appropriate security rigts.',
            Hint: {
                Name: 'e.g. ACME',
                Code: 'e.g. ABC123',
            }
        },
        WorkspaceProjects: {
            NoProject: '- No Project Selected -',
            NewProject: 'New Project',
            ReadMe: 'Read me for quick info',
            Applications: 'Applications',
            DataConns: 'Data Connections',
            DataModels: 'Data Models',
            DataViews: 'Data Views',
            Reports: 'Reports',
            Services: 'Services',
        },
        ProjectNew: {
            Title: 'Create a new project...',
            Hint: {
                Name: 'e.g. Sales Tracking',
                Desc: 'e.g. Gather data for visual presentation to management.',
            }
        },
        FormFeedback: {
            Title: 'Feedback',
            Type: 'Type',
            TypeList: [
                'General Feedback',
                'Feature Request',
                'Suggestion',
                'Report a bug'
            ],
            Hint: {
                DescFeedback: 'e.g. I find it difficult to locate the help documentation. It would be nice it it was easily accessible.',
                DescRequest: 'e.g. Please add export output type for data to Excel file.',
                DescSuggest: 'e.g. Adding shortcuts for often used functions would greatly improve my productivity. Functions I use often are ...',
            }
        },
        ConnFileNew: {
            Title: 'File Source',
            Type: 'Source Type',
            TypeList: [
                { Key: 'Local', Text: 'Local/Network file' },
                // { Key: 'Network', Text: 'Network file' },
                { Key: 'Internet', Text: 'Internet file' },
                // { Key: 'FTP', Text: 'FTP file' },
            ],
            Location: 'Location (HTTP or FTP)',
            DataModel: 'Data Model - Choose from existing models or create one based on the data',
            Content: 'Content type of the source file',
            ContentList: [
                { Key: 'AUTO', Text: 'Auto - Detect the contents type' },
                { Key: 'CSV', Text: 'CSV - Character separated data' },
                { Key: 'JSON', Text: 'JSON - JavaScript Object Notation' },
                { Key: 'XML', Text: 'XML - Extensible Markup Language' },
                { Key: 'XLSX', Text: 'XLSX - Sheet based data' },
                // PDF
            ],
            AuthType: 'Authorisation Type',
            AuthTypeList: [
                { Key: 'NO_AUTH', Text: 'No Auth' },
                { Key: 'API_KEY', Text: 'API Key' },
                { Key: 'BEARER', Text: 'Bearer Token' },
                { Key: 'BASIC', Text: 'Basic Auth' },
                { Key: 'DIGEST', Text: 'Digest Auth' },
                { Key: 'OAUTH_1', Text: 'OAuth 1.0' },
                { Key: 'OAUTH_2', Text: 'OAuth 2.0' },
                { Key: 'HAWK', Text: 'Hawk Authentication' },
                { Key: 'AWS_SIG', Text: 'AWS Signature' },
                { Key: 'NTLM', Text: 'NTLM Authentication' },
                { Key: 'AKAMAI', Text: 'Akamai EdgeGrid' },
            ],
            AuthOptions: 'Authorisation Type Options',
            Key: 'Key',
            Value: 'Value',
            AddTo: 'Add To',
            AddToList: [
                { Key: 'HEADER', Text: 'Header' },
                { Key: 'QUERY', Text: 'Query parameters' },
            ],
            Token: 'Token',
            OptionalOpt: 'Optional advanced configuration options',
            Realm: 'Realm',
            RealmPlace: 'qarealm@example.com',
            Nonce: 'Nonce',
            NoncePlace: 'Nonce',
            Algorithm: 'Algorithm',
            qop: 'qop',
            qopPlace: 'e.g. auth-int',
            NonceCount: 'Nonce Count',
            NonceCountPlace: 'e.g. 0000001',
            ClientNonce: 'Client Nonce',
            ClientNoncePlace: 'e.g. 0AC792F8',
            Opaque: 'Opaque',
            OpaquePlace: 'Opaque',
        },
        // ----
        AddConnectionType: {
            Title: 'Connection Type',
            Info: 'Connection type to add. Please refer to the documentation for a detailed explanation of each connection type and its properties.',
            File: 'File',
            FileDesc: 'A file can be opened from a local location, network drive or FTP server.',
            Service: 'Service',
            ServiceDesc: 'Webservices that outputs XML or REST data.',
            Queue: 'Queue',
            QueueDesc: 'Message queue system, similar to a webservice.',
            Database: 'Database',
            DatabaseDesc: 'Direct database connection. Server-side only.',
        },
        ConfigConnectionFile: {
            Title: 'File (Connection)',
            Type: 'Source Type',
            TypeList: [
                { Key: 'Local', Text: 'Local file' },
                { Key: 'Network', Text: 'Network file' },
                { Key: 'Internet', Text: 'Internet file' },
                { Key: 'FTP', Text: 'FTP file' },
            ],
            Location: 'Location',
            Content: 'Content type of the source file',
            ContentList: [
                { Key: 'AUTO', Text: 'Auto - Detect the content type' },
                { Key: 'CSV', Text: 'CSV - Character separated data' },
                { Key: 'JSON', Text: 'JSON - JavaScript Object Notation' },
                { Key: 'XML', Text: 'XML - Extensible Markup Language' },
                { Key: 'XLSX', Text: 'XLSX - Sheet based data' },
                // PDF
            ],
            AuthType: 'Authorisation Type',
            AuthTypeList: [
                { Key: 'NO_AUTH', Text: 'No Auth' },
                { Key: 'API_KEY', Text: 'API Key' },
                { Key: 'BEARER', Text: 'Bearer Token' },
                { Key: 'BASIC', Text: 'Basic Auth' },
                { Key: 'DIGEST', Text: 'Digest Auth' },
                { Key: 'OAUTH_1', Text: 'OAuth 1.0' },
                { Key: 'OAUTH_2', Text: 'OAuth 2.0' },
                { Key: 'HAWK', Text: 'Hawk Authentication' },
                { Key: 'AWS_SIG', Text: 'AWS Signature' },
                { Key: 'NTLM', Text: 'NTLM Authentication' },
                { Key: 'AKAMAI', Text: 'Akamai EdgeGrid' },
            ],
            AuthOptions: 'Authorisation Type Options',
            Key: 'Key',
            Value: 'Value',
            AddTo: 'Add To',
            AddToList: [
                { Key: 'HEADER', Text: 'Header' },
                { Key: 'QUERY', Text: 'Query parameters' },
            ],
            Token: 'Token',
            OptionalOpt: 'Optional advanced configuration options',
            Realm: 'Realm',
            RealmPlace: 'qarealm@example.com',
            Nonce: 'Nonce',
            NoncePlace: 'Nonce',
            Algorithm: 'Algorithm',
            qop: 'qop',
            qopPlace: 'e.g. auth-int',
            NonceCount: 'Nonce Count',
            NonceCountPlace: 'e.g. 0000001',
            ClientNonce: 'Client Nonce',
            ClientNoncePlace: 'e.g. 0AC792F8',
            Opaque: 'Opaque',
            OpaquePlace: 'Opaque',
        },
        Builder: {
            AppName: 'FireOcto Builder',
            ProjectFiles: 'Project Files',
            Controls: 'Controls',
            PropsEvents: 'Control Properties & Events',
            Project: 'Project',
            Wizard: 'Wizard',
            NewFile: 'New File',
            NewFolder: 'New Folder',
            RefreshExplorer: 'Refresh Explorer',
            Properties: 'Properties',
            Events: 'Events',
            SortAsc: 'Sort Ascending',
            SortDesc: 'Sort Descending',
            Grouped: 'Grouped',
            SampleProjectClient: 'Sample Project (Front-end Web Client)', // Web App
            SampleProjectServer: 'Sample Project (Back-end Server)', // Server App
            Configuration: 'Configuration',
            ServiceEndpoints: 'Service Endpoints',
            Templates: 'Templates',
            Translations: 'Translations',
            ViewDesigns: 'View Designs',
            //
            Connections: 'Connections',
            DataViews: 'Data Views',
            ScheduledTasks: 'Scheduled Tasks',
            ServiceWorkers: 'Service Workers',
            //
            // Connections: 'Connections',
            // DataViews: 'Data Views',
            // ServiceWorkers: 'Service Workers',
            // Translations: 'Translations',
            // ViewDesigns: 'View Designs',
            //
        }
    }
};
