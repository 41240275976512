export default class Color {
    /**
     * Converts alpha hex color to rgba.
     *
     * @param {any} ahex Hex value e.g. FF000000
     * @param {any} parts Optional flag that indicates to return the rgba as an object.
     * @returns RGBA string or object with colour parts.
     */
    static ahex2rgba (ahex, parts) {
        parts = parts === true;
        const v = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(ahex);
        if (!v) return null;

        const a = parseInt(v[1], 16) / 255;
        const r = parseInt(v[2], 16);
        const g = parseInt(v[3], 16);
        const b = parseInt(v[4], 16);

        if (parts) return { r: r, g: g, b: b, a: a };
        else return `rgba(${r},${g},${b},${a})`;
    }

    /**
     * Gets the colour value from a colour definition.
     *
     * @param {any} c Colour definition.
     * @param {any} tColours Array of theme main colours.
     * @returns A colour value.
     */
    static getColor (c, themeColours) {
        if (c.auto || c.indexed !== undefined) return '#000000';
        let clr = '#000000';
        if (c.theme !== undefined) { // Theme colour.
            clr = themeColours[c.theme];
            if (clr && c.tint) { // The theme colour is tinted.
                clr = `#${Color.tintColor(c.tint, clr)}`;
            }
        }
        else if (c.rgb) {
            clr = c.rgb.length === 8 ? Color.ahex2rgba(c.rgb) : Color.hex2rgb(c.rgb);
        }

        return clr;
    }

    /**
     * Checks a (background) colour then returns the appropriate text colour (black or white) to display on it.
     * @param {*} rgba Colour value.
     * @returns Black or White
     */
    static getTextColor (color) {
        if (color[0] === '#') color = Color.hex2rgb(color);
        const rgba = color.match(/\d+/g);
        if ((rgba[0] * 0.299) + (rgba[1] * 0.587) + (rgba[2] * 0.114) > 186) {
            return 'black';
        }
        else {
            return 'white';
        }
    }

    /**
     * Converts hex color to rgb.
     *
     * @param {any} hex Hex value e.g. 000000
     * @param {any} parts Optional flag that indicates to return the RGB as an object.
     * @returns RGB string or object with colour parts.
     */
    static hex2rgb (hex, parts) {
        parts = parts === true;
        if (hex.length === 4) {
            hex = '#' + hex.substr(1).split('').map(function (hex) {
                return hex + hex;
            }).join('');
        }
        const v = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if (!v) return null;

        const r = parseInt(v[1], 16);
        const g = parseInt(v[2], 16);
        const b = parseInt(v[3], 16);

        if (parts) return { r: r, g: g, b: b };
        else return `rgb(${r},${g},${b})`;
    }

    /**
     * Turns RGB colour values into a HEX colour.
     *
     * @param {any} r Red value (0 - 255)
     * @param {any} g Green value (0 - 255)
     * @param {any} b Blue value (0 - 255)
     * @returns Hex value.
     */
    static rgb2hex (r, g, b) {
        if (arguments.length === 1) {
            r = r.replace(/[^0-9.,]/g, '');
            const l = r.split(',');
            r = l[0] * 1;
            g = l[1] * 1;
            b = l[2] * 1;
        }
        function cX (c) {
            const h = c.toString(16);
            return h.length === 1 ? '0' + h : h;
        }
        return (cX(r) + cX(g) + cX(b)).toUpperCase(); // #
    }

    /**
     * Creates an RGB object from the rgb colour string.
     *
     * @param {any} rgb Colour string.
     * @returns Object with colour parts.
     */
    static rgb2obj (rgb) {
        const pos = rgb.indexOf('(') + 1;
        const p = rgb.substr(pos, rgb.length - pos - 1).split(',');

        return {
            r: parseInt(p[0], 10),
            g: parseInt(p[1], 10),
            b: parseInt(p[2], 10),
            a: p[3] === undefined ? 1 : parseFloat(p[3])
        };
    }

    /**
     * Recolours a colour of the given image e.g. red to green.
     * One pixel consists of 3 colours.
     * Sample (black to green): recolorImage(img,   0, 0, 0,   0, 255, 0);
     *
     * @param {any} img HTML Image object.
     * @param {any} oldRed Red colour to replace from 0 to 255.
     * @param {any} oldGreen Green colour to replace.
     * @param {any} oldBlue Blue colour to replace.
     * @param {any} newRed New red colour.
     * @param {any} newGreen New green colour.
     * @param {any} newBlue New blue colour.
     * @returns Recoloured image element.
     */
    static recolorImage (img, oldRed, oldGreen, oldBlue, newRed, newGreen, newBlue, asPattern, cb) {
        // Create an offscreen canvas.
        const c = document.createElement('canvas');
        // Adjust to the image size.
        const w = img.width;
        const h = img.height;

        c.width = w;
        c.height = h;
        /* // Set the attribute size.
        c.setAttribute('width', w * window.devicePixelRatio);
        c.setAttribute('height', h * window.devicePixelRatio);
        // Set the style size.
        c.style.width = `${w}px`;
        c.style.height = `${h}px`;
        // Scale the context for crispness.
        c.getContext('2d').scale(window.devicePixelRatio, window.devicePixelRatio); */

        // Draw the image on the temporary canvas.
        const ctx = c.getContext('2d', { willReadFrequently: true });
        ctx.drawImage(img, 0, 0, w, h);

        // Pull the image into an array of pixel data.
        const imageData = ctx.getImageData(0, 0, w, h);

        // Examine every pixel; change old rgb to the new-rgb.
        const len = imageData.data.length;
        for (let i = 0; i < len; i += 4) {
            // Is this pixel the old rgb?
            if (imageData.data[i] === oldRed && imageData.data[i + 1] === oldGreen && imageData.data[i + 2] === oldBlue) {
                // Change to new rgb.
                imageData.data[i] = newRed;
                imageData.data[i + 1] = newGreen;
                imageData.data[i + 2] = newBlue;
            }
        }

        // Put the altered data back on the canvas.
        ctx.putImageData(imageData, 0, 0);
        const newImg = new Image();
        newImg.onload = function () { // Action once loaded.
            cb(asPattern ? ctx.createPattern(newImg, 'repeat') : newImg);
        };
        newImg.src = c.toDataURL('image/png'); // This should be fine (don't wait for onload) for small images e.g. the patterns.

        // return c.toDataURL('image/png'); // Get the re-colored image data.
    }

    /**
     * Shades a HEX color lighter or darker. tint is -1 to 1.
     * @link http://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
     *
     * @param {any} p Tint percent from 0 to 1 or 0 to -1.
     * @param {any} c0 Colour 1.
     * @param {any} c1 Colour 2 (optional).
     * @returns Tinted colour string.
     */
    static tintColor (p, c0, c1) {
        const n = p < 0 ? p * -1 : p;
        const u = Math.round;
        const w = parseInt;
        let f;
        let t;
        let R;
        let G;
        let B;
        if (c0.length > 7) {
            f = c0.split(',');
            t = (c1 || p < 0 ? 'rgb(0,0,0)' : 'rgb(255,255,255)').split(',');
            R = w(f[0].slice(4));
            G = w(f[1]);
            B = w(f[2]);
            return 'rgb(' + (u((w(t[0].slice(4)) - R) * n) + R) + ',' + (u((w(t[1]) - G) * n) + G) + ',' + (u((w(t[2]) - B) * n) + B) + ')';
        }
        else {
            if (c0.charAt(0) !== '#') c0 = '#' + c0;
            if (c1 && c1.charAt(0) !== '#') c1 = '#' + c1;
            f = w(c0.slice(1), 16);
            t = w((c1 || p < 0 ? '#000000' : '#FFFFFF').slice(1), 16);
            R = f >> 16;
            G = f >> 8 & 0x00FF;
            B = f & 0x0000FF;
            // return '#'+(0x1000000+(u(((t>>16)-R)*n)+R)*0x10000+(u(((t>>8&0x00FF)-G)*n)+G)*0x100+(u(((t&0x0000FF)-B)*n)+B)).toString(16).slice(1)
            return (0x1000000 + (u(((t >> 16) - R) * n) + R) * 0x10000 + (u(((t >> 8 & 0x00FF) - G) * n) + G) * 0x100 + (u(((t & 0x0000FF) - B) * n) + B)).toString(16).slice(1);
        }
    }

    // https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)
    static shadeBlendConvert (p, c0, c1, l) {
        let r,g,b,P,f,t,h,m=Math.round,a=typeof(c1)=="string";
        if(typeof(p)!="number"||p<-1||p>1||typeof(c0)!="string"||(c0[0]!='r'&&c0[0]!='#')||(c1&&!a))return null;
        h=c0.length>9,h=a?c1.length>9?true:c1=="c"?!h:false:h,f=Color.pSBCr(c0),P=p<0,t=c1&&c1!="c"?Color.pSBCr(c1):P?{r:0,g:0,b:0,a:-1}:{r:255,g:255,b:255,a:-1},p=P?p*-1:p,P=1-p;
        if(!f||!t)return null;
        if(l)r=m(P*f.r+p*t.r),g=m(P*f.g+p*t.g),b=m(P*f.b+p*t.b);
        else r=m((P*f.r**2+p*t.r**2)**0.5),g=m((P*f.g**2+p*t.g**2)**0.5),b=m((P*f.b**2+p*t.b**2)**0.5);
        a=f.a,t=t.a,f=a>=0||t>=0,a=f?a<0?t:t<0?a:a*P+t*p:0;
        if(h)return"rgb"+(f?"a(":"(")+r+","+g+","+b+(f?","+m(a*1000)/1000:"")+")";
        else return"#"+(4294967296+r*16777216+g*65536+b*256+(f?m(a*255):0)).toString(16).slice(1,f?undefined:-2)
    }

    static pSBCr (d) {
        const i=parseInt;
        let n=d.length,x={};
        if (n > 9) {
            const [r, g, b, a] = (d = d.split(','));
            n = d.length;
            if(n<3||n>4)return null;
            x.r=i(r[3]=="a"?r.slice(5):r.slice(4)),x.g=i(g),x.b=i(b),x.a=a?parseFloat(a):-1
        }
        else {
            if(n==8||n==6||n<4)return null;
            if(n<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(n>4?d[4]+d[4]:"");
            d=i(d.slice(1),16);
            if(n==9||n==5)x.r=d>>24&255,x.g=d>>16&255,x.b=d>>8&255,x.a=Math.round((d&255)/0.255)/1000;
            else x.r=d>>16,x.g=d>>8&255,x.b=d&255,x.a=-1
        }
        return x
    }
}
