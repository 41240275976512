import Control from './control';
import Util from '../util/util';
import UI from '../util/ui';

export default class Label extends Control {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'Label';
        // definition.Props.ClassFixed = 'foui-ctrl foui-control-panel foui-stretch';
        super(definition, Label._DEF.Properties);
    }

    init () {
        // Setting `this.meta.ClassFixed` only works if `Class` is handled in `onChange`.
        this.addClass('foui-ctrl foui-control-panel foui-stretch');
        // Name starts with _ so that it won't be added to the $name lookups.
        this.ui.label = this.newControl({ Type: 'CoreLabel', Props: { Name: `_${this.props.Name}_label`, ClassFixed: 'foui-label unselectable' } });
        this.bindControl(this.ui.label, [
            { other: 'Class', local: 'Class' },
            // { other: 'ClassFixed', local: 'ClassFixed' },
            { other: 'For', local: 'For' },
            // { other: 'HTML', local: 'HTML' },
            // { other: 'Text', local: 'Text' },
            { other: 'Tip', local: 'Tip' },
        ]);
    }

    onChange (changes) {
        // console.log('Label changes', changes);
        for (const [key, v] of changes) {
            const value = Util.checkForBoundedValue(v, this);
            switch (key) {
                case 'Alignment': this.updateAlignment(); break;
                case 'HTML': UI.attr(this.props.Icon ? this.ui.text : this.ui.label, { html: value }); break;
                case 'Icon': this.updateIcon(); break;
                case 'IconLocation': this.updateIconLocation(); break;
                case 'IconSpace': this.updateIconSpace(); break;
                case 'IconHeight': this.updateIconHeight(); break;
                case 'IconWidth': this.updateIconWidth(); break;
                case 'Text': UI.attr(this.props.Icon ? this.ui.text : this.ui.label, { text: value }); break;
                case 'Width': this.updateWidth(); break;
            }
        }
    }

    updateAlignment () {
        UI.removeClass(this.ui.label, 'foui-flex-align-self-start');
        UI.removeClass(this.ui.label, 'foui-flex-align-self-end');
        UI.removeClass(this.ui.label, 'foui-flex-align-self-center');
        UI.removeClass(this.elem, 'foui-flex-justify-content-start');
        UI.removeClass(this.elem, 'foui-flex-justify-content-end');
        UI.removeClass(this.elem, 'foui-flex-justify-content-center');
        const align = this.props.Alignment;
        switch (align) {
            case 'start':
                UI.addClass(this.ui.label, 'foui-flex-align-self-center');
                UI.addClass(this.elem, 'foui-flex-justify-content-start');
                break;
            case 'end':
                UI.addClass(this.ui.label, 'foui-flex-align-self-center');
                UI.addClass(this.elem, 'foui-flex-justify-content-end');
                break;
            case 'center':
                UI.addClass(this.ui.label, 'foui-flex-align-self-center');
                UI.addClass(this.elem, 'foui-flex-justify-content-center');
                break;
            case 'start-top':
                UI.addClass(this.ui.label, 'foui-flex-align-self-start');
                UI.addClass(this.elem, 'foui-flex-justify-content-start');
                break;
            case 'end-top':
                UI.addClass(this.ui.label, 'foui-flex-align-self-start');
                UI.addClass(this.elem, 'foui-flex-justify-content-end');
                break;
            case 'center-top':
                UI.addClass(this.ui.label, 'foui-flex-align-self-start');
                UI.addClass(this.elem, 'foui-flex-justify-content-center');
                break;
            case 'start-bottom':
                UI.addClass(this.ui.label, 'foui-flex-align-self-end');
                UI.addClass(this.elem, 'foui-flex-justify-content-start');
                break;
            case 'end-bottom':
                UI.addClass(this.ui.label, 'foui-flex-align-self-end');
                UI.addClass(this.elem, 'foui-flex-justify-content-end');
                break;
            case 'center-bottom':
                UI.addClass(this.ui.label, 'foui-flex-align-self-end');
                UI.addClass(this.elem, 'foui-flex-justify-content-center');
                break;
            default:
                UI.addClass(this.ui.label, 'foui-flex-align-self-center');
                UI.addClass(this.elem, 'foui-flex-justify-content-start');
                break;
        }
    }

    updateWidth () {
        let value = this.props.Width;
        if (value === '' || value === 'auto' || value === undefined) {
            UI.attr(this.elem, { style: 'flex-basis:inherit' });
            UI.attr(this.elem, { style: 'min-width:inherit' });
            if (value === 'auto') {
                this.addClass('foui-flex-shrink');
                this.removeClass('foui-stretch');
            }
            else this.addClass('foui-stretch');
            return;
        }
        if (typeof value === 'number') value = `${value}px`;
        UI.attr(this.elem, { style: `flex-basis:${value}` });
        UI.attr(this.elem, { style: `min-width:${value}` });
        this.removeClass('foui-flex-shrink');
        this.removeClass('foui-stretch');
    }

    updateIcon () {
        if (this.props.Icon) {
            // Add or update.
            let iconStr = Util.getIconString(this.props.Icon);
            if (!iconStr) iconStr = Util.getIconPlaceholderString();
            if (this.ui.icon) { // Icon element exists.
                if (this.ui.icon.dataset.name !== this.props.Icon) this.ui.icon.innerHTML = iconStr; // Update.
                return;
            }
            this.ui.icon = UI.create('span', { class: 'foui-control-ico', 'data-name': this.props.Icon, html: iconStr });
            this.ui.text = UI.create('span', { class: 'foui-control-text', text: this.props.Text });
            this.ui.label.innerHTML = '';

            this.ui.label.elem.append(this.ui.icon);
            this.ui.label.elem.append(this.ui.text);

            this.updateIconLocation();
            return;
        }

        // Remove.
        if (this.ui.icon) {
            UI.remove(this.ui.icon);
            if (this.props.Text) UI.attr(this.elem, { text: this.props.Text });
        }
    }

    updateIconLocation () {
        UI.removeClass(this.ui.label, 'foui-flex-row');
        UI.removeClass(this.ui.label, 'foui-flex-row-reverse');
        UI.removeClass(this.ui.label, 'foui-flex-column');
        UI.removeClass(this.ui.label, 'foui-flex-column-reverse');
        setTimeout(() => {
            const location = this.props.IconLocation || 'start';
            switch (location) {
                case 'start': UI.addClass(this.ui.label, 'foui-flex-row'); break;
                case 'end': UI.addClass(this.ui.label, 'foui-flex-row-reverse'); break;
                case 'top': UI.addClass(this.ui.label, 'foui-flex-column'); break;
                case 'bottom': UI.addClass(this.ui.label, 'foui-flex-column-reverse'); break;
            }
        });
        this.updateIconSpace();
    }

    updateIconSpace () {
        if (!this.ui.icon) return;
        if (this.props.Text === '') { // No text therefore no margins.
            UI.attr(this.ui.icon, { style: 'margin:0' });
            return;
        }

        const location = this.props.IconLocation || 'start';
        const space = this.props.IconSpace || 6;
        let margin;
        switch (location) {
            case 'top': margin = `0 0 ${space}px 0`; break;
            case 'end': margin = `0 0 0 ${space}px`; break;
            case 'bottom': margin = `${space}px 0 0 0`; break;
            case 'start': margin = `0 ${space}px 0 0`; break;
        }
        UI.attr(this.ui.icon, { style: `margin:${margin}` });
    }

    updateIconHeight () {
        if (!this.ui.icon) return;
        if (Util.isNullOrEmpty(this.props.IconHeight)) {
            UI.style(this.ui.icon, 'height:auto');
            UI.removeClass(this.ui.icon, 'foui-stretch-y');
        }
        else if (this.props.IconHeight === 'auto') {
            UI.style(this.ui.icon, 'height:auto');
            UI.addClass(this.ui.icon, 'foui-stretch-y');
        }
        else {
            const w = parseFloat(this.props.IconHeight);
            const unit = Util.getValueUnit(this.props.IconHeight);
            UI.removeClass(this.ui.icon, 'foui-stretch-y');
            UI.style(this.ui.icon, `height:${w}${unit}`);
        }
    }

    updateIconWidth () {
        if (!this.ui.icon) return;
        if (Util.isNullOrEmpty(this.props.IconWidth)) {
            UI.style(this.ui.icon, 'width:auto');
            UI.removeClass(this.ui.icon, 'foui-stretch-x');
        }
        else if (this.props.IconWidth === 'auto') {
            UI.style(this.ui.icon, 'width:auto');
            UI.addClass(this.ui.icon, 'foui-stretch-x');
        }
        else {
            const w = parseFloat(this.props.IconWidth);
            const unit = Util.getValueUnit(this.props.IconWidth);
            UI.removeClass(this.ui.icon, 'foui-stretch-x');
            UI.style(this.ui.icon, `width:${w}${unit}`);
        }
    }
}

// Control property definitions.
Object.defineProperty(Label, '_DEF', {
    value: {
        Group: 'Common Controls',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            Alignment: { Default: undefined, Group: 'Style', Label: 'Alignment', Type: 'Text', Note: 'The overall location of the content.' },
            // AlignHorizontal: { Default: 'start', Group: 'Alignment', Label: 'Align Horizontal', Type: 'List', items: PropertyConstants.ALIGN_HORIZONTAL, Note: 'Text horizontal alignment.' },
            // AlignVertical: { Default: 'middle', Group: 'Alignment', Label: 'Align Vertical', Type: 'List', items: PropertyConstants.ALIGN_VERTICAL, Note: 'Text vertical alignment.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Style Class', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            // Cursor: { Default: undefined, Group: 'Style', Label: 'Mouse Pointer', Type: 'Text', Note: 'Mouse pointer (cursor) icon when the mouse is over.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout panel into design mode.' },
            For: { Default: undefined, Group: 'General', Label: 'For Control', Type: 'Text', Note: 'Name of control to focus on click.' },
            HTML: { Default: undefined, Group: 'General', Label: 'HTML', Type: 'Text', Note: 'HTML code as content.' },
            Icon: { Default: undefined, Group: 'Style', Label: 'Icon', Type: 'Text', Note: 'Icon name from the configured icon set.' },
            IconLocation: { Default: undefined, Group: 'Style', Label: 'Icon Location', Type: 'Text', Note: 'The location of the icon relative to the text.' },
            IconSpace: { Default: undefined, Group: 'Style', Label: 'Icon Space Between', Type: 'Number', Note: 'Spacing between the icon and the text.' },
            IconHeight: { Default: 18, Group: 'Style', Label: 'Icon Height', Type: 'Any', Note: 'Height of the icon. Leave blank for the default size. A value of "auto", px or % is valid.' },
            IconWidth: { Default: 18, Group: 'Style', Label: 'Icon Width', Type: 'Any', Note: 'Width of the icon. Leave blank for the default size. A value of "auto", px or % is valid.' },
            // Margins: { Default: '0', Group: 'Style', Label: 'Margins', Type: 'Text', Note: 'Margins outside the control borders.' },
            // Padding: { Default: undefined, Group: 'Style', Label: 'Padding', Type: 'Text', Note: 'Spacing inside the control borders.' },
            // Size: { Default: 'dynamic', Group: 'General', Label: 'Size', Type: 'List', items: PropertyConstants.SIZE, Note: 'Size behaviour.' },
            Text: { Default: 'Label', Group: 'General', Label: 'Text', Type: 'Text', Note: 'Text value to display.' },
            Tip: { Default: undefined, Group: 'General', Label: 'Tool Tip', Type: 'Any', Note: 'Information related to the control.' },
            // Truncate: { Default: false, Group: 'State', Label: 'Truncate', Type: 'Flag', Note: 'Truncate text with ellipsis when longer than container width.' },
            // Visible: { Default: true, Group: 'State', Label: 'Visible', Type: 'Flag', Note: 'Visible or hidden state of the control.' },
            Width: { Default: undefined, Group: 'Style', Label: 'Width', Type: 'Any', Note: 'Fixed control width. Clear (leave blank) to allow the control to use the maximum available space or auto to size according to text.' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
