import Control from './control';
import Util from '../util/util';
import UI from '../util/ui';

export default class Spacer extends Control {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'Spacer';
        // definition.Props.ClassFixed = 'foui-ctrl foui-control-panel foui-stretch';
        super(definition, Spacer._DEF.Properties);
    }

    init () {
        // Setting `this.meta.ClassFixed` only works if `Class` is handled in `onChange`.
        this.addClass('foui-ctrl foui-control-panel');
        // Name starts with _ so that it won't be added to the $name lookups.
        this.ui.span = this.newControl({ Type: 'CoreSpan', Props: { Name: `_${this.props.Name}_spacer`, ClassFixed: 'foui-spacer unselectable' } });
        this.bindControl(this.ui.span, [
            { other: 'Class', local: 'Class' },
            // { other: 'ClassFixed', local: 'ClassFixed' },
            { other: 'HTML', local: 'HTML' },
        ]);
    }

    onChange (changes) {
        // console.log('Label changes', changes);
        for (const [key, v] of changes) {
            const value = Util.checkForBoundedValue(v, this);
            switch (key) {
                /* case 'DesignMode': {
                    if (value) {
                        // UI.attr(this.ui.span.elem, { tabindex: -1, 'class+': 'unclickable' });
                        UI.attr(this.elem, { draggable: true });
                        this.eventsOff();
                    }
                    else {
                        // UI.attr(this.ui.span.elem, { tabindex: 0, 'class-': 'unclickable' });
                        UI.attr(this.elem, { draggable: false });
                        this.eventsOn();
                    }
                    break;
                } */
                case 'Direction': {
                    if (value === 'horizontal') {
                        UI.removeClass(this.ui.span, 'foui-vertical');
                        UI.addClass(this.ui.span, 'foui-horizontal');
                        this.addClass('foui-stretch-x');
                        this.removeClass('foui-stretch-y');
                        // foui-flex-shrink
                    }
                    else {
                        UI.addClass(this.ui.span, 'foui-vertical');
                        UI.removeClass(this.ui.span, 'foui-horizontal');
                        this.addClass('foui-stretch-y');
                        this.removeClass('foui-stretch-x');
                    }
                    this.updateSize();
                    break;
                }
                case 'Size': {
                    this.updateSize();
                    break;
                }
            }
        }
    }

    updateSize () {
        let value = this.props.Size;
        if (value === '' || value === undefined) {
            UI.attr(this.elem, { style: 'flex-basis:inherit' });
            UI.attr(this.elem, { style: 'min-width:inherit' });
            UI.attr(this.elem, { style: 'min-height:inherit' });
            return;
        }
        if (typeof value === 'number') value = `${value}px`;
        UI.attr(this.elem, { style: `flex-basis:${value}` });
        if (this.props.Direction === 'horizontal') {
            UI.attr(this.elem, { style: `min-width:${value}` });
            UI.attr(this.elem, { style: 'min-height:inherit' });
        }
        else {
            UI.attr(this.elem, { style: 'min-width:inherit' });
            UI.attr(this.elem, { style: `min-height:${value}` });
        }
    }
}

// Control property definitions.
Object.defineProperty(Spacer, '_DEF', {
    value: {
        Group: 'Common Controls',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Style Class', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            // Cursor: { Default: undefined, Group: 'Style', Label: 'Mouse Pointer', Type: 'Text', Note: 'Mouse pointer (cursor) icon when the mouse is over.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout panel into design mode.' },
            HTML: { Default: undefined, Group: 'General', Label: 'HTML', Type: 'Text', Note: 'HTML code as content.' },
            // IconName: { Default: undefined, Group: 'General', Label: 'Icon Name', Type: 'Text', Note: 'Name of Icon in the icon set.' },
            // IconLocation: { Default: undefined, Group: 'General', Label: 'Icon Location', Type: 'Text', Note: 'Where to position the icon.' },
            // IconSpace: { Default: 10, Group: 'General', Label: 'Icon Space Between', Type: 'Number', Note: 'Spacing between the icon and the neighbouring element.' },
            Direction: { Default: undefined, Group: 'Style', Label: 'Direction', Type: 'Text', Note: 'Direction of the splitter line. Either one of horizontal or vertical.' },
            // Margins: { Default: '0', Group: 'Style', Label: 'Margins', Type: 'Text', Note: 'Margins outside the control borders.' },
            // Padding: { Default: undefined, Group: 'Style', Label: 'Padding', Type: 'Text', Note: 'Spacing inside the control borders.' },
            Size: { Default: undefined, Group: 'Style', Label: 'Width', Type: 'Any', Note: 'Fixed control size. Clear (leave blank) to allow the control to use the maximum available space.' },
            // Visible: { Default: true, Group: 'State', Label: 'Visible', Type: 'Flag', Note: 'Visible or hidden state of the control.' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
