// Core.
import CoreButton from './core/button';
import CoreDiv from './core/div';
import CoreInput from './core/input';
import CoreLabel from './core/label';
import CoreSpan from './core/span';
import CoreTable from './core/table';
// Standard.
// import Alert from './alert';
import Button from './button';
// import Calendar from './calendar';
import Check from './check';
// import Dialog from './dialog';
// import FileDrop from './file-drop';
// import Icon from './icon';
// import InfoBlock from './info-block';
import HeaderLine from './header-line';
import Label from './label';
import LayoutGrid from './layout-grid';
import LayoutGridCell from './layout-grid-cell';
// import LineHorizontal from './line-horizontal';
import List from './list';
import Option from './option';
import Panel from './panel';
// import ProgressBar from './progress-bar';
import PointerDraw from './pointer-draw';
import Popup from './popup';
import PopupMenu from './popup-menu';
import PropertyForm from './property-form';
import PropertyGrid from './property-grid';
// import Repeater from './repeater';
// import Scrollbar from './scrollbar';
import Select from './select';
// import Slider from './slider';
import Spacer from './spacer';
import Splitter from './splitter';
import Switch from './switch';
import Table from './table';
import Tabs from './tabs';
import Text from './text';
// import TextLines from './text-lines';
import Toolbar from './toolbar';
// import ToolboxTitle from './toolbox-title';
// import TouchCoder from './touch-coder';
import Tree from './tree';
// import View from './view';

// import DashLine from './charts/dash-line';
// import PieLine from './charts/pie-line';

import WorkflowBoard from './workflow/workflow-board';

export default {
    // -- Core.
    CoreButton,
    CoreDiv,
    CoreInput,
    CoreLabel,
    CoreSpan,
    CoreTable,

    // -- Standard.
    // Alert,
    Button,
    // Calendar,
    Check,
    // Dialog,
    // FileDrop,
    HeaderLine,
    // Icon,
    // InfoBlock,
    Label,
    LayoutGrid,
    LayoutGridCell,
    // LineHorizontal,
    List,
    Option,
    Panel,
    // ProgressBar,
    PointerDraw,
    Popup,
    PopupMenu,
    PropertyForm,
    PropertyGrid,
    // Repeater,
    // Scrollbar,
    Select,
    // Slider,
    Spacer,
    Splitter,
    Switch,
    Table,
    Tabs,
    Text,
    // TextLines,
    Toolbar,
    // TouchCoder,
    Tree,
    // View,

    // -- Chart/Graph
    // DashLine,
    // PieLine,

    // -- Workflow
    WorkflowBoard,
};
