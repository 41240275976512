import Controls from './index';
import UI from '../util/ui';
import Util from '../util/util';
import Watch from '../util/watch';
import { customAlphabet } from 'nanoid';
import { CONTROL_EVENTS } from '../constants/mapped-events';

const ignoredDesignCovers = ['LayoutGrid', 'LayoutGridCell', 'Panel'];
const nanoid = customAlphabet('1234567890abcdef', 10);

export default class Control {
    /**
     * Creates an instance of a control.
     * Standard controls extend this base class.
     *
     * @param {object} definition Control definition.
     * @param {object} propertiesDef Control properties definition.
     * @memberof Base
     */
    constructor (definition, propertiesDef) {
        this.FOUI = window[window.FOUI_APP_KEY].FOUI;
        let autoName = false;
        // Properties.
        const props = definition.Props || {};
        if (!props.Name) {
            autoName = true;
            props.Name = `_FO${nanoid()}`;
        }
        this.meta = {
            type: definition.Type,
            _props: props
        };
        if (propertiesDef._isExtended) this.meta.isExtended = true;

        // A quick access ref for controls by name.
        if (!this.$name && definition.$name) this.$name = definition.$name;

        if (definition.CodeBindThis) {
            this.code = {};
            const keys = Object.keys(definition.Code);
            keys.forEach(key => {
                if (definition.Code[key] && definition.Code[key].bind) {
                    this.code[key] = definition.Code[key].bind(this);
                }
            });
        }
        else {
            this.code = definition.Code || {};
        }

        this.handlers = {
            listenProperties: this.listenProperties.bind(this),
            listenData: this.listenData.bind(this),
            // onBaseElemFocus: this.onBaseElemFocus.bind(this),
        };

        this.data = definition.Data;
        if (this.data && this.data.listen) this.data.listen(this.handlers.listenData);

        // if (this.ControlType === 'LayoutGridCell') console.log(JSON.stringify(props, null, 4));
        const mapped = Util.getControlProperties(props, propertiesDef, this.data, this.code, this);
        this.meta.boundProps = new Map();

        this.props = Watch(mapped.Props, props.Name);
        this.props.listen(this.handlers.listenProperties);

        for (const [propLocal, propBound] of mapped.Bind) {
            this.meta.boundProps.set(propBound, propLocal);
            // if (this.meta.type === 'List') console.log('Bind|', this.meta.type, propBound, propLocal);
            this.props.bindTo(this.data, propBound, propLocal);
            this.data.bindTo(this.props, propLocal, propBound);
        }

        this.ui = {};
        this.elem = UI.create('div', {
            id: props.Name,
        });

        // Add the element to the parent.
        this.parent = definition.Parent;
        if (definition.Parent) {
            UI.add(this.elem, this.parent);
        }

        if (definition.Meta) this.meta.Meta = definition.Meta;

        if (this.init) this.init();
        if (this.attachEvents) this.attachEvents();
        this.eventsOn();

        if (!autoName && this.$name && !props.Name.startsWith('_')) { // If the control is named and there is a control reference object, add it for easy access on a View, unless it starts wit an underscore.
            this.$name[props.Name] = this;
        }
        if (!definition.GlobalHide) {
            if (this.FOUI.hasControl(props.Name)) {
                console.error(`Another control with the name "${props.Name}" has already been created. Please review the names and change one to prevent unexpected behaviour.`);
                console.warn(this, this.FOUI.getControl(props.Name));
            }
            this.FOUI.addControl(props.Name, this);
        }

        // Send all the properties to the control's onChange handler so that it can update the UI.
        if (this.props.ClassFixed && this.props.Class === undefined) this.props.Class = ''; // If no class, set to blank so that `ClassFixed` is actioned on init.
        if (this.onChange) this.onChange(Util.objectToMap(this.props));

        if (definition.CodeBindThis && this.code.init) this.code.init();
    }

    /**
     * Bidirectionally binds another control's properties to this control's.
     *
     * @param {Object} control The other control to use.
     * @param {Array} props A list of only the properties to map. Consists of `{ other, local }`. `other` is the property from the other (passed in) control. `local` is the property of this control.
     */
    bindControl (control, props) {
        for (const prop of props) {
            // Map to each other.
            this.props.bindTo(control.props, prop.other, prop.local);
            control.props.bindTo(this.props, prop.local, prop.other);
            // Set the local value on the control.
            // console.log(prop.other, prop.local, control.props[prop.other], this.props[prop.local]);
            if (control.props[prop.other] !== this.props[prop.local]) {
                control.props[prop.other] = this.props[prop.local];
            }
        }
    }

    /**
     * Attach listeners to the specified event handler.
     */
    eventsOn () {
        // UI.on(this.elem, 'focus', this.handlers.onBaseElemFocus);
        // Register events if there are any mapped events from the properties.
        this.meta.evtOn = true;
        if (!this.meta._props.Events) return;
        const evts = this.meta._props.Events;
        const keys = Object.keys(evts);
        for (const key of keys) {
            if (!CONTROL_EVENTS[key]) return;
            UI.on(this.elem, CONTROL_EVENTS[key], typeof evts[key] === 'function' ? evts[key] : this.code[evts[key]], false);
        }
    }

    /**
     * Detach listeners from the specified event handler.
     */
    eventsOff () {
        // UI.off(this.elem, 'focus', this.handlers.onBaseElemFocus);
        // Deregister events if there are any mapped events from the properties.
        this.meta.evtOn = false;
        if (!this.meta._props.Events) return;
        const evts = this.meta._props.Events;
        const keys = Object.keys(evts);
        for (const key of keys) {
            if (!CONTROL_EVENTS[key]) return;
            UI.off(this.elem, CONTROL_EVENTS[key], typeof evts[key] === 'function' ? evts[key] : this.code[evts[key]], false);
        }
    }

    // -> Event handlers.
    /**
     * Handles any changes that happen on the local properties.
     *
     * @param {Map} changes A Map of recent changes.
     */
    listenProperties (changes) {
        if (changes.has('DesignMode')) this.updateDesignMode(changes.get('DesignMode'));
        // Fire a change event if the extended control is listening for it.
        for (const [key, v] of changes) {
            const dotPos = key.indexOf('.');
            if (dotPos !== -1 && this.meta.boundProps.has(key.substring(0, dotPos))) {
                changes.set(`${this.meta.boundProps.get(key.substring(0, dotPos))}.${key.substring(dotPos + 1)}`, v);
            }
            // changes.delete(key); // Not for this control.
        }
        if (this.onChange) this.onChange(changes);
    }

    /**
     * Handles any changes that happen on the data object.
     *
     * @param {Map} changes A Map of recent changes.
     */
    listenData (changes) {
        if (this.onDataChange) this.onDataChange(changes);
    }

    /* onBaseElemFocus () {
        console.log(this.FOUI.Meta.popup);
        // UI.sendEvent(document.body, 'close-popup', 1);
    } */

    updateDesignMode (v) {
        if (ignoredDesignCovers.indexOf(this.meta.type) > -1) return;
        const value = Util.checkForBoundedValue(v, this);
        if (value) {
            this.ui.designCover = this.newControl({ Type: 'CoreDiv', Props: { Name: `_${this.props.Name}_cover`, Class: 'foui-ctrl-design-cover' } });
            UI.attr(this.elem, { draggable: true });
            this.eventsOff();
            if (this.detachEvents) this.detachEvents();
        }
        else {
            if (this.ui.designCover) this.ui.designCover.remove();
            UI.attr(this.elem, { draggable: false });
            // if (!this.handlers && this.attachEvents) this.attachEvents();
            if (!this.meta.evtOn && this.attachEvents) this.attachEvents(); // Check `this.meta.evtOn`. Events can be attached already.
            this.eventsOn();
        }
    }
    // ------------------
    // <- Event handlers.

    // -> Control helper functions.
    /**
     * Adds a class to the control.
     *
     * @param {string} value Class name to add.
     */
    addClass (value) {
        UI.addClass(this.elem, value);
    }

    /**
     * Checks if the element has the given class name assigned to it.
     *
     * @param {string} value Class name to check for.
     * @returns {*|boolean}
     */
    hasClass (value) {
        return UI.hasClass(this.elem, value);
    }

    /**
     * Creates a new control from the given definition.
     * The control element is also added (`append`) to the parent element.
     *
     * @param controlDef Control definition with properties and events.
     * @returns {*} Control
     */
    newControl (controlDef, parent) {
        if (!Controls[controlDef.Type]) return console.error(`Control type "${controlDef.Type}" does not exist in the framework or has not been loaded.`);
        // const control = this.props.DesignMode ? def : Util.duplicate(def);
        return new Controls[controlDef.Type]({ Props: controlDef.Props, Parent: parent === undefined ? this : parent, Data: this.data, Code: this.code, $name: this.$name });
    }

    /**
     * Removes a class from the control.
     *
     * @param {Any} value Class name to remove.
     */
    removeClass (value) {
        UI.removeClass(this.elem, value);
    }

    /**
     * Sets the class names of the control. Full overwrite.
     *
     * @param {Any} value Class name(s) to set.
     */
    setClass (value) {
        const cls = this.props.ClassFixed === undefined ? value : `${this.props.ClassFixed.trim()} ${value || ''}`;
        UI.attr(this.elem, { class: cls });
    }

    /**
     * Sets the element height.
     *
     * @param {Any} value Class name(s) to set.
     */
    setHeight (value) {
        const v = typeof value === 'number' ? `${value}px` : value;
        UI.attr(this.elem, { style: `height:${v};max-height:${v};min-height:${v}` });
    }

    /**
     * Sets a style on the element.
     *
     * @param {String} key Style key to set.
     * @param {String} value Style value to set.
     */
    setStyle (key, value) {
        UI.attr(this.elem, { style: `${key}:${value}` });
    }

    /**
     * Sets the element width.
     *
     * @param {Any} value Class name(s) to set.
     */
    setWidth (value) {
        const v = typeof value === 'number' ? `${value}px` : value;
        UI.attr(this.elem, { style: `width:${v};min-width:${v}` });
    }
    // ----------------------------
    // <- Control helper functions.

    destroy () {
        this.eventsOff();
        if (this.detachEvents) this.detachEvents();
        if (this.FOUI.hasControl(this.props.Name)) {
            this.FOUI.removeControl(this.props.Name);
        }
        delete this.handlers;
        UI.remove(this.elem);
    }
}
