const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
const reMsAjax = /^\/Date\((d|-|.*)\)[/|\\]$/;

export default class Time {
    static checkForDate (str) {
        if (str.length === 24 &&
            str.indexOf('-') === 4 &&
            str.indexOf('-', 5) === 7 &&
            str.indexOf('T', 8) === 10 &&
            str.indexOf(':', 9) === 13 &&
            str.indexOf(':', 14) === 16 &&
            str.indexOf('.', 15) === 19 &&
            str.indexOf('Z', 20) === 23) {
            return new Date(str);
        }

        return str;
    }

    static checkKeysForDate (o) {
        if (!o) return;
        const keys = Object.keys(o);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            // eslint-disable-next-line
            o[key] = this.checkDates(o[key]);
        }
    }

    static checkDates (v) {
        if (Array.isArray(v)) {
            const len = v.length;
            for (let i = 0; i < len; i++) {
                const rec = v[i];
                switch (typeof rec) {
                    case 'object': this.checkKeysForDate(rec); break;
                    case 'string': return this.dateParser(rec);
                    default: return rec;
                }
            }
        }
        else {
            switch (typeof v) {
                case 'object': this.checkKeysForDate(v); break;
                case 'string': return this.dateParser(v);
                default: return v;
            }
        }

        return v;
    }

    /**
     * Parses string dates (from service result JSON that is in ISO or MS Ajax format) to JS Dates.
     *
     * @param {*} key Property name that is parsed.
     * @param {*} value Property value.
     * @returns Date
     */
    static dateParser (value) {
        if (typeof value === 'string') {
            let a = reISO.exec(value);
            if (a) return new Date(value);
            a = reMsAjax.exec(value);
            if (a) {
                const b = a[1].split(/[-+,.]/);
                return new Date(b[0] ? +b[0] : 0 - +b[1]);
            }
        }
        return value;
    }

    static dateToDateString (dt) {
        dt = dt || new Date();
        const m = `0${dt.getMonth() + 1}`;
        const s = `0${dt.getDate()}`;
        return `${dt.getFullYear()}-${m.substr(m.length - 2, 2)}-${s.substr(s.length - 2, 2)}`;
    }

    static dateToISOString (dt) {
        dt = dt || new Date();
        const p = this.pad;
        return `${dt.getFullYear()}-${p(dt.getMonth() + 1, 2)}-${p(dt.getDate(), 2)}T${p(dt.getHours(), 2)}:${p(dt.getMinutes(), 2)}:${p(dt.getSeconds(), 2)}.000Z`;
    }

    static firstDayOfMonth (dt) {
        dt = dt || new Date();
        return new Date(dt.getFullYear(), dt.getMonth(), 1);
    }

    static firstDayOfMonthString (dt) {
        return this.dateToDateString(this.firstDayOfMonth(dt));
    }

    static pad (val, len) {
        return `${val}`.pad(len, '0');
        /* if (val === null || val === undefined) val = '';
        len = len || 1;
        char = char || '0';
        pre = pre !== false; // Default is true.
        const str = new Array(len).fill(char);

        if (pre) {
            val = `${str}${val}`;
            return val.substr(val.length - len, len);
        }

        val = `${val}${str}`;
        return val.substr(0, len); */
    }

    static numberToTime (value) {
        /* const i = parseInt(value, 10);
        const frac = value - i;
        const mins = `${60 * frac}00`.substr(0, 2);
        return `${i}:${mins}`; */

        if (typeof value !== 'number') return '0:00';
        value += ''; // Make a string.
        if (value.indexOf('.') > -1) { // Float.
            value = value.split('.');
            if ((!value[0] || value[0] === '0') && (!value[1] || value[1] === '0')) {
                value = [];
            }
            else {
                if (!value[0]) value[0] = '0';
                if (!value[1] || value[1] === '0') {
                    value[1] = '00';
                }
                else {
                    let m = Math.round(60 * parseFloat(`0.${value[1]}`));
                    if (m < 1 && (!value[0] || value[0] === '0')) {
                        value = [];
                    }
                    else {
                        if (m < 10) m = `0${m}`;
                        value[1] = `${m}00`.substring(0, 2);
                    }
                }
            }
        }
        else { // Integer.
            value = (!value || value === '0') ? [] : [value, '00'];
        }
        return value.length ? value.join(':') : '0:00';
    }

    static timeToNumber (value) {
        if (typeof value !== 'string') return 0;
        if (!value) return 0;
        if (value.indexOf(':') === -1) {
            value += ':00';
        }
        value = value.split(':');
        value[0] = parseInt(value[0], 10);
        value[1] = parseInt(value[1], 10);
        if (value[1] > 59) value[1] = 59;

        if (value[1] === 0) return value[0];
        return value[0] + (value[1] / 60);
    }
}
