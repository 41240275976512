// import ControlBase from './control';
import Panel from './panel';
// import Util from '../util/util';

export default class Toolbar extends Panel {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'Toolbar';
        definition.Props.ClassFixed = 'foui-toolbar unselectable foui-stretch-y foui-flex-grow';
        super(definition, Toolbar._DEF.Properties);
    }

    /* init () {
        super.init();
        this.addClass('foui-toolbar unselectable foui-stretch-y foui-flex-grow');
    } */
}

// Control property definitions.
Object.defineProperty(Toolbar, '_DEF', {
    value: {
        Group: 'Common Controls',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Style Class', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            Controls: { Default: undefined, Group: 'General', Label: 'Controls', Type: 'List', Note: 'Other control items "inside" this control.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout into design mode.' },
            Height: { Default: undefined, Group: 'Size', Label: 'Width', Type: 'Number', Note: 'Height set as px or %.' },
            HTML: { Default: undefined, Group: 'General', Label: 'HTML', Type: 'Text', Note: 'HTML code as content.' },
            // Padding: { Default: undefined, Group: 'General', Label: 'Style', Type: 'Text', Note: 'Spacing inside the control borders.' },
            Tip: { Default: undefined, Group: 'General', Label: 'Tool Tip', Type: 'Any', Note: 'Information related to the control.' },
            // Visible: { Default: true, Group: 'State', Label: 'Visible', Type: 'Flag', Note: 'Visible or hidden state of the control.' },
            Width: { Default: undefined, Group: 'Size', Label: 'Width', Type: 'Number', Note: 'Width set as px or %.' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
