import Control from './control';
import UI from '../util/ui';
import Util from '../util/util';

export default class Tree extends Control {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'Tree';
        // definition.Props.ClassFixed = 'foui-ctrl foui-control-panel foui-stretch-y';
        super(definition, Tree._DEF.Properties);
    }

    init () {
        const clsFixed = `foui-ctrl foui-control-panel foui-tree animate ${this.props.ClassFixed || ''}`.trim();
        this.addClass(clsFixed);
        // Setting `this.meta.ClassFixed` only works if `Class` is handled in `onChange`.
        this.props.ClassFixed = clsFixed;
        UI.attr(this.elem, { tabindex: 0 });

        this.ui.list = UI.create('div', { class: 'foui-tree-list foui-stretch', 'data-depth': -1 });
        this.elem.append(this.ui.list);
    }

    attachEvents () {
        // Bind to this control.
        // this.handlers.onInput = this.onInput.bind(this);
        this.handlers.onKeyDown = this.onKeyDown.bind(this);
        this.handlers.onClick = this.onClick.bind(this);
        // Attach the listener(s).
        // UI.on(this.meta.ui.input, 'input', Util.debounce(this.handlers.onInput, 300), true);
        UI.on(this.elem, 'keydown', this.handlers.onKeyDown, true);
        UI.on(this.ui.list, 'click', this.handlers.onClick, true);
    }

    detachEvents () {
        UI.off(this.elem, 'keydown', this.handlers.onKeyDown, true);
        UI.off(this.ui.list, 'click', this.handlers.onClick, true);
    }

    onChange (changes) {
        // console.log('Button changes', changes);
        for (const [key, v] of changes) {
            const value = Util.checkForBoundedValue(v, this);
            switch (key) {
                case 'Bordered': {
                    if (value) this.addClass('bordered');
                    else this.removeClass('bordered');
                    break;
                }
                // case 'Class': break;
                // case 'Disabled': UI.attr(this.ui.button, { disabled: v }); break;
                // case 'IndentDepth': break;
                case 'Items': this.updateItems(value); break;
                // case 'Renamable': break;
                // case 'RootKey': break;
                // case 'ShowIcons': break;
                case 'Value': {
                    if (this.props.Value) this.addClass('foui-selected');
                    else this.removeClass('foui-selected');
                    break;
                }
            }
        }
    }

    onClick (evt) {
        /* if (this.props.Toggle) {
            if (this.props.ToggleGroup) {
                // Deselect the other buttons in the group.
                const parentToolbar = this.elem.closest('.foui-toolbar'); // 'ToggleGroup'
                const parentCell = parentToolbar ? null : this.elem.closest('.foui-layout-grid-cell');
                const parentBox = parentToolbar || parentCell;
                const parent = this.FOUI.getControl(parentBox.id);
                const controls = parent.meta.ControlList.filter(o => o.props.ToggleGroup === this.props.ToggleGroup);
                for (const ctrl of controls) {
                    if (ctrl.props.Value) ctrl.props.Value = false;
                }
            }
            if (this.props.Value === 'undefined') this.props.Value = true;
            else this.props.Value = !this.props.Value;
            setImmediate(() => {
                UI.sendEvent(this.elem, 'change', this.props.Name);
            });
        } */
        const elem = evt.target;
        const item = elem.closest('.foui-tree-item');
        if (!item) return;
        if (UI.hasClass(elem, 'foui-tree-plusmin') || UI.hasClass(elem.parentElement, 'foui-tree-plusmin')) {
            const plusmin = UI.hasClass(elem, 'foui-tree-plusmin') ? elem.firstChild : elem;
            const box = UI.find(this.ui.list, `[data-link="${item.dataset.key}"]`);
            if (UI.hasClass(plusmin, 'foui-tree-closed')) { // Is closed.
                UI.removeClass(plusmin, 'foui-tree-closed');
                UI.addClass(plusmin, 'foui-tree-open');
                // console.log(box.offsetHeight);
                UI.removeClass(box, 'foui-hidden');
                // Temporarily make visible to get the size.
                box.style.maxHeight = 'none';
                const h = box.offsetHeight;
                // Reset to 0 then animate with small delay.
                box.style.maxHeight = '0';
                setTimeout(() => {
                    box.style.maxHeight = `${h}px`;
                    setTimeout(() => { box.style.maxHeight = 'max-content'; }, 100);
                }, 1);
            }
            else { // Is open.
                UI.addClass(plusmin, 'foui-tree-closed');
                UI.removeClass(plusmin, 'foui-tree-open');
                const h = box.offsetHeight;
                box.style.maxHeight = `${h}px`;
                setTimeout(() => {
                    box.style.maxHeight = `0`;
                    setTimeout(() => { UI.addClass(box, 'foui-hidden'); }, 100);
                }, 1);
            }
            return;
        }

        const prevSel = UI.find(this.ui.list, '.foui-selected');
        if (prevSel) UI.removeClass(prevSel, 'foui-selected');
        UI.addClass(item, 'foui-selected');
        this.onChange(item.dataset.key);
    }

    onKeyDown (evt) {
        // console.log(evt.key, evt.code);
        switch (evt.code) {
            case 'Space': {
                if (UI.hasClass(this.ui.listPop, 'foui-hidden')) {
                    this.showPopup();
                    evt.preventDefault();
                }
                break;
            }
            case 'Enter': {
                if (UI.hasClass(this.ui.listPop, 'foui-hidden')) {
                    this.showPopup();
                    evt.preventDefault();
                    return;
                }
                const el = this.getSelectedElem();
                this.hidePopup();
                this.elem.focus();
                if (this.prop('Value') === el.dataset.key) return;
                this.prop('Value', el.dataset.key);
                setImmediate(() => { UI.sendEvent(this.elem, 'change', el.dataset.key); });
                break;
            }
            case 'Escape': {
                this.hidePopup();
                this.elem.focus();
                break;
            }
            case 'ArrowDown': {
                if (UI.hasClass(this.ui.listPop, 'foui-hidden')) {
                    this.showPopup();
                    evt.preventDefault();
                    return;
                }
                const el = this.getSelectedElem();
                let elTo = el ? el.nextElementSibling : this.ui.list.firstElementChild;
                if (!elTo) {
                    if (!this.prop('LoopScroll')) { evt.preventDefault(); return; } // At the top and not loop scrolling.
                    else elTo = this.ui.list.firstElementChild; // Loop scrolling. Select and focus the first element in the list.
                }
                UI.removeClass(el, 'foui-selected');
                UI.addClass(elTo, 'foui-selected');
                evt.preventDefault();
                break;
            }
            case 'ArrowUp': {
                const el = this.getSelectedElem();
                let elTo = el ? el.previousElementSibling : this.ui.list.firstElementChild;
                if (!elTo) {
                    if (!this.prop('LoopScroll')) { evt.preventDefault(); return; } // At the bottom and not loop scrolling.
                    else elTo = this.ui.list.lastElementChild; // Loop scrolling. Select and focus the last element in the list.
                }
                UI.removeClass(el, 'foui-selected');
                UI.addClass(elTo, 'foui-selected');
                evt.preventDefault();
                break;
            }
        }
    }

    /* updateIcon () {
        if (this.props.Icon) {
            // Add or update.
            if (this.ui.icon) { // Icon element exists.
                if (this.ui.icon.dataset.name !== this.props.Icon) this.ui.icon.innerHTML = Util.getIconString(this.props.Icon); // Update.
                return;
            }
            this.ui.icon = UI.create('span', { class: 'foui-control-ico', 'data-name': this.props.Icon, html: Util.getIconString(this.props.Icon) });
            this.ui.text = UI.create('span', { class: 'foui-control-text', text: this.props.Text });
            this.ui.button.innerHTML = '';

            this.ui.button.elem.append(this.ui.icon);
            this.ui.button.elem.append(this.ui.text);

            this.updateIconLocation();
            return;
        }

        // Remove.
        if (this.ui.icon) {
            UI.remove(this.ui.icon);
            if (this.props.Text) UI.attr(this.elem, { text: this.props.Text });
        }
    }

    updateIconLocation () {
        UI.removeClass(this.ui.button, 'foui-flex-row');
        UI.removeClass(this.ui.button, 'foui-flex-row-reverse');
        UI.removeClass(this.ui.button, 'foui-flex-column');
        UI.removeClass(this.ui.button, 'foui-flex-column-reverse');
        setTimeout(() => {
            const location = this.props.IconLocation || 'start';
            switch (location) {
                case 'start': UI.addClass(this.ui.button, 'foui-flex-row'); break;
                case 'end': UI.addClass(this.ui.button, 'foui-flex-row-reverse'); break;
                case 'top': UI.addClass(this.ui.button, 'foui-flex-column'); break;
                case 'bottom': UI.addClass(this.ui.button, 'foui-flex-column-reverse'); break;
            }
        });
        this.updateIconSpace();
    }

    updateIconSpace () {
        if (!this.ui.icon) return;
        if (this.props.Text === '') { // No text therefore no margins.
            UI.attr(this.ui.icon, { style: 'margin:0' });
            return;
        }

        const location = this.props.IconLocation || 'start';
        const space = this.props.IconSpace || 6;
        let margin;
        switch (location) {
            case 'top': margin = `0 0 ${space}px 0`; break;
            case 'end': margin = `0 0 0 ${space}px`; break;
            case 'bottom': margin = `${space}px 0 0 0`; break;
            case 'start': margin = `0 ${space}px 0 0`; break;
        }
        UI.attr(this.ui.icon, { style: `margin:${margin}` });
    } */

    updateItems (value) {
        const fieldLink = this.props.FieldLinkParent;
        const fieldKey = this.props.FieldKey;
        const fieldOpen = this.props.FieldOpenState;
        const fieldText = this.props.FieldText;
        const fieldIcon = this.props.FieldIcon;
        const items = value || this.props.Items || [];
        /* const filterValue = this.prop('Filterable')
            ? this.meta.ui.input.value === '' ? null : new RegExp(this.meta.ui.input.value, 'gi')
            : null;
        const items = filterValue === null
            ? itemList
            : itemList.filter(o => o.Text.match(filterValue) !== null); // o.Key.startsWith(filterValue) || o.Text.startsWith(filterValue) */
        // Show that there are no properties.
        if (!items.length) {
            this.ui.list.innerHTML = '';
            return;
        }
        this.buildTree('', this.ui.list, -1, items, fieldLink, fieldKey, fieldText, fieldIcon, fieldOpen);
        /* // Create or update the list items.
        const selected = this.props.Value;
        // const gridWidth = this.elem.offsetWidth;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const isObject = typeof item === 'object';
            const key = isObject ? item[fieldKey] : item;
            const text = isObject ? item[fieldText] || key : item;
            const keyRow = UI.find(this.ui.list, `div[data-key="${key}"]`);
            if (keyRow) { // If the property row exists, update it.
                console.log('TODO: List Key Row update', keyRow);
            }
            else { // Add a new property row.
                // const schema = this.props.Schema[key];
                const row = UI.create('div', { class: 'foui-list-item foui-text-truncate', 'data-idx': i, 'data-key': key });
                if (this.props.ItemRenderer) UI.attr(row, { html: this.props.ItemRenderer(i, item) });
                else UI.attr(row, { text });
                // if (canDrag) UI.attr(label, { draggable: true });
                if (selected === key || selected === text) {
                    UI.addClass(row, 'foui-list-item-selected');
                }
                this.ui.list.append(row);
            }
        } */
    }

    buildTree (linkKey, parentChildBox, depth, items, fieldLink, fieldKey, fieldText, fieldIcon, fieldOpen) {
        // const selected = this.props.Value;
        depth += 1;
        const pad = this.props.IndentDepth || 15;
        const recs = items.filter(o => o[fieldLink] === linkKey);
        const len = recs.length;
        for (let i = 0; i < len; i++) {
            const rec = recs[i];
            const leftPad = depth * pad;
            const leftLine = leftPad + 8;
            const hasLink = items.find(o => o[fieldLink] === rec[fieldKey]);
            const isOpen = rec[fieldOpen] !== false && rec[fieldOpen] !== 0;
            let linksBox;

            // Check if the element already exists.
            const current = UI.find(this.ui.list, `#${rec.Key}`);
            if (current) {
                // TODO: Check linking. Move if needed.
                const label = UI.find(current, '.foui-tree-item label');
                if (label.innerText !== rec.Text) label.innerText = rec.Text;
            }
            else {
                const main = UI.create('div', { id: rec[fieldKey] });
                const item = UI.create('div', { 'data-key': rec[fieldKey], class: 'foui-tree-item', style: `padding-left:${leftPad}px` });
                if (hasLink || !this.props.NoValueSpacing) {
                    const plusminbox = UI.create('span', { class: 'foui-tree-plusmin' });
                    const plusmin = UI.create('span', { class: hasLink ? (isOpen ? 'foui-tree-open' : 'foui-tree-closed') : 'foui-tree-none' });
                    plusminbox.append(plusmin);
                    item.append(plusminbox);
                }
                // const sc = UI.create('span', { class: rec.Key }); // Source control icon. // TODO:
                // item.append(sc);
                if (this.props.ShowIcons !== false && (!this.props.NoValueSpacing || !Util.isNullOrEmpty(rec[fieldIcon]))) {
                    const iconBox = UI.create('div', { class: 'foui-tree-icon' });
                    const icon = UI.create('span', { html: Util.getIconString(rec[fieldIcon]) }); // this.FOUI.Themes[this.FOUI.ThemeKey].svg.tree[rec.Icon]
                    iconBox.append(icon);
                    item.append(iconBox);
                }
                // const label = UI.create('label', { text: rec[fieldText] });
                const label = UI.create('label', { class: 'foui-tree-label' });
                if (this.props.ItemRenderer) UI.attr(label, { html: this.props.ItemRenderer(i, rec) });
                else UI.attr(label, { text: rec[fieldText] });
                item.append(label);
                /* if (rec.Tags) {
                    const tag = UI.create('span', { text: rec.Tags, class: `foui-tree-item-tag ${rec.tagColor || 'abui-dull'}` });
                    item.append(tag);
                } */
                main.append(item);
                parentChildBox.append(main);
                linksBox = UI.create('div', { 'data-link': rec[fieldKey], class: `foui-tree-child-box${isOpen ? '' : ' foui-hidden'}${this.props.HideTreeLine ? ' foui-tree-child-box-nolines' : ''}`, style: `background-position: ${leftLine}px 0;` });
                main.append(linksBox);
            }
            if (hasLink) {
                this.buildTree(rec[fieldKey], linksBox, depth, items, fieldLink, fieldKey, fieldText, fieldIcon, fieldOpen);
            }
        }
    }
}

// Control property definitions.
Object.defineProperty(Tree, '_DEF', {
    value: {
        Group: 'Data',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            Bordered: { Default: true, Group: 'Style', Label: 'Bordered', Type: 'Flag', Note: 'Display a border around the control. Useful to disable when using this control inside another composite control.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Class (Style)', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout panel into design mode.' },
            Disabled: { Default: undefined, Group: 'General', Label: 'Disabled', Type: 'Flag', Note: 'Disables the control.' },
            // Draggable: { default: false, group: 'General', label: 'Dragable Items', type: 'Flag', note: 'Items can be dragged an dropped.' },
            // Filterable: { default: false, group: 'General', label: 'Filterable', type: 'Flag', note: 'Allow filtering if list items.' },
            // Fit: { Default: undefined, Group: 'General', Label: 'Fit Layout', Type: 'Flag', Note: 'Sets the control to fit the layout panel width.' },
            // HTML: { Default: undefined, Group: 'General', Label: 'HTML', Type: 'Text', Note: 'HTML code as content.' },
            // Icon: { Default: undefined, Group: 'Style', Label: 'Icon', Type: 'Text', Note: 'Icon name from the configured icon set.' },
            // IconLocation: { Default: undefined, Group: 'Style', Label: 'Icon Location', Type: 'Text', Note: 'The location of the icon relative to the text.' },
            // IconSpace: { Default: undefined, Group: 'Style', Label: 'Icon Space Between', Type: 'Number', Note: 'Spacing between the icon and the text.' },
            FieldIcon: { Default: undefined, Group: 'Data', Label: 'Field Icon', Type: 'Text', Note: 'Field name of the record property that specifies the icon name in the configured icon set.' },
            FieldLinkParent: { Default: undefined, Group: 'Data', Label: 'Field Link Parent', Type: 'Text', Note: 'Field name to use to check the link to the parent record in the data tree.' },
            FieldKey: { Default: undefined, Group: 'Data', Label: 'Field Key', Type: 'Text', Note: 'Field name to use to as the record key and identifier, when using object/record Items.' },
            FieldOpenState: { Default: undefined, Group: 'Data', Label: 'Field Open State', Type: 'Text', Note: 'Field name of the record property to monitor and set for the open or closed state of an item.' },
            FieldText: { Default: undefined, Group: 'Data', Label: 'Field Text', Type: 'Text', Note: 'Field name to use for the display text, when using object/record Items.' },
            HideTreeLine: { Default: undefined, Group: 'General', Label: 'Hide Tree Line', Type: 'Flag', Note: 'Hide the tree line in deeper sections.' },
            IndentDepth: { Default: 15, Group: 'General', Label: 'Indent Depth', Type: 'Number', Note: 'Size in px to indent shift sub items.' },
            ItemRenderer: { Default: undefined, Group: 'Data', Label: 'Item Renderer', Type: 'Text', Note: 'A renderer function to call for the handling handling of the creation of each item, called by item.' },
            Items: { Default: undefined, Group: 'Data', Label: 'Items', Type: 'Any', Note: 'A list of data items.' },
            NoValueSpacing: { Default: false, Group: 'General', Label: 'No Value Spacing', Type: 'Flag', Note: 'True/False that will collapse spacing for plus and minus or no icon placeholders.' },
            Renamable: { Default: undefined, Group: 'General', Label: 'Renamable Items', Type: 'Flag', Note: 'Items can be renamed.' },
            RootKey: { Default: '', Group: 'General', Label: 'Root Key', Type: 'Text', Note: 'Entries with this Key value will be on the root level.' },
            ShowIcons: { default: undefined, group: 'State', label: 'Show Icons', type: 'Flag', note: 'Shows item icons if true.' },
            // Text: { Default: undefined, Group: 'General', Label: 'Text', Type: 'Text', Note: 'Text value to display.' },
            // Toggle: { Default: undefined, Group: 'State', Label: 'Toggle', Type: 'Flag', Note: 'Changes the button to function like a checkbox e.g. on/off (pressed/depressed).' },
            // ToggleGroup: { Default: undefined, Group: 'State', Label: 'Toggle Group', Type: 'Text', Note: 'The name of the toggle group this button belongs to. Only one button in the group can be the main selected button. Does not span containers.' },
            Tip: { Default: undefined, Group: 'General', Label: 'Tool Tip', Type: 'Any', Note: 'Information related to the control.' },
            Value: { Default: undefined, Group: 'General', Label: 'Value', Type: 'Any', Note: 'Single selected value or array of selected values, when Multiple is set.' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
