export default {
    cssVars: {
        /* Main colours */
        'primary': '#1d88df',
        'primary2': '#007ccf',
        'secondary': '#222',
        'dull': '#F8F8F8',
        'success': '#4CAF50',
        'warning': '#FAA05A',
        'danger': '#F44336',
        'info': '#39F',

        'primary-text': '#FFF',
        'primary2-text': '#FFF',
        'secondary-text': '#FFF',
        'dull-text': '#AAA',
        'success-text': '#FFF',
        'warning-text': '#FFF',
        'danger-text': '#FFF',
        'info-text': '#FFF',

        /* Text */
        'text-main': '#444',
        'text-opposite': '#FFF',
        'text-disabled': '#CCC',
        'text-dull': '#555',
        'text-input': '#111',

        /* Backgrounds */
        'theme-input': '#FFF',
        'theme-window': '#F5F5F5',
        'back-main': '#F5F5F5', /* Default background colour */
        'back-opposite': '#222', /* Default text colour for default background */
        'back-disabled': '#EFEFEF', /* Default disabled background colour */
        'back-input': '#FFF',

        'back-gen': 'var(--back-main)',
        'text-gen': 'var(--text-main)',

        'border': '#FFF',
        'border2': '#E5E5E5',
        'border-hover': '#CCC',

        /* Font */
        '--font-stack': '"Source Sans Pro", Roboto, sans-serif',
        '--font-size': '11pt',
        '--font-system': '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
        '--font-gen': 'var(--font-size) var(--font-stack)',

        'scroller-width': '12px',
        'scroller-tag': '#AAA',
        'scroller-track': 'transparent',

        'box-shadow-duration': '0.1s',
        'global-small-box-shadow': '0 2px 8px rgba(0,0,0,0.3)',
        'global-medium-box-shadow': '0 5px 15px rgba(0,0,0,0.3)',
        'global-large-box-shadow': '0 14px 25px rgba(0,0,0,0.5)',
        'global-xlarge-box-shadow': '0 28px 50px rgba(0,0,0,0.5)',

        /* Lines */
        'focus-border-thick': '3px',
        'focus-border': '0 0 0 1px #FFF, 0 0 0 3px var(--primary2)',

        /* Button */
        'button-back': 'linear-gradient(0deg, rgba(210,210,210,1) 0%, rgba(226,226,226,1) 100%)',
        'button-border': '0 0 0 1px var(--border2), 0 0 0 2px var(--border)', /* Shadow */
        'button-color': 'var(--text-main)',
        'button-radius': '2px',
        'button-padding': '2px 6px',

        /* Check */
        'check-box-size': '17px',
        'check-back': 'var(--back-input)',
        'check-border': '0 0 0 1px var(--border2), 0 0 0 2px var(--border)', /* Shadow */
        'check-color': 'var(--text-input)',
        'check-color-check': 'var(--text-input)',
        'check-radius': '2px',
        'check-padding': '0 5px',
        'check-mark-left': '3px',
        'check-mark-top': '-1px',
        'check-mark-width': '6px',
        'check-mark-height': '10px',
        'check-mark-thick': '2px',

        /* Option / Radio */
        'option-box-size': '16px',
        'option-back': 'var(--back-input)',
        'option-border': '0 0 0 1px var(--border2), 0 0 0 2px var(--border)', /* Shadow */
        'option-color': 'var(--text-input)',
        'option-color-check': 'var(--text-input)',
        'option-radius': '50%',
        'option-padding': '0 5px',
        'option-pos': '25%',
        'option-size': '50%',

        /* Select */
        'select-back': 'var(--back-input)',
        'select-back-filter': 'var(--back-input)',
        'select-back-filter-sep': 'var(--border2)',
        'select-border': '0 0 0 1px var(--border2), 0 0 0 2px var(--border)', /* Shadow */
        'select-border-hover': '0 0 0 1px var(--border-hover)', /* Shadow */
        'select-button-color': 'transparent',
        'select-ico-color': 'var(--text-input)',
        'select-color': 'var(--text-input)',
        'select-color-item': 'var(--text-input)',
        'select-radius': '0',
        'select-pad-row': '4px',
        'select-pad-side': '5px',
        'select-list-border-shadow': 'var(--global-medium-box-shadow)',

        /* Text */
        'text-back': 'var(--back-input)',
        'text-border': '0 0 0 1px var(--border2), 0 0 0 2px var(--border)', /* Shadow */
        'text-border-hover': '0 0 0 1px var(--border-hover)', /* Shadow */
        'text-color': 'var(--text-input)',
        'text-radius': '0',
        'text-pad-side': '5px',
    },
    svg: {}
};
