export default {
    name: 'Dark',
    cssVars: {
        // /* Main colours */
        // 'primary': '#247448',
        // 'primary2': '#358559',
        // 'secondary': '#222',
        // 'dull': '#F8F8F8',
        // 'success': '#4CAF50',
        // 'warning': '#FAA05A',
        // 'danger': '#F44336',
        // 'info': '#39F',

        // 'primary-text': '#FFF',
        // 'primary2-text': '#FFF',
        // 'secondary-text': '#FFF',
        // 'dull-text': '#AAA',
        // 'success-text': '#FFF',
        // 'warning-text': '#FFF',
        // 'danger-text': '#FFF',
        // 'info-text': '#FFF',

        // /* Text */
        // 'text-main': '#C5C5C5',
        // 'text-opposite': '#222',
        // 'text-disabled': '#CCC',
        // 'text-dull': '#AAA',
        // 'text-input': '#FFF',

        // /* Backgrounds */
        // 'theme-input': '#404040',
        // 'theme-window': '#424242',
        // 'back-main': '#3B3B3B', /* Default background colour */
        // 'back-opposite': '#222', /* Default text colour for default background */
        // 'back-disabled': '#555', /* Default disabled background colour */
        // 'back-input': '#3F3F3F',

        // 'back-gen': 'var(--back-main)',
        // 'text-gen': 'var(--text-main)',

        // 'border': '#555',
        // /* border2: '#2B2B2B', */
        // 'border-hover': '#666',

        // /* Font */
        // 'font-stack': '"Source Sans Pro", Roboto, sans-serif',
        // 'font-size': '11pt',
        // 'font-system': '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
        // 'font-gen': 'var(--font-size) var(--font-stack)',

        // 'scroller-width': '12px',
        // 'scroller-tag': '#777',
        // 'scroller-track': 'transparent',

        // 'box-shadow-duration': '0.1s',
        // 'global-small-box-shadow': '0 2px 8px rgba(0,0,0,0.3)',
        // 'global-medium-box-shadow': '0 5px 15px rgba(0,0,0,0.3)',
        // 'global-large-box-shadow': '0 14px 25px rgba(0,0,0,0.5)',
        // 'global-xlarge-box-shadow': '0 28px 50px rgba(0,0,0,0.5)',

        // /* Lines */
        // 'focus-border-thick': '3px',
        // 'focus-border': '0 0 0 1px black, 0 0 0 3px var(--primary2)',

        // /* Button */
        // 'button-back': 'var(--border)',
        // 'button-border': '0', /* 1px solid #CACACA, */
        // 'button-color': 'var(--text-main)',
        // 'button-radius': '3px',
        // 'button-padding': '2px 6px',

        // /* Check */
        // 'check-box-size': '17px',
        // 'check-back': 'var(--back-input)',
        // 'check-border': '0 0 0 1px var(--border)', /* Shadow */
        // 'check-color': 'var(--text-input)',
        // 'check-color-check': 'var(--text-input)',
        // 'check-radius': '0',
        // 'check-padding': '0 5px',
        // 'check-mark-left': '3px',
        // 'check-mark-top': '-1px',
        // 'check-mark-width': '6px',
        // 'check-mark-height': '10px',
        // 'check-mark-thick': '2px',

        // /* Option / Radio */
        // 'option-box-size': '16px',
        // 'option-back': 'var(--back-input)',
        // 'option-border': '0 0 0 1px var(--border)', /* Shadow */
        // 'option-color': 'var(--text-input)',
        // 'option-color-check': 'var(--text-input)',
        // 'option-radius': '50%',
        // 'option-padding': '0 5px',
        // 'option-pos': '25%',
        // 'option-size': '50%',

        // /* Select */
        // 'select-back': 'var(--back-input)',
        // 'select-back-filter': 'var(--back-input)',
        // 'select-back-filter-sep': 'var(--border)',
        // 'select-border': '0 0 0 1px var(--border)', /* Shadow */
        // 'select-border-hover': '0 0 0 1px var(--border-hover)', /* Shadow */
        // 'select-button-color': 'transparent',
        // 'select-ico-color': 'var(--text-input)',
        // 'select-color': 'var(--text-input)',
        // 'select-color-item': 'var(--text-input)',
        // 'select-radius': '3px',
        // 'select-pad-row': '4px',
        // 'select-pad-side': '5px',
        // 'select-list-border-shadow': 'var(--global-medium-box-shadow)',

        // /* Text */
        // 'text-back': 'var(--back-input)',
        // 'text-border': '0 0 0 1px var(--border)', /* Shadow */
        // 'text-border-hover': '0 0 0 1px var(--border-hover)', /* Shadow */
        // 'text-color': 'var(--text-input)',
        // 'text-radius': '3px',
        // 'text-pad-side': '5px',
    },
    svg: {
        check: '<svg width="100%" height="100%" viewBox="0 0 20 20" version="1.1"><g><path class="foui-check-ico" d="M 5,9 8,13 15,7" /></g></svg>',
        option: '<svg width="100%" height="100%" viewBox="0 0 20 20" version="1.1"><g><circle class="foui-option-ico" cx="10" cy="10" r="5" /></g></svg>',
        alert: {
            Info: `<svg width="100%" viewBox="0 0 40 40" version="1.1"><g>
            <circle cx="20" cy="20" r="20" class="foui-alert-ico-back" />
            <rect width="4" height="15" x="18" y="17" ry="1" class="foui-alert-ico-ico" />
            <circle cx="20" cy="11" r="3" class="foui-alert-ico-ico" />
            </g></svg>`,
            Help: `<svg width="100%" viewBox="0 0 40 40" version="1.1"><g>
            <circle cx="20" cy="20" r="20" class="foui-alert-ico-back" />
            <path class="foui-alert-ico-ico" d="m 15.187491,30.625004 q 0,0.765625 0.5,1.265625 0.5,0.5 1.390625,0.5 0.875,0 1.375,-0.5 0.515625,-0.5 0.515625,-1.265625 0,-0.75 -0.515625,-1.25 -0.5,-0.515625 -1.40625,-0.515625 -0.859375,0 -1.359375,0.515625 -0.5,0.5 -0.5,1.25 z m 5.8125,-8.5 q -2.328125,0 -2.328125,2.328125 l 0.01563,2.53125 q 0,0.625 -0.625,0.625 h -1.890625 q -0.625,0 -0.625,-0.625 v -2.53125 q 0,-5.453125 5.453125,-5.453125 v 0.01563 q 2.328125,0 2.328125,-2.328125 v -2.375 q 0,-2.328125 -2.328125,-2.328125 h -6.421875 q -0.625,0 -0.625,-0.609375 V 9.4843839 q 0,-0.625 0.625,-0.625 h 6.421875 q 5.453125,0 5.453125,5.4531251 v 2.375 q 0,5.453125 -5.453125,5.453125 z" />
            </g></svg>`,
            Success: `<svg width="100%" viewBox="0 0 40 40" version="1.1"><g>
            <circle cx="20" cy="20" r="20" class="foui-alert-ico-back" />
            <rect width="4" height="20.92462" x="29.486435" y="-12.02081" ry="1" transform="rotate(45)" class="foui-alert-ico-ico" />
            <rect width="4" height="12.475506" x="-8.9497528" y="20.758512" ry="1" transform="rotate(-45)" class="foui-alert-ico-ico" />
            </g></svg>`,
            Warning: `<svg width="100%" viewBox="0 0 40 40" version="1.1"><g>
            <path class="foui-alert-ico-back" d="M 19.999996,1 C 25.999996,1 42,32 38,36 34,40 5,40 2,36 -1,32 12.999996,1 19.999996,1 Z" />
            <rect class="foui-alert-ico-ico" width="4" height="15" x="17.999996" y="10.000004" ry="0.78947389" />
            <circle cx="20" cy="30" r="3" class="foui-alert-ico-ico" />
            </g></svg>`,
            Error: `<svg width="100%" viewBox="0 0 40 40" version="1.1"><g>
            <circle cx="20" cy="20" r="20" class="foui-alert-ico-back" />
            <rect width="4" height="25" x="26.637825" y="-12.146441" ry="1" transform="rotate(45)" class="foui-alert-ico-ico" />
            <rect width="4" height="25" x="-2.3535588" y="16.137825" ry="1" transform="rotate(-45)" class="foui-alert-ico-ico" />
            </g></svg>`,
        },
        icon: {},
    }
};
