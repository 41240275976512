import Control from './control';
import paper from 'paper';
import UI from '../util/ui';
import Util from '../util/util';
import { CONTROL_EVENTS } from '../constants/mapped-events';

export default class PointerDraw extends Control {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'PointerDraw';
        super(definition, PointerDraw._DEF.Properties);
    }

    init () {
        const style = getComputedStyle(document.body);
        const controlClr = style.getPropertyValue('--control');
        this.colour = {
            primary: style.getPropertyValue('--primary'),
            accent: style.getPropertyValue('--accent'),
            success: style.getPropertyValue('--success'),
            warning: style.getPropertyValue('--warning'),
            danger: style.getPropertyValue('--danger'),
            info: style.getPropertyValue('--info'),
            dull: style.getPropertyValue('--dull'),
            control: controlClr,
            border: style.getPropertyValue('--border'),
            main: style.getPropertyValue('--text-main'),
            input: style.getPropertyValue('--text-input'),
            primaryText: style.getPropertyValue('--primary-text'),
        };

        this.meta.steps = [];
        this.meta.redos = [];
        this.addClass('foui-ctrl foui-control-panel foui-stretch');
        setTimeout(() => {
            this.ui.canvas = UI.create('canvas', { class: 'foui-pointer-draw', style: 'width: 100%; height: 100%;' });
            this.elem.append(this.ui.canvas);
            paper.setup(this.ui.canvas);
            this.meta.tool = new paper.Tool();
            this.meta.tool.activate();
            this.meta.tool.minDistance = 15;
            this.meta.tool.maxDistance = 20;
            // this.setBackground();
            // paper.view.autoUpdate = true;
            // Events.
            this.meta.tool.on('mousedown', this.handlers.onMouseDown);
            this.meta.tool.on('mousedrag', this.handlers.onMouseDrag);
            this.meta.tool.on('mouseup', this.handlers.onMouseUp);
            // paper.view.zoom = 0.75;
            // paper.view.translate(new paper.Point(100, 100));
        }, 50);
    }

    attachEvents () {
        // Bind to this control.
        // this.handlers.onClick = this.onClick.bind(this);
        this.handlers.onMouseDown = this.onMouseDown.bind(this);
        this.handlers.onMouseDrag = this.onMouseDrag.bind(this);
        this.handlers.onMouseUp = this.onMouseUp.bind(this);
        // Attach the listener(s).
        // UI.on(this.elem, 'click', this.handlers.onClick, true);

        this.handlers.sendChange = Util.throttle(this.sendChange, 200).bind(this);
    }

    detachEvents () {
        // UI.off(this.elem, 'click', this.handlers.onClick, true); // mousedown
        paper.view.off('mousedown', this.handlers.onMouseDown);
        paper.view.off('mousedrag', this.handlers.onMouseDrag);
        paper.view.off('mouseup', this.handlers.onMouseUp);
    }

    onChange (changes) {
        for (const [key, v] of changes) {
            const value = Util.checkForBoundedValue(v, this);
            switch (key) {
                case 'Class': {
                    this.setClass(value);
                    if (this.props.Bordered) this.addClass('bordered');
                    else this.removeClass('bordered');
                    break;
                }
                case 'Height': this.setHeight(value); break;
                case 'Width': this.setWidth(value); break;
            }
        }
    }

    onMouseDown (evt) {
        this.meta.path = new paper.Path();
        /* this.meta.path.fillColor = {
            hue: Math.random() * 360,
            saturation: 1,
            brightness: 1
        }; */
        this.meta.path.fillColor = this.colour.main;
        this.meta.path.add(evt.point);
    }

    onMouseDrag (evt) {
        const step = evt.delta.divide(2);
        step.angle += 90;

        const top = evt.middlePoint.add(step);
        const bottom = evt.middlePoint.subtract(step);

        this.meta.path.add(top);
        this.meta.path.insert(0, bottom);
        this.meta.path.smooth();
    }

    onMouseUp (evt) {
        this.meta.path.add(evt.point);
        this.meta.path.closed = true;
        this.meta.path.smooth();
        this.meta.steps.push(this.meta.path);
        this.sendChange(this.ui.canvas.toDataURL('image/png'));
    }

    clear () {
        if (!this.meta.steps.length) return;
        this.meta.redos = [];
        let last;
        do {
            last = this.meta.steps.pop();
            if (last) last.remove();
        } while (last);
    }

    undo () {
        if (!this.meta.steps.length) return;
        const last = this.meta.steps.pop();
        this.meta.redos.push(last.segments);
        last.remove();
    }

    redo () {
        if (!this.meta.redos.length) return;
        const segments = this.meta.redos.pop();
        const path = new paper.Path(segments);
        path.fillColor = this.colour.main;
        this.meta.steps.push(path);
    }

    sendChange (data) {
        UI.trigger(this.elem, CONTROL_EVENTS.Change, data);
    }
}

// Control property definitions.
Object.defineProperty(PointerDraw, '_DEF', {
    value: {
        Group: 'Common Controls',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            BackColor: { Default: undefined, Group: 'General', Label: 'Back Colour', Type: 'Text', Note: 'The background colour. Will be transparent if unset.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Style Class', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout panel into design mode.' },
            DrawColor: { Default: undefined, Group: 'General', Label: 'Draw Colour', Type: 'Text', Note: 'The line colour. Will be the default text colour if unset.' },
            // Visible: { Default: true, Group: 'State', Label: 'Visible', Type: 'Flag', Note: 'Visible or hidden state of the control.' },
            // TabIndex
            // Margins: { default: '0', group: 'General', label: 'Style', type: 'Text', note: 'Margins outside the control borders.' },
            // Size: { default: 'FitContainer', group: 'General', label: 'Size', type: 'List', items: PropertyConstants.SIZE, note: 'Size behaviour.' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
