import Control from './control';
import Util from '../util/util';
import UI from '../util/ui';
// import Check from './check';

export default class Select extends Control {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'Select';
        // definition.Props.ClassFixed = 'foui-ctrl foui-control-panel foui-stretch';
        super(definition, Select._DEF.Properties);
    }

    init () {
        const clsFixed = `foui-ctrl foui-control-panel foui-stretch ${this.props.ClassFixed || ''}`.trim();
        this.addClass(clsFixed);
        // Setting `this.meta.ClassFixed` only works if `Class` is handled in `onChange`.
        this.props.ClassFixed = clsFixed;
        // UI.attr(this.elem, { tabindex: 0 });
        // Name starts with _ so that it won't be added to the $name lookups.
        this.meta._inputId = `_${this.props.Name}_input`;
        this.ui.input = UI.create('div', { class: 'foui-select', id: this.meta._inputId, tabindex: 0 });
        this.ui.label = UI.create('label', { class: 'foui-select-label foui-text-truncate' });
        this.ui.button = UI.create('div', { class: 'foui-select-button' });
        this.ui.buttonIcon = UI.create('div', { class: 'foui-select-button-ico' });
        this.ui.button.append(this.ui.buttonIcon);
        this.ui.input.append(this.ui.label);
        this.ui.input.append(this.ui.button);
        this.elem.append(this.ui.input);
        /* this.ui.select = this.newControl({ Type: 'CoreLabel', Props: { Name: `_${this.props.Name}_select`, ClassFixed: 'foui-select unselectable' } });
        this.bindControl(this.ui.select, [
            { other: 'Class', local: 'Class' },
            // { other: 'ClassFixed', local: 'ClassFixed' },
            { other: 'For', local: 'For' },
            // { other: 'HTML', local: 'HTML' },
            // { other: 'Text', local: 'Text' },
            { other: 'Tip', local: 'Tip' },
        ]); */
    }

    attachEvents () {
        // Bind to this control.
        this.handlers.onClick = this.onClick.bind(this);
        // Attach the listener(s).
        UI.on(this.elem, 'click', this.handlers.onClick, true);
    }

    detachEvents () {
        UI.off(this.elem, 'click', this.handlers.onClick, true); // mousedown
        delete this.handlers;
    }

    onChange (changes) {
        // console.log('Select changes', changes);
        for (const [key, v] of changes) {
            const value = Util.checkForBoundedValue(v, this);
            switch (key) {
                case 'Bordered': {
                    // if (value) this.addClass('bordered');
                    // else this.removeClass('bordered');
                    if (value) UI.addClass(this.ui.input, 'bordered');
                    else UI.removeClass(this.ui.input, 'bordered');
                    break;
                }
                case 'Class': this.setClass(value); break;
                // case 'HTML': UI.attr(this.props.Icon ? this.ui.text : this.ui.select, { html: value }); break;
                // case 'Icon': this.updateIcon(); break;
                // case 'IconLocation': this.updateIconLocation(); break;
                // case 'IconSpace': this.updateIconSpace(); break;
                // case 'Items': this.updateItems(value); break;
                // case 'Text': UI.attr(this.props.Icon ? this.ui.text : this.ui.select, { text: value }); break;
                case 'Value': {
                    this.selectByValue(value);
                    break;
                }
                case 'Width': this.updateWidth(); break;
            }
        }
    }

    onClick () {
        const list = this.newControl({
            Type: 'List',
            GlobalHide: true,
            Props: {
                Name: `_${this.props.Name}_list`,
                Value: this.props.Value,
                Items: this.props.Items,
                Multiple: this.props.Multiple,
                FieldKey: this.props.FieldKey,
                FieldText: this.props.FieldText,
                ItemRenderer: this.props.ItemRenderer,
            }
        }, null); // , ClassFixed: 'inset-focus'
        window.popup({
            Anchor: this.elem,
            Position: 'Start',
            Control: list,
            ParentControl: this,
            MaxHeight: 200,
            CloseOnEvent: 'change',
            /* PreCloseValidate: (v) => {
                console.log(v);
            } */
        }, (detail) => {
            list.destroy();
            if (detail === undefined) return; // Popup closed but nothing was selected.
            if (this.props.ValueAsObject) {
                this.props.Value = detail;
                return;
            }
            const isObject = typeof detail === 'object';
            const fieldKey = this.props.FieldKey;
            this.props.Value = isObject
                ? detail[fieldKey]
                : detail;
        });
        /* window.popup({
            Anchor: this.elem,
            Position: 'Start',
            ParentControl: this,
            Items: [
                { Type: 'Header', Text: 'Add / Remove' },
                { Type: 'Label', Key: 'col-add-1', Text: 'Add 1 column', Icon: 'ColumnAdd' },
                { Type: 'Label', Key: 'row-add-1', Text: 'Add 1 row', Icon: 'RowAdd' },
                { Type: 'Label', Key: 'col-add-n', Text: 'Add n columns', Icon: 'ColumnAddN' },
                { Type: 'Label', Key: 'row-add-n', Text: 'Add n rows', Icon: 'RowAddN' },
                { Type: 'Separator' },
                { Type: 'Label', Key: 'col-rem-1', Text: 'Remove column', Icon: 'ColumnRemove' },
                { Type: 'Label', Key: 'row-rem-1', Text: 'Remove row', Icon: 'RowRemove' },
                { Type: 'Separator' },
                { Type: 'Header', Text: 'Resizing and Behaviour' },
                { Type: 'Label', Key: 'sz-horz', Text: 'Resize horizontally', Icon: 'SizeHorz' },
                { Type: 'Label', Key: 'sz-vert', Text: 'Resize vertically', Icon: 'SizeVert' },
                { Type: 'Label', Key: 'sz-both', Text: 'Resize in both directions', Icon: 'SizeBoth' },
                { Type: 'Separator' },
                { Type: 'Label', Key: 'sz-col-even', Text: 'Size columns evenly', Icon: 'DistributeEvenHorz' },
                { Type: 'Label', Key: 'sz-col-to', Text: 'Size columns to...' },
                { Type: 'Label', Key: 'sz-row-even', Text: 'Size rows evenly', Icon: 'DistributeEvenVert' },
                { Type: 'Label', Key: 'sz-row-to', Text: 'Size rows to...' },
                { Type: 'Separator' },
                { Type: 'Header', Text: 'Layout Template' },
                { Type: 'Label', Key: 'tmpl-store', Text: 'Store as template', Icon: 'Save' },
                { Type: 'Label', Key: 'tmpl-load', Text: 'Load template...', Icon: 'Template' },
            ]
        }, (detail) => {
            if (detail === undefined) return; // Popup closed but nothing was selected.
            console.log(detail);
        }); */ // this.onContextMenu.bind(this)
    }

    selectByValue (value) {
        // this.removeHighlight();
        const fieldKey = this.props.FieldKey;
        const fieldText = this.props.FieldText;
        const item = this.props.Items.find(o => o[fieldKey] === value || o[fieldText] === value || o === value);
        if (item === undefined) return;

        const isObject = typeof item === 'object';
        const key = isObject ? item[fieldKey] : item;
        const text = isObject ? item[fieldText] || key : item;
        const i = this.props.Items.indexOf(item);

        // UI.attr(this.ui.label, { text: typeof item === 'object' ? item[fieldText] || item[fieldKey] : value });
        if (this.props.ItemRenderer) UI.attr(this.ui.label, { html: this.props.ItemRenderer(i, item) });
        else UI.attr(this.ui.label, { text });
        // const pos = items.indexOf(item);
        // UI.attr(this.ui.label, { text: item.Text === undefined ? item.Key : item.Text });
        // this.meta.ui.input.value = item.Text === undefined ? item.Key : item.Text;
        /* const el = UI.find(this.ui.list, typeof item === 'object' ? `[data-key="${item[fieldKey]}"` : `[data-key="${value}"`);
        if (el) UI.addClass(el, 'foui-list-item-selected'); */
    }

    updateWidth () {
        let value = this.props.Width;
        if (value === '' || value === 'auto' || value === undefined) {
            UI.attr(this.elem, { style: 'flex-basis:inherit' });
            UI.attr(this.elem, { style: 'min-width:inherit' });
            if (value === 'auto') {
                this.addClass('foui-flex-shrink');
                this.removeClass('foui-stretch');
            }
            else this.addClass('foui-stretch');
            return;
        }
        if (typeof value === 'number') value = `${value}px`;
        UI.attr(this.elem, { style: `flex-basis:${value}` });
        UI.attr(this.elem, { style: `min-width:${value}` });
        this.removeClass('foui-flex-shrink');
        this.removeClass('foui-stretch');
    }

    /* updateIcon () {
        if (this.props.Icon) {
            // Add or update.
            if (this.ui.icon) { // Icon element exists.
                if (this.ui.icon.dataset.name !== this.props.Icon) this.ui.icon.innerHTML = Util.getIconString(this.props.Icon); // Update.
                return;
            }
            this.ui.icon = UI.create('span', { class: 'foui-control-ico', 'data-name': this.props.Icon, html: Util.getIconString(this.props.Icon) });
            this.ui.text = UI.create('span', { class: 'foui-control-text', text: this.props.Text });
            this.ui.select.innerHTML = '';

            this.ui.select.elem.append(this.ui.icon);
            this.ui.select.elem.append(this.ui.text);

            this.updateIconLocation();
            return;
        }

        // Remove.
        if (this.ui.icon) {
            UI.remove(this.ui.icon);
            if (this.props.Text) UI.attr(this.elem, { text: this.props.Text });
        }
    }

    updateIconLocation () {
        UI.removeClass(this.ui.select, 'foui-flex-row');
        UI.removeClass(this.ui.select, 'foui-flex-row-reverse');
        UI.removeClass(this.ui.select, 'foui-flex-column');
        UI.removeClass(this.ui.select, 'foui-flex-column-reverse');
        setTimeout(() => {
            const location = this.props.IconLocation || 'start';
            switch (location) {
                case 'start': UI.addClass(this.ui.select, 'foui-flex-row'); break;
                case 'end': UI.addClass(this.ui.select, 'foui-flex-row-reverse'); break;
                case 'top': UI.addClass(this.ui.select, 'foui-flex-column'); break;
                case 'bottom': UI.addClass(this.ui.select, 'foui-flex-column-reverse'); break;
            }
        });
        this.updateIconSpace();
    }

    updateIconSpace () {
        if (!this.ui.icon) return;
        if (this.props.Text === '') { // No text therefore no margins.
            UI.attr(this.ui.icon, { style: 'margin:0' });
            return;
        }

        const location = this.props.IconLocation || 'start';
        const space = this.props.IconSpace || 6;
        let margin;
        switch (location) {
            case 'top': margin = `0 0 ${space}px 0`; break;
            case 'end': margin = `0 0 0 ${space}px`; break;
            case 'bottom': margin = `${space}px 0 0 0`; break;
            case 'start': margin = `0 ${space}px 0 0`; break;
        }
        UI.attr(this.ui.icon, { style: `margin:${margin}` });
    } */
}

// Control property definitions.
Object.defineProperty(Select, '_DEF', {
    value: {
        Group: 'Common Controls',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            Bordered: { Default: true, Group: 'Style', Label: 'Bordered', Type: 'Flag', Note: 'Display a border around the control. Useful to disable when using this control inside another composite control.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Style Class', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout panel into design mode.' },
            FieldKey: { Default: undefined, Group: 'Data', Label: 'Field Key', Type: 'Text', Note: 'Field name to use to as the record key and identifier, when using object/record Items.' },
            FieldText: { Default: undefined, Group: 'Data', Label: 'Field Text', Type: 'Text', Note: 'Field name to use for the display text, when using object/record Items.' },
            For: { Default: undefined, Group: 'General', Label: 'For Control', Type: 'Text', Note: 'Name of control to focus on click.' },
            HTML: { Default: undefined, Group: 'General', Label: 'HTML', Type: 'Text', Note: 'HTML code as content.' },
            // Icon: { Default: undefined, Group: 'Style', Label: 'Icon', Type: 'Text', Note: 'Icon name from the configured icon set.' },
            // IconLocation: { Default: undefined, Group: 'Style', Label: 'Icon Location', Type: 'Text', Note: 'The location of the icon relative to the text.' },
            // IconSpace: { Default: undefined, Group: 'Style', Label: 'Icon Space Between', Type: 'Number', Note: 'Spacing between the icon and the text.' },
            ItemRenderer: { Default: undefined, Group: 'Data', Label: 'Item Renderer', Type: 'Text', Note: 'A renderer function to call for the handling handling of the creation of each item, called by item.' },
            Items: { Default: undefined, Group: 'Data', Label: 'Items', Type: 'Text', Note: 'A list of data items.' },
            // Margins: { Default: '0', Group: 'Style', Label: 'Margins', Type: 'Text', Note: 'Margins outside the control borders.' },
            Multiple: { Default: undefined, Group: 'State', Label: 'Multiple', Type: 'Flag', Note: 'Allows the selection of multiple items.' },
            // Padding: { Default: undefined, Group: 'Style', Label: 'Padding', Type: 'Text', Note: 'Spacing inside the control borders.' },
            // Size: { Default: 'dynamic', Group: 'General', Label: 'Size', Type: 'List', items: PropertyConstants.SIZE, Note: 'Size behaviour.' },
            // Text: { Default: 'Label', Group: 'General', Label: 'Text', Type: 'Text', Note: 'Text value to display.' },
            Tip: { Default: undefined, Group: 'General', Label: 'Tool Tip', Type: 'Any', Note: 'Information related to the control.' },
            // Truncate: { Default: false, Group: 'State', Label: 'Truncate', Type: 'Flag', Note: 'Truncate text with ellipsis when longer than container width.' },
            Value: { Default: undefined, Group: 'General', Label: 'Value', Type: 'Any', Note: 'Single selected value or array of selected values, when Multiple is set.' },
            ValueAsObject: { Default: undefined, Group: 'General', Label: 'Value as Object', Type: 'Flag', Note: 'Selected value is set to the selected object and not the selected key only.' },
            // Visible: { Default: true, Group: 'State', Label: 'Visible', Type: 'Flag', Note: 'Visible or hidden state of the control.' },
            Width: { Default: undefined, Group: 'Style', Label: 'Width', Type: 'Any', Note: 'Fixed control width. Clear (leave blank) to allow the control to use the maximum available space or auto to size according to text.' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
