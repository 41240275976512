import Control from './control';
import Color from '../util/color';
import UI from '../util/ui';
import Util from '../util/util';
// import CoreInput from './core/input';
// import Check from './check';

export default class List extends Control {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'List';
        // definition.Props.ClassFixed = 'foui-ctrl foui-control-panel foui-stretch-y';
        super(definition, List._DEF.Properties);
    }

    init () {
        const clsFixed = `foui-ctrl foui-control-panel foui-list foui-stretch-y ${this.props.ClassFixed || ''}`.trim();
        this.addClass(clsFixed);
        // Setting `this.meta.ClassFixed` only works if `Class` is handled in `onChange`.
        this.props.ClassFixed = clsFixed;
        UI.attr(this.elem, { tabindex: 0 });
        // Name starts with _ so that it won't be added to the $name lookups.
        /* this.ui.button = this.newControl({ Type: 'CoreButton', Props: { Name: `_${this.props.Name}_button`, ClassFixed: 'foui-button unselectable foui-stretch-y foui-flex-grow', Events: { Click: this.handlers.onClick } } }); // Value: this.props.Value, //
        this.bindControl(this.ui.button, [
            { other: 'Class', local: 'Class' },
            // { other: 'ClassFixed', local: 'ClassFixed' },
            { other: 'Disabled', local: 'Disabled' },
            // { other: 'HTML', local: 'HTML' },
            // { other: 'Text', local: 'Text' },
            { other: 'Tip', local: 'Tip' },
        ]); */

        this.ui.list = UI.create('div', { class: 'foui-list-list' });
        // this.meta.ui.listPop.append(this.meta.ui.list);
        this.elem.append(this.ui.list);

        this.meta.propertyHandlers = {
            Bordered: this._Bordered.bind(this),
            Items: this._Items.bind(this),
            Value: this._Value.bind(this),
        };
    }

    attachEvents () {
        // Bind to this control.
        this.handlers.onClick = this.onClick.bind(this);
        this.handlers.onMouseDown = this.onMouseDown.bind(this);
        this.handlers.onMouseMove = this.onMouseMove.bind(this);
        this.handlers.onMouseUp = this.onMouseUp.bind(this);
        // Attach the listener(s).
        UI.on(this.elem, 'click', this.handlers.onClick, true);
        UI.on(this.ui.list, 'mousedown', this.handlers.onMouseDown, true);
    }

    detachEvents () {
        UI.off(this.elem, 'click', this.handlers.onClick, true);
        UI.off(this.ui.list, 'mousedown', this.handlers.onMouseDown, true);
        delete this.handlers;
    }

    onChange (changes) {
        // console.log('List changes', changes);
        for (const [key, v] of changes) {
            const value = Util.checkForBoundedValue(v, this);
            // let action = actions['default'];
            for (const k in this.meta.propertyHandlers) {
                // if (key === 'default') {
                //     continue;
                // }
                if (key.startsWith(k)) { // if (key.includes(k)) {
                    // action = actions[key];
                    this.meta.propertyHandlers[k](value, key);
                    break;
                }
            }
            // action();
        }
        /* for (const [key, v] of changes) {
            const value = Util.checkForBoundedValue(v, this);
            switch (key) {
                case 'Bordered': {
                    if (value) this.addClass('bordered');
                    else this.removeClass('bordered');
                    // if (value) UI.addClass(this.ui.list, 'bordered');
                    // else UI.removeClass(this.ui.list, 'bordered');
                    break;
                }
                // case 'Disabled': UI.attr(this.ui.button, { disabled: v }); break;
                // case 'Icon': this.updateIcon(); break;
                // case 'IconLocation': this.updateIconLocation(); break;
                // case 'IconSpace': this.updateIconSpace(); break;
                case 'Items': this.updateItems(value); break;
                // case 'Text': UI.attr(this.props.Icon ? this.ui.text : this.ui.button, { text: value }); break;
                case 'Value': {
                    // if (this.props.Value) this.addClass('foui-selected');
                    // else this.removeClass('foui-selected');
                    this.selectByValue(value);
                    break;
                }
            }
        } */
    }

    _Bordered (value) {
        if (value) this.addClass('bordered');
        else this.removeClass('bordered');
    }

    _Items (value, ns) {
        if (ns === 'Items') this.updateItems(value);
        else this.updateItemNs(value, ns); // ns can be something like `Items.5.name`.
    }

    _Value (value) {
        this.selectByValue(value);
    }

    /* onDataChange (changes) {
        console.log('List DATA changes', changes);
    } */

    onClick (evt) {
        const target = UI.hasClass(evt.target, 'foui-interact') ? evt.target : evt.target.closest('.foui-interact');
        if (!target) return;

        if (UI.hasClass(target, 'foui-list-item-action')) {
            const item = target.closest('.foui-list-item');
            UI.sendEvent(this.elem, 'action', { Action: target.dataset.key, Index: +item.dataset.idx, Key: +item.dataset.key });
            return;
        }

        /* const target = UI.hasClass(evt.target, 'foui-list-item')
            ? evt.target
            : evt.target.closest('.foui-list-item');
        if (!target) return; */
        const item = this.props.Items[+target.dataset.idx];
        this.selectByValue(typeof item === 'object' ? item[this.props.FieldKey || this.props.FieldText] : item);
        UI.sendEvent(this.elem, 'change', item);
        /* if (this.props.Toggle) {
            if (this.props.ToggleGroup) {
                // Deselect the other buttons in the group.
                const parentToolbar = this.elem.closest('.foui-toolbar'); // 'ToggleGroup'
                const parentCell = parentToolbar ? null : this.elem.closest('.foui-layout-grid-cell');
                const parentBox = parentToolbar || parentCell;
                const parent = this.FOUI.getControl(parentBox.id);
                const controls = parent.meta.ControlList.filter(o => o.props.ToggleGroup === this.props.ToggleGroup);
                for (const ctrl of controls) {
                    if (ctrl.props.Value) ctrl.props.Value = false;
                }
            }
            if (this.props.Value === 'undefined') this.props.Value = true;
            else this.props.Value = !this.props.Value;
            setImmediate(() => {
                UI.sendEvent(this.elem, 'change', this.props.Name);
            });
        } */
    }

    onMouseDown (evt) {
        if (!this.props.Actions || !this.props.Actions.length) return;

        const target = UI.hasClass(evt.target, 'foui-interact') ? evt.target : evt.target.closest('.foui-interact');
        if (!target) return;

        if (UI.hasClass(target, 'foui-list-item-action')) return;

        UI.on(document.body, 'mousemove', this.handlers.onMouseMove, true);
        UI.on(document.body, 'mouseup', this.handlers.onMouseUp, true);

        this.meta.actionTarget = target; // UI.hasClass(evt.target, 'foui-list-item') ? evt.target : evt.target.closest('foui-list-item');
        this.meta.actionButton = Array.from(UI.findAll(this.meta.actionTarget, '.foui-list-item-action')).reverse();
        this.meta.startX = evt.clientX;
        this.meta.currentX = this.meta.startX;
        this.meta.actionWidth = +(this.props.ActionWidth || 50); // this.meta.actionButton.offsetWidth;
        this.meta.isActioning = false;

        // Remove animation.
        for (const elem of this.meta.actionButton) {
            UI.removeClass(elem, 'foui-trans-02');
        }
    }

    onMouseMove (evt) {
        this.meta.currentX = evt.clientX;
        const translateX = Math.min(0, this.meta.currentX - this.meta.startX);
        this.meta.actionButton.forEach((elem, i) => {
            elem.style.transform = `translateX(${translateX * (i + 1)}px)`;
        });

        if (translateX < -this.meta.actionWidth / 2) {
            this.meta.isActioning = true;
        }
        else {
            this.meta.isActioning = false;
        }

        if (translateX < -this.meta.actionWidth) {
            this.onMouseUp(evt);
        }
    }

    onMouseUp (evt) {
        UI.off(document.body, 'mousemove', this.handlers.onMouseMove, true);
        UI.off(document.body, 'mouseup', this.handlers.onMouseUp, true);

        if (this.meta.isActioning) {
            this.meta.actionButton.forEach((elem, i) => {
                elem.style.transform = `translateX(-${this.meta.actionWidth * (i + 1)}px)`;
                // elem.style.display = 'block';
            });
        }
        else {
            for (const elem of this.meta.actionButton) {
                elem.style.transform = 'translateX(0)';
                // elem.style.display = 'none';
            }
        }

        // Add animation back.
        for (const elem of this.meta.actionButton) {
            UI.addClass(elem, 'foui-trans-02');
        }
    }

    selectByValue (value) {
        this.removeHighlight();
        const fieldKey = this.props.FieldKey;
        const fieldText = this.props.FieldText;
        const item = this.props.Items.find(o => o[fieldKey] === value || o[fieldText] === value || o === value);
        if (!item) return;
        // const pos = items.indexOf(item);
        // UI.attr(this.ui.label, { text: item.Text === undefined ? item.Key : item.Text });
        // this.meta.ui.input.value = item.Text === undefined ? item.Key : item.Text;
        const el = UI.find(this.ui.list, typeof item === 'object' ? `[data-key="${item[fieldKey]}"` : `[data-key="${value}"`);
        if (el) {
            UI.addClass(el, 'foui-list-item-selected');
            setTimeout(() => { // Have to timeout to give the UI time to render the control otherwise values are 0.
                const viewer = this.elem.getBoundingClientRect();
                const target = el.getBoundingClientRect();
                if (target.top < viewer.top) el.scrollIntoView({ block: 'start', inline: 'nearest' });
                else if (target.bottom > viewer.bottom) el.scrollIntoView({ block: 'end', inline: 'nearest' });
            }, 25);
        }
    }

    removeHighlight () {
        UI.findAll(this.ui.list, '.foui-list-item-selected').forEach(el => {
            UI.removeClass(el, 'foui-list-item-selected');
        });
    }

    updateItems (value) {
        // console.log(value);
        const fieldKey = this.props.FieldKey;
        const fieldText = this.props.FieldText;
        const items = value || this.props.Items || [];
        /* const filterValue = this.prop('Filterable')
            ? this.meta.ui.input.value === '' ? null : new RegExp(this.meta.ui.input.value, 'gi')
            : null;
        const items = filterValue === null
            ? itemList
            : itemList.filter(o => o.Text.match(filterValue) !== null); // o.Key.startsWith(filterValue) || o.Text.startsWith(filterValue) */
        // Show that there are no properties.
        if (!items.length) {
            this.ui.list.innerHTML = '';
            return;
        }
        // Create or update the list items.
        const selected = this.props.Value;
        // const gridWidth = this.elem.offsetWidth;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const isObject = typeof item === 'object';
            const key = isObject ? item[fieldKey] : item;
            const text = isObject ? item[fieldText] || key : item;
            const keyRow = UI.find(this.ui.list, `div[data-key="${key}"]`);
            // console.log(key, keyRow);
            if (keyRow) { // If the property row exists, update it.
                if (this.props.ItemRenderer) UI.attr(keyRow, { html: this.props.ItemRenderer(i, item) });
                this.updateItem(item, keyRow, i);
            }
            else {
                const row = this.makeItem(item, i, key, text, selected);
                this.ui.list.append(row);
            }
        }
    }

    updateItemNs (value, ns) {
        // console.log(ns);
        const selected = this.props.Value;
        const fieldKey = this.props.FieldKey;
        const fieldText = this.props.FieldText;
        const items = this.props.Items || [];
        if (ns === 'Items.length') {
            // An item was deleted.
            const elems = Array.from(UI.findAll(this.ui.list, `.foui-list-item`));
            const len = elems.length;
            for (let i = 0; i < len; i++) {
                const o = elems[i];
                const item = items.find(x => `${x[fieldKey]}` === o.dataset.key);
                if (item) continue;
                o.remove(); // This item does not exist anymore.
                break;
            }
            return;
        }
        // Because the list is bound to an array, use the first instance of indexes.
        const dotPos1 = ns.indexOf('.');
        let dotPos2 = ns.indexOf('.', dotPos1 + 1);
        if (dotPos2 === -1) dotPos2 = ns.length;
        const idx = +ns.substring(dotPos1 + 1, dotPos2);
        const item = items[idx];
        // console.log(ns, idx, item);
        const isObject = typeof item === 'object';
        const key = isObject ? item[fieldKey] : item;
        const text = isObject ? item[fieldText] || key : item;
        const keyRow = UI.find(this.ui.list, `div[data-key="${key}"]`);
        if (keyRow) {
            if (+keyRow.dataset.idx !== idx) keyRow.dataset.idx = `${idx}`;
            this.updateItem(item, keyRow, idx);
        }
        else {
            const row = this.makeItem(item, idx, key, text, selected);
            // console.log(idx);
            // Add the row in front (first), otherwise in place.
            if (idx === 0) this.ui.list.prepend(row);
            else {
                let nextRow = null;
                const elems = Array.from(UI.findAll(this.ui.list, `.foui-list-item`));
                const len = elems.length;
                for (let i = 0; i < len; i++) {
                    const o = elems[i];
                    if (+o.dataset.idx > idx) {
                        nextRow = o;
                        break;
                    }
                }
                // console.log(nextRow);
                if (nextRow) nextRow.before(row);
                else this.ui.list.append(row); // No next row therefore add as last.
                // this.ui.list.append(row);
            }
        }
    }

    makeItem (item, idx, key, text, selected) {
        // Add a new property row.
        // const schema = this.props.Schema[key];
        const row = UI.create('div', { class: 'foui-list-item foui-interact foui-text-truncate', 'data-idx': idx, 'data-key': key });
        if (this.props.ItemRenderer) UI.attr(row, { html: this.props.ItemRenderer(idx, item) });
        else UI.attr(row, { text });
        // if (canDrag) UI.attr(label, { draggable: true });
        if (selected === key || selected === text) {
            UI.addClass(row, 'foui-list-item-selected');
        }
        if (this.props.Actions && this.props.Actions.length) {
            this.makeActionButton(row);
        }
        return row;
    }

    updateItem (item, row, idx) {
        if (this.props.ItemRenderer) {
            UI.attr(row, { html: this.props.ItemRenderer(idx, item) });
            if (this.props.Actions && this.props.Actions.length) {
                this.makeActionButton(row);
            }
        }
    }

    makeActionButton (elem) {
        for (const act of this.props.Actions) {
            const colour = Color.getTextColor(act.Color);
            let icon = Util.getIconString(act.Icon);
            icon = icon.replace(/foui-cico-dull/g, colour);
            icon = icon.replace(/foui-cico-normal/g, colour);
            const action = UI.create('div', { class: 'foui-list-item-action foui-interact', 'data-key': act.Id, html: icon });
            action.style.backgroundColor = act.Color;
            action.style.color = colour;
            elem.append(action);
        }
    }
}

// Control property definitions.
Object.defineProperty(List, '_DEF', {
    value: {
        Group: 'Common Controls',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            ActionWidth: { Default: undefined, Group: 'General', Label: 'Action Width', Type: 'Number', Note: 'The width of an action button in pixels.' },
            Actions: { Default: undefined, Group: 'General', Label: 'Actions', Type: 'List', Note: 'A list of actions that will display (if set) on mouse over or swipe.' },
            ActionsOnHove: { Default: undefined, Group: 'General', Label: 'Actions On Hover', Type: 'Flag', Note: 'If set, actions will be displayed on mouse over.' },
            ActionsOnSwipe: { Default: undefined, Group: 'General', Label: 'Actions On Swipe', Type: 'Flag', Note: 'If set, actions will be displayed on swipe.' },
            Bordered: { Default: true, Group: 'Style', Label: 'Bordered', Type: 'Flag', Note: 'Display a border around the control. Useful to disable when using this control inside another composite control.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Class (Style)', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout panel into design mode.' },
            Disabled: { Default: undefined, Group: 'General', Label: 'Disabled', Type: 'Flag', Note: 'Disables the control.' },
            FieldKey: { Default: undefined, Group: 'Data', Label: 'Field Key', Type: 'Text', Note: 'Field name to use to as the record key and identifier, when using object/record Items.' },
            FieldText: { Default: undefined, Group: 'Data', Label: 'Field Text', Type: 'Text', Note: 'Field name to use for the display text, when using object/record Items.' },
            ItemRenderer: { Default: undefined, Group: 'Data', Label: 'Item Renderer', Type: 'Text', Note: 'A renderer function to call for the handling handling of the creation of each item, called by item.' },
            Items: { Default: undefined, Group: 'Data', Label: 'Items', Type: 'Text', Note: 'A list of data items.' },
            Multiple: { Default: undefined, Group: 'State', Label: 'Multiple', Type: 'Flag', Note: 'Allows the selection of multiple items.' },
            // Text: { Default: undefined, Group: 'General', Label: 'Text', Type: 'Text', Note: 'Text value to display.' },
            Tip: { Default: undefined, Group: 'General', Label: 'Tool Tip', Type: 'Any', Note: 'Information related to the control.' },
            Value: { Default: undefined, Group: 'State', Label: 'Value', Type: 'Any', Note: 'Single selected value or array of selected values, when Multiple is set.' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
