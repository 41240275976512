exports.ALERT_TYPE = [
    { Key: 'info', Text: 'Info' },
    { Key: 'success', Text: 'Success' },
    { Key: 'warning', Text: 'Warning' },
    { Key: 'error', Text: 'Error' },
    { Key: 'help', Text: 'Help' }
];
exports.ALIGN_HORIZONTAL = [
    { Key: 'start', Text: 'Start' },
    { Key: 'center', Text: 'Center' },
    { Key: 'end', Text: 'End' }
];
exports.ALIGN_VERTICAL = [
    { Key: 'top', Text: 'Top' },
    { Key: 'middle', Text: 'Middle' },
    { Key: 'bottom', Text: 'Bottom' }
];
exports.BACKGROUND_STYLE_ALL = [
    { Key: 'none', Text: 'None' },
    { Key: 'image', Text: 'Image' },
    { Key: 'linear', Text: 'Linear Gradient' },
    { Key: 'pattern', Text: 'Pattern' },
    { Key: 'radial', Text: 'Radial Gradient' },
    { Key: 'solid', Text: 'Solid' }
];
exports.BACKGROUND_STYLE_COLOUR = [
    { Key: 'none', Text: 'None' },
    { Key: 'linear', Text: 'Linear Gradient' },
    { Key: 'radial', Text: 'Radial Gradient' },
    { Key: 'solid', Text: 'Solid' }
];
exports.BORDER_STYLE = [
    { Key: 'none', Text: 'None' },
    { Key: 'dashed', Text: 'Dashed' },
    { Key: 'dotted', Text: 'Dotted' },
    { Key: 'double', Text: 'Double' },
    { Key: 'solid', Text: 'Solid' }
];
exports.HORIZONTAL_VERTICAL = [
    { Key: 'horz', Text: 'Horizontal' },
    { Key: 'vert', Text: 'Vertical' }
];
exports.HORIZONTAL_VERTICAL_AUTO = [
    { Key: 'auto', Text: 'Auto' },
    { Key: 'horz', Text: 'Horizontal' },
    { Key: 'vert', Text: 'Vertical' }
];
exports.TABLE_BORDERS = [
    { Key: 'all', Text: 'All' },
    { Key: 'horz', Text: 'Horizontal' },
    { Key: 'vert', Text: 'Vertical' },
    { Key: 'none', Text: 'None' }
];
exports.RGBA_PARTS = { Red: 0, Green: 0, Blue: 0, Alpha: 0 };
exports.SIDE_PARTS = { Top: 0, Right: 0, Bottom: 0, Left: 0 }; // Margin, padding, position.
exports.SIZE = [
    { Key: 'dynamic', Text: 'Dynamic' },
    { Key: 'fit', Text: 'Fit Container' },
    { Key: 'fixed', Text: 'Fixed' }
];
exports.SORT = [
    { Key: 'no', Text: 'No' },
    { Key: 'asc', Text: 'Ascending' },
    { Key: 'dec', Text: 'Descending' }
];
exports.TABLE_STICKY = [
    { Key: 'no', Text: 'No' },
    { Key: 'asis', Text: 'As Is' },
    { Key: 'top', Text: 'Top' }
];
exports.TOOLBAR_BUTTON_TYPE = [
    { Key: 'icon', Text: 'Icon Only' },
    { Key: 'text', Text: 'Text Only' },
    { Key: 'bottom', Text: 'Text Bottom' },
    { Key: 'top', Text: 'Text Top' },
    { Key: 'start', Text: 'Text Start' },
    { Key: 'end', Text: 'Text End' }
];
