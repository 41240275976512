import Control from './control';
import Util from '../util/util';
import UI from '../util/ui';

export default class Text extends Control {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'Text';
        // definition.Props.ClassFixed = 'foui-ctrl foui-control-panel foui-stretch';
        super(definition, Text._DEF.Properties);
    }

    init () {
        this.addClass('foui-ctrl foui-control-panel foui-stretch');
        // , Map: { Control: 'label', Prop: 'Text' }
        // Name starts with _ so that it won't be added to the $name lookups.
        this.ui.text = this.newControl({ Type: 'CoreInput', Props: { Name: `_${this.props.Name}_text`, ClassFixed: 'foui-text foui-stretch-y' } });
        this.bindControl(this.ui.text, [
            { other: 'Class', local: 'Class' },
            // { other: 'ClassFixed', local: 'ClassFixed' },
            { other: 'Disabled', local: 'Disabled' },
            { other: 'Max', local: 'Max' },
            { other: 'Min', local: 'Min' },
            { other: 'Multiple', local: 'Multiple' },
            { other: 'Placeholder', local: 'Placeholder' },
            { other: 'ReadOnly', local: 'ReadOnly' },
            { other: 'Step', local: 'Step' },
            { other: 'Tip', local: 'Tip' },
            { other: 'Type', local: 'Type' },
            { other: 'Value', local: 'Value' },
        ]);
    }

    onChange (changes) {
        // console.log('Text changes', changes);
        for (const [key, v] of changes) {
            const value = Util.checkForBoundedValue(v, this);
            switch (key) {
                case 'Bordered': {
                    if (this.ui.text.props.Class) {
                        if (value) {
                            if (this.ui.text.props.Class.indexOf('bordered') === -1) this.ui.text.props.Class += ' bordered';
                        }
                        else {
                            if (this.ui.text.props.Class.indexOf('bordered') > -1) this.ui.text.props.Class = this.ui.text.props.Class.replace('bordered', '');
                        }
                    }
                    else {
                        if (value) this.ui.text.props.Class = 'bordered';
                    }
                    // if (value) UI.addClass(this.ui.text, 'bordered');
                    // else UI.removeClass(this.ui.text, 'bordered');
                    break;
                }
                /* case 'DesignMode': {
                    if (value) {
                        // UI.attr(this.ui.text.elem, { tabindex: -1, 'class+': 'unclickable' });
                        UI.attr(this.elem, { draggable: true });
                        this.eventsOff();
                    }
                    else {
                        // UI.attr(this.ui.text.elem, { tabindex: 0, 'class-': 'unclickable' });
                        UI.attr(this.elem, { draggable: false });
                        this.eventsOn();
                    }
                    break;
                } */
                case 'Width': this.updateWidth(); break;
            }
        }
    }

    updateWidth () {
        let value = this.props.Width;
        if (value === '' || value === undefined) {
            UI.attr(this.elem, { style: 'flex-basis:inherit' });
            UI.attr(this.elem, { style: 'min-width:inherit' });
            return;
        }
        if (typeof value === 'number') value = `${value}px`;
        UI.attr(this.elem, { style: `flex-basis:${value}` });
        UI.attr(this.elem, { style: `min-width:${value}` });
    }
}

// Control property definitions.
Object.defineProperty(Text, '_DEF', {
    value: {
        Group: 'Common Controls',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            Bordered: { Default: true, Group: 'Style', Label: 'Bordered', Type: 'Flag', Note: 'Display a border around the control. Useful to disable when using this control inside another composite control.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Style Class', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout panel into design mode.' },
            Disabled: { Default: undefined, Group: 'General', Label: 'Disabled', Type: 'Flag', Note: 'Disables the control.' },
            Max: { Default: undefined, Group: 'General', Label: 'Maximum', Type: 'Number', Note: 'The maximum number allowed (for number and slider types).' },
            Min: { Default: undefined, Group: 'General', Label: 'Minimum', Type: 'Number', Note: 'The minimum number allowed (for number and slider types).' },
            Multiple: { Default: undefined, Group: 'General', Label: 'Multiple', Type: 'Flag', Note: 'Indicates multiple email addresses allowed, comma separated.' },
            // NoBorder: { Default: false, Group: 'Style', Label: 'No Border', Type: 'Flag', Note: 'Indicates that no border should be displayed around the control.' },
            // Padding: { Default: '', Group: 'General', Label: 'Style', Type: 'Text', Note: 'Spacing inside the control borders.' },
            // Password: { Default: false, Group: 'State', Label: 'Password', Type: 'Flag', Note: 'Password flag to mask text.' },
            Placeholder: { Default: undefined, Group: 'General', Label: 'Placeholder', Type: 'Text', Note: 'Describe the expected value.' },
            ReadOnly: { Default: undefined, Group: 'General', Label: 'Read Only', Type: 'Flag', Note: 'Prevents input.' },
            Step: { Default: undefined, Group: 'General', Label: 'Step', Type: 'Number', Note: 'Increase or decrease by this value (for number and slider types).' },
            Tip: { Default: undefined, Group: 'General', Label: 'Tool Tip', Type: 'Any', Note: 'Information related to the control.' },
            Type: { Default: 'text', Group: 'General', Label: 'Type', Type: 'Text', Note: 'Input type.' }, // text | password | email (multiple) | search | tel | url | number (min, max, step) | slider (min, max, step) | datetime-local | month | time | week | color
            Value: { Default: '', Group: 'General', Label: 'Value', Type: 'Text', Note: 'Text value.' },
            // Visible: { Default: true, Group: 'State', Label: 'Visible', Type: 'Flag', Note: 'Visible or hidden state of the control.' },
            Width: { Default: undefined, Group: 'Style', Label: 'Width', Type: 'Any', Note: 'Fixed control width. Clear (leave blank) to allow the control to use the maximum available space.' },
            // MaxLength
            // PasswordPeak
            // TabIndex
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
