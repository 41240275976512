import Control from './control';
// import CoreTable from './core/table';
import Check from './check';
import Text from './text';
import UI from '../util/ui';
import Util from '../util/util';
import CoreInput from './core/input';

export default class PropertyGrid extends Control {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'PropertyGrid';
        // definition.Props.ClassFixed = 'foui-ctrl foui-property-grid';
        super(definition, PropertyGrid._DEF.Properties);
    }

    init () {
        // this.meta.ClassFixed = 'foui-property-grid';
        this.addClass('foui-ctrl foui-property-grid');
        this.meta.ControlList = {};
        // Name starts with _ so that it won't be added to the $name lookups.
        // this.ui.holder = new CoreTable({ Props: { Name: `_${this.props.Name}_holder`, ClassFixed: 'foui-property-holder unselectable foui-stretch-y foui-flex-grow' }, Parent: this, Data: this.data, Code: this.code, $name: this.$name }); // Value: this.props.Value, //
        // this.ui.grid = new CoreTable({ Props: { Name: `_${this.props.Name}_table`, ClassFixed: 'foui-property-table unselectable' }, Parent: this.ui.holder.elem, Data: this.data, Code: this.code, $name: this.$name }); // Value: this.props.Value, //
        /* this.bindControl(this.ui.button, [
            { other: 'Class', local: 'Class' },
            { other: 'Disabled', local: 'Disabled' },
            { other: 'HTML', local: 'HTML' },
            { other: 'Text', local: 'Text' },
            { other: 'Tip', local: 'Tip' },
        ]); */

        // The holder is on the same depth as the note. The holder allows the table to grow and scroll as needed without hiding the note.
        const holder = UI.create('div', { class: 'foui-property-holder' });
        this.ui.grid = UI.create('table', { class: 'foui-property-table' });
        const header = UI.create('thead', { class: 'foui-property-head' });
        /* if (this.props.HideHeader) {
            UI.addClass(header, 'foui-hidden');
        } */
        /* if (this.props.RowAutoHeight) {
            UI.addClass(table, 'auto-height');
        } */
        const tr = UI.create('tr', { 'data-idx': -1 });
        header.append(tr);
        // Property name.
        const th1 = UI.create('th', { text: 'Property' }); // , width: `${Math.floor(this.elem.offsetWidth / 2)}px`
        tr.append(th1);
        const th2 = UI.create('th', { text: 'Value' });
        tr.append(th2);
        this.ui.body = UI.create('tbody', { class: 'foui-property-body' });
        // this.loadGrid();
        this.ui.grid.append(header);
        this.ui.grid.append(this.ui.body);
        holder.append(this.ui.grid);
        this.elem.append(holder);
        const note = UI.create('div', { class: 'foui-property-note' });
        this.ui.noteTitle = UI.create('div', { class: 'foui-property-note-title', text: 'Property' });
        this.ui.noteDescription = UI.create('div', { text: 'Description.' });
        note.append(this.ui.noteTitle);
        note.append(this.ui.noteDescription);
        this.elem.append(note);
    }

    attachEvents () {
        // Bind to this control.
        this.handlers.onClick = this.onClick.bind(this);
        // Attach the listener(s).
        UI.on(this.ui.body, 'mousedown', this.handlers.onClick, true);
    }

    detachEvents () {
        UI.off(this.elem, 'click', this.handlers.onClick, true); // mousedown
        delete this.handlers;
    }

    onChange (changes) {
        // console.log('Button changes', changes);
        for (const [key, v] of changes) {
            switch (key) {
                /* case 'DesignMode': {
                    const value = Util.checkForBoundedValue(v, this);
                    if (value) {
                        // UI.attr(this.ui.grid.elem, { tabindex: -1, 'class+': 'unclickable' });
                        UI.attr(this.elem, { draggable: true });
                        this.eventsOff();
                    }
                    else {
                        // UI.attr(this.ui.grid.elem, { tabindex: 0, 'class-': 'unclickable' });
                        UI.attr(this.elem, { draggable: false });
                        this.eventsOn();
                    }
                    break;
                } */
                // case 'Disabled': UI.attr(this.ui.button, { disabled: v }); break;
                case 'Item': {
                    const value = Util.checkForBoundedValue(v, this);
                    this.updateItems(value);
                    break;
                }
                // case 'Schema': break;
            }
        }
    }

    updateItems (value) {
        const keys = typeof value === 'object' ? Object.keys(value) : [];
        // Show that there are no properties.
        if (!keys.length) {
            this.ui.body.innerHTML = '';
            const tr = UI.create('tr', { 'data-idx': -1 });
            const td = UI.create('td', { colSpan: 2, text: 'No properties.' });
            tr.append(td);
            this.ui.body.append(tr);
            this.meta.oldItem = value;
            return;
        }
        if (!this.meta.oldItem) this.meta.oldItem = value;
        // If the item is new, unbind the previous listeners.
        if (value !== this.meta.oldItem) {
            // Clean the grid and watchers.
            const oKeys = Object.keys(this.meta.oldItem);
            for (let i = 0; i < oKeys.length; i++) {
                const key = oKeys[i];
                const input = this.meta.ControlList[key];
                input.props.unbindFrom(value, key, 'Value');
                value.unbindFrom(input.props, 'Value', key);
            }
            this.ui.body.innerHTML = '';
            this.meta.oldItem = value;
            this.ui.noteTitle.textContent = 'Property';
            this.ui.noteDescription.textContent = 'Description';
        }
        // Create or update the inputs for the item's properties.
        this.sortBy(keys);
        // const gridWidth = this.elem.offsetWidth;
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const keyRow = UI.find(this.ui.body, `tr[data-prop="${key}"]`);
            if (keyRow) { // If the property row exists, update it.
                console.log('PG Key Row', keyRow);
            }
            else { // Add a new property row.
                const schema = this.props.Schema[key];
                const tr = UI.create('tr', { 'data-idx': i, 'data-prop': key });
                const keyProps = { html: schema ? schema.Label : key };
                if (i === 0) {
                    // Set the width on the first TD. Cannot hide the header without losing size in Chrome.
                    // keyProps.style = `width:${Math.floor(gridWidth / 2)}px;`;
                    keyProps.style = `width:50%;`;
                }
                const tdKey = UI.create('td', keyProps);
                tr.append(tdKey);

                const valueProps = { Class: 'pty-edit' }; // html: value[key],
                if (i === 0) {
                    // Firefox requires the width on all cells.
                    // valueProps.style = `width:${Math.floor(gridWidth / 2)}px;`;
                    valueProps.style = `width:50%;`;
                }
                const tdValue = UI.create('td', valueProps);
                tr.append(tdValue);
                // TODO: Control input type.
                let input;
                switch (schema.Type) {
                    case 'Any':
                    case 'Text':
                        input = new CoreInput({ Props: { Name: `_${key}_text`, ClassFixed: 'foui-text foui-stretch-y', Value: value[key] }, Parent: tdValue, Data: this.data, Code: this.code, $name: this.$name }); // Value: this.props.Value,
                        // input = new Text({ Props: { Name: `_${key}_text`, ClassFixed: 'foui-text foui-stretch-y', Value: value[key] }, Parent: tdValue, Data: this.data, Code: this.code, $name: this.$name }); // Value: this.props.Value,
                        break;
                    case 'Number':
                        input = new CoreInput({ Props: { Name: `_${key}_text`, ClassFixed: 'foui-text foui-stretch-y', Value: value[key], Type: 'number' }, Parent: tdValue, Data: this.data, Code: this.code, $name: this.$name });
                        break;
                    case 'Flag':
                        // input = new CoreInput({ Props: { Name: `_${key}_text`, ClassFixed: 'foui-text foui-stretch-y', Value: value[key], Type: 'checkbox' }, Parent: tdValue, Data: this.data, Code: this.code, $name: this.$name });
                        input = new Check({ Props: { Name: `_${key}_check`, Value: value[key], Text: '' }, GlobalHide: true, Parent: tdValue, Data: this.data, Code: this.code, $name: this.$name });
                        break;
                    case 'List':
                        input = new CoreInput({ Props: { Name: `_${key}_text`, ClassFixed: 'foui-text foui-stretch-y', Value: value[key] }, Parent: tdValue, Data: this.data, Code: this.code, $name: this.$name }); // Value: this.props.Value,
                        break;
                    case 'Object':
                        input = new CoreInput({ Props: { Name: `_${key}_text`, ClassFixed: 'foui-text foui-stretch-y', Value: value[key] }, Parent: tdValue, Data: this.data, Code: this.code, $name: this.$name }); // Value: this.props.Value,
                        break;
                }
                // Bind the two data objects.
                if (!input) console.warn(key, schema);
                input.props.bindTo(value, key, 'Value');
                value.bindTo(input.props, 'Value', key);
                this.meta.ControlList[key] = input;

                this.ui.body.append(tr);
            }
        }
    }

    onClick (evt) {
        // Selection highlight.
        const row = evt.target.closest('tr');
        // const clickCol = [...row.children].indexOf(evt.target);
        const prevSel = UI.find(this.ui.body, '.foui-selected');
        if (prevSel) UI.removeClass(prevSel, 'foui-selected');
        UI.addClass(row, 'foui-selected');

        // Note.
        const key = row.dataset.prop;
        const schema = this.props.Schema[key];
        this.ui.noteTitle.textContent = schema ? schema.Label : key;
        this.ui.noteDescription.textContent = schema ? schema.Note : '';
    }

    sortBy (keys) {
        // TODO: Add sort options ASC, DESC and in grouped.
        // Sort by the key names.
        keys.sort();
        // Remove name and re-add it to make it the first item.
        const pos = keys.indexOf('Name');
        const name = keys.splice(pos, 1);
        keys.unshift(name);
    }
}

// Control property definitions.
Object.defineProperty(PropertyGrid, '_DEF', {
    value: {
        Group: 'Data',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Style Class', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout panel into design mode.' },
            Disabled: { Default: undefined, Group: 'General', Label: 'Disabled', Type: 'Flag', Note: 'Disables the control.' },
            Item: { Default: {}, Group: 'General', Label: 'Item', Type: 'Object', Note: 'Object item instance with properties to edit.' },
            Schema: { Default: {}, Group: 'General', Label: 'Schema', Type: 'Object', Note: 'The schema detailing the object item properties.' },
            // Text: { Default: 'Label', Group: 'General', Label: 'Text', Type: 'Text', Note: 'Text value to display.' },
            // Tip: { Default: undefined, Group: 'General', Label: 'Tool Tip', Type: 'Any', Note: 'Information related to the control.' },
            //
            // Filterable: { default: false, group: 'General', label: 'Filterable', type: 'Flag', note: 'Allow filtering if list items.' },
            // HideHeader: { default: false, group: 'State', label: 'Hide Header', type: 'Flag', note: 'Hides the header if true.' },
            // Key: { default: 'Select1', group: 'General', label: 'Key', type: 'Text', note: 'Unique item key options.' },
            // RowAutoHeight: { default: false, group: 'State', label: 'Row Auto Height', type: 'Flag', note: 'Allows text to wrap and expand the row if set to true.' },
            // ShowIcons: { default: true, group: 'State', label: 'Show Icons', type: 'Flag', note: 'Shows item icons if true.' },
            // Value: { default: '', group: 'General', label: 'Value', type: 'Text', note: 'Text value.' },
            // Visible: { default: true, group: 'State', label: 'Visible', type: 'Flag', note: 'Visible or hidden state of the control.' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
