import Control from './control';
import UI from '../util/ui';
import Util from '../util/util';

export default class Button extends Control {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'Button';
        // definition.Props.ClassFixed = 'foui-ctrl foui-control-panel foui-stretch-y';
        super(definition, Button._DEF.Properties);
    }

    init () {
        // Setting `this.meta.ClassFixed` only works if `Class` is handled in `onChange`.
        this.addClass('foui-ctrl foui-control-panel foui-stretch-y');
        // Name starts with _ so that it won't be added to the $name lookups.
        this.ui.button = this.newControl({ Type: 'CoreButton', Props: { Name: `_${this.props.Name}_button`, ClassFixed: 'foui-button unselectable foui-stretch-y foui-flex-grow', Events: { Click: this.handlers.onClick } } }); // Value: this.props.Value, //
        this.bindControl(this.ui.button, [
            { other: 'Class', local: 'Class' },
            // { other: 'ClassFixed', local: 'ClassFixed' },
            { other: 'Disabled', local: 'Disabled' },
            // { other: 'HTML', local: 'HTML' },
            // { other: 'Text', local: 'Text' },
            { other: 'Tip', local: 'Tip' },
        ]);
    }

    attachEvents () {
        // Bind to this control.
        this.handlers.onClick = this.onClick.bind(this);
        // Attach the listener(s).
        UI.on(this.elem, 'click', this.handlers.onClick, true);
    }

    detachEvents () {
        UI.off(this.elem, 'click', this.handlers.onClick, true); // mousedown
    }

    onChange (changes) {
        // console.log('Button changes', changes);
        for (const [key, v] of changes) {
            const value = Util.checkForBoundedValue(v, this);
            switch (key) {
                // case 'Disabled': UI.attr(this.ui.button, { disabled: v }); break;
                case 'Fit': {
                    if (value) {
                        this.addClass('foui-flex-grow');
                        UI.addClass(this.ui.button, 'foui-flex-grow');
                        // UI.removeClass(this.ui.button, 'foui-flex-shrink');
                    }
                    else {
                        this.removeClass('foui-flex-grow');
                        UI.removeClass(this.ui.button, 'foui-flex-grow');
                        // UI.addClass(this.ui.button, 'foui-flex-shrink');
                    }
                    break;
                }
                case 'HTML': UI.attr(this.props.Icon ? this.ui.text : this.ui.button, { html: value }); break;
                case 'Icon': this.updateIcon(); break;
                case 'IconLocation': this.updateIconLocation(); break;
                case 'IconSpace': this.updateIconSpace(); break;
                case 'IconHeight': this.updateIconHeight(); break;
                case 'IconWidth': this.updateIconWidth(); break;
                case 'Text': UI.attr(this.props.Icon ? this.ui.text : this.ui.button, { text: value }); break;
                // case 'Toggle': break;
                case 'ToggleGroup': UI.attr(this.ui.button, { 'data-group': value }); break;
                case 'Value': {
                    if (this.props.Value) this.addClass('foui-selected');
                    else this.removeClass('foui-selected');
                    break;
                }
            }
        }
    }

    onClick (evt) {
        if (this.props.Toggle) {
            if (this.props.ToggleGroup) {
                // Deselect the other buttons in the group.
                const parentToolbar = this.elem.closest('.foui-toolbar'); // 'ToggleGroup'
                const parentCell = parentToolbar ? null : this.elem.closest('.foui-layout-grid-cell');
                const parentBox = parentToolbar || parentCell;
                const parent = this.FOUI.getControl(parentBox.id);
                const controls = parent.meta.ControlList.filter(o => o.props.ToggleGroup === this.props.ToggleGroup);
                for (const ctrl of controls) {
                    if (ctrl.props.Value) ctrl.props.Value = false;
                }
            }
            if (this.props.Value === 'undefined') this.props.Value = true;
            else this.props.Value = !this.props.Value;
            setImmediate(() => {
                UI.sendEvent(this.elem, 'change', this.props.Name);
            });
        }
    }

    updateIcon () {
        if (this.props.Icon) {
            // Add or update.
            if (this.ui.icon) { // Icon element exists.
                if (this.ui.icon.dataset.name !== this.props.Icon) this.ui.icon.innerHTML = Util.getIconString(this.props.Icon); // Update.
                return;
            }
            this.ui.icon = UI.create('span', { class: 'foui-control-ico', 'data-name': this.props.Icon, html: Util.getIconString(this.props.Icon) });
            this.ui.text = UI.create('span', { class: 'foui-control-text', text: this.props.Text });
            this.ui.button.innerHTML = '';

            this.ui.button.elem.append(this.ui.icon);
            this.ui.button.elem.append(this.ui.text);

            this.updateIconLocation();
            return;
        }

        // Remove.
        if (this.ui.icon) {
            UI.remove(this.ui.icon);
            if (this.props.Text) UI.attr(this.elem, { text: this.props.Text });
        }
    }

    updateIconLocation () {
        UI.removeClass(this.ui.button, 'foui-flex-row');
        UI.removeClass(this.ui.button, 'foui-flex-row-reverse');
        UI.removeClass(this.ui.button, 'foui-flex-column');
        UI.removeClass(this.ui.button, 'foui-flex-column-reverse');
        setTimeout(() => {
            const location = this.props.IconLocation || 'start';
            switch (location) {
                case 'start': UI.addClass(this.ui.button, 'foui-flex-row'); break;
                case 'end': UI.addClass(this.ui.button, 'foui-flex-row-reverse'); break;
                case 'top': UI.addClass(this.ui.button, 'foui-flex-column'); break;
                case 'bottom': UI.addClass(this.ui.button, 'foui-flex-column-reverse'); break;
            }
        });
        this.updateIconSpace();
    }

    updateIconSpace () {
        if (!this.ui.icon) return;
        if (this.props.Text === '') { // No text therefore no margins.
            UI.attr(this.ui.icon, { style: 'margin:0' });
            return;
        }

        const location = this.props.IconLocation || 'start';
        const space = this.props.IconSpace || 6;
        let margin;
        switch (location) {
            case 'top': margin = `0 0 ${space}px 0`; break;
            case 'end': margin = `0 0 0 ${space}px`; break;
            case 'bottom': margin = `${space}px 0 0 0`; break;
            case 'start': margin = `0 ${space}px 0 0`; break;
        }
        UI.attr(this.ui.icon, { style: `margin:${margin}` });
    }

    updateIconHeight () {
        if (!this.ui.icon) return;
        if (Util.isNullOrEmpty(this.props.IconHeight)) {
            UI.style(this.ui.icon, 'height:auto');
            UI.removeClass(this.ui.icon, 'foui-stretch-y');
        }
        else if (this.props.IconHeight === 'auto') {
            UI.style(this.ui.icon, 'height:auto');
            UI.addClass(this.ui.icon, 'foui-stretch-y');
        }
        else {
            const w = parseFloat(this.props.IconHeight);
            const unit = Util.getValueUnit(this.props.IconHeight);
            UI.removeClass(this.ui.icon, 'foui-stretch-y');
            UI.style(this.ui.icon, `height:${w}${unit}`);
        }
    }

    updateIconWidth () {
        if (!this.ui.icon) return;
        if (Util.isNullOrEmpty(this.props.IconWidth)) {
            UI.style(this.ui.icon, 'width:auto');
            UI.removeClass(this.ui.icon, 'foui-stretch-x');
        }
        else if (this.props.IconWidth === 'auto') {
            UI.style(this.ui.icon, 'width:auto');
            UI.addClass(this.ui.icon, 'foui-stretch-x');
        }
        else {
            const w = parseFloat(this.props.IconWidth);
            const unit = Util.getValueUnit(this.props.IconWidth);
            UI.removeClass(this.ui.icon, 'foui-stretch-x');
            UI.style(this.ui.icon, `width:${w}${unit}`);
        }
    }
}

// Control property definitions.
Object.defineProperty(Button, '_DEF', {
    value: {
        Group: 'Common Controls',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Class (Style)', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout panel into design mode.' },
            Disabled: { Default: undefined, Group: 'General', Label: 'Disabled', Type: 'Flag', Note: 'Disables the control.' },
            Fit: { Default: undefined, Group: 'General', Label: 'Fit Layout', Type: 'Flag', Note: 'Sets the control to fit the layout panel width.' },
            HTML: { Default: undefined, Group: 'General', Label: 'HTML', Type: 'Text', Note: 'HTML code as content.' },
            Icon: { Default: undefined, Group: 'Style', Label: 'Icon', Type: 'Text', Note: 'Icon name from the configured icon set.' },
            IconLocation: { Default: undefined, Group: 'Style', Label: 'Icon Location', Type: 'Text', Note: 'The location of the icon relative to the text.' },
            IconSpace: { Default: undefined, Group: 'Style', Label: 'Icon Space Between', Type: 'Number', Note: 'Spacing between the icon and the text.' },
            IconHeight: { Default: 18, Group: 'Style', Label: 'Icon Height', Type: 'Any', Note: 'Height of the icon. Leave blank for the default size. A value of "auto", px or % is valid.' },
            IconWidth: { Default: 18, Group: 'Style', Label: 'Icon Width', Type: 'Any', Note: 'Width of the icon. Leave blank for the default size. A value of "auto", px or % is valid.' },
            Text: { Default: undefined, Group: 'General', Label: 'Text', Type: 'Text', Note: 'Text value to display.' },
            Toggle: { Default: undefined, Group: 'State', Label: 'Toggle', Type: 'Flag', Note: 'Changes the button to function like a checkbox e.g. on/off (pressed/depressed).' },
            ToggleGroup: { Default: undefined, Group: 'State', Label: 'Toggle Group', Type: 'Text', Note: 'The name of the toggle group this button belongs to. Only one button in the group can be the main selected button. Does not span containers.' },
            Tip: { Default: undefined, Group: 'General', Label: 'Tool Tip', Type: 'Any', Note: 'Information related to the control.' },
            Value: { Default: undefined, Group: 'State', Label: 'Value', Type: 'Flag', Note: 'True/False value state if the button Toggle property is set to true.' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
