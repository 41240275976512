import Util from './util';

export default class PaperUtil {
    static makeStep (paper, props) {
        /* const pathSelect = new paper.Path.Circle({
            center: new paper.Point(33, 33),
            radius: 40,
            strokeColor: props.colour.primary,
            strokeWidth: 6,
        }); */
        const rectSelect = new paper.Rectangle(new paper.Point(0, 0), new paper.Size(66, 66));
        const radiusSelect = new paper.Size(5, 5);
        const pathSelect = new paper.Path.Rectangle(rectSelect, radiusSelect);
        pathSelect.strokeColor = props.colour.primary;
        pathSelect.strokeWidth = 4; // 2
        pathSelect.strokeCap = 'round';
        // pathSelect.dashArray = [6, 4];
        // pathSelect.fillColor = '#454545';
        pathSelect.visible = false;
        pathSelect.shadowColor = new paper.Color(props.colour.primary);
        pathSelect.shadowBlur = 8;
        pathSelect.shadowOffset = new paper.Point(0, 0);

        const rect = new paper.Rectangle(new paper.Point(0, 0), new paper.Size(60, 60));
        const radius = new paper.Size(3, 3);
        const path = new paper.Path.Rectangle(rect, radius);
        path.strokeColor = props.colour.border;
        path.strokeWidth = 2;
        // path.dashArray = [10, 12];
        // path.strokeCap = 'round';
        path.fillColor = props.colour.control;
        // path.locked = true;

        let iconString = Util.getIconString(props.icon);
        // The icon cannot have 100% width/height. Set a fixed size.
        if (iconString.indexOf('width="100%"') > -1) iconString = iconString.replace('width="100%"', 'width="32px"');
        if (iconString.indexOf('height="100%"') > -1) iconString = iconString.replace('height="100%"', 'height="32px"');
        const svg = paper.project.importSVG(iconString);
        svg.name = `icon_${props.icon}`;
        svg.data.type = 'icon';
        // svg.scale(2);
        svg.position = new paper.Point(30, 30);
        // svg.locked = true;

        const text = new paper.PointText({
            point: [0, 0],
            content: props.text,
            fillColor: props.colour.input,
            fontFamily: 'Inter var',
            fontWeight: '300',
            fontSize: 14,
            justification: 'center',
        });
        text.data.type = 'text';
        // text.pivot = new paper.Point(text.bounds.x, text.bounds.y * -1);
        text.position = new paper.Point(30, 75);
        // text.locked = true;

        const desc = new paper.PointText({
            point: [0, 0],
            content: 'Read/Write', // props.text,
            fillColor: props.colour.main,
            fontFamily: 'Inter var',
            fontWeight: '300',
            fontSize: 10,
            justification: 'center',
        });
        // text.pivot = new paper.Point(text.bounds.x, text.bounds.y * -1);
        desc.position = new paper.Point(30, 94);
        // text.locked = true;

        const run = new paper.Path.Rectangle({
            point: [0, 40],
            size: [60, 20],
            fillColor: props.colour.primary, // '#F12300',
            opacity: 0,
            // blendMode: 'overlay',
            shadowColor: new paper.Color(props.colour.primary), // '#F12300' // '#25A79A'
            shadowBlur: 5,
            shadowOffset: new paper.Point(0, 0),
        });
        const running = new paper.PointText({
            point: [0, 0],
            content: 'RUNNING...',
            fillColor: props.colour.primaryText,
            opacity: 0,
            fontFamily: 'Inter var',
            fontWeight: '500',
            fontSize: 9,
            justification: 'left',
        });
        running.position = new paper.Point(30, 51);

        const g = new paper.Group([pathSelect, path, svg, text, desc, run, running]); // , run, running
        g.data.workstep = 1;
        g.data.dir = 1;
        g.pivot = new paper.Point(30, 30);
        g.position = new paper.Point(50, 50);

        pathSelect.position.x -= 3;
        pathSelect.position.y -= 3;

        return g;
    }

    static makeJoinLine (paper, fromPoint, toPoint, colour) {
        const clr = '#E5E5E5';
        /* const link = new paper.Path();
        link.strokeColor = clr;
        link.add(fromPoint); // item1.bounds.x + item1.bounds.width
        link.add(toPoint);
        link.strokeWidth = 3;
        const fromAnchor = new paper.Path.Circle({
            center: fromPoint,
            radius: 4,
            fillColor: clr
        }); */
        // const vector = toPoint.subtract(fromPoint);
        // const arrowVector = vector.normalize(10);
        // const end = fromPoint.add(vector);

        // Circle start connector.
        const contact1 = new paper.Path.Circle({
            center: fromPoint,
            radius: 5,
            fillColor: clr
        });

        // The line.
        // const line = new paper.Path([fromPoint, toPoint]);
        const fromOffset = fromPoint.add(new paper.Point(10, 0));
        const toOffset = toPoint.subtract(new paper.Point(10, 0));
        const line = new paper.Path([fromPoint, fromOffset, toOffset, toPoint]);
        line.strokeWidth = 2;
        line.strokeColor = clr;
        /* line.strokeCap = 'round';
        line.strokeJoin = 'round'; */
        line.segments[1].handleOut = new paper.Point(30, 0);
        line.segments[2].handleIn = new paper.Point(-30, 0);
        // line.fullySelected = true;

        // Arrow end connector.
        const arrow1 = toPoint.add(new paper.Point(-10, -5));
        const arrow2 = toPoint.add(new paper.Point(-10, +5));
        const arrowHead = new paper.Path([
            arrow1,
            toPoint,
            arrow2,
            arrow1,
        ]);
        arrowHead.fillColor = clr;
        arrowHead.position.x += 5;

        // Group all.
        const link = new paper.Group([
            contact1,
            line,
            arrowHead,
        ]);
        link.data.worklink = 1;

        return link;

        /* var path = new Path({
            strokeColor: 'black'
        });

        path.add(new Point(30, 75));

        // Add a segment with handles:
        var point = new Point(100, 20);
        var handleIn = new Point(-50, 0);
        var handleOut = new Point(50, 0);
        var added = path.add(new Segment(point, handleIn, handleOut));

        // Select the added segment, so we can see its handles:
        added.selected = true;

        path.add(new Point(200, 50)); */
    }

    static drawArrowhead (ctx, x, y, radians) {
        ctx.save();
        ctx.beginPath();
        ctx.translate(x, y);
        ctx.rotate(radians);
        ctx.moveTo(0, 0);
        ctx.lineTo(5, 20);
        ctx.lineTo(-5, 20);
        ctx.closePath();
        ctx.restore();
        ctx.fill();
    }

    static replaceStepIcon (paper, step, iconName) {
        let iconString = Util.getIconString(iconName);
        // The icon cannot have 100% width/height. Set a fixed size.
        if (iconString.indexOf('width="100%"') > -1) iconString = iconString.replace('width="100%"', 'width="32px"');
        if (iconString.indexOf('height="100%"') > -1) iconString = iconString.replace('height="100%"', 'height="32px"');
        const svg = paper.project.importSVG(iconString);
        svg.name = `icon_${iconName}`;
        svg.data.type = 'icon';
        // svg.scale(2);
        svg.position = new paper.Point(30, 30);
        // svg.locked = true;

        const old = step.getItem({
            match: (o) => o.data.type === 'icon',
        });
        svg.position = old.position.clone();
        old.replaceWith(svg);
    }
}
