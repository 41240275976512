import Control from './control';
import UI from '../util/ui';
import Util from '../util/util';

export default class PopupMenu extends Control {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'PopupMenu';
        // definition.Props.ClassFixed = 'foui-ctrl foui-control-panel foui-stretch-y';
        super(definition, PopupMenu._DEF.Properties);
    }

    init () {
        // Setting `this.meta.ClassFixed` only works if `Class` is handled in `onChange`.
        this.addClass('foui-ctrl foui-control-panel foui-popup-menu');
        this.props.ClassFixed = 'foui-ctrl foui-control-panel foui-popup-menu';
        // Name starts with _ so that it won't be added to the $name lookups.
        /* this.ui.button = this.newControl({ Type: 'CoreButton', Props: { Name: `_${this.props.Name}_button`, ClassFixed: 'foui-button unselectable foui-stretch-y foui-flex-grow', Events: { Click: this.handlers.onClick } } }); // Value: this.props.Value, //
        this.bindControl(this.ui.button, [
            { other: 'Class', local: 'Class' },
            // { other: 'ClassFixed', local: 'ClassFixed' },
            { other: 'Disabled', local: 'Disabled' },
            // { other: 'HTML', local: 'HTML' },
            // { other: 'Text', local: 'Text' },
            { other: 'Tip', local: 'Tip' },
        ]); */

        this.ui.list = UI.create('div', { class: 'foui-popup-menu-list' });
        // this.meta.ui.listPop.append(this.meta.ui.list);
        this.elem.append(this.ui.list);
    }

    attachEvents () {
        // Bind to this control.
        this.handlers.onClick = this.onClick.bind(this);
        // Attach the listener(s).
        UI.on(this.elem, 'click', this.handlers.onClick, true); // mousedown
    }

    detachEvents () {
        UI.off(this.elem, 'click', this.handlers.onClick, true); // mousedown
        delete this.handlers;
    }

    onChange (changes) {
        // console.log('PopupMenu changes', changes);
        for (const [key, v] of changes) {
            const value = Util.checkForBoundedValue(v, this);
            switch (key) {
                /* case 'DesignMode': {
                    if (value) {
                        // UI.attr(this.ui.button.elem, { tabindex: -1, 'class+': 'unclickable' });
                        UI.attr(this.elem, { draggable: true });
                        this.eventsOff();
                    }
                    else {
                        // UI.attr(this.ui.button.elem, { tabindex: 0, 'class-': 'unclickable' });
                        UI.attr(this.elem, { draggable: false });
                        this.eventsOn();
                    }
                    break;
                } */
                // case 'Disabled': UI.attr(this.ui.button, { disabled: v }); break;
                // case 'HTML': UI.attr(this.props.Icon ? this.ui.text : this.ui.button, { html: value }); break;
                case 'Items': this.updateItems(value); break;
                // case 'Text': UI.attr(this.props.Icon ? this.ui.text : this.ui.button, { text: value }); break;
                case 'Value': {
                    this.selectByValue(value);
                    break;
                }
            }
        }
    }

    onClick (evt) {
        const target = UI.hasClass(evt.target, 'foui-list-item')
            ? evt.target
            : evt.target.closest('foui-list-item');
        const item = this.props.Items[+target.dataset.idx];
        this.selectByValue(typeof item === 'object' ? item[this.props.FieldKey || this.props.FieldText] : item);
        /* if (this.props.Toggle) {
            if (this.props.ToggleGroup) {
                // Deselect the other buttons in the group.
                const parentToolbar = this.elem.closest('.foui-toolbar'); // 'ToggleGroup'
                const parentCell = parentToolbar ? null : this.elem.closest('.foui-layout-grid-cell');
                const parentBox = parentToolbar || parentCell;
                const parent = this.FOUI.getControl(parentBox.id);
                const controls = parent.meta.ControlList.filter(o => o.props.ToggleGroup === this.props.ToggleGroup);
                for (const ctrl of controls) {
                    if (ctrl.props.Value) ctrl.props.Value = false;
                }
            }
            if (this.props.Value === 'undefined') this.props.Value = true;
            else this.props.Value = !this.props.Value;
            setImmediate(() => {
                UI.sendEvent(this.elem, 'change', this.props.Name);
            });
        } */
    }

    selectByValue (value) {
        this.removeHighlight();
        const fieldKey = this.props.FieldKey;
        const fieldText = this.props.FieldText;
        const item = this.props.Items.find(o => o[fieldKey] === value || o[fieldText] === value || o === value);
        if (!item) return;
        // const pos = items.indexOf(item);
        // UI.attr(this.ui.label, { text: item.Text === undefined ? item.Key : item.Text });
        // this.meta.ui.input.value = item.Text === undefined ? item.Key : item.Text;
        const el = UI.find(this.ui.list, typeof item === 'object' ? `[data-key="${item[fieldKey]}"` : `[data-key="${value}"`);
        if (el) UI.addClass(el, 'foui-list-item-selected');
    }

    removeHighlight () {
        UI.findAll(this.ui.list, '.foui-list-item-selected').forEach(el => {
            UI.removeClass(el, 'foui-list-item-selected');
        });
    }

    updateItems (value) {
        const fieldKey = this.props.FieldKey;
        const fieldText = this.props.FieldText;
        const items = value || this.props.Items || [];
        /* const filterValue = this.prop('Filterable')
            ? this.meta.ui.input.value === '' ? null : new RegExp(this.meta.ui.input.value, 'gi')
            : null;
        const items = filterValue === null
            ? itemList
            : itemList.filter(o => o.Text.match(filterValue) !== null); // o.Key.startsWith(filterValue) || o.Text.startsWith(filterValue) */
        // Show that there are no properties.
        if (!items.length) {
            this.ui.list.innerHTML = '';
            // const tr = UI.create('tr', { 'data-idx': -1 });
            // const td = UI.create('td', { colSpan: 2, text: 'No properties.' });
            // tr.append(td);
            // this.ui.list.append(tr);
            // this.meta.oldItem = value;
            return;
        }
        // if (!this.meta.oldItem) this.meta.oldItem = value;
        /* // If the item is new, unbind the previous listeners.
        if (value !== this.meta.oldItem) {
            // Clean the grid and watchers.
            const oKeys = Object.keys(this.meta.oldItem);
            for (let i = 0; i < oKeys.length; i++) {
                const key = oKeys[i];
                const input = this.meta.ControlList[key];
                input.props.unbindFrom(value, key, 'Value');
                value.unbindFrom(input.props, 'Value', key);
            }
            this.ui.list.innerHTML = '';
            this.meta.oldItem = value;
            this.ui.noteTitle.textContent = 'Property';
            this.ui.noteDescription.textContent = 'Description';
        } */
        // Create or update the inputs for the items' properties.
        const hasIcons = items.find(o => o.Icon) !== undefined;
        const selected = this.props.Value;
        // const gridWidth = this.elem.offsetWidth;
        for (let i = 0; i < items.length; i++) {
            /*
             Item Types
             ----------
             Separator
             Header
             Label
             (Icon || Check/Option || Radio) + Label + Tag
             Table
             */

            const item = items[i];
            const isObject = typeof item === 'object';
            const key = isObject ? item[fieldKey] : item;
            const text = isObject ? item[fieldText] : item;
            const keyRow = UI.find(this.ui.list, `div[data-idx="${i}"]`);
            if (keyRow) { // If the property row exists, update it.
                console.log('TODO: Popup Key Row update', keyRow);
            }
            else { // Add a new property row.
                // const schema = this.props.Schema[key];
                /* const row = UI.create('div', { class: 'foui-list-item foui-text-truncate', text, 'data-idx': i, 'data-key': key });
                // if (canDrag) UI.attr(label, { draggable: true });
                if (selected === key || selected === text) {
                    UI.addClass(row, 'foui-list-item-selected');
                } */
                //
                switch (item.Type) {
                    case 'Header': {
                        const sep = UI.create('div', { 'data-idx': i, class: 'foui-popup-header', text: item.Text });
                        this.ui.list.append(sep);
                        break;
                    }
                    case 'Label': {
                        // const label = UI.create('div', { 'data-idx': i, class: 'foui-popup-item foui-text-truncate', text: item.Text, 'data-key': item.Key });
                        const holder = UI.create('div', { 'data-idx': i, class: 'foui-popup-item foui-text-truncate', 'data-key': item.Key });
                        if (hasIcons) {
                            const icon = UI.create('div', { class: 'foui-popup-item-icon', html: item.Icon ? Util.getIconString(item.Icon) : '' });
                            holder.append(icon);
                        }
                        const label = UI.create('div', { class: 'foui-popup-item-label', text: item.Text });
                        holder.append(label);
                        if (item.Margins && typeof item.Margins === 'string') {
                            const parts = item.Margins.split(' ');
                            if (parts.length === 1 || parts.length === 2) {
                                for (let oi = 0; oi < parts.length; oi++) {
                                    const part = parts[oi];
                                    parts[oi] = Util.sanitiseNumberUnit(part);
                                }
                                if (parts.length === 1) parts.unshift('0');
                                else {
                                    parts.reverse();
                                    parts.unshift('0');
                                    parts.splice(2, 0, '0')
                                }
                                UI.attr(label, { style: `margin:${parts.join(' ')}` });
                            }
                        }
                        this.ui.list.append(holder);
                        break;
                    }
                    case 'Separator': {
                        const sep = UI.create('div', { 'data-idx': i, class: 'foui-popup-separator' });
                        this.ui.list.append(sep);
                        break;
                    }
                }

                // this.ui.list.append(row);
            }
        }
    }
}

// Control property definitions.
Object.defineProperty(PopupMenu, '_DEF', {
    value: {
        Group: 'Popup & Menu',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Class (Style)', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout panel into design mode.' },
            Disabled: { Default: undefined, Group: 'General', Label: 'Disabled', Type: 'Flag', Note: 'Disables the control.' },
            // HTML: { Default: undefined, Group: 'General', Label: 'HTML', Type: 'Text', Note: 'HTML code as content.' },
            Items: { Default: undefined, Group: 'Data', Label: 'Items', Type: 'Text', Note: 'A list of data items.' },
            // Text: { Default: undefined, Group: 'General', Label: 'Text', Type: 'Text', Note: 'Text value to display.' },
            Tip: { Default: undefined, Group: 'General', Label: 'Tool Tip', Type: 'Any', Note: 'Information related to the control.' },
            // Value: { Default: undefined, Group: 'State', Label: 'Value', Type: 'Flag', Note: 'True/False value state if the button Toggle property is set to true.' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
