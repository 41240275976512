exports.AUTH_PROVIDERS = [
    undefined,
    'sys',
    'auth0'
];

exports.WS_ACTION = {
    BLANK: 0,
    SEND: 1,
    JOIN: 2,
    LEAVE: 3,
    SUBSCRIPTIONS: 4,
    REQUEST: 5,
    PING: 6,
    GREET: 7,
};

exports.WS_ACTION_NAMES = [
    '',
    'Send',
    'Join',
    'Leave',
    'Subscriptions',
    'Request',
    'Ping',
    'Greet',
];

exports.WS_ACTION_ICONS = [
    '',
    '📨️', // Send/Publish
    '💞', // Join/Subscribe
    '💔', // Leave/Unsubscribe
    '📗', // Subscriptions
    '🎫', // Request
    '🏓', // Ping
    '👋', // Greet
];

exports.WS_MSG_TYPE = {
    SOCKETID: 0,
    MESSAGE: 1,
};

exports.BACKLOG_MAX = 10000;

exports.CRUD_FUNCS = [
    { func: 'exists', path: '/:id/exists', method: 'GET' }, // Checks if a record exists by the given id.
    { func: 'find', path: '/', method: 'GET' }, // Finds all records.
    { func: 'findById', path: '/:id', method: 'GET' }, // Finds the record by the given id.
    { func: 'insert', path: '/', method: 'POST' }, // Inserts a new record.
    // { func: 'remove', path: '/', method: 'DELETE' }, // Removes records by the given criteria.
    { func: 'removeById', path: '/:id', method: 'DELETE' }, // Removes a record by the given id.
    // { func: 'update', path: '/', method: 'PATCH' }, // Updates the specific fields of existings record by the given criteria.
    { func: 'updateById', path: '/:id', method: 'PATCH' }, // Updates the specific fields of an existing record by the given id.
    // { func: 'updateAll', path: '/', method: 'PUT' }, // Updates the specific fields of existings record by the given criteria.
    // { func: 'updateAllById', path: '/:id', method: 'PUT' }, // Updates the specific fields of an existing record by the given id.
];

exports.DB_DEFAULT = {
    SELECT_LIMIT: 20
};

exports.HTTP = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
}

exports.QUEUE_STATUS = {
    Creating: 0,
    New: 1,
    Processing: 2,
    Hold: 3,
    Error: 4,
    Done: 5,
};

exports.TABLE = {
    CNF: {},
    LKP: {
        PROVIDER_TYPE: 'lkp.provider_type',
        QUEUE_STATUS: 'lkp.queue_status',
        STATUS: 'lkp.status',
        USER_STATUS: 'lkp.user_status',
    },
    LNK: {
        USER_WORKSPACE: 'lnk.user_workspace',
        WORKSPACE_PROJECT: 'lnk.workspace_project',
    },
    LOG: {
        LOGS: 'log.logs',
    },
    TRN: {
        PROJECT: 'trn.project',
        QUEUE: 'trn.queue',
        WORKSPACE: 'trn.workspace',
    },
    USR: {
        AUTH_PROVIDER: 'usr.auth_provider',
        USER: 'usr.user',
    }
};
