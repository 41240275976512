import Control from './control';
import Util from '../util/util';
import UI from '../util/ui';

export default class Switch extends Control {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'Switch';
        // definition.Props.ClassFixed = 'foui-ctrl foui-control-panel foui-stretch';
        super(definition, Switch._DEF.Properties);
    }

    init () {
        // Setting `this.meta.ClassFixed` only works if `Class` is handled in `onChange`.
        this.setClass('foui-ctrl foui-control-panel foui-stretch foui-switch');
        this.props.ClassFixed = 'foui-ctrl foui-control-panel foui-stretch foui-switch';
        // Name starts with _ so that it won't be added to the $name lookups.
        this.meta._inputId = `_${this.props.Name}_input`;
        this.ui.input = this.newControl({ Type: 'CoreInput', Props: { Name: this.meta._inputId, Type: 'checkbox', ClassFixed: 'foui-switch-noview', Value: this.props.Value } });
        this.bindControl(this.ui.input, [
            { other: 'Value', local: 'Value' },
        ]);
        if (this.props.Value) this.ui.input.checked = true;
        this.ui.track = UI.create('label', { class: 'foui-switch-track', for: this.meta._inputId });
        this.ui.tag = UI.create('label', { class: 'foui-switch-tag', for: this.meta._inputId });
        this.ui.track.append(this.ui.tag);
        this.elem.append(this.ui.track);
        // TODO: Add labels for the True and False text.
        // TODO: Add a flag to display Icon instead of text.
        // this.ui.track = this.newControl({ Type: 'CoreLabel', Props: { Name: `_${this.props.Name}_track`, Text: '', For: this.meta._inputId, ClassFixed: 'foui-switch-track' } });
        // this.ui.tag = this.newControl({ Type: 'CoreLabel', Props: { Name: `_${this.props.Name}_tag`, Text: '', For: this.meta._inputId, ClassFixed: 'foui-switch-tag' } });
        /* this.bindControl(this.ui.label, [
            { other: 'Text', local: 'Text' },
        ]); */
    }

    onChange (changes) {
        for (const [key, v] of changes) {
            const value = Util.checkForBoundedValue(v, this);
            switch (key) {
                case 'Class': this.setClass(value); break;
                /* case 'DesignMode': {
                    if (value) {
                        // UI.attr(this.ui.input, { tabindex: -1, 'class+': 'unclickable' });
                        // UI.attr(this.ui.track, { tabindex: -1, 'class+': 'unclickable' });
                        // UI.attr(this.ui.track, { tabindex: -1, 'class+': 'unclickable' });
                        // this.ui.track.props.For = '';
                        // this.ui.track.props.For = '';
                        UI.attr(this.elem, { draggable: true });
                        this.eventsOff();
                    }
                    else {
                        // UI.attr(this.ui.input, { tabindex: 0, 'class-': 'unclickable' });
                        // UI.attr(this.ui.track, { tabindex: 0, 'class-': 'unclickable' });
                        // UI.attr(this.ui.track, { tabindex: 0, 'class-': 'unclickable' });
                        // this.ui.track.props.For = this.meta._inputId;
                        // this.ui.track.props.For = this.meta._inputId;
                        UI.attr(this.elem, { draggable: false });
                        this.eventsOn();
                    }
                    break;
                } */
            }
        }
    }
}

// Control property definitions.
Object.defineProperty(Switch, '_DEF', {
    value: {
        Group: 'Common Controls',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Style Class', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout panel into design mode.' },
            Disabled: { Default: undefined, Group: 'General', Label: 'Disabled', Type: 'Flag', Note: 'Disables the control.' },
            FalseText: { Default: undefined, Group: 'General', Label: 'True Text', Type: 'Text', Note: 'Text label when the value is false/off.' },
            ReadOnly: { Default: undefined, Group: 'General', Label: 'Read Only', Type: 'Flag', Note: 'Prevents input.' },
            Text: { Default: undefined, Group: 'General', Label: 'Text', Type: 'Text', Note: 'Text value to display.' },
            Tip: { Default: undefined, Group: 'General', Label: 'Tool Tip', Type: 'Any', Note: 'Information related to the control.' },
            TrueText: { Default: undefined, Group: 'General', Label: 'True Text', Type: 'Any', Note: 'Text label when the value is true/on.' },
            Value: { Default: undefined, Group: 'General', Label: 'Value', Type: 'Flag', Note: 'True/False value state.' },
            // Visible: { Default: true, Group: 'State', Label: 'Visible', Type: 'Flag', Note: 'Visible or hidden state of the control.' },
            // TabIndex
            // Margins: { default: '0', group: 'General', label: 'Style', type: 'Text', note: 'Margins outside the control borders.' },
            // Size: { default: 'FitContainer', group: 'General', label: 'Size', type: 'List', items: PropertyConstants.SIZE, note: 'Size behaviour.' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
