// FOUI event names to HTML event names map.
exports.CONTROL_EVENTS = {
    Action: 'action',
    CellClick: 'cell-click',
    CellDblClick: 'cell-dblclick',
    Click: 'click',
    Change: 'change',
    Close: 'close',
    DblClick: 'dblclick',
    Done: 'done',
    DragDrop: 'drop',
    DragEnd: 'dragend',
    DragOver: 'dragover',
    DragStart: 'dragstart',
    LayoutSelect: 'layout-select',
    ManualClose: 'manual-close',
    Select: 'select',
};
