// import { APP_KEY } from "../../demo-views/builder/app-constants";
const NO_VALUEPROPS = ['disabled', 'readonly', 'draggable', 'checked'];

export default class UI {
    static get nsSvg () { return 'http://www.w3.org/2000/svg'; }
    static get nsXLink () { return 'http://www.w3.org/1999/xlink'; }
    static get nsXHtml () { return 'http://www.w3.org/1999/xhtml'; }

    /**
     * Adds a child control to a parent control/element.
     *
     * @param {element} o Control to add.
     * @param {element} parent Parent to add the control to.
     */
    static add (o, parent) {
        if (!o) return;
        const elem = !o.tagName && o.elem ? o.elem : o;
        if (!parent) return;
        (!parent.tagName && parent.elem ? parent.elem : parent).append(elem);
    }

    /**
     * Adds a class to the element.
     *
     * @param {element} o Element to add the class to.
     * @param {string} cls Class name (without preceeding dot). Can pass in many space separated classes.
     */
    static addClass (o, cls) {
        if (!o) return;
        cls = cls || '';
        const elem = !o.tagName && o.elem ? o.elem : o;
        if (elem.classList) {
            if (cls.indexOf(' ') === -1) elem.classList.add(cls);
            else {
                const list = cls.split(' ');
                list.forEach(c => {
                    elem.classList.add(c);
                });
            }
        }
        else elem.className += ` ${cls}`;
    }

    /**
     * Adjust the canvas for pixel density.
     *
     * @param {any} c Canvas element.
     * @param {any} w Width to set.
     * @param {any} h Height to set.
     */
    static adjustCanvasRatio (c, w, h) {
        // Set the attribute size.
        c.setAttribute('width', w * window.devicePixelRatio);
        c.setAttribute('height', h * window.devicePixelRatio);
        // Set the style size.
        c.style.width = `${w}px`;
        c.style.height = `${h}px`;
        // Scale the context for crispness.
        c.getContext('2d').scale(window.devicePixelRatio, window.devicePixelRatio);
    }

    /**
     * Sets attributes on an element.
     * The element can be SVG or HTML on which normal attributes are set.
     * It could also be a class style control, on which properties will be set.
     *
     * @param {Element} o Element to apply the attributes/properties to.
     * @param {Object} props Key/value object that contains properties to set on the element.
     */
    static attr (o, props) {
        if (!o) return;
        const elem = !o.tagName && o.elem ? o.elem : o;
        props = props || {};
        if (typeof props === 'string') return elem.getAttribute(props);
        // Class control.
        if (!elem.tagName) {
            // console.log(elem, props, new Error('Log').stack);
            const ctrl = elem.instance;
            const pks = Object.keys(props);
            const plen = pks.length;
            for (let pi = 0; pi < plen; pi++) {
                const pk = pks[pi];
                if (pk[0] === '_') continue; // Skip underscore properties.
                if (ctrl[pk] !== props[pk]) {
                    ctrl[pk] = props[pk];
                }
            }
            return elem;
        }

        if (props.xmlns) {
            const ns = props.xmlns;
            delete props.xmlns;
            elem.setAttribute('xmlns', ns);
        }

        if (Object.prototype.hasOwnProperty.call(props, 'text')) {
            const isText = props.text !== undefined && props.text !== null;
            if (isText) elem.textContent = props.text;
        }
        if (Object.prototype.hasOwnProperty.call(props, 'html')) {
            const isHtml = props.html !== undefined && props.html !== null;
            if (isHtml) elem.innerHTML = props.html;
        }

        const skipProps = ['html', 'text']; // 'data'
        const keys = Object.keys(props);
        const len = keys.length;
        for (let i = 0; i < len; i++) {
            const key = keys[i];
            if (skipProps.indexOf(key) > -1) continue; // This is a special Watch property for the control itself and not HTML.
            if (props[key] === undefined) {
                elem.removeAttribute(key, props[key]);
                continue;
            }
            if (props[key] !== null && typeof props[key] === 'object') continue;
            if (elem.tagName === 'INPUT' && key === 'value') elem.value = props[key];
            else if (key === 'class+') this.addClass(elem, props[key]);
            else if (key === 'class-') this.removeClass(elem, props[key]);
            else if (key === 'style') this.style(elem, props[key]);
            else if ((NO_VALUEPROPS.indexOf(key) > -1 && !props[key]) || props[key] === undefined) {
                elem.removeAttribute(key, props[key]);
                continue;
            }
            else elem.setAttribute(key, props[key]);
        }

        return elem;
    }

    /**
     * Converts a dashed string to camel case.
     *
     * @param {string} str String to convert.
     */
    static camelCase (str) {
        return str
            .split('-')
            .reduce((a, b) => a + b.charAt(0).toUpperCase() + b.slice(1));
        // str.replace(/[\-_](\w)/g, (match) => match.charAt(1).toUpperCase());
    }

    /**
     * Reads a value from the system clipboard.
     * {@link https://w3c.github.io/clipboard-apis Clipboard}
     *
     * @param {*} valueType Type of value e.g. text/plain | text/csv | text/html etc.
     * @returns Promise
     */
    // NOTE: Firefox has weird stuff https://developer.mozilla.org/en-US/docs/Mozilla/Tech/XPCOM/Using_the_clipboard.
    static clipboardGet (pasteData) {
        return new Promise(resolve => {
            if (pasteData !== undefined && pasteData !== null) { // The data came from a paste event because the browser doesn't support the new `clipboard`.
                resolve(pasteData);
                return;
            }
            if (navigator.clipboard && navigator.clipboard.readText) { // Try the new `clipboard`.
                navigator.clipboard.readText().then(value => {
                    resolve(value);
                }).catch(err => {
                    console.warn('Getting a value from the clipboard failed.', err);
                    resolve('');
                });
            }
            else if (document.queryCommandSupported && document.queryCommandSupported('paste')) { // Otherwise try the `execCommand`.
                var textarea = document.createElement('textarea');
                textarea.textContent = '';
                textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
                document.body.appendChild(textarea);
                textarea.select();
                try {
                    document.execCommand('paste'); // Security exception may be thrown by some browsers.
                    setTimeout(() => resolve(textarea.value), 0);
                }
                catch (ex) {
                    console.warn('Getting a value from the clipboard failed.', ex);
                    setTimeout(() => resolve(''), 0);
                }
                finally {
                    document.body.removeChild(textarea);
                }
            }
            else {
                alert('Your browser is unable to get a value from the clipboard. Please upgrade it to the latest version or use the Chrome, Edge or Firefox browser.');
                setTimeout(() => resolve(''), 0);
            }
        });
    }

    /**
     * Copies the value to the system clipboard.
     * {@link https://w3c.github.io/clipboard-apis Clipboard}
     *
     * @param {*} value Value to copy.
     * @param {*} valueType Type of value e.g. text/plain | text/csv | text/html etc.
     * @returns Clipboard text.
     */
    static clipboardSet (value) {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(value).then(() => {
                //
            }).catch(err => {
                console.warn('Setting a value to the clipboard failed.', err);
            });
        }
        else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
            const textarea = document.createElement('textarea');
            textarea.textContent = value;
            textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand('copy'); // Security exception may be thrown by some browsers.
            }
            catch (ex) {
                console.warn('Setting a value to the clipboard failed.', ex);
                return false;
            }
            finally {
                document.body.removeChild(textarea);
            }
        }
        else alert('Your browser is unable to set a value to the clipboard. Please upgrade it to the latest version or use the Chrome, Edge or Firefox browser.');
    }

    /**
     * Creates an HTML/SVG element.
     * Interesting note. The `.style` property is not available when using `createElementNS`.
     *
     * @param {String} type Type of element to create.
     * @param {Object} props Key/value object that contains properties to set on the element.
     * @param {String} ns Element namespace.
     * @return {string|*|Element}
     */
    static create (type, props, ns = undefined) {
        if (!props) props = {};
        if (props.xmlns) {
            ns = props.xmlns;
            delete props.xmlns;
        }
        const elem = ns ? document.createElementNS(ns, type) : document.createElement(type);
        return this.attr(elem, props);
    }

    static createControl (props) {
        props = props || {};
        if (!props.type) { // Will default to text.
            if (!props.text) props.text = 'Text'; // Check that there is text to display.
        }
        return this.create(props.type || 'text', props);
    }

    static extendCanvasFuncs () {
        /**
         * Draws a rounded rectangle using the current state of the canvas.
         * If you omit the last three params, it will draw a rectangle
         * outline with a 5 pixel border radius.
         *
         * @param {Number} x The top left x coordinate.
         * @param {Number} y The top left y coordinate.
         * @param {Number} width The width of the rectangle.
         * @param {Number} height The height of the rectangle.
         * @param {Object} radius All corner radii. Defaults to 0,0,0,0;
         */
        CanvasRenderingContext2D.prototype.roundRect = function (x, y, width, height, rad) {
            let cr = { TL: rad, TR: rad, BL: rad, BR: rad }; // Corner radius.
            if (typeof rad === 'object') {
                cr = rad;
            }

            this.beginPath();
            this.moveTo(x + cr.TL, y);
            this.lineTo(x + width - cr.TR, y);
            this.quadraticCurveTo(x + width, y, x + width, y + cr.TR);
            this.lineTo(x + width, y + height - cr.BR);
            this.quadraticCurveTo(x + width, y + height, x + width - cr.BR, y + height);
            this.lineTo(x + cr.BL, y + height);
            this.quadraticCurveTo(x, y + height, x, y + height - cr.BL);
            this.lineTo(x, y + cr.TL);
            this.quadraticCurveTo(x, y, x + cr.TL, y);
            this.closePath();
        };
    }

    /**
     * Finds the element by query selector.
     * If anything is found, only the first element is returned, otherwise null.
     *
     * @export
     * @param {element} el Element to search on, otherwise if selector is null, this is the query selector.
     * @param {string} selector The query selector.
     * @returns the first element.
     */
    static find (el, selector) {
        let elems;
        if (!selector) elems = document.querySelectorAll(el);
        else elems = el.querySelectorAll(selector);
        return elems.length ? elems[0] : null;
    }

    /**
     * Finds all the element by query selector.
     *
     * @export
     * @param {element} el Element to search on, otherwise if selector is null, this is the query selector.
     * @param {string} selector The query selector.
     * @returns A list of elements.
     */
    static findAll (el, selector) {
        if (!selector) return document.querySelectorAll(el);
        else return el.querySelectorAll(selector);
    }

    /**
     * Returns the OS name.
     * One of Windows, Macintosh, Linux or Other.
     *
     * @returns Sy=tring
     */
    static getOSType () {
        const agent = navigator.userAgent;
        if (agent.indexOf('Windows') > -1) return 'Windows';
        else if (agent.indexOf('Macintosh')) return 'Macintosh';
        else if (agent.indexOf('Linux')) return 'Linux';
        else return 'Other';
    }

    /**
     * Checks if an element has the cls class name assigned.
     *
     * @param {element} o Element to check.
     * @param {string} cls Class name to check for (without preceeding dot).
     * @returns True if it is found.
     */
    static hasClass (o, cls) {
        if (!o) return;
        const elem = !o.tagName && o.elem ? o.elem : o;
        if (elem.classList) return elem.classList.contains(cls);
        else return new RegExp(`(^| )${cls}( |$)`, 'gi').test(elem.className);
    }

    /**
     * Calculates the element position, taking scroll top into account.
     *
     * @param {element} o Element to calculate on.
     * @returns Object with top and left positions.
     */
    static offset (o) {
        if (!o) return null;
        const elem = !o.tagName && o.elem ? o.elem : o;
        const rect = elem.getBoundingClientRect();

        return {
            top: rect.top + document.body.scrollTop,
            left: rect.left + document.body.scrollLeft
        };
    }

    /**
     * Remove event listener.
     *
     * @param {element} o Element to action on.
     * @param {string} type Event type e.g. "click".
     * @param {function} listener The event listener/handler.
     * @param {boolean} remove Flag indicating to remove events instead of adding them.
     */
    static off (o, type, listener, useCapture = false) {
        if (!o) return;
        const elem = !o.tagName && o.elem ? o.elem : o;
        this.on(elem, type, listener, useCapture, true); // Last param indicates removal.
    }

    /**
     * Add/remove event listener.
     *
     * @param {element} o Element to action on.
     * @param {string} type Event type e.g. "click".
     * @param {function} listener The event listener/handler.
     * @param {boolean} useCapture A boolean value indicating whether events of this type will be dispatched to the registered listener before being dispatched to any EventTarget beneath it in the DOM tree. Events that are bubbling upward through the tree will not trigger a listener designated to use capture.
     * @param {boolean} remove Flag indicating to remove events instead of adding them.
     */
    static on (o, type, listener, useCapture = false, remove = false) {
        if (!o) return;
        const elem = !o.tagName && o.elem ? o.elem : o;
        if (remove === true) elem.removeEventListener(type, listener, useCapture === true);
        else elem.addEventListener(type, listener, useCapture === true);
    }

    /**
     * From MDN - cross browser wheel listener.
     *
     * @param {element} o Element to add the listener to.
     * @param {function} callback Listener/handler.
     * @param {boolean} useCapture A boolean value indicating whether events of this type will be dispatched to the registered listener before being dispatched to any EventTarget beneath it in the DOM tree. Events that are bubbling upward through the tree will not trigger a listener designated to use capture.
     */
    static onMouseWheel (o, callback, useCapture) {
        if (!o) return;
        const elem = !o.tagName && o.elem ? o.elem : o;
        // Detect available wheel event.
        const wheelSupport = 'onwheel' in document.createElement('div')
            ? 'wheel' // Modern browsers support 'wheel'
            : document.onmousewheel !== undefined
                ? 'mousewheel' // Webkit and IE support at least 'mousewheel'
                : 'DOMMouseScroll'; // Let's assume that remaining browsers are older Firefox (MozMousePixelScroll).

        elem.addEventListener(
            wheelSupport,
            wheelSupport === 'wheel'
                ? callback
                : (originalEvent) => {
                    !originalEvent && (originalEvent = window.event);

                    // Create a normalized event object.
                    const event = {
                        // Keep a ref to the original event object.
                        originalEvent: originalEvent,
                        target: originalEvent.target || originalEvent.srcElement,
                        type: 'wheel',
                        deltaMode: originalEvent.type === 'MozMousePixelScroll' ? 0 : 1,
                        deltaX: 0,
                        deltaZ: 0,
                        preventDefault: () => {
                            originalEvent.preventDefault
                                ? originalEvent.preventDefault()
                                : originalEvent.returnValue = false;
                        }
                    };

                    // Calculate deltaY (and deltaX) according to the event.
                    if (wheelSupport === 'mousewheel') {
                        event.deltaY = -1 / 40 * originalEvent.wheelDelta;
                        // Webkit also support wheelDeltaX.
                        originalEvent.wheelDeltaX && (event.deltaX = -1 / 40 * originalEvent.wheelDeltaX);
                    }
                    else {
                        event.deltaY = originalEvent.detail;
                    }

                    // It's time to fire the callback.
                    return callback(event);
                }, useCapture || false);
    }

    /**
     * Calculates the element position.
     *
     * @param {element} o Element to calculate on.
     * @returns Object with top and left positions.
     */
    static position (o) {
        if (!o) return;
        const elem = !o.tagName && o.elem ? o.elem : o;
        return {
            left: elem.offsetLeft,
            top: elem.offsetTop
        };
    }

    /**
     * Calculates the element position and size using client rect.
     *
     * @param {element} o Element to calculate on.
     * @returns DOMRect object with eight properties: left, top, right, bottom, x, y, width, height
     */
    static positionRect (o) {
        if (!o) return;
        const elem = !o.tagName && o.elem ? o.elem : o;
        return elem.getBoundingClientRect();
    }

    /**
     * Removes and element from its parent element.
     *
     * @param {element} o Element to remove.
     */
    static remove (o) {
        if (!o) return;
        const elem = !o.tagName && o.elem ? o.elem : o;
        if (!elem.parentNode) return;
        elem.parentNode.removeChild(elem);
    }

    /**
     * Removes the specified class name from the element.
     *
     * @param {element} o Element to remove it from.
     * @param {string} cls Class name to remove (without preceeding dot).
     */
    static removeClass (o, cls) {
        if (!o) return;
        const elem = !o.tagName && o.elem ? o.elem : o;
        if (elem.classList) elem.classList.remove(cls);
        else elem.className = elem.className.replace(new RegExp(['(^|\\b)', cls.split(' ').join('|'), '(\\b|$)'].join(''), 'gi'), ' ');
    }

    static safeId (v) {
        return v.replace(/[^a-zA-Z 0-9]+/g, '').replace(/\s/g, '_');
    }

    /**
     * Selects eveything inside the element.
     *
     * @param {element} o Element to remove it from.
     */
    static selectAll (o) {
        if (!o) return;
        const elem = !o.tagName && o.elem ? o.elem : o;
        if (window.getSelection && document.createRange) {
            const range = document.createRange();
            range.selectNodeContents(elem);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        }
        else if (document.body.createTextRange) {
            const range = document.body.createTextRange();
            range.moveToElementText(elem);
            range.select();
        }
    }

    static sanitize (str) {
        const map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#x27;',
            '/': '&#x2F;',
            '`': '&grave;',
        };
        const reg = /[&<>"'/`]/ig;
        return str.replace(reg, (match) => (map[match]));
    }

    /**
     * Send a custom event from the provided element.
     *
     * @param {element} o Element to send the event from.
     * @param {string} name Event name.
     * @param {any} data Data to attach to th event.
     */
    static sendEvent (o, name, data) {
        if (!o) return;
        const elem = !o.tagName && o.elem ? o.elem : o;
        const event = new CustomEvent(name, { detail: data });
        elem.dispatchEvent(event);
    }

    /**
     * An object with keys relating to the actual css property, barring the leading --.
     *
     * @param {Object} o Theme variable key/value object.
     */
    static setThemeVariables (o) {
        const root = document.documentElement;
        const keys = Object.keys(o);
        keys.forEach(key => {
            root.style.setProperty(`--${key}`, o[key]);
        });
    }

    static stopEvent (evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    static storeView (view) {
        const FOUI = window[window.FOUI_APP_KEY].FOUI;
        if (Array.isArray(view)) {
            for (const v of view) {
                FOUI.Views[v.design.Name] = v;
            }
        }
        else FOUI.Views[view.design.Name] = view;
    }

    /**
     * Creates HTML elements from the provided string.
     * Wraps it in a SPAN if htmlString does not start and end with < and > respectively.
     *
     * @param {string} htmlString HTML string to convert.
     * @returns HTML DOM elements.
     */
    static stringToElement (htmlString) {
        if (!htmlString.startsWith('<') || !htmlString.endsWith('>')) htmlString = `<span>${htmlString}</span>`;
        const node = document.createElement('div');
        node.innerHTML = htmlString;
        return node.children[0];
    }

    /**
     * Sets styles on an element from a style string e.g. `width:100px;height:100px;`.
     *
     * @param {Element} o Element to apply the styles to.
     * @param {string} styleString Style(s) to apply.
     * @returns HTML DOM element.
     */
    static style (o, styleString) {
        if (!o) return;
        const elem = !o.tagName && o.elem ? o.elem : o;
        const styles = styleString.split(';');
        const len = styles.length;
        for (let i = 0; i < len; i++) {
            const style = styles[i];
            if (style === '') continue;
            const kv = style.trim().split(':');
            if (kv.length !== 2) continue;
            elem.style[this.camelCase(kv[0])] = kv[1];
        }
        return elem;
    }

    /**
     * Triggers and event.
     * If only name, then it is treated as a native event.
     * If data is passed, it is treated as a custom event.
     *
     * @export
     * @param {element} o Element to trigger the event on.
     * @param {string} name Event name e.g. click.
     * @param {any} data Custome event data.
     */
    static trigger (o, name, data) {
        if (!o) return;
        const elem = !o.tagName && o.elem ? o.elem : o;
        let event;
        if (!data) { // Native.
            // For a full list of event types: https://developer.mozilla.org/en-US/docs/Web/API/document.createEvent
            event = document.createEvent('HTMLEvents');
            event.initEvent(name, true, true);
        }
        else { // Custom.
            if (window.CustomEvent) {
                event = new CustomEvent(name, { detail: data, bubbles: true, cancelable: true, composed: false });
            }
            else {
                event = document.createEvent('CustomEvent');
                event.initCustomEvent(name, true, true, data);
            }
        }

        elem.dispatchEvent(event);
    }

    static extendPixi (Graphics) {
        Graphics.prototype.drawTorus = function (x, y, innerRadius, outerRadius, startArc = 0, endArc = Math.PI * 2) {
            if (Math.abs(endArc - startArc) >= Math.PI * 2) {
                return this
                    .drawCircle(x, y, outerRadius)
                    .beginHole()
                    .drawCircle(x, y, innerRadius)
                    .endHole();
            }
            this.finishPoly();
            this
                .arc(x, y, innerRadius, endArc, startArc, true)
                .arc(x, y, outerRadius, startArc, endArc, false)
                .finishPoly();
            return this;
        };
    }
}
