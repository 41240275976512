// import './index.scss';
import 'setimmediate';
import FOUI from './libs/foui/foui.mjs';
import { APP_KEY } from './views/app-constants';
import mainView from './views/Main';
import './service-worker';

// Languages.
import langEn from './language/en.js';
import langAf from './language/af.js';

window.FOUI_APP_KEY = APP_KEY;

FOUI.Util.ready(() => {
    window[APP_KEY] = new FOUI.App('#app', {
        APP_KEY,
        Languages: {
            en: langEn,
            af: langAf,
        },
        Http: {
            Path: 'http://localhost:9020', // 'http://realestate.ff1.co.za/api'
            // Timeout: 1000,
            // Headers: { 'X-Foui': '0.0.1' }
        },
        /* Ws: {
            Path: 'ws://localhost:3000/connect'
        } */
        // wsPath: '',
        StartView: mainView,
    });
    window[APP_KEY].init();
});
