// import { APP_KEY } from "../../demo-views/builder/app-constants";

// const noBaseKey = ['Boxes', 'Controls'];
import Data from './data';
const blankCheckValues = ['', undefined, null];

export default class Util {
    static applyLanguage (baseKey, obj, lang) {
        if (obj === null || obj === undefined) return obj;
        if (lang === undefined) {
            const FOUI = window[window.FOUI_APP_KEY].FOUI;
            lang = FOUI.Languages[FOUI.Lang];
        }
        if (Array.isArray(obj)) {
            obj.forEach((item, i) => {
                if (typeof item === 'object') {
                    obj[i] = Util.applyLanguage(baseKey, item, lang);
                }
                else if (typeof item === 'string' && item[0] === '@') {
                    obj[i] = Util.langText(`${baseKey}.${item.substr(1)}`, lang);
                    // const langKey = item.substr(1);
                    // if (lang.views[baseKey] && lang.views[baseKey][langKey] !== undefined) obj[i] = lang.views[baseKey][langKey];
                    // else if (lang.shared[langKey] !== undefined) obj[i] = lang.shared[langKey];
                }
            });
        }
        else if (typeof obj === 'object') {
            const keys = Object.keys(obj);
            keys.forEach(key => {
                if (typeof obj[key] === 'object') {
                    // const newBase = noBaseKey.indexOf(key) > -1 ? baseKey : `${baseKey}.${key}`;
                    // Util.applyLanguage(newBase, obj[key], lang);
                    Util.applyLanguage(baseKey, obj[key], lang);
                }
                else if (typeof obj[key] === 'string' && obj[key][0] === '@') {
                    // console.log(obj[key], obj, `${baseKey}.${obj[key].substr(1)}`);
                    obj[key] = Util.langText(`${baseKey}.${obj[key].substr(1)}`, lang);
                    // const langKey = obj[key].substr(1);
                    // if (lang.views[baseKey] && lang.views[baseKey][langKey] !== undefined) obj[key] = lang.views[baseKey][langKey];
                    // else if (lang.shared[langKey] !== undefined) obj[key] = lang.shared[langKey];
                }
            });
        }
        else if (typeof obj === 'string' && obj[0] === '@') {
            return Util.langText(`${baseKey}.${obj.substr(1)}`, lang);
            // const langKey = obj.substr(1);
            // if (lang.views[baseKey] && lang.views[baseKey][langKey] !== undefined) return lang.views[baseKey][langKey];
            // else if (lang.shared[langKey] !== undefined) return lang.shared[langKey];
        }
        return obj;
    }

    /**
     * Format the number of bytes into the appropriate human-readable unit.
     *
     * @param {Number} bytes Number of bytes.
     * @param {Number} decimals Decimal places. Not enforced e.g. 7.20 will yield 7.2, but 7.2334 will yield 7.23
     * @returns String representation in bytes unit.
     */
    static byteSize (bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const dm = decimals < 0 ? 0 : decimals;
        const e = Math.floor(Math.log(bytes) / Math.log(1024));
        return `${parseFloat((bytes / Math.pow(1024, e)).toFixed(dm))} ${' KMGTP'.charAt(e)}B`; // parseFloat to strip trailing 0's.
    }

    /**
     * Checks the value for a bound data reference e.g. `:age` and if so, retrieves the value from the control's data object.
     *
     * @param {Any} value Value to check.
     * @param {Object} control FOUI Control instance.
     * @param {Bool} deep Flag to indicate that the check must traverse the entire object (if value is an object) or not.
     * @returns {Any}
     */
    static checkForBoundedValue (value, control, deep = false) {
        // Check if property is mapped to data.
        if (typeof value === 'string' && value.startsWith(':')) {
            if (!control.data) console.warn(`No bound data for core control ${control.props.Name} (${control.meta.type}).`);
            const propName = value.substring(1);
            value = control.data[propName];
        }
        if (deep && typeof value === 'object') {
            if (Array.isArray(value)) {
                for (let i = 0; i < value.length; i++) {
                    value[i] = Util.checkForBoundedValue(value[i], control);
                }
            }
            else {
                const keys = Object.keys(value);
                for (const key of keys) {
                    value[key] = Util.checkForBoundedValue(value[key], control);
                }
            }
        }
        return value;
    }

    /**
     * Enforces that a function not be called again until a certain amount of time has passed without it being called,
     * e.g. execute the function only if 100 milliseconds have passed without it being called.
     * ```
     * const dbFunc = Util.debounce(myFunc, 50);
     * dbFunc(parms);
     * ```
     *
     * @param {Function} func Function to limit.
     * @param {Number} delay Delay in ms.
     * @returns Function
     */
    static debounce (func, delay) {
        let inDebounce;
        return function (...args) {
            const context = this;
            clearTimeout(inDebounce);
            inDebounce = setTimeout(() => func.apply(context, args), delay);
        };
    }

    /**
     * Shallow field comparison between two objects.
     * Returns the changed fields of oUpdated if different from oSource.
     * Overlapping fields from oSource are returned if overlap is true.
     *
     * @param {*} oSource Master object to compare against.
     * @param {*} oUpdated Updated object and values to test.
     * @param {*} overlap If a boolean, indicates that overlapping fields from source must be returned, otherwise it can be the master object fields.
     * @returns Object
     */
    static delta (oSource, oUpdated, overlap) {
        const delta = {};
        if (!oSource || !oUpdated) return null;
        let keys;
        if (typeof overlap === 'object') keys = Object.keys(overlap);
        else if (overlap === true) keys = Object.keys(oSource);
        else keys = Object.keys(oUpdated);
        const len = keys.length;
        if (len === 0) return null;
        keys.forEach(key => { // Check each property.
            if (Array.isArray(oUpdated[key])) {
                if (typeof oUpdated[key][0] === 'object') {
                    delta[key] = oUpdated[key];
                }
                else {
                    if (!this.isArrayEqual(oSource[key], oUpdated[key])) {
                        delta[key] = oUpdated[key];
                    }
                }
            }
            else if (typeof oUpdated[key] === 'object') {
                const deltaSub = this.delta(oSource[key], oUpdated[key]); // Only the changed fields.
                if (deltaSub) {
                    delta[key] = oUpdated[key]; // Value is different. Add to delta.
                }
            }
            else {
                if (oUpdated[key] !== oSource[key]) { // Check strict value.
                    delta[key] = oUpdated[key]; // Value is different. Add to delta.
                }
            }
        });

        if (Object.keys(delta).length === 0) return null; // No changed fields.
        return delta;
    }

    /**
     * Can clone anything by using JSON parse and stringify. Deep copy.
     * Slow but makes a full deep copy.
     *
     * @export
     * @param {any} o Object to duplicate.
     * @returns Duplicated object.
     */
    static duplicate (o) {
        // return o ? JSON.parse(JSON.stringify(o)) : '';
        if (typeof o !== 'object') return o; // Not an object.
        else if (o && o.toISOString) return o; // Date.
        return Array.isArray(o)
            ? (o ? JSON.parse(JSON.stringify(o)) : '')
            : Util.xcopy(o);
    }

    /**
     * Can clone anything by using JSON parse and stringify. Deep copy.
     * Slow but makes a full deep copy.
     * Similar to duplicate but reinstates all functions.
     *
     * @export
     * @param {any} o Object to duplicate.
     * @returns Duplicated object.
     */
    static xcopy (o, o2) {
        if (o === undefined || o === null) return;
        if (o.toISOString) return o; // Date.
        if (!o2) o2 = {};
        const keys = Object.keys(o);
        for (const key of keys) {
            switch (typeof o[key]) {
                case 'object':
                    if (Array.isArray(o[key])) {
                        o2[key] = [];
                        for (const item of o[key]) {
                            switch (typeof item) {
                                case 'object': o2[key].push(Util.xcopy(item)); break;
                                default: o2[key].push(item); break;
                            }
                        }
                    }
                    else if (o2.toISOString) o2[key] = o[key]; // Date.
                    else o2[key] = Util.xcopy(o[key]);
                    break;
                default:
                    o2[key] = o[key];
                    break;
            }
        }
        return o2;
    }

    /**
     * Example: extend({}, objA, objB);
     *
     * @export
     * @param {any} out Extended object.
     * @returns Extended object.
     */
    static extend (...args) {
        const out = args[0] || {};
        for (let i = 1; i < args.length; i++) {
            if (!args[i]) continue;
            const keys = Object.keys(args[i]);
            for (let ki = 0; ki < keys.length; ki++) {
                const key = keys[ki];
                out[key] = args[i][key];
            }
        }
        return out;
    }

    /**
     * Checks the class string for add and remove of class names.
     * Can be "+selected -highlight" indicating one is added and the other removed.
     *
     * @param {String} classNames Class names separated by space.
     * @param {Object} obj Object to set the result changes on as `class+` and `class-`.
     */
    static getClassChanges (classNames, obj) {
        const parts = classNames.split(' ');
        for (const cls of parts) {
            if (cls[0] === '-') {
                if (!obj['class-']) obj['class-'] = [];
                obj['class-'].push(cls.substring(1));
                continue;
            }
            if (!obj['class+']) obj['class+'] = [];
            obj['class+'].push(cls[0] === '+' ? cls.substring(1) : cls);
        }
    }

    /**
     * Gets the properties that match between the local control's properties object and the bound data object.
     *
     * @param props
     * @param propertiesDef
     * @param boundData
     * @returns {{Bind: Map<any, any>, HtmlAttrs: {}, Props: {}, Attr: Map<any, any>}}
     */
    static getControlProperties (props, propertiesDef, boundData, boundCode, ctx) {
        const bindAttr = new Map(); // Control property mapped to an HTML attribute.
        const bindList = new Map(); // Control property bound to external data object.
        const htmlAttrs = {};
        const initProps = {};
        const keys = Object.keys(propertiesDef);
        // console.log('-> getControlProperties ---');
        for (const p of keys) {
            if (props[p] !== undefined) { // Property value provided by instance.
                const v = props[p];
                if (typeof v === 'string') {
                    if (v.startsWith(':') && !v.startsWith('::')) { // Escape means the literal : must be used.
                        if (!boundData) console.warn(`No bound data for control ${props.Name} (${props.meta.type}).`);
                        const propName = v.substring(1);
                        // console.log(propName);
                        // if (boundData[propName] === undefined) console.warn();
                        initProps[p] = Data.objGetProp(boundData, propName); // boundData[propName];
                        bindList.set(p, propName); // local, other
                    }
                    else if (v.startsWith('@') && !v.startsWith('@@')) { // Escape means the literal @ must be used.
                        if (!boundCode) console.warn(`No bound code for control ${props.Name} (${props.meta.type}).`);
                        const funcName = v.substring(1);
                        initProps[p] = boundCode[funcName].bind(ctx);
                    }
                    else {
                        if (v.startsWith('::') || v.startsWith('@@')) initProps[p] = v.substring(1);
                        else initProps[p] = v;
                        // if (p === 'Class') console.log('UTIL', Data.objGetProp(boundData, p), p, boundData);
                    }
                }
                /* if (typeof v === 'string' && v.startsWith(':') && !v.startsWith('::')) { // Escape means the literal : must be used.
                    if (!boundData) console.warn(`No bound data for control ${props.Name} (${props.meta.type}).`);
                    const propName = v.substring(1);
                    // console.log(propName);
                    // if (boundData[propName] === undefined) console.warn();
                    initProps[p] = Data.objGetProp(boundData, propName); // boundData[propName];
                    bindList.set(p, propName); // local, other
                }
                else if (typeof v === 'string' && v.startsWith('@') && !v.startsWith('@@')) { // Escape means the literal @ must be used.
                    if (!boundCode) console.warn(`No bound code for control ${props.Name} (${props.meta.type}).`);
                    const funcName = v.substring(1);
                    initProps[p] = boundCode[funcName].bind(ctx);
                } */
                else {
                    initProps[p] = v;
                    // if (p === 'Class') console.log('UTIL', Data.objGetProp(boundData, p), p, boundData);
                }
                // bindList.set(p, propName); // local, other
            }
            else { // Not provided. Use `default` from the control definition.
                if (p === 'Name') {
                    initProps[p] = propertiesDef[p].Default || props.Name;
                    continue;
                }
                if (propertiesDef[p].Default !== undefined) {
                    initProps[p] = typeof propertiesDef[p].Default === 'object'
                        ? Util.duplicate(propertiesDef[p].Default)
                        : propertiesDef[p].Default;
                }
            }
            // Set the property to html attribute mapping and initial value.
            if (propertiesDef[p].Attr) {
                bindAttr.set(p, propertiesDef[p].Attr); // local, attr
                htmlAttrs[propertiesDef[p].Attr] = initProps[p];
            }
        }
        // console.log('<--------------------------');
        return { Props: initProps, Bind: bindList, Attr: bindAttr, HtmlAttrs: htmlAttrs };
    }

    /**
     * Gets the placeholder icon string.
     *
     * @return {string}
     */
    static getIconPlaceholderString () {
        const FOUI = window[window.FOUI_APP_KEY].FOUI;
        return FOUI.Themes[FOUI.ThemeKey].svg.icon.Placeholder;
    }

    /**
     * Gets the specified theme icon string (SVG string).
     *
     * @param {string} key Key to retrieve the icon for.
     * @return {string}
     */
    static getIconString (key) {
        if (typeof key !== 'string') return '';
        if (key.indexOf('.') === -1) key = `icon.${key}`;
        const FOUI = window[window.FOUI_APP_KEY].FOUI;
        const svg = FOUI.Themes[FOUI.ThemeKey].svg;
        const value = key.split('.').reduce((accumulator, currentValue) => {
            return accumulator[currentValue];
        }, svg);
        return value;
    }

    /**
     * Returns the unit of the value as either % or px.
     *
     * @param {any} value Value to check. Can be string or number.
     * @return {string} % or px
     */
    static getValueUnit (value) {
        return typeof value === 'number'
            ? 'px'
            : value.indexOf('%') > -1 ? '%' : 'px';
    }

    /**
     * Returns error info by checking if the exception is an Http (Axios)
     * response error or a normal exception.
     *
     * @param {Error} ex Error object or an error message.
     * @returns New error info.
     */
    static handleError (ex, title, noAlert) {
        const msg = typeof ex === 'object'
            ? ex.response ? ex.response.data.message || ex.response.data.msg : ex.message
            : ex;
        // TODO: Do lang lookup. Also check for lang variables e.g. @error
        // TODO: Log to service.
        const err = {
            Title: title || 'Error',
            Message: msg
        };
        if (!noAlert) alert(err.Message, err.Title, 'Error');
        return err;
    }

    static encodeSvg (svgString) {
        return svgString.replace('<svg', (~svgString.indexOf('xmlns') ? '<svg' : '<svg xmlns="http://www.w3.org/2000/svg"'))
            .replace(/"/g, '\'')
            .replace(/%/g, '%25')
            .replace(/#/g, '%23')
            .replace(/{/g, '%7B')
            .replace(/}/g, '%7D')
            .replace(/</g, '%3C')
            .replace(/>/g, '%3E')
            .replace(/\s+/g, ' ');
        // The maybe list (add on documented fail)
        // .replace(/&/g, '%26')
        // .replace('|', '%7C')
        // .replace('[', '%5B')
        // .replace(']', '%5D')
        // .replace('^', '%5E')
        // .replace('`', '%60')
        // .replace(';', '%3B')
        // .replace('?', '%3F')
        // .replace(':', '%3A')
        // .replace('@', '%40')
        // .replace('=', '%3D');
    }

    /**
     * Gets value by key from localStorage.
     *
     * @param {*} key Key to retrieve value from localStorage.
     */
    static getLS (key) {
        const value = localStorage.getItem(key);
        if (!value) return value;
        try {
            return JSON.parse(value);
        }
        catch {
            return value;
        }
    }

    /**
     * Gets the escaped character for a key such as newline (\n) for Enter.
     *
     * @param {string} key Key name from keydown e.g. Enter.
     */
    static getKeyEscapeChar (key) {
        switch (key) {
            case 'Enter': return '\n';
            // case 'Tab': return '\t';
        }
    }

    static gridUnitToElem (size, count) {
        if (size === 'auto') return '25px';
        if (size.endsWith('fr')) return (parseInt(size) / count) * 100;
        return size;
    }

    /**
     * Checks if the given value represents a float. "3.3" returns true.
     *
     * @export
     * @param {any} v The value to check.
     * @returns Boolean.
     */
    static isFloat (v) {
        const p = Number.parseFloat(v);
        return !Number.isNaN(p) && !Number.isInteger(p);
    }

    /**
     * Checks if the given value is blank (""), undefined or null.
     *
     * @param {any} value Value to check.
     * @return {boolean}
     */
    static isNullOrEmpty (value) {
        return blankCheckValues.indexOf(value) > -1;
    }

    static isValidDate (dt) {
        return dt && Object.prototype.toString.call(dt) === '[object Date]' && !isNaN(dt);
    }

    /**
     * Get the text by dictionary key from the loaded language pack.
     *
     * @param {String} key Message key.
     * @param {Object} lang Optional language pack.
     * @returns String
     */
    static langText (key, lang) {
        if (lang === undefined) {
            const FOUI = window[window.FOUI_APP_KEY].FOUI;
            lang = FOUI.Languages[FOUI.Lang];
        }
        const keys = key.split('.');
        const len = keys.length - 1;
        let startIdx = 1;
        // Check if the values is in the `views` group.
        // Get the group to look in. The view name or `shared`.
        let group = null;
        if (keys[0] === 'views' || keys[0] === 'shared') { // If one of these are supplied, skip over.
            group = lang.views[keys[1]] === undefined ? lang.shared[keys[1]] : lang.views[keys[1]];
            if (len === 2) return group;
            startIdx = 2;
        }
        else group = lang.views[keys[0]];
        // If not, check if the value is in the `shared` group.
        if (group === undefined || group[keys[startIdx]] === undefined) group = lang.shared; // First value.
        // if (v === undefined) v = lang.shared[keys[1]]; // Second value.
        let v;
        if (group !== undefined && len > 0) {
            for (let i = startIdx; i <= len; i++) {
                const k = keys[i];
                v = group[k];
                if (v === undefined) return v; // Not found.
                group = v;
            }
        }
        return v; // Reching here means the loop found each `tree` entry.
    }

    /**
     * Converts and object to string with fields sorted ascending.
     *
     * @param {Object} o Object to convert.
     * @returns String
     */
    static makeValueString (o) {
        const data = [];
        const keys = Object.keys(o).sort();
        for (let i = 0; i < keys.length; i++) {
            data.push(o[keys[i]]);
        }
        return data.join('');
    }

    /**
     * Creates a map of the provided object.
     * Used when creating `changes` data for events.
     *
     * @param {Object} o Object to use.
     * @returns {Map<any, any>}
     */
    static objectToMap (o) {
        const changes = new Map();
        const keys = Object.keys(o);
        for (const key of keys) {
            if (key === 'Name') continue;
            changes.set(key, o[key]);
        }
        return changes;
    }

    /**
     * Count the number of occurrences of one string inside another.
     *
     * @param {String} strOf String to check for.
     * @param {String} strIn String to check in.
     */
    static occurrences (strOf, strIn) {
        return strIn.split(strOf).length - 1;
    }

    static prepDef (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        if (!definition.Data) definition.Data = {};
        return definition;
    }

    static randomInteger (min, max) {
        return ~~(Math.random() * (max - min + 1)) + min;
    }

    /**
     * Waits for the document to load then fires the callback function.
     *
     * @param {Function} cb Callback function to fire once the document is loaded.
     */
    static ready (cb) {
        if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') cb();
        else document.addEventListener('DOMContentLoaded', cb);
    }

    static rgba2hex (rgba) {
        const parts = rgba.substr(5).split(')')[0].split(',');
        /* for (const R in parts) {
            const r = parts[R];
            if (r.indexOf('%') > -1) {
                const p = r.substr(0, r.length - 1) / 100;

                if (R < 3) {
                    parts[R] = Math.round(p * 255);
                }
                else {
                    parts[R] = p;
                }
            }
        } */
        let r = (+parts[0]).toString(16);
        let g = (+parts[1]).toString(16);
        let b = (+parts[2]).toString(16);
        // let a = Math.round(+parts[3] * 255).toString(16);

        if (r.length === 1) r = '0' + r;
        if (g.length === 1) g = '0' + g;
        if (b.length === 1) b = '0' + b;
        // if (a.length === 1) a = '0' + a;

        // return '#' + r + g + b + a;
        return '#' + r + g + b;
    }

    static sanitiseNumberUnit (o) {
        const os = `${o}`;
        const num = parseInt(os.replace(/[^0-9]/g, ''), 10); // Strip all but numbers.
        if (os.indexOf('px') > -1) return `${num}px`;
        else if (os.indexOf('%') > -1) return `${num}%`;
        else return '0';
    }

    /**
     * Send a custom event from the provided element.
     *
     * @param {Element} el Element to send the event from.
     * @param {string} name Event name.
     * @param {any} data Data to attach to the event.
     */
    static sendEvent (el, name, data) {
        if (!el) return;
        const event = new CustomEvent(name, { detail: data });
        el.dispatchEvent(event);
    }

    /**
     * Sets a value by key in localStorage.
     *
     * @param {*} key Key to set value for in localStorage.
     * @param {*} value Value to store. May be an object.
     */
    static setLS (key, value) {
        if (!key || !value) return;
        // Objects must be stringified.
        if (typeof value === 'object') value = JSON.stringify(value);
        localStorage.setItem(key, value);
    }

    /**
     * Move the coordinates of an object by the specified amounts in x and y.
     *
     * @param {Object} co Coords object.
     * @param {Number} x Amount to shift x by.
     * @param {Number} y Amount to move y by.
     */
    static shiftCoords (co, x, y, w, h) {
        if (x) {
            if (co.x !== undefined) co.x += x;
            if (co.x1 !== undefined) co.x1 += x;
            if (co.x2 !== undefined) co.x2 += x;
        }
        if (y) {
            if (co.y !== undefined) co.y += y;
            if (co.y1 !== undefined) co.y1 += y;
            if (co.y2 !== undefined) co.y2 += y;
        }
        if (w && co.w !== undefined) co.w += w;
        if (h && co.h !== undefined) co.h += h;
    }

    /**
     * Natural sorter that sorts any object array by field, ascending or descending and case insensitive.
     *
     * @example
     * // Create a sort function by specifying the field to sort on.
     * const sorter = Util.sorter('Location');
     * // Use the standard array sort with the created sorter function.
     * this.props.Cells.sort(sorter);
     *
     * @param {*} field Field to sort on.
     * @param {*} asc True for ascending.
     * @returns Function.
     */
    static sorter (field, asc = true) {
        return function (ao, bo) {
            const a = asc ? ao : bo;
            const b = asc ? bo : ao;
            // Undefined and null take preference 1.
            if ((a[field] === undefined || a[field] === null) && (b[field] !== undefined || b[field] !== null)) return -1;
            if ((a[field] !== undefined || a[field] !== null) && (b[field] === undefined || b[field] === null)) return 1;
            // Numbers take preference 2.
            if (!isNaN(a[field]) && isNaN(b[field])) return -1;
            if (isNaN(a[field]) && !isNaN(b[field])) return 1;
            if (!isNaN(a[field]) && !isNaN(b[field])) return a[field] - b[field];
            // Same type takes preference 3.
            return `${a[field] || ''}`.toLocaleLowerCase().localeCompare(`${b[field] || ''}`.toLocaleLowerCase());
        };
    }

    /**
     * Separates a string by the given separator, but retains the separator in the result.
     *
     * @param {String} str Value to split.
     * @param {String} separator Separator to split by.
     * @param {String} method Method.
     */
    static splitAndKeep (str, separator, method = 'seperate') {
        function splitAndKeep (str, separator, method = 'seperate') {
            if (method === 'seperate') {
                str = str.split(new RegExp(`(${separator})`, 'g'));
            }
            else if (method === 'infront') {
                str = str.split(new RegExp(`(?=${separator})`, 'g'));
            }
            else if (method === 'behind') {
                str = str.split(new RegExp(`(.*?${separator})`, 'g'));
                str = str.filter(el => el !== '');
            }
            return str;
        }
        if (Array.isArray(separator)) {
            let parts = splitAndKeep(str, separator[0], method);
            for (let i = 1; i < separator.length; i++) {
                const partsTemp = parts;
                parts = [];
                for (var p = 0; p < partsTemp.length; p++) {
                    parts = parts.concat(splitAndKeep(partsTemp[p], separator[i], method));
                }
            }
            return parts;
        }
        else {
            return splitAndKeep(str, separator, method);
        }
    }

    /**
     * Template strings.
     *
     * let t1Closure = template`${0}${1}${0}!`;
     * t1Closure('Y', 'A');
     *
     * let t2Closure = template`${0} ${'foo'}!`;
     * t2Closure('Hello', {foo: 'World'}); // "Hello World!"
     *
     * let t3Closure = template`I'm ${'name'}. I'm almost ${'age'} years old.`;
     * t3Closure({name: 'MDN', age: 30}); //"I'm MDN. I'm almost 30 years old."
     *
     * @param {any} strings Values
     * @param  {...any} keys Keys
     */
    static template (strings, ...keys) {
        return function (...values) {
            const dict = values[values.length - 1] || {};
            const result = [strings[0]];
            keys.forEach(function (key, i) {
                const value = Number.isInteger(key) ? values[key] : dict[key];
                result.push(value, strings[i + 1]);
            });
            return result.join('');
        };
    }

    /**
     * Limit the amount a function is invoked.
     * Enforces a maximum number of times a function can be called over time,
     * e.g. execute the function at most once every n milliseconds.
     *
     * @param {Function} func Function to limit.
     * @param {Number} limit Time limit in ms.
     * @returns Function
     */
    static throttle (func, limit) {
        let lastFunc;
        let lastRan;
        return function (...args) {
            const context = this;
            if (!lastRan) {
                func.apply(context, args);
                lastRan = Date.now();
            }
            else {
                clearTimeout(lastFunc);
                lastFunc = setTimeout(() => {
                    if ((Date.now() - lastRan) >= limit) {
                        func.apply(context, args);
                        lastRan = Date.now();
                    }
                }, limit - (Date.now() - lastRan));
            }
        };
    }

    static toTitleCase (str) {
        return str.replace(/\b\w+/g, s => s.charAt(0).toUpperCase() + s.substr(1).toLowerCase());
    }

    static trunc (num) {
        return (~~(num * 100) / 100);
    }
}
