import UI from '../../util/ui';
import Util from '../../util/util';
import Watch from '../../util/watch';
import { CONTROL_EVENTS } from '../../constants/mapped-events';
import { customAlphabet } from 'nanoid';

const nanoid = customAlphabet('1234567890abcdef', 10);

export default class CoreBase {
    /**
     * Creates an instance of a control.
     * Core controls extend this base class.
     *
     * @param {object} definition Control definition.
     * @param {object} propertiesDef Control properties definition.
     * @memberof Base
     */
    constructor (definition, propertiesDef) {
        let autoName = false;
        // Properties.
        const props = definition.Props || { Tag: 'div' };
        if (!props.Name) {
            autoName = true;
            props.Name = `_FO${nanoid()}`;
        }
        this.meta = {
            type: definition.Type,
            _props: props
        };

        // A quick access ref for controls by name.
        if (!this.$name && definition.$name) this.$name = definition.$name;

        if (definition.CodeBindThis) {
            this.code = {};
            const keys = Object.keys(definition.Code);
            keys.forEach(key => {
                if (definition.Code[key] && definition.Code[key].bind) {
                    this.code[key] = definition.Code[key].bind(this);
                }
            });
        }
        else {
            this.code = definition.Code || {};
        }

        this.handlers = {
            listenProperties: this.listenProperties.bind(this),
        };

        this.data = definition.Data;

        const mapped = Util.getControlProperties(props, propertiesDef, this.data, this.code, this);
        this.bindAttr = mapped.Attr; // Control properties bound to html element attributes.

        this.props = Watch(mapped.Props, props.Name);
        this.props.listen(this.handlers.listenProperties);

        // if (props.Tag === 'input' && mapped.HtmlAttrs.type === 'checkbox') console.log(props.Tag, mapped.HtmlAttrs);
        for (const [propLocal, propBound] of mapped.Bind) {
            this.props.bindTo(this.data, propBound, propLocal);
            this.data.bindTo(this.props, propLocal, propBound);
        }

        this.elem = UI.create(props.Tag, mapped.HtmlAttrs);

        // Add the element to the parent.
        this.parent = definition.Parent;
        if (this.parent) {
            UI.add(this.elem, this.parent);
            /* this.meta.inDesigner = this.elem.closest('.foui-designer') !== null;
            if (this.meta.inDesigner) {
                if (specialControls.indexOf(this.meta.controlType) === -1) { // Special controls cannot be interacted with in design view.
                    // draggable="true" ondragstart
                    UI.attr(this.elem, { draggable: true });
                }
            } */
        }

        // Call the extended control's initialiser.
        if (this.init) this.init();
        // Handle events differently when in design view.
        // if (this.meta.inDesigner && specialControls.indexOf(this.meta.controlType) === -1) { // Special controls cannot be interacted with in design view.
        /* if (this.inDesigner) {
            // this.handlers.onDesignerClick = this.onDesignerClick.bind(this);
            // this.handlers.onDesignerDragStart = this.onDesignerDragStart.bind(this);
            // UI.on(this.elem, 'click', this.handlers.onDesignerClick, false);
            // UI.on(this.elem, 'dragstart', this.handlers.onDesignerDragStart, true);
        } */
        // else { // Otherwise normal setup.
        if (this.attachEvents) this.attachEvents();
        // this.mapEvents(props);
        this.eventsOn(); // Register control events.
        // if (this.props.Class !== 'foui-ctrl-design-cover')
        // }

        if (!autoName && this.$name && !props.Name.startsWith('_')) { // If the control is named and there is a control reference object, add it for easy access on a View.
            this.$name[props.Name] = this;
        }

        // if (this.meta.ClassFixed && this.props.Class === undefined) this.props.Class = ''; // If no class, set to blank so that `ClassFixed` is actioned on init.
        this.listenProperties(Util.objectToMap(this.props));
    }

    /**
     * Handles any changes that happen on the local properties.
     * This also updates the relevant HTML attribute, if specified in `propertiesDef`.
     *
     * @param {Map} changes A Map of recent changes.
     */
    listenProperties (changes) {
        // Check if there are changes for mapped attributes.
        // Create an attribute object with all the updates.
        const oVal = {};
        let count = 0;
        for (const [prop, value] of changes) {
            if (this.bindAttr.has(prop)) {
                oVal[this.bindAttr.get(prop)] = value;
                count += 1;
            }
        }
        if (count) { // There are changes.
            // If `class` is part of the changes and `ClassFixed` is set, combine the two.
            if (oVal.class !== undefined && this.props.ClassFixed !== undefined) oVal.class = `${this.props.ClassFixed} ${oVal.class}`;
            // Do the actual update.
            UI.attr(this.elem, oVal);
        }
        // Fire a change event if the extended control is listening for it.
        if (this.onChange) this.onChange(changes);
    }

    /**
     * Attach listeners to the specified event handler.
     */
    eventsOn () {
        // Register events if there are any mapped events from the properties.
        if (!this.meta._props.Events) return;
        const evts = this.meta._props.Events;
        const keys = Object.keys(evts);
        for (const key of keys) {
            if (!CONTROL_EVENTS[key]) return;
            UI.on(this.elem, CONTROL_EVENTS[key], typeof evts[key] === 'function' ? evts[key] : this.code[evts[key]], false);
        }
    }

    /**
     * Detach listeners from the specified event handler.
     */
    eventsOff () {
        // Deregister events if there are any mapped events from the properties.
        if (!this.meta._props.Events) return;
        const evts = this.meta._props.Events;
        const keys = Object.keys(evts);
        for (const key of keys) {
            if (!CONTROL_EVENTS[key]) return;
            UI.off(this.elem, CONTROL_EVENTS[key], typeof evts[key] === 'function' ? evts[key] : this.code[evts[key]], false);
        }
    }

    /**
     * Adds a class to the control.
     *
     * @param {String} cls Class name to add.
     */
    addClass (cls) {
        UI.addClass(this.elem, cls);
    }

    /**
     * Removes a class from the control.
     *
     * @param {string} cls Class name to remoe.
     */
    removeClass (cls) {
        UI.removeClass(this.elem, cls);
    }

    /**
     * Sets the class names of the control. Full overwrite.
     *
     * @param {Any} value Class name(s) to set.
     */
    setClass (value) {
        const cls = this.props.ClassFixed === undefined ? value : `${this.props.ClassFixed.trim()} ${value || ''}`;
        UI.attr(this.elem, { class: cls });
    }
}
