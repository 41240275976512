import Control from './control';
import UI from '../util/ui';
import Util from '../util/util';
import { CONTROL_EVENTS } from '../constants/mapped-events';

export default class LayoutGrid extends Control {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'LayoutGrid';
        definition.Props.ClassFixed = 'foui-layout-grid';
        super(definition, LayoutGrid._DEF.Properties);
    }

    init () {
        // this.meta.ClassFixed = 'foui-layout-grid';
        // this.addClass('foui-layout-grid');
        this.meta.ControlList = [];
        this.meta.CellCache = { cells: [] };
        this.meta.Selected = [];

        this.meta.DragImage = new Image();
        // img.src = `data:image/svg+xml;utf8,${Util.getIconString('Realestate')}`;
        let iconString = Util.getIconString('Block');
        // The icon cannot have 100% width/height. Set a fixed size.
        if (iconString.indexOf('width="100%"') > -1) iconString = iconString.replace('width="100%"', 'width="20px"');
        if (iconString.indexOf('height="100%"') > -1) iconString = iconString.replace('height="100%"', 'height="20px"');
        this.meta.DragImage.src = `data:image/svg+xml,${Util.encodeSvg(iconString)}`; // Design
        // img.src = 'https://icons.iconarchive.com/icons/iconsmind/outline/512/Drag-Right-icon.png';
    }

    attachEvents () {
        this.handlers.onClick = this.onClick.bind(this);
        // this.handlers.onMouseDown = this.onMouseDown.bind(this);
        // Drag & Drop events.
        this.handlers.onDragStart = this.onDragStart.bind(this);
        this.handlers.onDragOver = this.onDragOver.bind(this);
        this.handlers.onDragDrop = this.onDragDrop.bind(this);
        this.handlers.onDragEnter = this.onDragEnter.bind(this);
        this.handlers.onDragLeave = this.onDragLeave.bind(this);
        // Sizer events.
        this.handlers.onSizerDown = this.onSizerDown.bind(this);
        this.handlers.onSizerMove = this.onSizerMove.bind(this);
        this.handlers.onSizerUp = this.onSizerUp.bind(this);

        UI.on(this.elem, 'click', this.handlers.onClick);
        // UI.on(this.elem, 'mousedown', this.handlers.onMouseDown);
        // Drag & Drop events.
        UI.on(this.elem, 'dragstart', this.handlers.onDragStart, true);
        UI.on(this.elem, 'dragover', this.handlers.onDragOver, true);
        UI.on(this.elem, 'drop', this.handlers.onDragDrop, true);
        UI.on(this.elem, 'dragenter', this.handlers.onDragEnter, true);
        UI.on(this.elem, 'dragleave', this.handlers.onDragLeave, true);
    }

    detachEvents () {
        UI.off(this.elem, 'click', this.handlers.onClick);
        // UI.off(this.elem, 'mousedown', this.handlers.onMouseDown);
        // Drag & Drop events.
        UI.off(this.elem, 'dragstart', this.handlers.onDragStart, true);
        UI.off(this.elem, 'dragover', this.handlers.onDragOver, true);
        UI.off(this.elem, 'drop', this.handlers.onDragDrop, true);
        UI.off(this.elem, 'dragenter', this.handlers.onDragEnter, true);
        UI.off(this.elem, 'dragleave', this.handlers.onDragLeave, true);
        delete this.handlers;
    }

    onChange (changes) {
        // console.log('LayoutGrid changes', changes);
        for (const [key, v] of changes) {
            const value = Util.checkForBoundedValue(v, this);
            switch (key) {
                case 'Cells': {
                    // console.log(JSON.stringify(this.props.Cells, null, 4));
                    /* const cls = this.meta.ClassFixed === undefined ? value : `${this.meta.ClassFixed.trim()} ${value || ''}`;
                    UI.attr(this.elem, { class: cls }); */
                    // console.log(Util.delta(this.meta.CellCache, { cells: value }));

                    this.meta.ControlList = [];

                    // If in Design mode, add all cells.
                    // TODO: Cater for `repeat()`
                    if (this.props.DesignMode) {
                        const rows = this.props.Rows.split(' ');
                        const cols = this.props.Columns.split(' ');
                        const hList = []; // Row heights.
                        const wList = []; // Column widths.
                        for (const r of rows) {
                            hList.push(Util.gridUnitToElem(r, rows.length));
                        }
                        for (const c of cols) {
                            wList.push(Util.gridUnitToElem(c, cols.length));
                        }
                        // Rows.
                        hList.forEach((h, r) => {
                            // Columns.
                            wList.forEach((w, c) => {
                                // Container cells.
                                const loc = `${r + 1} / ${c + 1}`;
                                // const hasDef = this.prop('Boxes').find(o => o.Location && o.Location.startsWith(loc));
                                // if (!hasDef) this.boxes.push(new Controls.LayoutGridBox({ Props: { Location: loc, Controls: [] }, parent: this.elem, parentControl: this, data: this.data, codeHandlers: this.codeHandlers }));
                                const cell = this.newControl({ Type: 'LayoutGridCell', Props: { Location: loc, Controls: [] } });
                                this.meta.ControlList.push(cell);
                            });
                        });
                    }

                    if (value && value.length) {
                        for (const def of value) {
                            const control = this.props.DesignMode ? def : Util.duplicate(def);
                            if (this.props.DesignMode) control.DesignMode = true;
                            const ctrl = this.newControl({ Type: 'LayoutGridCell', Props: control });
                            this.meta.ControlList.push(ctrl);
                        }
                    }
                    break;
                }
                case 'Class': this.setClass(value); break;
                case 'Columns': this.setStyle('grid-template-columns', value); break;
                case 'DesignMode': {
                    if (value) this.addClass('foui-design-mode');
                    else this.removeClass('foui-design-mode');
                    for (const cell of this.meta.ControlList) {
                        cell.props.DesignMode = value;
                        for (const ctrl of cell.meta.ControlList) {
                            // ctrl.props.Disabled = true;
                            UI.attr(ctrl.elem, { tabindex: 0 });
                            // if (ctrl.meta.type === 'Text') ctrl.props.ReadOnly = true;
                        }
                    }
                    break;
                }
                case 'GapCols':
                case 'GapRows': {
                    if (this.props.GapCols === this.props.GapRows) this.setStyle('gap', `${value}px`);
                    else this.setStyle('gap', `${this.props.GapRows}px ${this.props.GapCols}px`);
                    if (this.props.ShowSizers) {
                        this.props.ShowSizers = false;
                        setImmediate(() => {
                            this.props.ShowSizers = true;
                        });
                    }
                    break;
                }
                case 'Height': this.setHeight(value); break;
                case 'Rows': this.setStyle('grid-template-rows', value); break;
                // case 'SizeBy': console.log('By', value); break;
                case 'ShowCells': value ? this.addClass('show-cells') : this.removeClass('show-cells'); break;
                case 'ShowSizers': value ? this.addSizers() : this.removeSizers(); break;
                case 'Width': this.setWidth(value); break;
            }
        }
    }

    onClick (evt) {
        if (!this.props.DesignMode) return;
        this.deselectAll();
        let target = evt.target;
        if (!UI.hasClass(target, 'foui-ctrl')) {
            // Select the control element.
            target = target.closest('.foui-ctrl');
            if (!target) return;
        }

        const id = target.id;
        for (const cell of this.meta.ControlList) {
            if (!cell.meta.ControlList.length) continue;
            const ctrl = cell.meta.ControlList.find(o => o.props.Name === id);
            if (ctrl) {
                ctrl.addClass('foui-design-select');
                this.meta.Selected = [ctrl];
                target.focus();
                UI.trigger(this.parent, CONTROL_EVENTS.LayoutSelect, ctrl);
                break;
            }
        }
    }

    /* onMouseDown (evt) {
        // let target = evt.target;
        if (evt.shiftKey) { // Selecting controls.
            //
        }
        else if (this.meta.Selected.length) { // Dragging selection.
            //
        }
    } */

    onDragStart (evt) {
        // console.log(evt.target);
        if (!UI.hasClass(evt.target, 'foui-ctrl')) return;
        // console.log(this.parent.props.Controls);
        // evt.dataTransfer.setData('foui/control-type-key', this.meta.type);
        evt.dataTransfer.setData('foui/control-type-key', 'DesignMode');
        // evt.dataTransfer.setData('foui/control-instance', JSON.stringify({ Source: 'Designer', Id: evt.target.id, ParentControls: this.parent ? (this.parent.props.Controls.toJSON ? this.parent.props.Controls.toJSON() : this.parent.props.Controls) : [] }));
        evt.dataTransfer.setData('foui/control-instance', JSON.stringify({ Source: 'LayoutGrid', SourceName: this.props.Name, ControlName: evt.target.id }));
        evt.dataTransfer.setDragImage(this.meta.DragImage, 10, 10);
    }

    onDragOver (evt) {
        // console.log('OVER');
        // const isDef = evt.dataTransfer.types.includes('foui/control-type-key');
        // if (isDef) {
        evt.preventDefault();
        evt.dataTransfer.dropEffect = 'copy';
        // }
    }

    onDragDrop (evt) {
        // Util.debounce(func, 50)
        // console.log('DROP');
        // console.log(evt.target);
        const inst = evt.dataTransfer.getData('foui/control-instance');
        const instance = inst ? JSON.parse(inst) : null;
        UI.removeClass(this.elem, 'foui-dragover');

        // console.log(evt.target);
        // Get the drop target and make sure it is a grid cell.
        let target = evt.target;
        if (!UI.hasClass(evt.target, 'foui-layout-grid-cell')) target = target.closest('foui-layout-grid-cell');
        if (!target) return;
        // Get the cell control instance.
        let newCell = false;
        const cell = this.FOUI.getControl(target.id);
        if (!cell) newCell = true;
        // Get the dropped control instance.
        const control = this.FOUI.getControl(instance.ControlName);
        if (!control || control.parent === cell) return;
        // Add the control UI to the drop cell container.
        target.append(UI.find(this.elem, `#${instance.ControlName}`));

        // Get the layout grid.
        const gridArea = cell.elem.style.gridArea;
        // Break it apart.
        const area = gridArea.split('/').map(o => o.trim());
        // Check if the span is auto.
        const gridArea2 = gridArea.endsWith('/ auto / auto') ? area.slice(0, 2).join(' / ') : gridArea;
        // If there is only one control defined, simply adjust the container location.
        const sourceCell = control.parent;
        if (sourceCell.props.Controls.length === 1) {
            sourceCell.Location = gridArea2;
        }
        // The container has multiple controls defined. Remove from it and add to the drop container.
        else {
            // Remove the definition from the original location.
            const cPos = sourceCell.props.Controls.indexOf(control);
            sourceCell.props.Controls.splice(cPos, 1);
            // Find the grid cell definition for the drop location.
            // const cell = this.props.Cells.find(o => o.Location === gridArea || o.Location === gridArea2);
            if (newCell) { // Nothing defined.
                // console.log(1, ctrlDef);
                this.props.Cells.push({ Location: gridArea, Controls: [{ Type: control.type, Props: control.props }] }); // { Type: 'Label', Props: { Text: ':LabelText', Class: 'foui-float-left' } }
            }
            else { // Grid cell definition found. Add the dropped control to it.
                // console.log(2);
                cell.props.Controls.push({ Type: control.type, Props: control.props }); // { Type: 'Label', Props: { Text: ':LabelText', Class: 'foui-float-left' } }
            }
        }
        // Sort the cells based on their location.
        this.props.Cells.sort((a, b) => {
            const aParts = a.Location.split('/').map(o => +o.trim());
            const bParts = b.Location.split('/').map(o => +o.trim());
            return aParts[0] - bParts[0] || aParts[1] - bParts[1]; // Sort by row and column.
        });
    }

    onDragEnter (evt) {
        const isDef = evt.dataTransfer.types.includes('foui/control-type-key');
        if (isDef) {
            evt.preventDefault();
            // UI.removeClass(this.elem, 'blank');
            // if (!UI.hasClass(this.elem, 'filled')) UI.addClass(this.elem, 'foui-dragover');
            UI.addClass(this.elem, 'foui-dragover');
        }
    }

    onDragLeave (evt) {
        const isDef = evt.dataTransfer.types.includes('foui/control-type-key');
        if (isDef) {
            // if (!UI.hasClass(this.elem, 'filled')) UI.removeClass(this.elem, 'foui-dragover');
            // if (!UI.hasClass(this.elem, 'filled')) UI.addClass(this.elem, 'blank');
            UI.removeClass(this.elem, 'foui-dragover');
        }
    }

    deselectAll () {
        for (const ctrl of this.meta.Selected) {
            ctrl.removeClass('foui-design-select');
        }
    }

    addSizers () {
        const grid = this.elem;
        // Get the latest style.
        const compStyles = window.getComputedStyle(grid);
        // Store the padding for later use when dragging.
        this.meta.padTop = parseFloat(compStyles.getPropertyValue('padding-top') || '0');
        this.meta.padLeft = parseFloat(compStyles.getPropertyValue('padding-left') || '0');
        // console.log(compStyles.getPropertyValue('grid-template-columns'));
        // console.log(compStyles.getPropertyValue('grid-template-rows'));
        // Get the defined rows and columns.
        this.cols = compStyles.getPropertyValue('grid-template-columns').split(' '); // firstGrid.style.gridTemplateColumns.split(' ');
        this.rows = compStyles.getPropertyValue('grid-template-rows').split(' '); // firstGrid.style.gridTemplateRows.split(' ');
        // Get the gap between columns.
        const colGap = grid.style.columnGap;
        const colGapN = parseFloat(colGap);
        // this.meta.data2.GapCols = colGapN;
        // Get the gap between rows.
        const rowGap = grid.style.rowGap;
        const rowGapN = parseFloat(rowGap);
        // this.meta.data2.GapRows = rowGapN;
        // Add sizer lines and tags to drag for sizing.
        let y = this.meta.padTop;
        let x = this.meta.padLeft;
        const w = grid.offsetWidth;
        const h = grid.offsetHeight;
        const tagSz = 15;
        const colTagShift = (colGapN > tagSz ? (colGapN - tagSz) / 2 : -((tagSz - colGapN) / 2)) - 1;
        const rowTagShift = (rowGapN > tagSz ? (rowGapN - tagSz) / 2 : -((tagSz - rowGapN) / 2)) - 1;
        // Columns.
        this.cols.forEach((col, i) => {
            const sz = parseFloat(col);
            x += isNaN(sz) ? 60 : sz;
            const line = UI.create('div', { class: 'foui-view-designer-sizer for-col', style: `height:${h}px;left:${x}px;width:${colGap};`, 'data-idx': i });
            const tag = UI.create('div', { class: 'foui-view-designer-tag for-col', style: `margin-left:${colTagShift}px;`, text: `${i + 1}` });
            const info = UI.create('div', { class: 'sz-info-val', text: col });
            tag.append(info);
            line.append(tag);
            grid.append(line);
            x += colGapN;
        });
        // Rows.
        this.rows.forEach((row, i) => {
            const sz = parseFloat(row);
            y += isNaN(sz) ? 30 : sz;
            const line = UI.create('div', { class: 'foui-view-designer-sizer for-row', style: `height:${rowGap};top:${y}px;width:${w}px;`, 'data-idx': i });
            const tag = UI.create('div', { class: 'foui-view-designer-tag for-row', style: `margin-top:${rowTagShift}px;`, text: `${i + 1}` });
            const info = UI.create('div', { class: 'sz-info-val', text: row });
            tag.append(info);
            line.append(tag);
            grid.append(line);
            y += rowGapN;
        });
        // const szRowItem = this.meta.data2.SettingsControls.find(o => o.Key === 'GapRows');
        // if (szRowItem) szRowItem.Value = rowGapN;
        UI.on(grid, 'mousedown', this.handlers.onSizerDown, true);
        // UI.on(firstGrid, 'mouseup', this.handlers.onSizerUp, true);
    }

    removeSizers () {
        const grid = this.elem;
        const sizers = UI.findAll(grid, '.foui-view-designer-sizer');
        sizers.forEach(s => {
            s.remove();
        });
        UI.off(grid, 'mousedown', this.handlers.onSizerDown, true);
        // UI.off(firstGrid, 'mouseup', this.handlers.onSizerUp, true);
    }

    onSizerDown (evt) {
        this.meta.isRow = UI.hasClass(evt.target, 'for-row');
        this.meta.isCol = UI.hasClass(evt.target, 'for-col');
        if (this.meta.isRow || this.meta.isCol) {
            evt.stopPropagation();
            evt.preventDefault();
            const grid = this.elem;
            this.meta.sizer = UI.hasClass(evt.target, 'foui-view-designer-sizer') ? evt.target : evt.target.parentNode;
            this.meta.szIdx = parseInt(this.meta.sizer.dataset.idx);
            this.meta.szMin = 0;
            this.meta.szMax = 0;
            if (this.meta.szIdx === 0) this.meta.szMin = this.meta.isRow ? this.meta.padTop : this.meta.padLeft;
            else {
                const prev = UI.find(grid, `.foui-view-designer-sizer.for-${this.meta.isRow ? 'row' : 'col'}[data-idx="${this.meta.szIdx - 1}"]`);
                const next = UI.find(grid, `.foui-view-designer-sizer.for-${this.meta.isRow ? 'row' : 'col'}[data-idx="${this.meta.szIdx + 1}"]`);
                this.meta.szMin = this.meta.isRow ? prev.offsetTop + prev.offsetHeight : prev.offsetLeft + prev.offsetWidth;
                this.meta.szMax = next ? (this.meta.isRow ? next.offsetTop - this.meta.sizer.offsetHeight : next.offsetLeft - this.meta.sizer.offsetWidth) : 10000;
            }
            this.isSizing = true;
            this.szMousePos = this.meta.isRow
                ? evt.y - this.meta.sizer.offsetTop
                : evt.x - this.meta.sizer.offsetLeft;
            // this.szMouseY = evt.offsetY;
            UI.on(window, 'mousemove', this.handlers.onSizerMove, true);
            UI.on(window, 'mouseup', this.handlers.onSizerUp, true);
        }
    }

    onSizerMove (evt) {
        UI.stopEvent(evt);
        const posPre = (this.meta.isRow ? evt.y : evt.x) - this.szMousePos;
        // const pos = posPre < this.meta.szMin ? this.meta.szMin : (posPre > this.meta.szMax ? this.meta.szMax : posPre);
        let pos = posPre < this.meta.szMin ? this.meta.szMin : posPre;
        const grid = this.elem;
        if (this.meta.isRow) {
            // Calculate the size difference since the last move.
            const prevSz = parseInt(this.rows[this.meta.szIdx]); // Previous size.
            const newSzPre = pos - this.meta.szMin; // New size.
            const newSz = this.props.SizeBy > 1
                ? Math.round(newSzPre / this.props.SizeBy) * this.props.SizeBy
                : newSzPre;
            const diff = newSz - prevSz; // Difference.
            this.rows[this.meta.szIdx] = `${newSz}px`; // Set the new size on the row definitions.
            grid.style.gridTemplateRows = this.rows.join(' '); // Update the layout grid.
            // Update the size info after the tag.
            const szInfo = UI.findAll(grid, '.foui-view-designer-sizer.for-row .sz-info-val')[this.meta.szIdx];
            szInfo.textContent = this.rows[this.meta.szIdx];
            // Shift the remaining row lines.
            const afterLines = UI.findAll(grid, '.foui-view-designer-sizer.for-row');
            afterLines.forEach(o => {
                if (parseInt(o.dataset.idx) > this.meta.szIdx) o.style.top = `${parseInt(o.style.top) + diff}px`;
            });
            // Adjust the slider to the SizeBy value.
            if (this.props.SizeBy > 1 && this.meta.szIdx > 0) {
                pos = parseInt(afterLines[this.meta.szIdx - 1].offsetTop) + parseInt(grid.style.rowGap) + newSz;
            }
            // Update the height of all the column lines.
            const cols = UI.findAll(grid, '.foui-view-designer-sizer.for-col');
            cols.forEach(o => {
                o.style.height = `${parseInt(o.style.height) + diff}px`;
            });
        }
        else if (this.meta.isCol) {
            // Calculate the size difference since the last move.
            const prevSz = parseInt(this.cols[this.meta.szIdx]); // Previous size.
            const newSzPre = pos - this.meta.szMin; // New size.
            const newSz = this.props.SizeBy > 1
                ? Math.round(newSzPre / this.props.SizeBy) * this.props.SizeBy
                : newSzPre;
            const diff = newSz - prevSz; // Difference.
            this.cols[this.meta.szIdx] = `${newSz}px`; // Set the new size on the row definitions.
            grid.style.gridTemplateColumns = this.cols.join(' '); // Update the layout grid.
            // Update the size info after the tag.
            const szInfo = UI.findAll(grid, '.foui-view-designer-sizer.for-col .sz-info-val')[this.meta.szIdx];
            szInfo.textContent = this.cols[this.meta.szIdx];
            // Shift the remaining row lines.
            const afterLines = UI.findAll(grid, '.foui-view-designer-sizer.for-col');
            afterLines.forEach(o => {
                if (parseInt(o.dataset.idx) > this.meta.szIdx) o.style.left = `${parseInt(o.style.left) + diff}px`;
            });
            // Adjust the slider to the SizeBy value.
            if (this.props.SizeBy > 1 && this.meta.szIdx > 0) {
                pos = parseInt(afterLines[this.meta.szIdx - 1].offsetLeft) + parseInt(grid.style.columnGap) + newSz;
            }
            // Update the height of all the row lines.
            const rows = UI.findAll(grid, '.foui-view-designer-sizer.for-row');
            rows.forEach(o => {
                o.style.width = `${parseInt(o.style.width) + diff}px`;
            });
        }
        this.meta.sizer.style[this.meta.isRow ? 'top' : 'left'] = `${pos}px`;
        // this.prop('Value', Math.round(pos / this.meta.unit));
    }

    onSizerUp (evt) {
        if (this.isSizing) {
            evt.stopPropagation();
            this.isSizing = false;
            UI.off(window, 'mousemove', this.handlers.onSizerMove, true);
            UI.off(window, 'mouseup', this.handlers.onSizerUp, true);
        }
    }

    setHeight (value) {
        super.setHeight(value);
        if (this.props.DesignMode) {
            // The official designer wraps the LayoutGrid in a Panel with the `foui-designer-panel` class. This need to be adjusted as well.
            const panel = this.elem.closest('.foui-designer-panel');
            if (!panel) return;
            /* if (value === 'auto') {
                UI.style(panel, 'width:inherit;height:inherit');
            } */
            if (typeof value === 'string' && value.endsWith('%')) {
                UI.style(panel, 'width:100%;height:100%');
            }
            else {
                UI.style(panel, 'width:inherit;height:inherit');
                // UI.style(panel, 'width:100%;height:inherit');
            }
        }
    }
}

// Control property definitions.
Object.defineProperty(LayoutGrid, '_DEF', {
    value: {
        Group: 'Containers',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            // Busy: { Default: undefined, Group: 'State', Label: 'Busy', Type: 'Flag', Note: 'Indicates the control is in a busy/processing state.' },
            Cells: { Default: undefined, Group: 'General', Label: 'Controls', Type: 'List', Note: 'Other control items "inside" this control.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Style Class', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            Columns: { Default: '1fr', Group: 'Layout', Label: 'Column Sizes', Type: 'Number', Note: 'Column sizes in order of appearance.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout into design mode.' },
            // Gap: { Default: '0px', Group: 'Size', Label: 'Gap Size', Type: 'Number', Note: 'Gap between the layout boxes.' },
            GapCols: { Default: 0, Group: 'Size', Label: 'Gap Columns', Type: 'Number', Note: 'Gap between the layout boxes.' },
            GapRows: { Default: 0, Group: 'Size', Label: 'Gap Rows', Type: 'Number', Note: 'Gap between the layout boxes.' },
            Height: { Default: '100%', Group: 'Size', Label: 'Width', Type: 'Number', Note: 'Height set as px or %.' },
            // Margins: { Default: undefined, Group: 'General', Label: 'Style', Type: 'Text', Note: 'Margins outside the control borders.' },
            // Padding: { Default: undefined, Group: 'General', Label: 'Style', Type: 'Text', Note: 'Spacing inside the control borders.' },
            Rows: { Default: '1fr', Group: 'Layout', Label: 'Row Sizes', Type: 'Number', Note: 'Row sizes in order of appearance.' },
            ShowCells: { Default: undefined, Group: 'General', Label: 'Show Cells', Type: 'Flag', Note: 'Highlights the layout cells.' },
            ShowSizers: { Default: undefined, Group: 'State', Label: 'Show Sizers', Type: 'Flag', Note: 'Toggle the visibility of the grid sizer controls.' },
            SizeBy: { Default: 10, Group: 'Size', Label: 'Size By', Type: 'Number', Note: 'The units to jump/snap to when sizing.' },
            // Tip: { Default: undefined, Group: 'General', Label: 'Tool Tip', Type: 'Any', Note: 'Information related to the control.' },
            // Visible: { Default: true, Group: 'State', Label: 'Visible', Type: 'Flag', Note: 'Visible or hidden state of the control.' },
            Width: { Default: '100%', Group: 'Size', Label: 'Width', Type: 'Number', Note: 'Width set as px or %.' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
