import Control from './control';
import Util from '../util/util';
import UI from '../util/ui';

export default class Tabs extends Control {
    /**
     * The Tabs is a multi-container.
     *
     * @param definition Control definition.
     */
    constructor (definition, extendWithProps) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'Tabs';
        definition.Props.ClassFixed = 'foui-tabs';
        super(definition, Tabs._DEF.Properties);
    }

    init () {
        const clsFixed = `foui-ctrl foui-control-panel foui-tabs foui-stretch-y ${this.props.ClassFixed || ''}`.trim();
        this.addClass(clsFixed);
        // Setting `this.meta.ClassFixed` only works if `Class` is handled in `onChange`.
        this.props.ClassFixed = clsFixed;
        UI.attr(this.elem, { tabindex: 0 });

        // this.ui.list = UI.create('div', { class: 'foui-list-list' });
        // // this.meta.ui.listPop.append(this.meta.ui.list);
        // this.elem.append(this.ui.list);

        this.ui.tabBox = UI.create('div', { class: 'foui-tabs-tab-box' });
        this.ui.contentBox = UI.create('div', { class: 'foui-tabs-content-box' });
        this.elem.appendChild(this.ui.tabBox);
        this.elem.appendChild(this.ui.contentBox);
    }

    attachEvents () {
        // Bind to this control.
        this.handlers.onTabClick = this.onTabClick.bind(this);
        // Attach the listener(s).
        UI.on(this.ui.tabBox, 'click', this.handlers.onTabClick, true); // mousedown
    }

    detachEvents () {
        UI.off(this.elem, 'click', this.handlers.onClick, true); // mousedown
        delete this.handlers;
    }

    onChange (changes) {
        for (const [key, v] of changes) {
            const value = Util.checkForBoundedValue(v, this);
            switch (key) {
                case 'Bordered': this.updateBordered(); break;
                case 'Class': {
                    this.setClass(value);
                    if (this.props.Bordered) this.addClass('bordered');
                    else this.removeClass('bordered');
                    break;
                }
                /* case 'Controls': {
                    if (value && value.length) {
                        for (const def of this.props.Controls) {
                            // const control = this.props.DesignMode ? def : Util.duplicate(def);
                            // const ctrl = new Controls[control.Type]({ Props: control.Props, Parent: this, Data: this.data, Code: this.code, $name: this.$name });
                            const ctrl = this.newControl(def);
                            this.meta.ControlList.push(ctrl);
                        }
                    }
                    break;
                } */
                /* case 'DesignMode': {
                    for (const ctrl of this.meta.ControlList) {
                        ctrl.props.DesignMode = value;
                    }
                    break;
                } */
                /* case 'Height': this.setHeight(value); break;
                case 'HTML': UI.attr(this.elem, { html: value }); break;
                case 'Text': UI.attr(this.elem, { text: value }); break;
                case 'Width': this.setWidth(value); break; */
                case 'Items': this.updateItems(value); break;
                // case 'Text': UI.attr(this.props.Icon ? this.ui.text : this.ui.button, { text: value }); break;
                case 'Value': {
                    // if (this.props.Value) this.addClass('foui-selected');
                    // else this.removeClass('foui-selected');
                    // this.selectByValue(value);
                    break;
                }
            }
        }
    }

    onTabClick (evt) {
        const target = !UI.hasClass(evt.target, 'foui-tabs-tab') ? evt.target.closest('.foui-tabs-tab') : evt.target;
        this.props.Value = target.dataset.key;
    }

    updateBordered () {
        if (this.props.Bordered) this.addClass('bordered');
        else this.removeClass('bordered');
    }

    updateItems (value) {
        // const fieldValue = this.props.FieldValue;
        const items = value || this.props.Items || [];
        /* const filterValue = this.prop('Filterable')
            ? this.meta.ui.input.value === '' ? null : new RegExp(this.meta.ui.input.value, 'gi')
            : null;
        const items = filterValue === null
            ? itemList
            : itemList.filter(o => o.Text.match(filterValue) !== null); // o.Key.startsWith(filterValue) || o.Text.startsWith(filterValue) */
        // Show that there are no properties.
        /* if (!items.length) {
            this.ui.list.innerHTML = '';
            // const tr = UI.create('tr', { 'data-idx': -1 });
            // const td = UI.create('td', { colSpan: 2, text: 'No properties.' });
            // tr.append(td);
            // this.ui.list.append(tr);
            // this.meta.oldItem = value;
            return;
        } */
        // if (!this.meta.oldItem) this.meta.oldItem = value;
        /* // If the item is new, unbind the previous listeners.
        if (value !== this.meta.oldItem) {
            // Clean the grid and watchers.
            const oKeys = Object.keys(this.meta.oldItem);
            for (let i = 0; i < oKeys.length; i++) {
                const key = oKeys[i];
                const input = this.meta.ControlList[key];
                input.props.unbindFrom(value, key, 'Value');
                value.unbindFrom(input.props, 'Value', key);
            }
            this.ui.list.innerHTML = '';
            this.meta.oldItem = value;
            this.ui.noteTitle.textContent = 'Property';
            this.ui.noteDescription.textContent = 'Description';
        } */
        // Create or update the list items.
        const selected = this.props.Value;
        console.log(selected, items);
        // const gridWidth = this.elem.offsetWidth;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            // const isObject = typeof item === 'object';
            // const key = isObject ? item[fieldKey] : item;
            // const text = isObject ? item[fieldText] || key : item;
            const tabElem = UI.find(this.ui.list, `div[data-key="${key}"]`);
            if (tabElem) { // If the property row exists, update it.
                console.log('TODO: List Key Row update', keyRow);
            }
            else { // Add a new property row.
                // const schema = this.props.Schema[key];
                /* const row = UI.create('div', { class: 'foui-list-item foui-text-truncate', 'data-idx': i, 'data-key': key });
                if (this.props.ItemRenderer) UI.attr(row, { html: this.props.ItemRenderer(i, item) });
                else UI.attr(row, { text });
                // if (canDrag) UI.attr(label, { draggable: true });
                if (selected === key || selected === text) {
                    UI.addClass(row, 'foui-list-item-selected');
                } */
                //
                /* const tr = UI.create('tr', { 'data-idx': i, 'data-prop': key });
                const keyProps = { html: schema ? schema.Label : key };
                if (i === 0) {
                    // Set the width on the first TD. Cannot hide the header without losing size in Chrome.
                    // keyProps.style = `width:${Math.floor(gridWidth / 2)}px;`;
                    keyProps.style = `width:50%;`;
                }
                const tdKey = UI.create('td', keyProps);
                tr.append(tdKey);

                const valueProps = { Class: 'pty-edit' }; // html: value[key],
                if (i === 0) {
                    // Firefox requires the width on all cells.
                    // valueProps.style = `width:${Math.floor(gridWidth / 2)}px;`;
                    valueProps.style = `width:50%;`;
                }
                const tdValue = UI.create('td', valueProps);
                tr.append(tdValue);
                // TODO: Control input type.
                let input;
                switch (schema.Type) {
                    case 'Any':
                    case 'Text':
                        input = new CoreInput({ Props: { Name: `_${key}_text`, ClassFixed: 'foui-text foui-stretch-y', Value: value[key] }, Parent: tdValue, Data: this.data, Code: this.code, $name: this.$name }); // Value: this.props.Value,
                        // input = new Text({ Props: { Name: `_${key}_text`, ClassFixed: 'foui-text foui-stretch-y', Value: value[key] }, Parent: tdValue, Data: this.data, Code: this.code, $name: this.$name }); // Value: this.props.Value,
                        break;
                    case 'Number':
                        input = new CoreInput({ Props: { Name: `_${key}_text`, ClassFixed: 'foui-text foui-stretch-y', Value: value[key], Type: 'number' }, Parent: tdValue, Data: this.data, Code: this.code, $name: this.$name });
                        break;
                    case 'Flag':
                        // input = new CoreInput({ Props: { Name: `_${key}_text`, ClassFixed: 'foui-text foui-stretch-y', Value: value[key], Type: 'checkbox' }, Parent: tdValue, Data: this.data, Code: this.code, $name: this.$name });
                        input = new Check({ Props: { Name: `_${key}_check`, Value: value[key], Text: '' }, GlobalHide: true, Parent: tdValue, Data: this.data, Code: this.code, $name: this.$name });
                        break;
                    case 'List':
                        input = new CoreInput({ Props: { Name: `_${key}_text`, ClassFixed: 'foui-text foui-stretch-y', Value: value[key] }, Parent: tdValue, Data: this.data, Code: this.code, $name: this.$name }); // Value: this.props.Value,
                        break;
                    case 'Object':
                        input = new CoreInput({ Props: { Name: `_${key}_text`, ClassFixed: 'foui-text foui-stretch-y', Value: value[key] }, Parent: tdValue, Data: this.data, Code: this.code, $name: this.$name }); // Value: this.props.Value,
                        break;
                }
                // Bind the two data objects.
                input.props.bindTo(value, key, 'Value');
                value.bindTo(input.props, 'Value', key);
                this.meta.ControlList[key] = input; */

                // this.ui.list.append(row);
            }
        }
    }
}

// Control property definitions.
Object.defineProperty(Tabs, '_DEF', {
    value: {
        Group: 'Containers',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            Bordered: { Default: undefined, Group: 'Style', Label: 'Bordered', Type: 'Flag', Note: 'Display a border around the control. Useful to disable when using this control inside another composite control.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Style Class', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            // ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            Contentless: { Default: false, Group: 'General', Label: 'Contentless', Type: 'Flag', Note: 'Only tab buttons with no switching body.' },
            Controls: { Default: undefined, Group: 'General', Label: 'Controls', Type: 'List', Note: 'Other control items "inside" this control.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout into design mode.' },
            // FieldValue: { Default: undefined, Group: 'Data', Label: 'Field Value', Type: 'Text', Note: 'Field name to use to as the record value, when using object/record Items.' },
            Height: { Default: undefined, Group: 'Size', Label: 'Width', Type: 'Number', Note: 'Height set as px or %.' },
            Items: { Default: undefined, Group: 'Data', Label: 'Items', Type: 'Text', Note: 'Tab definitions.' },
            // Padding: { Default: undefined, Group: 'General', Label: 'Style', Type: 'Text', Note: 'Spacing inside the control borders.' },
            Tip: { Default: undefined, Group: 'General', Label: 'Tool Tip', Type: 'Any', Note: 'Information related to the control.' },
            // Visible: { Default: true, Group: 'State', Label: 'Visible', Type: 'Flag', Note: 'Visible or hidden state of the control.' },
            Value: { Default: undefined, Group: 'State', Label: 'Value', Type: 'Any', Note: 'Selected tab name.' },
            Width: { Default: undefined, Group: 'Size', Label: 'Width', Type: 'Number', Note: 'Width set as px or %.' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
