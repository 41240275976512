// import Data from '../util/data';

// import { nanoid } from 'nanoid';

const listeners = new WeakMap();
const binders = new WeakMap();
const dispatch = Symbol('dispatch');
const isWatch = Symbol('isWatch');
const timer = Symbol('timer');
const isArray = Symbol('isArray');
const changes = Symbol('changes');
// const oid = Symbol('oid');
const ns = Symbol('ns');
const oname = Symbol('oname');
const oparent = Symbol('oparent');
// const dolinks = Symbol('dolinks');
const selfLinks = new Map(); // Handle property references on the same object.

/**
 * Public api
 * @type {Object}
 */
const API = {
    /**
     * Set a listener on any object function or array
     * @param   {Function} fn - callback function associated to the property to listen
     * @returns {API}
     */
    listen (...args) { // props, cb.
        let props = null;
        // let tags = null;
        let cb = null;
        const len = args.length;
        if (!len || len > 2) throw Error('Invalid arguments to Watch.listen.');
        if (len === 1) {
            props = '*';
            cb = args[0];
        }
        else {
            props = args[0];
            cb = args[1];
        }

        if (!cb) throw Error('The .listen method requires a callback function.');
        const type = typeof cb;
        if (type !== 'function') throw Error(`The Watch.listen method requires a callback "function", The provided type "${type}" is not allowed.`);
        if (!listeners.has(this)) listeners.set(this, []);
        // listeners.get(this).push(cb);
        listeners.get(this).push([props, cb]);

        return this;
    },

    /**
     * Unsubscribe to a property previously listened or to all of them
     * @param   {Function} fn - function to unsubscribe
     * @returns {API}
     */
    unlisten (cb) {
        const callbacks = listeners.get(this);
        if (!callbacks) return this;
        if (cb) {
            const index = callbacks.indexOf(cb);
            if (~index) callbacks.splice(index, 1);
        }
        else {
            listeners.set(this, []);
        }

        return this;
    },

    /**
     * Convert the Watch object into a valid JSON object
     * @returns {Object} - simple json object from a Proxy
     */
    toJSON () {
        return Object.keys(this).reduce((ret, key) => {
            const value = this[key];
            ret[key] = value && value.toJSON ? value.toJSON() : value;
            return ret;
        }, this[isArray] ? [] : {});
    },

    /**
     * Binds two watchable objects to each other on the given properties.
     *
     * @param otherObj Other watchable object.
     * @param otherProp Property on the other object to bind.
     * @param localProp Local property to bind.
     * @returns {API}
     */
    bindTo (otherObj, otherProp, localProp) {
        // console.log(otherProp, localProp);
        // if (!binders.has(this)) binders.set(this, new WeakMap());
        if (!binders.has(this)) binders.set(this, {});
        const o = binders.get(this);
        if (!o[localProp]) o[localProp] = [];
        const o2 = o[localProp];
        let bound = o2.find(o => o.obj === otherObj);
        if (!bound) {
            bound = { obj: otherObj, props: [] };
            o2.push(bound);
        }
        if (bound.props.indexOf(otherProp) > -1) return this; // Already bound.
        bound.props.push(otherProp);

        /* if (!o.has(otherObj)) o.set(otherObj, []);
        const bound = o.get(otherObj);
        if (bound.indexOf(localProp) === -1) */
        // if (!binders.get(otherObj)) this.binds.set(otherObj, {});

        // console.log('Binding|', otherProp, localProp);
        // Listen for changes on the props the other object is bound to.
        /* this.listen(changes => {
            // console.log('local >', changes, otherProp);
            if (changes.has(localProp) && otherObj[otherProp] !== changes.get(localProp)) {
                otherObj[otherProp] = changes.get(localProp);
                // Data.objSetProp(otherObj, otherProp, changes.get(localProp));
                // otherObj[dispatch](otherProp, Data.objGetProp(otherObj, otherProp));
            }
        });
        // Update self if the bound property of the other object changes.
        otherObj.listen(changes => {
            // console.log('other', changes, localProp);
            if (changes.has(otherProp) && this[localProp] !== changes.get(otherProp)) {
                this[localProp] = changes.get(otherProp);
                this[dispatch](localProp, this[localProp]);
                // Data.objSetProp(this, localProp, changes.get(otherProp));
                // this[dispatch](localProp, Data.objGetProp(this, localProp)); // this[localProp]
            }
        }); */
        return this;
    },

    /**
     * Unbinds two watchable objects from each other for the given properties.
     *
     * @param otherObj Other watchable object.
     * @param otherProp Property on the other object to unbind.
     * @param localProp Local property to unbind.
     */
    unbindFrom (otherObj, otherProp, localProp) {
        const o = binders.get(this);
        if (!o) return this; // Nothing to do. Already removed.
        if (!o[localProp]) return this; // Nothing to do. Already removed.
        const o2 = o[localProp];
        const bound = o2.find(o => o.obj === otherObj);
        if (!bound) return this; // Nothing to do. Already removed.
        const boundPos = o2.indexOf(bound);
        const pos = bound.props.indexOf(otherProp);
        if (pos > -1) return this; // Already removed.
        bound.props.splice(pos, 1);
        if (!bound.props.length) {
            o2.splice(boundPos, 1);
            if (!o2.length) delete o[localProp]; // Remove the property.
            if (!Object.keys(o).length) binders.delete(this); // Remove the object if no more properties.
        }
    },

    bindTo2 (otherObj, otherProp, localProp) {
        // if (!this.binds) this.binds = new Map();
        // if (!this.binds.get(otherObj)) this.binds.set(otherObj, {});

        // console.log('Binding|', otherProp, localProp);
        // Listen for changes on the props the other object is bound to.
        this.listen(changes => {
            // console.log('local >', changes, otherProp);
            if (changes.has(localProp) && otherObj[otherProp] !== changes.get(localProp)) {
                otherObj[otherProp] = changes.get(localProp);
                // Data.objSetProp(otherObj, otherProp, changes.get(localProp));
                // otherObj[dispatch](otherProp, Data.objGetProp(otherObj, otherProp));
            }
        });
        // Update self if the bound property of the other object changes.
        otherObj.listen(changes => {
            // console.log('other', changes, localProp);
            if (changes.has(otherProp) && this[localProp] !== changes.get(otherProp)) {
                this[localProp] = changes.get(otherProp);
                this[dispatch](localProp, this[localProp]);
                // Data.objSetProp(this, localProp, changes.get(otherProp));
                // this[dispatch](localProp, Data.objGetProp(this, localProp)); // this[localProp]
            }
        });
        return this;
    },

    bindSelf (prop1, prop2) {
        // Create maps in both directions to make lookup easy from either side.
        if (!selfLinks.has(prop1)) selfLinks.set(prop1, {});
        if (!selfLinks.has(prop2)) selfLinks.set(prop2, {});
        selfLinks.get(prop1)[prop2] = 1;
        selfLinks.get(prop2)[prop1] = 1;
        // console.log(selfLinks);
    },

    _parent () {
        return this[oparent];
    },

    _watching () { // For external.
        return true;
    },

    // Helper for debugging to print out the namespaced object tree.
    _mapOut (o) {
        if (o === undefined) o = this;
        const nsp = o[ns] === undefined ? '[root]' : o[ns];
        console.group(nsp);
        Object.keys(o).forEach(key => {
            if (typeof o[key] === 'object') this._mapOut(o[key]);
            else console.log(nsp === '[root]' ? key : `${nsp}.${key}`);
        });
        console.groupEnd(nsp);
    }
};

/**
 * Watch proxy handler
 * @type {Object}
 */
const WATCH_HANDLER = {
    set (target, property, value) {
        // console.log('SET', target, property, value);
        // filter the values that didn't change.
        /* if (typeof value === 'object' && Array.isArray(value)) {
            debugger;
        } */
        if (target[property] !== value) {
            if (value === Object(value) &&
                !value[isWatch] &&
                Object.prototype.toString.call(value) !== '[object Date]'
            ) { // Doing `Object(value)` instead of `typeof value === 'object'` because it caters for null.
                target[property] = Watch(value, property, target);
            }
            else {
                target[property] = value;
            }
            target[dispatch](property, value); // target[oname]
        }
        else if (typeof value === 'object' && !value[isWatch] && Object.prototype.toString.call(value) !== '[object Date]') { // Value is the same but not a watchable.
            target[property] = Watch(value, property, target);
        }

        return true;
    }
};

/**
 * Define a private property
 * @param   {*} obj - receiver
 * @param   {String} key - property name
 * @param   {*} value - value to set
 */
function define (obj, key, value) {
    Object.defineProperty(obj, key, {
        value: value,
        enumerable: false,
        configurable: false,
        writable: false
    });
}

function getFirstParentWithListener (nsList, obj) {
    if (!obj) return null;
    if (listeners.has(obj)) {
        if (obj[oname] !== undefined) nsList.unshift(obj[oname]);
        return { ns: nsList.join('.'), p: obj };
    }
    else if (obj[oparent]) {
        nsList.unshift(obj[oname]);
        return getFirstParentWithListener(nsList, obj[oparent]);
    }
    else return null;
}

function objGetProp (o, prop) {
    if (prop === undefined) return undefined;
    const pos = prop.indexOf('.'); // Check if a nested property.
    if (pos > -1) { // Nested property e.g. Object.Object.Value
        const startPart = prop.substring(0, pos); // get the first level name.
        if (o[startPart] !== undefined) { // Does the property exist.
            return objGetProp(o[startPart], prop.substring(pos + 1)); // Call self to go deeper.
        }
        return undefined; // The first level property does not exist.
    }
    return o[prop]; // Return the value.
}

function objSetProp (o, prop, value) {
    const pos = prop.indexOf('.'); // Check if a nested property.
    if (pos > -1) { // Nested property e.g. Object.Object.Value
        const startPart = prop.substring(0, pos); // get the first level name.
        // console.log(startPart);
        if (o[isNaN(startPart) ? startPart : +startPart] !== undefined) { // o[startPart] !== undefined
            // console.log('IN', o[isNaN(startPart) ? startPart : +startPart], prop.substring(pos + 1));
            objSetProp(o[isNaN(startPart) ? startPart : +startPart], prop.substring(pos + 1), value); // Call self to go deeper.
        }
        // else console.warn(startPart, o); // The first level property does not exist.
        return;
    }
    if (o === undefined) console.trace(prop, value);
    // if (value !== null && typeof value === 'object' && value._watching) return;
    // o[isNaN(prop) ? prop : +prop] = value.toJSON ? value.toJSON() : value; // Set the value.
    // console.log('else', isNaN(prop) ? prop : +prop);
    o[isNaN(prop) ? prop : +prop] = value; // Set the value.
}

/**
 * Get the root object of the passed in object.
 *
 * @param obj Object to check.
 * @returns {*} The parent object otherwise self.
 */
function getRoot (obj) {
    if (obj[oparent] === undefined) return obj;
    return getRoot(obj[oparent]);
}

/**
 * Enhance the Watch objects adding some hidden props to them and the API methods
 * @param   {*} obj - anything
 * @returns {*} the object received enhanced with some extra properties
 */
function enhance (obj, name, parent) {
    // add some "kinda hidden" properties
    /* Object.assign(obj, {
        [oname]: name,
        [oparent]: parent,
        [changes]: new Map(),
        [timer]: null,
        [isWatch]: true,
        [dispatch](property, value) {
            // console.log(this[oname], property, value);
            // if (property === 'Text' && value === 'Sales Tracking') debugger;
            // if (property === 'Text') debugger;
            // let checkParent = false;
            let objAct = obj;
            let namespace = property;
            let hasListener = listeners.has(obj);
            if (!hasListener) {
                // const isIndex = /^\d+$/.test(property); // Check for an index. TODO: parent must be an array.
                // console.log(namespace);
                const nsList = [obj[oname], property];
                const par = getFirstParentWithListener(nsList, obj[oparent]);
                if (par) {
                    // console.log(par);
                    hasListener = true;
                    namespace = par.ns.startsWith('.') ? par.ns.substr(1) : par.ns;
                    objAct = par.p;
                }
            }
            // console.log(namespace, value);
            if (namespace === 'Delimiter') console.log(namespace, value, hasListener, listeners.has(obj[oparent]), obj[oparent]);
            // if (/^\d+$/.test(property)) { // Check for an index. TODO: parent must be an array.
                // console.log(this[oname], obj, this[oparent]);
                // obj = this[oparent];
                // checkParent = true;
            // }
            if (hasListener) {
                clearImmediate(objAct[timer]);
                // objAct[changes].set(property, { _ns: namespace, _v: value });
                objAct[changes].set(namespace, value);
                objAct[timer] = setImmediate(() => {
                    const props = [...objAct[changes].keys()];
                    // console.log(listeners);
                    if (!props.length) return;
                    // console.log(this[oname], props, [...objAct[changes].values()]);
                    // listeners.get(objAct).forEach(function(cb) { cb(objAct[changes]) });
                    listeners.get(objAct).forEach(pcb => { // Props and callback.
                        const cb = pcb[1];
                        // console.log(pcb[0]);
                        if (pcb[0] === '*') cb(objAct[changes]);
                        else {
                            // Check if there are any listeners for the specific properties.
                            for (const prop of pcb[0]) {
                                if (~props.indexOf(prop)) {
                                    // console.log('MATCH', prop);
                                    // const tag = pcb[1] ? pcb[1][pcb[0].indexOf(prop)] : undefined;
                                    cb(objAct[changes]);
                                }
                            }
                            // Make a new `changes`
                        }
                    });
                    objAct[changes].clear();
                });
            }
        }
    }); */
    Object.assign(obj, {
        // [oid]: nanoid(),
        [oname]: name,
        [ns]: parent && parent[oname] ? `${parent[ns]}.${name}` : name,
        [oparent]: parent,
        [changes]: new Map(),
        [timer]: null,
        [isArray]: Array.isArray(obj),
        [isWatch]: true,
        [dispatch] (property, value) {
            let objAct = obj;
            let namespace = property;
            let hasListener = listeners.has(objAct);
            // console.log('Enhance|', property, 'hasListener', hasListener, objAct[oname], objAct[oparent]);
            do {
                // Handle the current object listener.
                if (hasListener) {
                    clearImmediate(objAct[timer]);
                    objAct[changes].set(namespace, value);
                    objAct[timer] = setImmediate(() => {
                        const props = [...objAct[changes].keys()];
                        if (!props.length) return;
                        listeners.get(objAct).forEach(pcb => { // Props and callback.
                            const cb = pcb[1];
                            if (pcb[0] === '*') cb(objAct[changes]);
                            else {
                                // Check if there are any listeners for the specific properties.
                                for (const prop of pcb[0]) {
                                    if (~props.indexOf(prop)) {
                                        cb(objAct[changes]);
                                    }
                                }
                            }
                        });
                        objAct[changes].clear();
                        // console.log(binders);
                    });
                }
                /* else {
                    console.log('NO|', property, objAct, oname, oparent);
                    console.log('Enhance NO|', property, 'hasListener', hasListener, objAct[oname], objAct[oparent]);
                } */
                // console.log(property);
                if (selfLinks.has(property)) {
                    const update = selfLinks.get(property);
                    const keys = Object.keys(update);
                    for (const key of keys) {
                        // console.log(key);
                        objSetProp(getRoot(objAct), key, value);
                    }
                }
                // Check if another object property is bound to this one.
                if (binders.has(objAct)) {
                    const bound = binders.get(objAct);
                    // console.log('Binders...', namespace, bound);
                    const dotPos = namespace.indexOf('.');
                    if (bound[namespace] !== undefined || (dotPos > -1 && bound[namespace.substring(0, dotPos)] !== undefined)) {
                        // console.log(property, bound[property]);
                        const boundInstances = dotPos === -1 ? bound[property] : bound[namespace.substring(0, dotPos)];
                        if (dotPos > -1) {
                            // Strip the first part since it is the name of the affected bound property.
                            const lastNamespace = namespace.substring(dotPos + 1);
                            // console.log(namespace, lastNamespace, bound);
                            for (const b of boundInstances) {
                                for (const p of b.props) {
                                    // Replace the starting mapped property name with the target property name.
                                    // console.log('...', b, `${p}.${lastNamespace}`, value);
                                    objSetProp(b.obj[p], lastNamespace, value);
                                    // b.obj[dispatch](p, objAct[namespace.substring(0, dotPos)]);
                                    b.obj[dispatch](namespace, value);
                                }
                            }
                            /* const parts = namespace.split('.');
                            for (const p of parts) {
                                //
                            }
                            // Only action if the last part is not an index.
                            if (isNaN(namespace.substring(lastPos + 1))) {
                                console.log('...', bv, namespace, value);
                                objSetProp(bv, namespace, value);
                            } */
                        }
                        else {
                            for (const b of boundInstances) {
                                // console.log('Bound', b);
                                for (const p of b.props) {
                                    // console.log('...', b.obj, p, value);
                                    objSetProp(b.obj, p, value);
                                }
                            }
                        }
                        /* let yes = true;
                        if (dotPos > -1) {
                            const lastPos = namespace.lastIndexOf(',');
                            // Only action if the last part is not an index.
                            if (isNaN(namespace.substring(lastPos + 1))) {
                                yes = false;
                                console.log('...', bv, namespace, value);
                                objSetProp(bv, namespace, value);
                            }
                        }
                        if (yes) {
                            for (const b of bv) {
                                // console.log('Bound', b);
                                for (const p of b.props) {
                                    // console.log('...', b.obj, p, value);
                                    objSetProp(b.obj, p, value);
                                }
                            }
                        } */
                    }
                }
                // Check for a parent object and listener.
                hasListener = false;
                // console.log(property);
                if (typeof property === 'string' && property.indexOf('.') === -1) {
                    const nsList = [objAct[oname], property];
                    const par = getFirstParentWithListener(nsList, objAct[oparent]);
                    if (par) {
                        // if (property === 'LocationType') console.log(property, par);
                        hasListener = true;
                        namespace = par.ns.startsWith('.') ? par.ns.substring(1) : par.ns;
                        objAct = par.p;
                    }
                }
            } while (hasListener);
        }
    });

    // Add the API methods bound to the original object
    Object.keys(API).forEach(key => {
        define(obj, key, API[key].bind(obj));
    });

    return obj;
}

/**
 * Factory function
 * @param   {*} obj - anything can be an Watch Proxy
 * @returns {Proxy}
 */
export default function Watch (obj, name, parent = undefined) {
    /* return new Proxy(
        enhance(obj || {}),
        Object.create(WATCH_HANDLER)
    ); */
    if (typeof obj !== 'object') return obj;
    // console.log({ obj, name, parent });
    // console.log({ name, parent });

    // Create a new watchable object.
    const o = new Proxy(
        // enhance(Array.isArray(obj) ? [] : {}, name, parent),
        enhance(obj, name, parent),
        Object.create(WATCH_HANDLER)
    );
    // Loop the keys of the original object and set the value on the watchable.
    if (Array.isArray(obj)) { // Array - set by index.
        for (let i = 0; i < obj.length; i++) {
            o[i] = obj[i];
        }
    }
    else { // Object - set by key.
        for (const [key, value] of Object.entries(obj)) {
            o[key] = value;
        }
    }
    return o;
};
