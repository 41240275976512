import Control from './control';
import Util from '../util/util';
// import UI from '../util/ui';

export default class LayoutGridCell extends Control {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'LayoutGridCell';
        definition.Props.ClassFixed = 'foui-layout-grid-cell';
        super(definition, LayoutGridCell._DEF.Properties);
    }

    init () {
        // this.meta.ClassFixed = 'foui-layout-grid-cell';
        // this.addClass('foui-layout-grid');
        this.meta.ControlList = [];
    }

    onChange (changes) {
        // console.log('LayoutGridCell changes', changes);
        for (const [key, v] of changes) {
            const value = Util.checkForBoundedValue(v, this);
            switch (key) {
                case 'Class': this.setClass(value); break;
                case 'Controls': {
                    this.meta.ControlList = [];
                    if (value && value.length) {
                        for (let i = 0; i < value.length; i++) {
                            const def = value[i];
                            const ctrl = this.newControl(def);
                            this.meta.ControlList.push(ctrl);
                        }
                        this.removeClass('foui-empty-cell');
                    }
                    else {
                        this.addClass('foui-empty-cell');
                    }
                    // if (this.props.Controls.length) console.log(this.props.Controls);
                    break;
                }
                case 'DesignMode': {
                    for (const ctrl of this.meta.ControlList) {
                        ctrl.props.DesignMode = value;
                    }
                    break;
                }
                case 'Location': this.setStyle('grid-area', value); break;
            }
        }
    }
}

// Control property definitions.
Object.defineProperty(LayoutGridCell, '_DEF', {
    value: {
        Group: 'Containers',
        // Note: '',
        // Icon: '',
        Design: 'NONE',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            // Busy: { Default: undefined, Group: 'State', Label: 'Busy', Type: 'Flag', Note: 'Indicates the control is in a busy/processing state.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Style Class', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            Controls: { Default: undefined, Group: 'General', Label: 'Controls', Type: 'List', Note: 'Other control items "inside" this control.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout panel into design mode.' },
            Location: { Default: '1 / 1', Group: 'Layout', Label: 'Location', Type: 'Text', Note: 'Row start / column start / row end / column end.' },
            // Margins: { Default: undefined, Group: 'General', Label: 'Style', Type: 'Text', Note: 'Margins outside the control borders.' },
            // Padding: { Default: undefined, Group: 'General', Label: 'Style', Type: 'Text', Note: 'Spacing inside the control borders.' },
            // Tip: { Default: undefined, Group: 'General', Label: 'Tool Tip', Type: 'Any', Note: 'Information related to the control.' },
            // Visible: { Default: true, Group: 'State', Label: 'Visible', Type: 'Flag', Note: 'Visible or hidden state of the control.' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
