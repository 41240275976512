export default {
    lang: 'af',
    shared: {
        ThemeLang: 'Tema & Taal',
    },
    views: {
        ViewDemo: {
            OctoCount: 'Octo Nommer Teller',
        // },
        // AddConnectionType: {
            Title: 'Connection Type',
            Info: 'Select the connection type to configure and add to the project.',
            File: 'File',
            FileDesc: 'A file can be opened from a local location, network drive or FTP server.',
            Service: 'Service',
            ServiceDesc: 'Webservices that output XML or REST data.',
            Queue: 'Queue',
            QueueDesc: 'Message queue system, similar to a webservice.',
            Database: 'Database',
            DatabaseDesc: 'Direct database connection. Only server side.',
        }
    }
};
