export default {
    cssVars: {
        /* Main colours */
        'primary': '#FEFEFE',
        'primary2': '#FFF',
        'secondary': '#222',
        'dull': '#F8F8F8',
        'success': '#4CAF50',
        'warning': '#FAA05A',
        'danger': '#F44336',
        'info': '#39F',

        'primary-text': '#00AEF3',
        'primary2-text': '#00AEF3',
        'secondary-text': '#FFF',
        'dull-text': '#AAA',
        'success-text': '#FFF',
        'warning-text': '#FFF',
        'danger-text': '#FFF',
        'info-text': '#FFF',

        /* Text */
        'text-main': '#DDFEFF',
        'text-opposite': '#000',
        'text-disabled': '#CCC',
        'text-dull': '#AAA',
        'text-input': '#FFF',

        /* Backgrounds */
        'theme-input': '#404040',
        'theme-window': '#424242',
        'back-main': '#00AEF3', /* Default background colour */
        'back-opposite': '#222', /* Default text colour for default background */
        'back-disabled': '#555', /* Default disabled background colour */
        'back-input': '#00AEF3',

        'back-gen': 'var(--back-main)',
        'text-gen': 'var(--text-main)',

        'border': '#FFF',
        /* border2: #E8E8E8; */
        'border-hover': '#D0E8FF',

        /* Font */
        'font-stack': '"Source Sans Pro", Roboto, sans-serif',
        'font-size': '11pt',
        'font-system': '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
        'font-gen': 'var(--font-size) var(--font-stack)',

        'scroller-width': '12px',
        'scroller-tag': 'rgba(255, 255, 255, 0.8)',
        'scroller-track': 'transparent',

        'box-shadow-duration': '0.1s',
        'global-small-box-shadow': '2px 3px 0 rgba(255,255,255,0.3)',
        'global-medium-box-shadow': '5px 6px 0 rgba(255,255,255,0.3)',
        'global-large-box-shadow': '10px 12px 0 rgba(255,255,255,0.6)',
        'global-xlarge-box-shadow': '20px 22px 0 rgba(255,255,255,0.6)',

        /* Lines */
        'focus-border-thick': '3px',
        'focus-border': '0 0 0 1px #FFF, 0 0 0 3px var(--primary2)',

        /* Button */
        'button-back': 'var(--border)',
        'button-border': '0', /* 1px solid #CACACA; */
        'button-color': 'var(--back-main)',
        'button-radius': '3px',
        'button-padding': '2px 6px',

        /* Check */
        'check-box-size': '20px',
        'check-back': 'var(--back-input)',
        'check-border': '0 0 0 1px var(--border)', /* Shadow */
        'check-color': 'var(--text-input)',
        'check-color-check': 'var(--text-input)',
        'check-radius': '0',
        'check-padding': '0 5px',
        'check-mark-left': '4px',
        'check-mark-top': '-1px',
        'check-mark-width': '6px',
        'check-mark-height': '11px',
        'check-mark-thick': '2px',

        /* Option / Radio */
        'option-box-size': '20px',
        'option-back': 'var(--back-input)',
        'option-border': '0 0 0 1px var(--border)', /* Shadow */
        'option-color': 'var(--text-input)',
        'option-color-check': 'var(--text-input)',
        'option-radius': '50%',
        'option-padding': '0 5px',
        'option-pos': '25%',
        'option-size': '50%',

        /* Select */
        'select-back': 'var(--back-input)',
        'select-back-filter': 'var(--back-input)',
        'select-back-filter-sep': 'var(--border)',
        'select-border': '0 0 0 1px var(--border)', /* Shadow */
        'select-border-hover': '0 0 0 1px var(--border-hover)', /* Shadow */
        'select-button-color': 'transparent',
        'select-ico-color': 'var(--text-input)',
        'select-color': 'var(--text-input)',
        'select-color-item': 'var(--text-input)',
        'select-radius': '3px',
        'select-pad-row': '4px',
        'select-pad-side': '5px',
        'select-list-border-shadow': '0 0 0 1px var(--border), var(--global-medium-box-shadow)',

        /* Text */
        'text-back': 'var(--back-input)',
        'text-border': '0 0 0 1px var(--border)', /* Shadow */
        'text-border-hover': '0 0 0 1px var(--border-hover)', /* Shadow */
        'text-color': 'var(--text-input)',
        'text-radius': '3px',
        'text-pad-side': '5px',
    },
    svg: {}
};
