// NPM
// import Axios from 'axios';
// import PubSub from 'pubsub-js';
// Local
// import AlertEngine from './util/alertEngine';
import App from './app';
// import Auth from './util/auth';
import Controls from './control';
// import PopupEngine from './util/popupEngine';
// import Time from './util/time';
// import WSClient from './util/wsClient';
import UI from './util/ui';
import Util from './util/util';
// import ViewNavigator from './util/viewNavigator';
import Watch from './util/watch';

import './theme/default/index.css';

export default {
    // NPM
    // Axios,
    // PubSub,
    // Local
    // AlertEngine,
    App,
    // Auth,
    Controls,
    // PopupEngine,
    // Time,
    // WSClient,
    UI,
    Util,
    // ViewNavigator,
    Watch
};
