import Control from './control';
import UI from '../util/ui';
// import Util from '../util/util';

export default class Popup extends Control {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'Popup';
        // definition.Props.ClassFixed = 'foui-ctrl foui-control-panel foui-stretch-y';
        super(definition, Popup._DEF.Properties);
    }

    init () {
        // Setting `this.meta.ClassFixed` only works if `Class` is handled in `onChange`.
        this.addClass('foui-ctrl foui-control-panel foui-popup');
        this.props.ClassFixed = 'foui-ctrl foui-control-panel foui-popup';

        this.ui.popControl = this.meta.Meta.PopControl;
        this.elem.append(this.ui.popControl.elem || this.ui.popControl);
    }

    attachEvents () {
        // Bind to this control.
        this.handlers.onAction = this.onAction.bind(this);
        // Attach the listener(s).
        UI.on(this.ui.popControl, this.meta.Meta.CloseOnEvent, this.handlers.onAction, true);
    }

    detachEvents () {
        UI.off(this.ui.popControl, this.meta.Meta.CloseOnEvent, this.handlers.onAction, true);
    }

    onChange (changes) {
        // console.log('Popup changes', changes);
        for (const [key, v] of changes) {
            // const value = Util.checkForBoundedValue(v, this);
            switch (key) {
                // case 'Disabled': UI.attr(this.ui.button, { disabled: v }); break;
                // case 'HTML': UI.attr(this.props.Icon ? this.ui.text : this.ui.button, { html: value }); break;
                // case 'Items': this.updateItems(value); break;
                // case 'Text': UI.attr(this.props.Icon ? this.ui.text : this.ui.button, { text: value }); break;
                // case 'Value': {
                //     this.selectByValue(value);
                //     break;
                // }
                case 'Width': this.updateWidth(); break;
            }
        }
    }

    onAction (evt) {
        // console.log(evt.detail);
        if (this.meta._props.Events && this.meta._props.Events.Close) {
            // TODO: Check for the PreCloseValidate and run it to check if can close.
            this.meta._props.Events.Close(evt);
        }
    }

    updateWidth () {
        let value = this.props.Width;
        if (value === '' || value === undefined) {
            UI.attr(this.elem, { style: 'flex-basis:inherit' });
            UI.attr(this.elem, { style: 'min-width:inherit' });
            return;
        }
        if (typeof value === 'number') value = `${value}px`;
        UI.attr(this.elem, { style: `flex-basis:${value}` });
        UI.attr(this.elem, { style: `min-width:${value}` });
    }
}

// Control property definitions.
Object.defineProperty(Popup, '_DEF', {
    value: {
        Group: 'Popup & Menu',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Class (Style)', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            // DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout panel into design mode.' },
            // Disabled: { Default: undefined, Group: 'General', Label: 'Disabled', Type: 'Flag', Note: 'Disables the control.' },
            // HTML: { Default: undefined, Group: 'General', Label: 'HTML', Type: 'Text', Note: 'HTML code as content.' },
            // Items: { Default: undefined, Group: 'Data', Label: 'Items', Type: 'Text', Note: 'A list of data items.' },
            // Text: { Default: undefined, Group: 'General', Label: 'Text', Type: 'Text', Note: 'Text value to display.' },
            // Tip: { Default: undefined, Group: 'General', Label: 'Tool Tip', Type: 'Any', Note: 'Information related to the control.' },
            // Value: { Default: undefined, Group: 'State', Label: 'Value', Type: 'Flag', Note: 'True/False value state if the button Toggle property is set to true.' },
            Width: { Default: undefined, Group: 'Style', Label: 'Width', Type: 'Any', Note: 'Fixed control width. Clear (leave blank) to allow the control to use the maximum available space.' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
