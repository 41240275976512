import Control from './control';
// import Util from '../util/util';
// import UI from '../util/ui';
import PropertyConstants from '../constants/property-constants';

export default class Check extends Control {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'Check';
        // definition.Props.ClassFixed = 'foui-ctrl foui-control-panel foui-stretch';
        super(definition, Check._DEF.Properties);
    }

    init () {
        this.addClass('foui-ctrl foui-control-panel foui-stretch');
        // , Map: { Control: 'label', Prop: 'Text' }
        // Name starts with _ so that it won't be added to the $name lookups.
        this.meta._inputId = `_${this.props.Name}_input`;
        this.ui.input = this.newControl({ Type: 'CoreInput', Props: { Name: this.meta._inputId, Type: 'checkbox', ClassFixed: 'foui-check-noview', Value: this.props.Value } });
        this.bindControl(this.ui.input, [
            { other: 'Value', local: 'Value' },
        ]);
        if (this.props.Value) this.ui.input.checked = true;
        this.ui.check = this.newControl({ Type: 'CoreLabel', Props: { Name: `_${this.props.Name}_check`, Text: '', HTML: this.FOUI.Themes[this.FOUI.ThemeKey].svg.check, For: this.meta._inputId, ClassFixed: 'foui-check-label-ico' } });
        this.ui.label = this.newControl({ Type: 'CoreLabel', Props: { Name: `_${this.props.Name}_label`, Text: this.props.Text, For: this.meta._inputId, ClassFixed: 'foui-check-label' } });
        this.bindControl(this.ui.label, [
            { other: 'Text', local: 'Text' },
        ]);
    }

    /* onChange (changes) {
        for (const [key, v] of changes) {
            switch (key) {
                case 'DesignMode': {
                    const value = Util.checkForBoundedValue(v, this);
                    if (value) {
                        // UI.attr(this.ui.input, { tabindex: -1, 'class+': 'unclickable' });
                        // UI.attr(this.ui.check, { tabindex: -1, 'class+': 'unclickable' });
                        // UI.attr(this.ui.label, { tabindex: -1, 'class+': 'unclickable' });
                        // this.ui.check.props.For = '';
                        // this.ui.label.props.For = '';
                        UI.attr(this.elem, { draggable: true });
                        this.eventsOff();
                    }
                    else {
                        // UI.attr(this.ui.input, { tabindex: 0, 'class-': 'unclickable' });
                        // UI.attr(this.ui.check, { tabindex: 0, 'class-': 'unclickable' });
                        // UI.attr(this.ui.label, { tabindex: 0, 'class-': 'unclickable' });
                        // this.ui.check.props.For = this.meta._inputId;
                        // this.ui.label.props.For = this.meta._inputId;
                        // UI.attr(this.elem, { draggable: false });
                        this.eventsOn();
                    }
                    break;
                }
            }
        }
    } */
}

// Control property definitions.
Object.defineProperty(Check, '_DEF', {
    value: {
        Group: 'Common Controls',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            AlignHorizontal: { Default: 'Start', Group: 'Alignment', Label: 'Align Horizontal', Type: 'List', Items: PropertyConstants.ALIGN_HORIZONTAL, Note: 'Text horizontal alignment.' },
            AlignVertical: { Default: 'Middle', Group: 'Alignment', Label: 'Align Vertical', Type: 'List', Items: PropertyConstants.ALIGN_VERTICAL, Note: 'Text vertical alignment.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Style Class', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout panel into design mode.' },
            Disabled: { Default: undefined, Group: 'General', Label: 'Disabled', Type: 'Flag', Note: 'Disables the control.' },
            ReadOnly: { Default: undefined, Group: 'General', Label: 'Read Only', Type: 'Flag', Note: 'Prevents input.' },
            Text: { Default: 'Check', Group: 'General', Label: 'Text', Type: 'Text', Note: 'Text value to display.' },
            Tip: { Default: undefined, Group: 'General', Label: 'Tool Tip', Type: 'Any', Note: 'Information related to the control.' },
            Value: { Default: undefined, Group: 'General', Label: 'Value', Type: 'Flag', Note: 'True/False value state.' },
            // Visible: { Default: true, Group: 'State', Label: 'Visible', Type: 'Flag', Note: 'Visible or hidden state of the control.' },
            // TabIndex
            // Margins: { default: '0', group: 'General', label: 'Style', type: 'Text', note: 'Margins outside the control borders.' },
            // Size: { default: 'FitContainer', group: 'General', label: 'Size', type: 'List', items: PropertyConstants.SIZE, note: 'Size behaviour.' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
