import CoreBase from './base';

export default class CoreLabel extends CoreBase {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'CoreLabel';
        definition.Props.Tag = 'label';
        super(definition, CoreLabel._DEF.Properties);

        // this.init(definition);

        // console.log('Label', definition);

        /* setTimeout(() => {
            this.props.Text = 'Lucy Smit';
        }, 2000); */
    }

    /* onChange (changes) {
        console.log('Label changes', changes);
    } */

    /* init (definition) {
        //
    } */
}

// Control property definitions.
Object.defineProperty(CoreLabel, '_DEF', {
    value: {
        Group: 'Core Controls',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.', Attr: 'id' },
            Class: { Default: undefined, Group: 'Style', Label: 'Style Class', Type: 'Text', Note: 'Style class name(s) to visually format the control.', Attr: 'class' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.', Attr: 'class' },
            Disabled: { Default: undefined, Group: 'General', Label: 'Disabled', Type: 'Flag', Note: 'Disables the control.', Attr: 'disabled' },
            For: { Default: undefined, Group: 'General', Label: 'For Control', Type: 'Text', Note: 'Name of control to focus on click.', Attr: 'for' },
            HTML: { Default: undefined, Group: 'General', Label: 'HTML', Type: 'Text', Note: 'HTML code as content.', Attr: 'html' },
            TabIndex: { Default: undefined, Group: 'General', Label: 'Tab Index', Type: 'Number', Note: 'Indicates that the control can be focused.', Attr: 'tabindex' },
            Text: { Default: undefined, Group: 'General', Label: 'Text', Type: 'Text', Note: 'Text value to display.', Attr: 'text' },
            Tip: { Default: undefined, Group: 'General', Label: 'Tool Tip', Type: 'Any', Note: 'Information related to the control.', Attr: 'title' },
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
