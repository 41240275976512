import Control from './control';
// import Util from '../util/util';
import UI from '../util/ui';

export default class HeaderLine extends Control {
    constructor (definition) {
        if (!definition) definition = {};
        if (!definition.Props) definition.Props = {};
        definition.Type = 'HeaderLine';
        // definition.Props.ClassFixed = 'foui-ctrl foui-control-panel foui-stretch';
        super(definition, HeaderLine._DEF.Properties);
    }

    init () {
        // Setting `this.meta.ClassFixed` only works if `Class` is handled in `onChange`.
        this.addClass('foui-ctrl foui-control-panel foui-stretch foui-header-line');
        this.props.ClassFixed = 'foui-ctrl foui-control-panel foui-stretch foui-header-line';
        // Name starts with _ so that it won't be added to the $name lookups.
        this.ui.text = this.newControl({ Type: 'CoreLabel', Props: { Name: `_${this.props.Name}_text`, ClassFixed: 'foui-header-line-title-label' } });
        this.bindControl(this.ui.text, [
            { other: 'Class', local: 'Class' },
            { other: 'Text', local: 'Text' },
        ]);

        // this.meta.ui.label = UI.create('label', { class: 'foui-header-line-title-label', text: this.prop('Text') });
        this.ui.pattern = UI.create('span', { class: 'foui-header-line-title-pattern', text: ' ' });
        // this.elem.appendChild(this.meta.ui.label);
        this.elem.appendChild(this.ui.pattern);
    }

    /* onChange (changes) {
        // console.log('Text changes', changes);
        for (const [key, v] of changes) {
            const value = Util.checkForBoundedValue(v, this);
            switch (key) {
                case 'DesignMode': {
                    if (value) {
                        // UI.attr(this.ui.text.elem, { tabindex: -1, 'class+': 'unclickable' });
                        UI.attr(this.elem, { draggable: true });
                        this.eventsOff();
                    }
                    else {
                        // UI.attr(this.ui.text.elem, { tabindex: 0, 'class-': 'unclickable' });
                        UI.attr(this.elem, { draggable: false });
                        this.eventsOn();
                    }
                    break;
                }
            }
        }
    } */
}

// Control property definitions.
Object.defineProperty(HeaderLine, '_DEF', {
    value: {
        Group: 'Common Controls',
        // Note: '',
        // Icon: '',
        Design: 'UI',
        Properties: {
            Name: { Default: undefined, Group: 'General', Label: '(Name)', Type: 'Text', Note: 'Name of the control that can be referenced in code.' },
            Class: { Default: undefined, Group: 'Style', Label: 'Style Class', Type: 'Text', Note: 'Style class name(s) to visually format the control.' },
            ClassFixed: { Default: undefined, Group: 'Style', Label: 'Fixed Class', Type: 'Text', Note: 'Style class name(s) that don\'t change when Class changes.' },
            DesignMode: { Default: undefined, Group: 'General', Label: 'Design Mode', Type: 'Flag', Note: 'Puts the layout panel into design mode.' },
            Text: { Default: 'Header', Group: 'General', Label: 'Text', Type: 'Text', Note: 'Text value to display.' },
            // Visible: { Default: true, Group: 'State', Label: 'Visible', Type: 'Flag', Note: 'Visible or hidden state of the control.' },
            Width: { Default: undefined, Group: 'Style', Label: 'Width', Type: 'Any', Note: 'Fixed control width. Clear (leave blank) to allow the control to use the maximum available space.' },
            // MaxLength
            // PasswordPeak
            // TabIndex
        }
    },
    writable: false,
    enumerable: true,
    configurable: false
});
